// import SriLankaNationalLogo from "./SL_Logo.png";
import { SectionColumn } from "../../layouts/sections";

export default function LogoHeader() {
	return (
		<div className='mx-auto'>
			<div className='logoImage'>
				<img src='./SL_Logo.png' alt='Picture of the author' width={60} height={80} />
			</div>
			<SectionColumn>
				<span className='docText'>Export Development Board</span>
				<span className='docSubText'>National Organic Control Unit</span>
			</SectionColumn>
		</div>
	);
}
