import React, { lazy, useEffect, useState } from "react";
import Navigation from "./layouts/navigation";
import Section, { SectionColumn } from "./components/section";
import Drawer from "./components/drawer/Drawer";
import OverView from "./features/overview/OverView";
import InquiryTable from "./features/inquiry/InquiryTable";
import SummaryHome from "./features/summery/summeryHome";
import { getDrawerItems } from "./Helpers/drawerHelper";
import UserProfileTable from "./features/userProfile/UserProfileTable";
import ProfileHome from "./features/profile/ProfileHome";
import ReportGeneration from "./features/report/ReportGeneration";
import { Route } from "react-router";
import {
	CERTIFICATION_BODY_ROUTES,
	EXPORTER_ROUTES,
	FARMER_ASSOCIATION_ROUTES,
	FARMER_ROUTES,
	FIELD_VISIT_ROUTES,
	FINAL_COMMENTS_ROUTES,
	FINAL_VOTE_VERIFICATION_ROUTES,
	IMPORTER_ROUTES,
	LABORATORIES_ROUTES,
	PROCESSOR_ROUTES,
	ISSUE_ROUTES,
	SLAAB_ROUTES,
	TECHNICAL_COMMITTEE_ROUTES,
	TRADER_ROUTES,
	PRODUCT_CATEGORY_ROUTES,
	PAYMENT_ROUTES,
	USER_MANAGEMENT_ROUTES,
	STAKEHOLDERS_MANAGEMENT_ROUTES,
	UPLOAD_CERTIFICATE_DETAILS_ROUTES,
} from "./app/router/routes";
import CertificationHome from "./features/certificationBody/certificationHome";
import FarmerHome from "./features/farmer/FarmerHome";
import FarmerAssociationHome from "./features/farmerAssociation/FarmerAssociationHome";
import ExporterHome from "./features/exporter/ExporterHome";
import ImposterHome from "./features/importer/ImposterHome";
import ProcessorHome from "./features/processor/ProcessorHome";
import SLAABHome from "./features/slaab/SLAABHome";
import FieldVisitHome from "./features/fieldVisit/FieldVisitHome";
import FinalCommentsHome from "./features/finalComments/FinalCommentsHome";
import { getUserId } from "./app/api/NOCU/helpers/applicationApiHelpers";
import { checkTabPermission, getPermissions, getTabs, setTabs } from "./app/api/NOCU/permissionAPI";
import LaboratoriesHome from "./features/labs/LaboratoriesHome";
import { Button } from "@mui/material";
import { setDrawerItemsList } from "./app/config";
import TechnicalCommitteeHome from "./features/technicalCommittee/TechnicalCommitteeHome";
import FinalVoteHome from "./features/finalVote/FinalVoteHome";
import SignatureHome from "./features/Signature/SignatureHome";
import TraderHome from "./features/trader/TraderHome";
import ProductCategoryHome from "./features/productCategory/productCategoryHome";
import PaymentHome from "./features/payment/PaymentHome";
import UserManagement from "./features/userManagment/UserManagement";
import { logOutApi } from "./app/api/authApi/authApi";
import { checkUserLogin } from "./features/auth/checkUserLogin";
import { navigateSelf } from "./app/router/routerManager";
import StakeholderManagement from "./features/stakeHolderManagement/StakeholderManagement";
import UploadCertificateDetailsHome from "./features/uploadCertificateDetails/UploadCertificateDetailsHome";

const WebPortal = () => {
	const [_tabs, _setTabs] = useState();

	useEffect(() => {
		console.log('getUserId()', getUserId())
		console.log('getTabs()', getTabs())
		getUserId() &&
			!getTabs() &&
			getPermissions(getUserId())
				.then((res) => {
					console.log('getPermissions API res---->>>>', res)
					_setTabs(setDrawerItemsList(setTabs(res.data)));
				})
				.catch((e) => console.log(e));
	}, []);

	return (
		<div className="pb-16">
			{/* Top Navigation */}

			<Navigation />
			<Section className='main-container'>
				{checkUserLogin() && (
					<SectionColumn className='lg:w-2/12 justify-center main-section-left '>
						{(getTabs() || _tabs) && <Drawer items={setDrawerItemsList(getTabs()) || _tabs} />}
					</SectionColumn>
				)}
				{checkUserLogin() && (
					<SectionColumn className='lg:w-10/12 main-section-right relative !shadow-2xl !bg-green-100'>
						<Route path={"/web/overview"} component={OverView} />
						<Route
							exact
							path={CERTIFICATION_BODY_ROUTES.HOME + "/:id"}
							component={CertificationHome}
						/>
						<Route path={FARMER_ROUTES.HOME + "/:id"} component={FarmerHome} />
						<Route path={FARMER_ASSOCIATION_ROUTES.HOME + "/:id"} component={FarmerAssociationHome} />
						<Route path={EXPORTER_ROUTES.HOME + "/:id"} component={ExporterHome} />
						<Route path={IMPORTER_ROUTES.HOME + "/:id"} component={ImposterHome} />
						<Route path={PROCESSOR_ROUTES.HOME + "/:id"} component={ProcessorHome} />
						<Route path={TRADER_ROUTES.HOME + "/:id"} component={TraderHome} />
						<Route path={SLAAB_ROUTES.HOME + "/:id"} component={SLAABHome} />
						<Route
							path={TECHNICAL_COMMITTEE_ROUTES.HOME + "/:id"}
							component={TechnicalCommitteeHome}
						/>
						<Route path={FIELD_VISIT_ROUTES.HOME + "/:id"} component={FieldVisitHome} />
						<Route path={UPLOAD_CERTIFICATE_DETAILS_ROUTES.HOME + "/:id"} component={UploadCertificateDetailsHome} />
						<Route path={FINAL_COMMENTS_ROUTES.HOME + "/:id"} component={FinalCommentsHome} />
						<Route path={FINAL_VOTE_VERIFICATION_ROUTES.HOME + "/:id"} component={FinalVoteHome} />
						<Route path={LABORATORIES_ROUTES.HOME + "/:id"} component={LaboratoriesHome} />
						<Route path={ISSUE_ROUTES.HOME + "/:id"} component={SignatureHome} />
						<Route path={PRODUCT_CATEGORY_ROUTES.HOME + "/:id"} component={ProductCategoryHome} />
						<Route path={PAYMENT_ROUTES.HOME + "/:id"} component={PaymentHome} />
						<Route path={USER_MANAGEMENT_ROUTES.HOME} component={UserManagement} />
						<Route path={STAKEHOLDERS_MANAGEMENT_ROUTES.HOME} component={StakeholderManagement} />
						<Route path={`/web/summery/:id`} component={SummaryHome} />
						<Route path={"/web/profile"} component={ProfileHome} />
						<Route path={"/web/inquiry"} component={InquiryTable} />
						<Route path={"/web/userProfileAPI"} component={UserProfileTable} />
						<Route path={"/web/report"} component={ReportGeneration} />
					</SectionColumn>
				)}
				{!checkUserLogin() && (
					<div>
						<Button
							onClick={() => {
								logOutApi()
									.then((res) => {
										sessionStorage.clear();
										localStorage.clear();
										navigateSelf("/login");
									})
									.catch((e) => console.log(e));
							}}>
							Log Out
						</Button>
					</div>
				)}
			</Section>
		</div>
	);
};

export default WebPortal;
