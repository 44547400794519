import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertModal({ isOpen, setIsOpen, title, content, agree }) {
	return (
		<div>
			<Dialog
				open={isOpen}
				onClose={() => {
					setIsOpen(false);
				}}
				TransitionComponent={Transition}>
				<DialogTitle className='bg-[#ED6C02]  text-white'>{title}</DialogTitle>
				<DialogContent className='mt-5'>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color='error'
						onClick={() => {
							setIsOpen(false);
						}}
						autoFocus>
						Cancel
					</Button>
					<Button
						variant='contained'
						color='warning'
						onClick={() => {
							agree();
							setIsOpen(false);
						}}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
