import React, { useEffect, useState } from "react";
import CertificationBodyReviewStep1 from "../../certificationBody/review/flow/certificationBodyReviewStep1";
import { setStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { SectionRow } from "../../../layouts/sections";
import { Button, Stack } from "@mui/material";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import TopButtonArray from "./components/TopButtonArray";
import CertificationBodyReviewStep2 from "../../certificationBody/review/flow/certificationBodyReviewStep2";
import CertificationBodyReviewStep3 from "../../certificationBody/review/flow/certificationBodyReviewStep3";
import CertificationBodyReviewStep4 from "../../certificationBody/review/flow/certificationBodyReviewStep4";
import CertificationBodyReviewStep5 from "../../certificationBody/review/flow/certificationBodyReviewStep5";
import LabReviewStep1 from "./../../labs/review/flow/LabStep1";
import LabReviewStep2 from "./../../labs/review/flow/LabStep2";
import LabReviewStep3 from "./../../labs/review/flow/LabStep3";
import LabReviewStep4 from "./../../labs/review/flow/LabStep4";
import LabReviewStep5 from "./../../labs/review/flow/LabStep5";
import ExporterStep1 from "./../../exporter/review/flow/ExporterStep1";
import ExporterStep2 from "./../../exporter/review/flow/ExporterStep2";
import ExporterStep3 from "./../../exporter/review/flow/ExporterStep3";
import ExporterStep4 from "./../../exporter/review/flow/ExporterStep4";
import ExporterStep5 from "./../../exporter/review/flow/ExporterStep5";
import ExporterStep6 from "./../../exporter/review/flow/ExporterStep6";
import ExporterStep7 from "./../../exporter/review/flow/ExporterStep7";
import ExporterStep8 from "./../../exporter/review/flow/ExporterStep8";
import ImporterStep1 from "./../../importer/review/flow/ImporterStep1";
import ImporterStep2 from "./../../importer/review/flow/ImporterStep2";
import ImporterStep3 from "./../../importer/review/flow/ImporterStep3";
import ImporterStep4 from "./../../importer/review/flow/ImporterStep4";
import ImporterStep5 from "./../../importer/review/flow/ImporterStep5";
import ImporterStep6 from "./../../importer/review/flow/ImporterStep6";
import ImporterStep7 from "./../../importer/review/flow/ImporterStep7";
import LoaderComponent from "../../../components/Loader/LoaderComponent";

const ImporterSummaryView = ({ applicationId }) => {
	const [selectedPage, setSelectedPage] = useState(0);
	const [statusArray, setStatusArray] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setStakeholderApplicationNumber(applicationId);
		getStepStatusByApplicationId(applicationId)
			.then((res) => {
				setStatusArray([
					res.data[0],
					res.data[1],
					res.data[2],
					res.data[6],
					res.data[7],
					res.data[3],
					res.data[4],
				]);
			})
			.finally(() => {
				setTimeout(() => setLoading(false), 1000);
			});
	}, [applicationId]);

	const btnNames = [
		"Business Details",
		"Contact Personnel",
		"Management Details",
		"Previous Product Details",
		"Current Product Details",
		"Certificates",
		"Other Documents",
	];

	return (
		<div>
			<SectionRow className='w-full justify-center'>
				{statusArray && (
					<TopButtonArray
						nameArray={btnNames}
						selectedPage={selectedPage}
						setSelectedPage={setSelectedPage}
						statusArray={statusArray}
					/>
				)}
			</SectionRow>
			{!loading && selectedPage === 0 ? (
				<ImporterStep1
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 1 ? (
				<ImporterStep2
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 2 ? (
				<ImporterStep3
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 3 ? (
				<ImporterStep4
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 4 ? (
				<ImporterStep5
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 5 ? (
				<ImporterStep6
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 6 ? (
				<ImporterStep7
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : (
				<></>
			)}
			<LoaderComponent isOpen={loading} />
		</div>
	);
};

export default ImporterSummaryView;
