// import CustomAxios from "CustomAxios";
import { API_BASE_URL } from "./core_api";
import CustomAxios from "./customAxios/customAxios";

export const rectedDocumentAPI = async (data) => {
	console.log("<><><><><><><>< rectedDocumentAPI request ><><><><><><", data);
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/rejected/doc/add",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("rectedDocumentAPI response:::", res);
			return res;
		})
		.catch((error) => {
			console.log("rectedDocumentAPI error", error);
		});
};

export const getCompanyTrailAPI = async (companyID) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/application/get/history/${companyID}`,
		headers: {},
	})
		.then((res) => {
			console.log("Trial response", res);
			return res.data;
		})
		.catch((error) => {
			throw error.response;
		});
};
