import React, { useEffect, useState } from "react";
import {
	getUserDetailsByID,
	getUserProfileRecordsByStatus,
	updateDocUserStatus,
} from "../../app/api/userProfileAPI/userProfileAPI";
import { Chip, Grid } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { Table } from "react-bootstrap";
import loadingGif from "../../assets/images/Icons/loadingGif.gif";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Eye from "@mui/icons-material/Visibility";
import UserApproval from "./UserApproval";
import { PENDING } from "../../Helpers/constants";
import { stringCrop } from "../../Helpers/stringCrop";
import {SectionRow} from "../../components/section";

const UserProfileTable = () => {
	const [currentUserID, setCurrentUserID] = useState();
	const [userData, setUserData] = useState();
	const [searchBy, setSearchBy] = useState("email");
	const [open, setOpen] = useState(false);
	const [records, setRecords] = useState([]);
	const [params, setParams] = useState({
		page: 0,
		size: 5,
		status: "ALL",
		email: "",
	});

	useEffect(() => {
		getUserProfileRecordsByStatus(params.status, params).then((res) => {
			setRecords(res.data);
		});
	}, [params]);
	let userStatuses = [
		{ key: "ALL", value: "ALL" },
		{ key: "PENDING", value: "PENDING" },
		{ key: "APPROVED", value: "APPROVED" },
		{ key: "REJECTED", value: "REJECTED" },
		// { key: "AVAILABLE", value: "AVAILABLE" },
		{ key: "VACATION", value: "VACATION" },
	];

	let columns = ["Full Name", "Designation", "Contact No", "Email", "Status", "View"];
	let newColumns = ["Full Name", "Designation", "Contact No", "Email", "Status", "View"];

	const renderTableHeading = (headings) => {
		return headings.map((heading, i) => {
			return (
				<th key={i} style={{ verticalAlign: "initial" }} className='thBtn'>
					{heading}
				</th>
			);
		});
	};

	const handleOpenUserApproval = (userId) => {
		getUserDetailsByID(userId)
			.then((res) => {
				setUserData(res.data);
				setCurrentUserID(userId);
				setOpen(true);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const renderRow = (data) => {
		return data.map((row) => (
			<tbody className='tbody'>
				<td className='tdStart'>{stringCrop(row.fullName, 30)}</td>
				<td className='tdStart'>{row.designation ? row.designation : "-"}</td>
				<td className='tdStart'>{row.contactNo}</td>
				<td className='tdStart'>{row.email}</td>
				<td className='tdStart'>{row.userStatus}</td>
				{/* {params.status === PENDING && ( */}
				<td className='tdStart'>
					<Eye
						onClick={() => {
							handleOpenUserApproval(row.userId);
							setCurrentUserID(row.userId);
						}}></Eye>
				</td>
				{/* )} */}
			</tbody>
		));
	};

	const handleNextPage = () => {
		setParams({ ...params, page: params.page + 1 });
	};

	const handlePrevPage = () => {
		setParams({ ...params, page: params.page - 1 });
	};

	return (
		<div className='userTbleContainer'>
			<SectionRow className='mb-5'>
				<span className='heading-1'>User Profile</span>
			</SectionRow>
			<UserApproval
				isOpen={open}
				handleDismiss={() => setOpen(false)}
				title={`User Profile`}
				user={currentUserID}
				data={userData}
			/>
			<Grid container>
				<Grid item xs={3} style={{ borderTopLeftRadius: "15px" }} className='tableHeaderItem'>
					<Chip icon={<HowToRegIcon />} label='User Profile List' />
				</Grid>
				<Grid item xs={4} className='tableHeaderItem'>
					<div className='perPageContainer'>
						<label className='mr-2'> Filter By Status :</label>
						<select
							className='perPage'
							name='size'
							id='size'
							onChange={(e) => setParams({ ...params, status: e.target.value, page: 0 })}>
							{userStatuses.map((fbb) => (
								<option key={fbb.key} value={fbb.value}>
									{fbb.key}
								</option>
							))}
							;
						</select>
					</div>
				</Grid>
				<Grid item xs={5} style={{ borderTopRightRadius: "15px" }} className='tableHeaderSearch'>
					<label className='mr-2'> Search By :</label>
					{/* <select
						className='perPage mr-2'
						name='search'
						id='search'
						onChange={(e) => setSearchBy(e.target.value)}>
						<option value={"email"}>Email</option>;
					</select> */}
					{searchBy === "email" && (
						<input
							placeholder='Email'
							className='searchIconText w-40'
							onChange={(event) => {
								setParams({ ...params, email: event.target.value });
							}}
						/>
					)}
				</Grid>
				{/*<Grid*/}
				{/*	item*/}
				{/*	xs={1}*/}
				{/*	style={{ borderTopRightRadius: "15px" }}*/}
				{/*	className='tableHeaderItem'></Grid>*/}
			</Grid>
			<Table responsive>
				<thead>
					{params.status === PENDING ? (
						<tr>{renderTableHeading(newColumns)}</tr>
					) : (
						<tr>{renderTableHeading(columns)}</tr>
					)}
				</thead>
				{records ? (
					renderRow(records)
				) : (
					<tbody className='tbody'>
						<tr align='center'>
							<td colSpan='12'>
								<br />
								<img src={loadingGif} alt='loading...' />
							</td>
						</tr>
					</tbody>
				)}
				{records.length === 0 && (
					<tbody className='tbody'>
						<tr align='center'>
							<td colSpan='12'>
								<br />
								<div style={{ fontSize: "10px", fontWeight: "bold" }}>
									No User Profile Details Found
								</div>
							</td>
						</tr>
					</tbody>
				)}
			</Table>
			<Grid container>
				<Grid
					item
					xs={6}
					style={{ borderBottomLeftRadius: "15px" }}
					className='paginationItem'></Grid>
				<Grid item xs={3} className='paginationItem'>
					<div>
						<label style={{ margin: "0px" }}> Rows per page  </label>
						<select
							className='perPage'
							style={{ cursor: "pointer" }}
							name='size'
							id='size'
							onChange={(e) => setParams({ ...params, size: e.target.value })}>
							<option label='5' value='5' />
							<option label='10' value='10' />
							<option label='25' value='25' />
						</select>
					</div>
				</Grid>
				<Grid item xs={3} style={{ borderBottomRightRadius: "15px" }} className='paginationItem'>
					<div>
						{params.page <= 0 ? (
							<ArrowBackIosIcon style={{ fontSize: "medium" }} color='disabled' />
						) : (
							<ArrowBackIosIcon
								style={{ fontSize: "medium" }}
								onClick={() => {
									handlePrevPage();
								}}
							/>
						)}
						<Chip label={`Showing Page ${params.page + 1}`} color='primary' size='small' />
						{records.length < params.size ? (
							<ArrowForwardIosIcon style={{ fontSize: "medium" }} color='disabled' />
						) : (
							<ArrowForwardIosIcon
								style={{ fontSize: "medium" }}
								onClick={() => {
									handleNextPage();
								}}
							/>
						)}
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default UserProfileTable;
