import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const getUserTableRecords = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/user/find-by-user-status`,
        headers: {},
        data: data
    })
        .then((res) => {
            console.log("getPermissions (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("getPermissions (error) ====>", error);
        });
};


export const setUserStatus = async (userId, data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/user/update-status/${userId}`,
        headers: {},
        data: data
    })
        .then((res) => {
            console.log("getPermissions (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("getPermissions (error) ====>", error);
        });
};

export const getUserAllocation = async (userId, data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/user/get-permission/${userId}`,
        headers: {},
        params: data
    })
        .then((res) => {
            console.log("getPermissions (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("getPermissions (error) ====>", error);
        });
};

export const updateUserPermission = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/user/update-permission`,
        headers: {},
        data: data
    })
        .then((res) => {
            console.log("getPermissions (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("getPermissions (error) ====>", error);
        });
};
export const updateUserMeetingModeSettings = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/user/update-meeting-mode`,
        headers: {},
        data: data
    })
        .then((res) => {
            console.log("getPermissions (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("getPermissions (error) ====>", error);
        });
};
export const getUserByPermission = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/user/get-user-by-permission`,
        headers: {},
        data: data
    })
        .then((res) => {
            console.log("getPermissions (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("getPermissions (error) ====>", error);
        });
};
export const reassignByApplication = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/user/application-reassign`,
        headers: {},
        data: data
    })
        .then((res) => {
            console.log("getPermissions (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("getPermissions (error) ====>", error);
        });
};