import { useEffect } from "react";
import { useState } from "react";
import { SectionColumn, SectionRow } from "../section";
import { FormGroup, Switch, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { ICON_PATHS, STATUS } from "../../app/constants";
import RejectModel from "../model/RejectModel";
import { CERTIFICATION_BODY_REJECT_REASONS } from "./../model/RejectModel";
import {
	getApplicationRejectDetailsAll,
	setApplicationRejectDetails,
} from "../../Helpers/rejectionHelpers";
import { stringCrop } from "../../Helpers/stringCrop";

const ReviewCheckList = ({ data, children, setForm, readOnly }) => {
	const [_data, setData] = useState(data);
	const [checkList, setCheckList] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [currentEvent, setCurrentEvent] = useState();

	const setState = (data) => {
		let obj = {};
		data.forEach((field, index) => {
			obj[field.name] = field.isChecked;
		});
		setCheckList(obj);
		console.log("obj", obj);
	};
 
	useEffect(() => {
		if (data) {
			setData(data);
			setState(data);
		}
	}, [data]);

	// useEffect(() => {
	// 	console.table(checkList);
	// }, [checkList]);

	const handleChange = (event, field, index) => {
		setCurrentEvent({
			event: event,
			field: field,
			index: index,
		});
		if (!event.target.checked) {
			setIsOpen(true);
		} else {
			const _field = { ...field, isChecked: event.target.checked, comment: "" };
			let updatedData = _data;
			updatedData[index] = _field;
			console.table(updatedData);
			setForm(updatedData);
			setCheckList({
				...checkList,
				[event.target.name]: event.target.checked,
			});
		}
	};

	const handleReject = (comment) => {
		const _field = { ...currentEvent.field, isChecked: false, comment: comment };
		let updatedData = _data;
		updatedData[currentEvent.index] = _field;
		console.table(updatedData);
		setForm(updatedData);
		setCheckList({
			...checkList,
			[currentEvent.event.target.name]: false,
		});

		setApplicationRejectDetails(STATUS.REJECTED, {
			isFile: false,
			name: currentEvent.field?.name,
			entityId: "",
			value: currentEvent.field?.label,
			comment: comment,
		});
	};

	const renderFormElement = (array) => {
		if (array) {
			return array.map((field, i) =>
				field.label && checkList ? (
					<div className='Rectangle-1 items-center flex-row flex relative' key={i}>
						<div className=' w-11/12  m-1 Business-Name'>{field.label}</div>
						<div className=' w-1/12  m-1 Business-Name'>
							<Switch
								sx={{ justifyContent: "center", alignItems: "center" }}
								color='success'
								name={field.name}
								className={readOnly ? "cursor-not-allowed" : ""}
								disabled={readOnly ? true : field.disable}
								checked={checkList[field.name]}
								inputProps={{ "aria-label": "controlled" }}
								icon={<img src={ICON_PATHS.modal_close} alt='cross' width={20} />}
								checkedIcon={<img src={ICON_PATHS.rounded_check_mark} alt='cross' width={20} />}
								onChange={(e) => {
									handleChange(e, field, i);
									// handleChange(e);
								}}
							/>
						</div>
						{field?.comment && (
							<Tooltip
								title={<div className='text-base p-2'>Reject Reason : {field?.comment}</div>}>
								<div className='!absolute !italic left-2 bottom-0 error-msg'>
									{stringCrop(field?.comment, 85)}
								</div>
							</Tooltip>
						)}
					</div>
				) : null
			);
		}
	};

	return (
		<>
			<div>
				{children}
				<SectionColumn className='h-auto items-center'>{renderFormElement(_data)}</SectionColumn>
				<RejectModel
					title='Test Doc'
					reasonsType={CERTIFICATION_BODY_REJECT_REASONS}
					handleReject={handleReject}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			</div>
		</>
	);
};

export default ReviewCheckList;
