import CustomAxios from "../../customAxios/customAxios";
import { AUTH_BASE_URL } from "../../core_api";

export const getGeneralInquiry = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: AUTH_BASE_URL,
		url: "/global_inquiry/v1/get-by-ticket-id",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("Get General Inquiry (res) ===>", res);
			return res;
		})
		.catch((error) => {
			console.log("Get General Inquiry (error) ===>", error);
		});
};

export const getGeneralInquiryByStatus = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: AUTH_BASE_URL,
		url: "/global_inquiry/v1/get-by-status-and-type",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("Get General Inquiry By Status (res) ===>", res);
			return res;
		})
		.catch((error) => {
			console.log("Get General Inquiry By Status (error) ===>", error);
		});
};

export const postGeneralInquiry = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: AUTH_BASE_URL,
		url: "/global_inquiry/v1/update",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("Post General Inquiry (res) ===>", res);
			return res;
		})
		.catch((error) => {
			console.log("Post General Inquiry (error) ===>", error);
		});
};
