import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { SectionColumn } from "../../../layouts/sections";
import { useAlert } from "../../../app/context/alerts";
import RoundedButton from "../../../components/buttons/RoundedButtons";

const FileUpload = (props) => {
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [acceptedFileNames, setAcceptedFileNames] = useState([]);
  const [pdfIconView, setPdfIconView] = useState(false);
  const { setAlert } = useAlert();

  const acceptTypes = {
    "application/pdf": [".pdf"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
  };

  const acceptTypesExcel = {
    excel: [".xlsx", ".xls"],
  };

  const viewAcceptedFiles = (acceptedFiles) => {
    let names = [];
    acceptedFiles?.map((file) => {
      names.push(file.name);
    });
    setAcceptedFileNames(names);
  };
  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }

    if (props.getValue) props.getValue(value);
  }, [props, value]);

  useEffect(() => {
    setError("");
  }, [value]);

  useEffect(() => {
    setError(props.error ? props.error : "");
  }, [props]);

  useEffect(() => {
    props.currentFiles ? setPdfIconView(true) : setPdfIconView(false);

    console.log(acceptedFileNames);
  }, []);

  const pdfIcon = (files) => {
    return (
      <div className="w-full flex justify-center text-center mt-10 flex-col">
        {files.map((file) => (
          <div
            key={file.name}
            className="flex items-center py-2"
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SectionColumn className="w-full grid justify-items-center">
              <div className="absolute flex justify-center">
                <div className="">
                  <img
                    src="https://img.icons8.com/ios-glyphs/20/f1410a/macos-close.png"
                    onClick={() => {
                      setPdfIconView(false);
                    }}
                    style={{
                      position: "absolute",
                      marginLeft: 25,
                      marginTop: -10,
                    }}
                    alt="cross"
                  />
                  <img src="https://img.icons8.com/ios/40/cccccc/pdf--v1.png" />
                </div>
              </div>

              <div className="absolute text-center mt-12">
                <span>{file.name}</span>
              </div>
            </SectionColumn>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Dropzone
        onDropAccepted={(acceptedFiles) => {
          props.setFile(acceptedFiles);
          viewAcceptedFiles(acceptedFiles);
          setPdfIconView(true);
        }}
        onDropRejected={(fileRejections) => {
          if (fileRejections[0].errors[0].code === "file-too-large") {
            setAlert({
              message: "Do not exceed the maximum file size.",
              severity: "error",
            });
          } else if (fileRejections[0].errors[0].code === "file-invalid-type") {
            setAlert({
              message: "Upload a valid file type.",
              severity: "error",
            });
          }
        }}
        accept={props.name === "Supplier List" ? acceptTypesExcel : acceptTypes}
        maxSize={10000000}
        multiple={props.multiple}
        maxFiles={props.maxFiles}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            {/* {!pdfIconView && (
              <div
                {...getRootProps()}
                className="w-full py-12 px-16 text-center mt-3 fileUploadPopupSpace uploadButton"
              >
                <input {...getInputProps()} />
                <SectionColumn className="mt-10">
                  <span className="font-bold text-sm">Upload File</span>
                  <span className="font-medium text-gray-400">
                    {props.name === "Supplier List"
                      ? "Drag & drop to upload the excel"
                      : "File Type : PDF/PNG/JPEG"}
                  </span>
                  <span className="font-medium text-gray-400">
                    File Size : 50MB
                  </span>
                </SectionColumn>
              </div>
            )} */}
            {!pdfIconView && (
              <div
                {...getRootProps()}
                className="w-full py-12 px-16 text-center mt-3 fileUploadPopupSpace uploadButton"
              >
                <input {...getInputProps()} />
                <SectionColumn className="mt-10">
                  <span className="font-bold text-sm">Drag and Drop</span>
                  <span className="font-medium text-gray-400">
                    {props.name === "Supplier List"
                      ? "Drag & drop to upload the excel"
                      : "File Type : PDF/PNG/JPEG"}
                  </span>
                  <span className="font-medium text-gray-400">
                    Maximum File Size : 10MB
                  </span>
                </SectionColumn>
              </div>
            )}
            {!pdfIconView && (
              <div
                className="w-full flex justify-center text-center"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <RoundedButton
                  ref={undefined}
                  onClick={undefined}
                  className="checkEligibilityBTN"
                  type="submit"
                >
                  <div className="w-full flex flex-row items-center justify-center">
                    <RoundedButton
                      className="mt-1 -mb-2 fileUploadPopupCardButton primaryButton"
                      ref={undefined}
                      onClick={undefined}
                    >
                      <span>Upload</span>
                    </RoundedButton>{" "}
                  </div>
                </RoundedButton>
              </div>
            )}
            {pdfIconView && (
              <div>
                <div className="w-full py-12 px-16 text-center mt-1 fileUploadPopupSpace uploadButton">
                  <div className="w-full h-full">
                    {pdfIconView && pdfIcon(props.currentFiles)}
                  </div>
                </div>
                <div className="mt-2 w-full flex flex-row items-center justify-center">
                  <RoundedButton
                    ref={undefined}
                    onClick={props.handleUploadConfirm}
                    className="mt-1 -mb-2 fileUploadPopupCardButton primaryButton"
                    type="submit"
                  >
                    Confirm
                  </RoundedButton>
                </div>
              </div>
            )}
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default FileUpload;
