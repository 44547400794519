import React, { useEffect } from "react";
import { SectionColumn } from "../../layouts/sections";
import ToggleButtonSet from "../../components/buttons/ToggleButtonSet";
import {
	getApplicationHistory,
	getMappedApplicationHistory,
} from "../../app/api/summaryAPI/summaryTableApi";
import TrailTable from "../../components/table/trialTable";
import TabHome from "../../components/Tabs/TabHome";
import GridViewIcon from "@mui/icons-material/GridView";
import { useState } from "react";
import ApplicationSummaryView from "./ApplicationSummaryView";
import VotingInfo from "./VotingInfo";
import CertificateView from "./CertificateView";
import { getApplicationNumberDetails } from "../../app/api/NOCU/summaryApi";

const applicationArray = [
	{ name: "Applications" },
	{ name: "History Trail" },
	{ name: "Voting & Field Visit Info" },
	{ name: "Certificates" },
];

const ViewMorePage = ({ applicationId }) => {
	const [currentView, setCurrentView] = useState(0);
	const [trailData, setTrailData] = React.useState();
	const [mappedTrailData, setMappedTrailData] = React.useState();
	const [mappedApplicationNumber, setMappedApplicationNumber] = useState();
	const manipulateData = (data) => {
		return data && Array.isArray(data)
			? data.map((application, index) => {
					return {
						label: application?.applicationNumber,
						component: (
							<div>
								<TrailTable trailData={application?.applicationSummeryResponse} />
							</div>
						),
					};
			  })
			: [];
	};

	useEffect(() => {
		applicationId && applicationId.toString().includes("SH-")
			? setMappedApplicationNumber(applicationId)
			: getApplicationNumberDetails({ applicationNumber: applicationId }).then((res) => {
					setMappedApplicationNumber(res.data?.mappedApplicationNumber);
			  });
	}, [applicationId]);

	useEffect(() => {
		mappedApplicationNumber &&
			getMappedApplicationHistory(mappedApplicationNumber)
				.then((res) => {
					console.log("History Prompt data", res.data);
					setMappedTrailData(manipulateData(res.data?.applicationDtoList));
				})
				.catch((e) => console.log(e));
	}, [mappedApplicationNumber]);

	const handleApplication = (index) => {
		setCurrentView(index);
	};

	return (
		<div>
			<SectionColumn className='w-full '>
				<SectionColumn className='w-full -mb-5 items-center'>
					<ToggleButtonSet
						maxWith={"1000px"}
						customIcon={<GridViewIcon />}
						data={applicationArray}
						handleClick={handleApplication}
						certificate={true}
					/>
				</SectionColumn>
				<SectionColumn>
					{currentView === 0 ? (
						<ApplicationSummaryView applicationId={mappedApplicationNumber} />
					) : currentView === 1 ? (
						<div className='min-w-[750px]'>
							{manipulateData && <TabHome componentArray={mappedTrailData} />}
						</div>
					) : currentView === 2 ? (
						<VotingInfo applicationId={mappedApplicationNumber} />
					) : (
						<CertificateView applicationId={mappedApplicationNumber} />
					)}
				</SectionColumn>
			</SectionColumn>
		</div>
	);
};

export default ViewMorePage;
