import React, { useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import { SLAAB_ROUTES } from "./../../app/router/routes";
import { Route } from "react-router-dom";
import CertificationBodyReviewStep4 from "../certificationBody/review/flow/certificationBodyReviewStep4";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const SLAAB = () => {
	const [isLoading, setIsLoading] = useState(true);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />

			<NavBar title={"SLAB"} tableIcon={DASHBOARD_ICONS.slaab} tableURL={SLAAB_ROUTES.HOME} />
			<Route path={SLAAB_ROUTES.REVIEW}>
				<CertificationBodyReviewStep4 slaabReview={true} setIsLoading={setIsLoading} />
			</Route>
		</div>
	);
};

export default SLAAB;
