import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Slide } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { color } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function ConfirmationPopup({
	isOpen,
	setIsOpen,
	title,
	content,
	handleClick,
	color,
}) {
	return (
		<div>
			<Dialog
				open={isOpen}
				onClose={() => {
					setIsOpen(false);
				}}
				TransitionComponent={Transition}>
				<DialogTitle
					style={{ backgroundColor: color == "error" ? "#d32f2f" : "#20b05e" }}
					className={`text-white`}>
					{title}
				</DialogTitle>
				<DialogContent className='mt-5'>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color='error'
						onClick={() => {
							setIsOpen(false);
						}}
						autoFocus>
						Cancel
					</Button>
					<Button
						variant='contained'
						color={color == "error" ? "error" : "success"}
						onClick={() => {
							handleClick();
							setIsOpen(false);
						}}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
