import { Chip } from "@mui/material";


export const getEndUserTableData = (records) => {
	const applicationList = records?.endUserDetailsForReportDtos;
	return applicationList
		? applicationList.map((item) => [
			item?.fullName ? item.fullName : "-",
			item?.regNo ? item.regNo : "-",
			item?.regDate ? item.regDate : "-",
			item?.certExpireDate ? item.certExpireDate : "-",
			item?.applicationList ? item.applicationList : "-",
			item?.contactNo ? item.contactNo : "-",
			item?.email ? item.email : "-",
		])
		: [];
};

export const getApplcationTableData = (records) => {
	const applicationList = records?.applicationCountDtos;
	return applicationList
		? applicationList.map((item) => [
			item?.date ? item.date : "0",
			item?.checkProcess ? item.checkProcess : "0",
			item?.finalComment ? item.finalComment : "0",
			item?.technicalCommittee ? item.technicalCommittee : "0",
			item?.finalVote ? item.finalVote : "0",
			item?.pendingToSign ? item.pendingToSign : "0",
			item?.issued ? item.issued : "0",
		])
		: [];
};


