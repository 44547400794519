import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";
import ReviewFormWithSwitch from "../../../../components/review/reviewFormWithSwitch";
import { Button, FormGroup, Switch, Tooltip } from "@mui/material";
import { APPROVED, DEFAULT, REJECTED } from "../../../../Helpers/constants";
import FileButtonSet from "../../../../components/buttons/FileButtonSet";
import { getFileURL } from "../../../../app/api/file_api";
import { SectionColumn, SectionRow } from "../../../../components/section";
import FileButtonWrapper from "../../../../components/buttons/FileButtonWrapper";
import { ICON_PATHS, STATUS } from "../../../../app/constants";
import RejectModel, {
	CERTIFICATION_BODY_REJECT_REASONS,
} from "../../../../components/model/RejectModel";
import { setApplicationRejectDetails } from "../../../../Helpers/rejectionHelpers";
import { stringCrop } from "../../../../Helpers/stringCrop";
import { filterByTypeId } from "./sopDataHelpers";

export default function SourceOfProducts({
	data,
	readOnly,
	selectedFile,
	setSelectedFile,
	rejections,
	setCanApprove,
	setDisabled,
	reviewStatus,
	hideBtns,
}) {
	const [processing, setProcessing] = useState();
	const [ownFarm, setOwnFarm] = useState();
	const [certifiedFarm, setCertifiedFarm] = useState();
	const [certifiedFarmerAssociation, setCertifiedFarmerAssociation] = useState();
	const [importers, setImporters] = useState();
	const [exporters, setExporters] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [currenEvent, setCurrenEvent] = useState();

	useEffect(() => {
		data?.processing &&
			Array.isArray(data?.processing) &&
			data?.processing.length > 0 &&
			setProcessing(
				data?.processing.map((processor) => {
					return {
						files: processor?.documentFilePath
							? [
									{
										name: "Subcontract Document",
										path: getFileURL(processor?.documentFilePath),
										status: filterByTypeId(
											rejections?.rejectReasonDetailList,
											"PROCESSOR",
											processor?.sourceOfProductProcessingId,
											true
										)
											? REJECTED
											: reviewStatus === "PENDING"
											? DEFAULT
											: APPROVED,
										comment: filterByTypeId(
											rejections?.rejectReasonDetailList,
											"PROCESSOR",
											processor?.sourceOfProductProcessingId,
											true
										),
									},
							  ]
							: null,

						formData: {
							name: processor?.name || "---",
							address: processor?.address || "---",
							typeOfProcessor: processor?.typeOfProcess || "---",
							isChecked: filterByTypeId(
								rejections?.rejectReasonDetailList,
								"PROCESSOR",
								processor?.sourceOfProductProcessingId,
								false
							)
								? false
								: true,
							comment: filterByTypeId(
								rejections?.rejectReasonDetailList,
								"PROCESSOR",
								processor?.sourceOfProductProcessingId,
								false
							),
						},

						entityId: processor?.sourceOfProductProcessingId,
					};
				})
			);
		data?.exportersOwnFarm?.documentFilePath &&
			setOwnFarm({
				files: [
					{
						name: "Own Farm Details",
						path: getFileURL(data?.exportersOwnFarm.documentFilePath),
						status: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"OWN_FARM",
							data?.sourceOfProductDetailId,
							true
						)
							? REJECTED
							: reviewStatus === "PENDING"
							? DEFAULT
							: APPROVED,
						comment: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"OWN_FARM",
							data?.sourceOfProductDetailId,
							true
						),
					},
				],

				entityId: data?.sourceOfProductDetailId,
			});
		data?.exporterCertifiedFarmer.documentFilePath &&
			setCertifiedFarm({
				files: [
					{
						name: "Certificated Farm Details",
						path: getFileURL(data?.exporterCertifiedFarmer.documentFilePath),
						status: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"CERTIFIED_FARM",
							data?.sourceOfProductDetailId,
							true
						)
							? REJECTED
							: reviewStatus === "PENDING"
							? DEFAULT
							: APPROVED,
						comment: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"CERTIFIED_FARM",
							data?.sourceOfProductDetailId,
							true
						),
					},
				],

				entityId: data?.sourceOfProductDetailId,
			});
		data?.exporterCertifiedFarmerAssociation.documentFilePath &&
			setCertifiedFarmerAssociation({
				files: [
					{
						name: "Certificated Farmer Association Details",
						path: getFileURL(data?.exporterCertifiedFarmerAssociation.documentFilePath),
						status: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"CERTIFIED_FARMER_ASSOCIATION",
							data?.sourceOfProductDetailId,
							true
						)
							? REJECTED
							: reviewStatus === "PENDING"
							? DEFAULT
							: APPROVED,
						comment: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"CERTIFIED_FARMER_ASSOCIATION",
							data?.sourceOfProductDetailId,
							true
						),
					},
				],

				entityId: data?.sourceOfProductDetailId,
			});
		data?.importers &&
			Array.isArray(data?.importers) &&
			setImporters(
				data?.importers.map((importerExporter) => {
					return {
						name: importerExporter.nameOfImporter,
						products: importerExporter.product.map((_product) => {
							return {
								name: _product.nameOfProduct,
								quantity: `${_product.quantity} ${_product.unit}`,
							};
						}),
						entityId: importerExporter.sourceOfProductImporterId,
						isChecked: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"IMPORTER",
							importerExporter?.sourceOfProductImporterId,
							false
						)
							? false
							: true,
						comment: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"IMPORTER",
							importerExporter?.sourceOfProductImporterId,
							false
						),
					};
				})
			);
		data?.exporters &&
			Array.isArray(data?.exporters) &&
			setExporters(
				data?.exporters.map((importerExporter) => {
					return {
						name: importerExporter.nameOfExporter,
						products: importerExporter.product.map((_product) => {
							return {
								name: _product.nameOfProduct,
								quantity: `${_product.quantity} ${_product.unit}`,
							};
						}),
						entityId: importerExporter.sourceOfProductExporterId,
						isChecked: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"EXPORTER",
							importerExporter?.sourceOfProductExporterId,
							false
						)
							? false
							: true,
						comment: filterByTypeId(
							rejections?.rejectReasonDetailList,
							"EXPORTER",
							importerExporter?.sourceOfProductExporterId,
							false
						),
					};
				})
			);
	}, [data, rejections]);

	useEffect(() => {}, [rejections]);

	const renderAccordionComponent = (title, content, disabled) => {
		return (
			<Accordion disabled={disabled}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1a-content'
					id='panel1a-header'>
					<Typography>{title}</Typography>
				</AccordionSummary>
				<AccordionDetails>{content}</AccordionDetails>
			</Accordion>
		);
	};

	const renderProcessing = (data, index) => {
		return (
			<div>
				<div className='text-sm text-gray-600'>
					{data.name}{" "}
					<FormControlLabel
						control={
							<Switch
								sx={{ justifyContent: "center", alignItems: "center" }}
								color='success'
								className={readOnly ? "cursor-not-allowed" : ""}
								name={"name"}
								checked={data.isChecked}
								disabled={readOnly ? true : false}
								inputProps={{ "aria-label": "controlled" }}
								icon={<img src={ICON_PATHS.modal_close} alt='cross' width={20} />}
								checkedIcon={<img src={ICON_PATHS.rounded_check_mark} alt='cross' width={20} />}
								onChange={(e, checked) => {
									handleChangeProcessorDetails(checked, index);
									// handleChange(e, field, i);
									// handleChange(e);
								}}
							/>
						}
					/>
					{data?.comment && (
						<Tooltip title={<div className='text-base p-2'>Reject Reason : {data?.comment}</div>}>
							<span className='!italic error-msg'>{stringCrop(data?.comment, 85)}</span>
						</Tooltip>
					)}
				</div>

				<div className='Rectangle-1 items-center flex-row flex relative '>
					<div className=' w-3/12  m-1 Business-Name'>Address</div>
					<div className=' w-1/12  m-1 Business-Name'>:</div>
					<div className=' w-7/12  m-1 Business-Name'>{data.address}</div>
				</div>
				<div className='Rectangle-1 items-center flex-row flex relative '>
					<div className=' w-3/12  m-1 Business-Name'>Type of Processor</div>
					<div className=' w-1/12  m-1 Business-Name'>:</div>
					<div className=' w-7/12  m-1 Business-Name'>{data.typeOfProcessor}</div>
				</div>
			</div>
		);
	};
	const renderImporterExporter = (data, type) => {
		return (
			data &&
			data.map((impExp, index) => {
				return (
					<div>
						<div className='text-sm text-gray-600'>
							{impExp.name}{" "}
							<Switch
								sx={{ justifyContent: "center", alignItems: "center" }}
								color='success'
								className={readOnly ? "cursor-not-allowed" : ""}
								name={"name"}
								checked={impExp.isChecked}
								disabled={readOnly ? true : false}
								inputProps={{ "aria-label": "controlled" }}
								icon={<img src={ICON_PATHS.modal_close} alt='cross' width={20} />}
								checkedIcon={<img src={ICON_PATHS.rounded_check_mark} alt='cross' width={20} />}
								onChange={(e, checked) => {
									type === "IMPORTER"
										? handleChangeImporterDetails(checked, index)
										: handleChangeExporterDetails(checked, index);
									// handleChange(e, field, i);
									// handleChange(e);
								}}
							/>
							{impExp?.comment && (
								<Tooltip
									title={<div className='text-base p-2'>Reject Reason : {impExp?.comment}</div>}>
									<span className='!italic error-msg'>{stringCrop(impExp?.comment, 85)}</span>
								</Tooltip>
							)}
						</div>
						{impExp?.products.map((product) => {
							return (
								<div className='Rectangle-1 mb-2 !mt-0 items-center flex-row flex relative '>
									<div className=' w-3/12  m-1 Business-Name'>{product.name}</div>
									<div className=' w-1/12  m-1 Business-Name'>:</div>
									<div className=' w-7/12  m-1 Business-Name'>{product.quantity}</div>
								</div>
							);
						})}
					</div>
				);
			})
		);
	};

	const updateFileArray = (fileArray, index) => {
		console.log("updateFileArray", fileArray, index, processing[index].files);
		let _processing = processing;
		_processing[index].files = fileArray;
		console.log("Updated processing", _processing);
		setProcessing(_processing);
	};
	const handleChangeProcessorDetails = (checked, index) => {
		setCurrenEvent({
			checked: checked,
			index: index,
			type: "PROCESSOR",
		});
		if (checked === true) {
			let _pro = processing;
			_pro[index].formData.isChecked = checked;
			_pro[index].formData.comment = "";
			console.log("Pro ====>", _pro);
			setProcessing(_pro);
			setApplicationRejectDetails(STATUS.APPROVED, {
				isFile: false,
				name: index,
				entityId: processing[index].entityId,
				value: index,
				comment: "",
				entityType: "PROCESSOR",
			});
		} else {
			setIsOpen(true);
		}
	};
	const handleChangeImporterDetails = (checked, index) => {
		setCurrenEvent({
			checked: checked,
			index: index,
			type: "IMPORTER",
		});
		if (checked === true) {
			let _pro = importers;
			_pro[index].isChecked = checked;
			_pro[index].comment = "";
			console.log("Pro ====>", _pro);
			setImporters(_pro);
			setApplicationRejectDetails(STATUS.APPROVED, {
				isFile: false,
				name: index,
				entityId: importers[index].entityId,
				value: index,
				comment: "",
				entityType: "IMPORTER",
			});
		} else {
			setIsOpen(true);
		}
	};
	const handleChangeExporterDetails = (checked, index) => {
		setCurrenEvent({
			checked: checked,
			index: index,
			type: "EXPORTER",
		});
		if (checked === true) {
			let _pro = exporters;
			_pro[index].isChecked = checked;
			_pro[index].comment = "";
			console.log("Pro ====>", _pro);
			setExporters(_pro);
			setApplicationRejectDetails(STATUS.APPROVED, {
				isFile: false,
				name: index,
				entityId: exporters[index].entityId,
				value: index,
				comment: "",
				entityType: "EXPORTER",
			});
		} else {
			setIsOpen(true);
		}
	};

	const handleReject = (comment) => {
		console.log("Current Event", currenEvent);
		if (currenEvent?.type === "PROCESSOR") {
			let _pro = processing;
			_pro[currenEvent?.index].formData.isChecked = currenEvent?.checked;
			_pro[currenEvent?.index].formData.comment = comment;
			console.log("Pro ====>", _pro);
			setProcessing(_pro);
			setApplicationRejectDetails(STATUS.REJECTED, {
				isFile: false,
				name: currenEvent?.index,
				entityId: processing[currenEvent?.index].entityId,
				value: currenEvent?.index,
				comment: comment,
				entityType: "PROCESSOR",
			});
		} else if (currenEvent?.type === "IMPORTER") {
			let _pro = importers;
			_pro[currenEvent?.index].isChecked = currenEvent?.checked;
			_pro[currenEvent?.index].comment = comment;
			console.log("Pro ====>", _pro);
			setImporters(_pro);
			setApplicationRejectDetails(STATUS.REJECTED, {
				isFile: false,
				name: currenEvent?.index,
				entityId: importers[currenEvent?.index].entityId,
				value: currenEvent?.index,
				comment: comment,
				entityType: "IMPORTER",
			});
		} else if (currenEvent?.type === "EXPORTER") {
			let _pro = exporters;
			_pro[currenEvent?.index].isChecked = currenEvent?.checked;
			_pro[currenEvent?.index].comment = comment;
			console.log("Pro ====>", _pro);
			setExporters(_pro);
			setApplicationRejectDetails(STATUS.REJECTED, {
				isFile: false,
				name: currenEvent?.index,
				entityId: exporters[currenEvent?.index].entityId,
				value: currenEvent?.index,
				comment: comment,
				entityType: "EXPORTER",
			});
		}
	};

	useEffect(() => {
		const processorFilesStatus = processing
			? processing.map((process) => {
					return process.files ? process.files[0].status : null;
			  })
			: [];

		const processingDetailsStatus = processing
			? processing.map((process) => {
					return process.formData.isChecked ? APPROVED : REJECTED;
			  })
			: [];
		const ownFarmFileStatus = ownFarm ? [ownFarm.files[0].status] : [];
		const certifiedFarmFileStatus = certifiedFarm ? [certifiedFarm.files[0].status] : [];
		const certifiedFarmerAssociationFileStatus = certifiedFarmerAssociation
			? [certifiedFarmerAssociation.files[0].status]
			: [];

		const importerDetailsStatus = importers
			? importers.map((importer) => (importer.isChecked ? APPROVED : REJECTED))
			: [];
		const exporterDetailsStatus = exporters
			? exporters.map((exporters) => (exporters.isChecked ? APPROVED : REJECTED))
			: [];

		const statusArray = processorFilesStatus.concat(
			processingDetailsStatus,
			ownFarmFileStatus,
			certifiedFarmFileStatus,
			certifiedFarmerAssociationFileStatus,
			importerDetailsStatus,
			exporterDetailsStatus
		);
		statusArray.includes("SELECT") || statusArray.includes("DEFAULT")
			? setCanApprove(false)
			: setCanApprove(true);
		console.log("Status Array", statusArray, importerDetailsStatus, exporterDetailsStatus);

		setDisabled(!statusArray.some((status) => status === REJECTED));
	}, [
		JSON.stringify(processing),
		JSON.stringify(ownFarm),
		JSON.stringify(certifiedFarm),
		JSON.stringify(certifiedFarmerAssociation),
		JSON.stringify(importers),
		JSON.stringify(exporters),
	]);

	return (
		<div className='w-[550px]  pb-10'>
			{renderAccordionComponent(
				"Processing",
				<div>
					{processing &&
						Array.isArray(processing) &&
						processing.map((proc, index) => {
							return (
								<div key={index}>
									<SectionColumn className='w-full'>
										<FormGroup>{renderProcessing(proc.formData, index)}</FormGroup>
									</SectionColumn>
									<SectionRow className='w-full h-5'></SectionRow>

									{proc?.files && (
										<FileButtonSet
											hideBtns={hideBtns}
											files={proc?.files}
											readOnly={readOnly}
											setFiles={(updatedFileArray) => updateFileArray(updatedFileArray, index)}
											selectedFile={selectedFile}
											setSelectedFile={setSelectedFile}
											updateFileArray={(updatedFileArray) =>
												updateFileArray(updatedFileArray, index)
											}
											entityId={proc.entityId}
											formData={proc?.formData}
											setDisabled={() => {}}
											entityType={"PROCESSOR"}
										/>
									)}
								</div>
							);
						})}
				</div>,
				!(data?.processing && Array.isArray(data?.processing) && data?.processing.length > 0)
			)}
			{renderAccordionComponent(
				"Exporter's Own Farm",
				<div>
					<FileButtonSet
						hideBtns={hideBtns}
						files={ownFarm?.files}
						readOnly={readOnly}
						setFiles={(updatedArray) => {
							setOwnFarm({ ...ownFarm, files: updatedArray });
						}}
						selectedFile={selectedFile}
						setSelectedFile={setSelectedFile}
						updateFileArray={(updatedArray) => {
							setOwnFarm({ ...ownFarm, files: updatedArray });
						}}
						entityId={ownFarm?.entityId}
						formData={processing?.formData}
						setDisabled={() => {}}
						entityType={"OWN_FARM"}
					/>
				</div>,
				!data?.exportersOwnFarm.documentFilePath
			)}
			{renderAccordionComponent(
				"Exporter Certified Farmer",
				<div>
					<FileButtonSet
						hideBtns={hideBtns}
						files={certifiedFarm?.files}
						readOnly={readOnly}
						setFiles={(updatedArray) => {
							setCertifiedFarm({ ...certifiedFarm, files: updatedArray });
						}}
						selectedFile={selectedFile}
						setSelectedFile={setSelectedFile}
						updateFileArray={(updatedArray) => {
							setCertifiedFarm({ ...certifiedFarm, files: updatedArray });
						}}
						entityId={certifiedFarm?.entityId}
						formData={processing?.formData}
						setDisabled={() => {}}
						entityType={"CERTIFIED_FARM"}
					/>
				</div>,
				!data?.exporterCertifiedFarmer.documentFilePath
			)}
			{renderAccordionComponent(
				"Exporter Certified Farmer Association",
				<div>
					<FileButtonSet
						hideBtns={hideBtns}
						files={certifiedFarmerAssociation?.files}
						readOnly={readOnly}
						setFiles={(updatedArray) => {
							setCertifiedFarmerAssociation({ ...certifiedFarmerAssociation, files: updatedArray });
						}}
						selectedFile={selectedFile}
						setSelectedFile={setSelectedFile}
						updateFileArray={(updatedArray) => {
							setCertifiedFarmerAssociation({ ...certifiedFarmerAssociation, files: updatedArray });
						}}
						entityId={certifiedFarmerAssociation?.entityId}
						formData={processing?.formData}
						setDisabled={() => {}}
						entityType={"CERTIFIED_FARMER_ASSOCIATION"}
					/>
				</div>,
				!data?.exporterCertifiedFarmerAssociation.documentFilePath
			)}
			{renderAccordionComponent(
				"Importers",
				<div>{renderImporterExporter(importers, "IMPORTER")}</div>,
				!data?.importers || !Array.isArray(data?.importers) || !data?.importers.length > 0
			)}
			{renderAccordionComponent(
				"Exporters",
				<div>{renderImporterExporter(exporters, "EXPORTER")}</div>,
				!data?.exporters || !Array.isArray(data?.exporters) || !data?.exporters.length > 0
			)}
			<RejectModel
				title=''
				reasonsType={CERTIFICATION_BODY_REJECT_REASONS}
				handleReject={handleReject}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</div>
	);
}
