import React, { useEffect, useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";
import { IMPORTER_ROUTES } from "../../../app/router/routes";
import { SectionColumn } from "../../../layouts/sections";
import { Route } from "react-router-dom";

import { createStepper } from "../../../components/stepper/dynamicStepper";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import { getStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { convertStepperRes } from "./dataManipulation";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import ImporterStep1 from "./flow/ImporterStep1";
import ImporterStep2 from "./flow/ImporterStep2";
import ImporterStep3 from "./flow/ImporterStep3";
import ImporterStep4 from "./flow/ImporterStep4";
import ImporterStep5 from "./flow/ImporterStep5";
import ImporterStep6 from "./flow/ImporterStep6";
import ImporterStep7 from "./flow/ImporterStep7";
import { getApplicationStatusOnSession } from "../../certificationBody/dataManipulation";

export const stepperName = "IMPORTER";

const ImpoterReviewHome = () => {
	const [res, setRes] = useState();
	const [stepper, setStepper] = useState();
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getStepStatusByApplicationId(getStakeholderApplicationNumber())
			.then((res) => {
				setRes(res.data);
				console.log("Data", res.data);
				console.log("Stepper=====>", convertStepperRes(res.data));
				setStepper(convertStepperRes(res.data));
			})
			.catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		stepper && setStepperData(createStepper(stepperName, stepper));
	}, [stepper]);

	const routeArray = [
		IMPORTER_ROUTES.STEP01,
		IMPORTER_ROUTES.STEP02,
		IMPORTER_ROUTES.STEP03,
		IMPORTER_ROUTES.STEP04,
		IMPORTER_ROUTES.STEP05,
		IMPORTER_ROUTES.STEP06,
		IMPORTER_ROUTES.STEP07,
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Importer"}
				tableIcon={DASHBOARD_ICONS.importer}
				tableURL={IMPORTER_ROUTES.HOME}
			/>

			{res && stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={IMPORTER_ROUTES.STEP01}>
						<ImporterStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[0]?.reviewStatus === "APPROVED" || res[0]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={IMPORTER_ROUTES.STEP02}>
						<ImporterStep2
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[1]?.reviewStatus === "APPROVED" || res[1]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={IMPORTER_ROUTES.STEP03}>
						<ImporterStep3
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[2]?.reviewStatus === "APPROVED" || res[2]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={IMPORTER_ROUTES.STEP04}>
						<ImporterStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[6]?.reviewStatus === "APPROVED" || res[6]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={IMPORTER_ROUTES.STEP05}>
						<ImporterStep5
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[7]?.reviewStatus === "APPROVED" || res[7]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={IMPORTER_ROUTES.STEP06}>
						<ImporterStep6
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[3]?.reviewStatus === "APPROVED" || res[3]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={IMPORTER_ROUTES.STEP07}>
						<ImporterStep7
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[4]?.reviewStatus === "APPROVED" || res[4]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default ImpoterReviewHome;
