import React, { useEffect, useState } from "react";
import SriLankaNationalLogo from "../../assets/images/DocImage/SriLankaNationalLogo.png";

// import ProfileWrapper from "../../features/user-profile/profileWrapper";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { SectionColumn, SectionRow } from "../../components/section";
import { Avatar, Chip } from "@material-ui/core";
import PopupButton from "../../components/buttons/PopupButton";
import { dateTimeGenerate } from "../../Helpers/datehelper";
import { useStore } from "../../app/context/store";
import { getName_ByRole } from "../../Helpers/uiHelper";
import { getUserNameFromLocal } from "../../Helpers/loginHelper";
import { userRoles } from "../../Helpers/constants";

const Navigation = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [cTime, setTime] = useState();
	const { store, setStore } = useStore();

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(() => {
				return dateTimeGenerate();
			});
		}, 1000);

		return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
	}, []);

	return (
		<nav className='flex flex-row  flex-wrap  justify-between navbar-row mb-4 lg:mb-0 '>
			<div className='w-6/12 justify-start items-start'>
				<div className='flex pl-10 '>
					<div className='header  justify-start'>
						<div className='parent grid-parent'>
							<div className='child'>
								<Avatar alt='Remy Sharp' src='/static/images/avatar/1.jpg' />
							</div>
							<div className='child'>
								<div className='cro-name text-sm lg:text-base'>Hello! {getUserNameFromLocal()}</div>
								<div className='cro-position !text-[10px]  lg:!text-xs'>
									({store?.role === userRoles.dg ? "HOD" : store?.role})
								</div>
							</div>
						</div>
						<div className='lg:pt-4 '>
							<Chip
								className='mt-2'
								size='small'
								label={cTime}
								component='a'
								href='#basic-chip'
								clickable
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='w-6/12 pr-10'>
				<SectionRow className=' justify-end '>
					{/* <PopupButton /> */}
					<div>

						<img
							src="../../SL_Logo.png"
							alt=''

							className='SriLankaNationalLogo !mx-0 text-center hidden sm:block'
						/>
					</div>
					<SectionColumn className='grid justify-self-center ml-8'>
						<span className='docText'>Export Development Board</span>
						<span className='docSubText'>National Organic Control Unit</span>
					</SectionColumn>
				</SectionRow>
			</div>
		</nav>
	);
};

export default Navigation;
