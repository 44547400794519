import { createTheme } from "@mui/material/styles";

const themeOptions = {
	palette: {
		mode: "light",

		themeGreen: {
			main: "#20B05E",
			contrastText: "rgba(255,255,255,1)",
		},
	},
};

export const nocuTheme = createTheme(themeOptions);
