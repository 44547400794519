export const setDropdownValue = (value) => {
	let list = [];
	value?.map((row) => list.push({ key: row, value: row }));

	return list;
};

export const setDropdownValueReactSelect = (value) => {
	let list = [];
	value?.map((row) => list.push({ value: row, label: row }));

	return list;
};