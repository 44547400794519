import React, { useEffect, useState } from "react";
import { SectionRow } from "../../components/section";
import HomeTable from "../../components/table/HomeTable";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { getStakeholderManagementTableRecords } from "../../app/api/NOCU/stakeholderManagementApi";
import TableCount from "../../components/section/tableCount";
import FullScreenDialog from "../../components/model/FullScreenDialog";
import Eye from "@mui/icons-material/Visibility";
import { deleteStakeholder, getStakeholder, getTableRecord, setStakeholder } from "./dataHelper";
import StakeholderManagementReview from "./StakeholderManagementReview";

const columns = ["Username", "Company Name", "Mobile No", "Status"];

const StakeholderManagement = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [count, setCount] = useState(0);
    const [response, setResponse] = useState();
    const [selectedRecord, setSelectedRecord] = useState();
    const [params, setParams] = useState({
		page: 0,
		size: 10,
		companyName: "",
		status: "ALL",
	});

    const handlePage = (page) => {
		setParams({ ...params, page: page });
	};

	const handleSize = (size) => {
		setParams({ ...params, size: size });
	};
    
	const handleFilter = (filter) => {
		setParams({ ...params, status: filter });
	};

    const viewFunction = (index) => {
		console.log("response[index]", response[index]);
		console.log("response?.response[index] >>>>>>>>>>", response[index]);
		setStakeholder(response[index]);
	};

    const handleSearchById = (companyName) => {
		setParams({ ...params, companyName: companyName });
	};

    let filters = [
		{ key: "ALL", value: "ALL" },
		{ key: "APPROVED", value: "APPROVED" },
		{ key: "SUSPENDED", value: "SUSPENDED" },
	];

    useEffect(() => {
		getStakeholderManagementTableRecords(params)
			.then((res) => {
                console.log("res >>>>>>>>>>>", res);
				setRecords(getTableRecord(res.data?.content));
				setCount(res.data?.totalElements);
				setResponse(res.data?.content);
			})
			.catch((e) => console.log(e))
			.finally(() => {
				setIsLoading(false);
			});
	}, [params]);

    return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0'>
				<span className='overViewTitle'>Stakeholder Management</span>
			</SectionRow>
			<SectionRow className='w-full mt-10'>
				<HomeTable
					customViewIcon={
						<FullScreenDialog
							button={<Eye />}
							children={<StakeholderManagementReview />}
							title={"Stakeholder Management Review"}
							onClose={deleteStakeholder}
						/>
					}
					applicationKey={1}
					customSearchPlaceHolder={"Company Name"}
					columns={columns}
					// tableTitle={"User Table"}
					handleSearchById={handleSearchById}
					rows={records}
					view={viewFunction}
					filters={filters}
					setFilter={handleFilter}
					customFilter={<TableCount count={count} />}
					// sort={sorting}
					// handleSort={handleSorting}
					handleSize={handleSize}
					count={count}
					page={params.page}
					size={params.size}
					handlePage={handlePage}
				/>
			</SectionRow>
		</div>
	);
};

export default StakeholderManagement;