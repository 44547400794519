import React from "react";
import { FaDownload   } from 'react-icons/fa';

function DownloadButton({ handleClick, title }) {
    return (
        <button className="download-button" onClick={handleClick}>
            <div className="mr-2">
                <FaDownload className="download-icon" />
            </div>

            {title}
        </button>
    );
}

export default DownloadButton;