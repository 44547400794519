import {
	CERTIFICATION_BODY_ROUTES,
	EXPORTER_ROUTES,
	FARMER_ASSOCIATION_ROUTES,
	FARMER_ROUTES,
	FIELD_VISIT_ROUTES,
	FINAL_COMMENTS_ROUTES,
	FINAL_VOTE_VERIFICATION_ROUTES,
	IMPORTER_ROUTES,
	LABORATORIES_ROUTES,
	PROCESSOR_ROUTES,
	ISSUE_ROUTES,
	SLAAB_ROUTES,
	TECHNICAL_COMMITTEE_ROUTES,
	TRADER_ROUTES,
	PRODUCT_CATEGORY_ROUTES,
	PAYMENT_ROUTES,
	UPLOAD_CERTIFICATE_DETAILS_ROUTES,
	USER_MANAGEMENT_ROUTES,
	STAKEHOLDERS_MANAGEMENT_ROUTES,
} from "./router/routes";
import { DASHBOARD_ICONS } from "../Helpers/ICONS";
import { checkTabPermission, getTabs } from "./api/NOCU/permissionAPI";
import { TABS } from "./constants";

const OVERVIEW = {
	icon: DASHBOARD_ICONS.overView,
	label: "Overview",
	path: "/web/overview",
};

const CERTIFICATION_BODY = {
	icon: DASHBOARD_ICONS.certificationBody,
	label: "Certification Body",
	path: CERTIFICATION_BODY_ROUTES.HOME + "/1",
	path2: CERTIFICATION_BODY_ROUTES.HOME,
};
const FARMER = {
	icon: DASHBOARD_ICONS.farmer,
	label: "Farmer",
	path: FARMER_ROUTES.HOME + "/1",
	path2: FARMER_ROUTES.HOME,
};
const LABORATORIES = {
	icon: DASHBOARD_ICONS.labs,
	label: "Laboratories",
	path: LABORATORIES_ROUTES.HOME + "/1",
	path2: LABORATORIES_ROUTES.HOME,
};
const FARMER_ASSOCIATION = {
	icon: DASHBOARD_ICONS.farmerAssociation,
	label: "Farmer Association",
	path: FARMER_ASSOCIATION_ROUTES.HOME + "/1",
	path2: FARMER_ASSOCIATION_ROUTES.HOME,
};
const EXPORTER = {
	icon: DASHBOARD_ICONS.exporter,
	label: "Exporter",
	path: EXPORTER_ROUTES.HOME + "/1",
	path2: EXPORTER_ROUTES.HOME,
};
const IMPORTER = {
	icon: DASHBOARD_ICONS.importer,
	label: "Importer",
	path: IMPORTER_ROUTES.HOME + "/1",
	path2: IMPORTER_ROUTES.HOME,
};
const PROCESSOR = {
	icon: DASHBOARD_ICONS.processor,
	label: "Processor",
	path: PROCESSOR_ROUTES.HOME + "/1",
	path2: PROCESSOR_ROUTES.HOME,
};
const TRADER = {
	icon: DASHBOARD_ICONS.trader,
	label: "Trader",
	path: TRADER_ROUTES.HOME + "/1",
	path2: TRADER_ROUTES.HOME,
};
const SLAAB = {
	icon: DASHBOARD_ICONS.slaab,
	label: "SLAB",
	path: SLAAB_ROUTES.HOME + "/1",
	path2: SLAAB_ROUTES.HOME,
};
const PRODUCT_CATEGORY = {
	icon: DASHBOARD_ICONS.productCategory,
	label: "Category Registration",
	path: PRODUCT_CATEGORY_ROUTES.HOME + "/1",
	path2: PRODUCT_CATEGORY_ROUTES.HOME,
};
const FIELD_VISIT = {
	icon: DASHBOARD_ICONS.fieldVisit,
	label: "Field Visit",
	path: FIELD_VISIT_ROUTES.HOME + "/1",
	path2: FIELD_VISIT_ROUTES.HOME,
};
const CERTIFICATE_UPLOAD = {
	icon: DASHBOARD_ICONS.certificateUpload,
	label: "Upload Cerificate Details",
	path: UPLOAD_CERTIFICATE_DETAILS_ROUTES.HOME + "/1",
	path2: UPLOAD_CERTIFICATE_DETAILS_ROUTES.HOME,
};
const FINAL_COMMENTS = {
	icon: DASHBOARD_ICONS.finalComments,
	label: "Tech Committee Recommendations",
	path: FINAL_COMMENTS_ROUTES.HOME + "/1",
	path2: FINAL_COMMENTS_ROUTES.HOME,
};
const TECHNICAL_COMMITTEE = {
	icon: DASHBOARD_ICONS.technicalCommittee,
	label: "Technical Committee",
	path: TECHNICAL_COMMITTEE_ROUTES.HOME + "/1",
	path2: TECHNICAL_COMMITTEE_ROUTES.HOME,
};
const FINAL_VOTE = {
	icon: DASHBOARD_ICONS.vote,
	label: "Final Vote",
	path: FINAL_VOTE_VERIFICATION_ROUTES.HOME + "/1",
	path2: FINAL_VOTE_VERIFICATION_ROUTES.HOME,
};
const ISSUE = {
	icon: DASHBOARD_ICONS.sign,
	label: "Signature",
	path: ISSUE_ROUTES.HOME + "/1",
	path2: ISSUE_ROUTES.HOME,
};
const PAYMENT = {
	icon: DASHBOARD_ICONS.payment,
	label: "Payment",
	path: PAYMENT_ROUTES.HOME + "/1",
	path2: PAYMENT_ROUTES.HOME,
};
const USER_MANAGEMENT = {
	icon: DASHBOARD_ICONS.userManagement,
	label: "User Management",
	path: USER_MANAGEMENT_ROUTES.HOME + "/1",
	path2: USER_MANAGEMENT_ROUTES.HOME,
};
const STAKEHOLDERS_MANAGEMENT = {
	icon: DASHBOARD_ICONS.userManagement,
	label: "Stakeholder Management",
	path: STAKEHOLDERS_MANAGEMENT_ROUTES.HOME + "/1",
	path2: STAKEHOLDERS_MANAGEMENT_ROUTES.HOME,
};
const REPORT_GENERATION = {
	icon: DASHBOARD_ICONS.report,
	label: "Reports",
	path: "/web/report",
};
const INQUIRY = {
	icon: DASHBOARD_ICONS.inquiry,
	label: "Inquiry",
	path: "/web/inquiry",
};

const SUMMARY = {
	icon: DASHBOARD_ICONS.summary,
	label: "Summary",
	path: "/web/summery" + "/1",
	path2: "/web/summery",
};
const PROFILE = {
	icon: DASHBOARD_ICONS.user,
	label: "Profile",
	path: "/web/profile",
};
const LOG_OUT = {
	icon: DASHBOARD_ICONS.logout,
	label: "Log out",
	path: "",
};

export const setDrawerItemsList = (list) => {
	let tabs = [];
	if (list) {
		list.includes(TABS.OVERVIEW) && tabs.push(OVERVIEW);
		list.includes(TABS.CERTIFICATION_BODY) && tabs.push(CERTIFICATION_BODY);
		list.includes(TABS.LABORATORIES) && tabs.push(LABORATORIES);
		list.includes(TABS.FARMER) && tabs.push(FARMER);
		list.includes(TABS.FARMER_ASSOCIATION) && tabs.push(FARMER_ASSOCIATION);
		list.includes(TABS.EXPORTER) && tabs.push(EXPORTER);
		list.includes(TABS.IMPORTER) && tabs.push(IMPORTER);
		list.includes(TABS.PROCESSOR) && tabs.push(PROCESSOR);
		list.includes(TABS.TRADER) && tabs.push(TRADER);
		list.includes(TABS.SLAAB) && tabs.push(SLAAB);
		list.includes(TABS.FIELD_VISIT) && tabs.push(FIELD_VISIT);
		list.includes(TABS.FINAL_COMMENT) && tabs.push(FINAL_COMMENTS);
		list.includes(TABS.CERTIFICATE_UPLOAD) && tabs.push(CERTIFICATE_UPLOAD);
		list.includes(TABS.TECHNICAL_COMMITTEE) && tabs.push(TECHNICAL_COMMITTEE);
		list.includes(TABS.FINAL_VOTE) && tabs.push(FINAL_VOTE);
		list.includes(TABS.ISSUE) && tabs.push(ISSUE);
		list.includes(TABS.PRODUCT_CATEGORY_REVIEW) && tabs.push(PRODUCT_CATEGORY);
		list.includes(TABS.PAYMENT) && tabs.push(PAYMENT);
		// list.includes(TABS.INQUIRY) && tabs.push(INQUIRY);
		list.includes(TABS.REPORT_GENERATION) && tabs.push(REPORT_GENERATION);
		list.includes(TABS.USER_MANAGEMENT) && tabs.push(USER_MANAGEMENT);
		list.includes(TABS.STAKEHOLDERS_MANAGEMENT) && tabs.push(STAKEHOLDERS_MANAGEMENT);
		list.includes(TABS.SUMMARY) && tabs.push(SUMMARY);
		list.includes(TABS.PROFILE) && tabs.push(PROFILE);
		list.includes(TABS.LOG_OUT) && tabs.push(LOG_OUT);
	}
	return tabs.length === 0 ? null : tabs;
};

const config = {
	all: setDrawerItemsList(getTabs()),
};

export default config;
