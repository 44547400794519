import React from "react";
import { FaAngleRight } from "react-icons/fa";

function ViewMoreButton({ name, icon, handleClick, className, title, disabled }) {
	return (
		<button
			disabled={disabled}
			name={name}
			//	variant={"contained"}
			className={`view-more-button ${className}`}
			//	startIcon={icon}
			onClick={handleClick}>
			<span className='text-sm'>{title}</span>
			<FaAngleRight />
		</button>
	);
}

export default ViewMoreButton;
