import React from "react";
import { Button } from "@mui/material";
import { height } from "@mui/system";
import { navigateSelf } from "../../app/router/routerManager";
import { FIELD_VISIT_ROUTES } from "../../app/router/routes";

function ResetButton({ name, icon, path, className, title, color, disabled }) {
	return (
		<Button
			disabled={disabled}
			name={name}
			variant={"contained"}
			className={`!capitalize ${className}`}
			sx={{
				width: 100,
				borderRadius: 3,
				mt: 1,
				height: 25,
				color: "#DCFCE7",
			}}
			color={color ? color : "error"}
			startIcon={icon}
			onClick={() => navigateSelf(path)}>
			{title}
		</Button>
	);
}

export default ResetButton;
