import React from "react";
import FileButtonWrapper from "./FileButtonWrapper";
import { getFileURL } from "./../../app/api/file_api";
import { APPROVED, SELECT } from "../../Helpers/constants";
import { DEFAULT, REJECTED } from "./../../Helpers/constants";
import { SectionRow } from "./../../layouts/sections/index";
import { setApplicationRejectDetails } from "../../Helpers/rejectionHelpers";
import { STATUS } from "../../app/constants";

const FileButtonSet = ({
	files,
	setFiles,
	selectedFile,
	setSelectedFile,
	entityId,
	updateFileArray,
	setDisabled,
	formData,
	readOnly,
	entityType,
	hideBtns,
}) => {
	const changeStatus = (obj, str, comment, showBTN) => ({
		...obj,
		status: str,
		comment: comment,
		showBTN: showBTN,
	});
	const handleFileClick = (file) => {
		let currentFile = file;
		currentFile.path = file.path;
		currentFile.showBTN = readOnly ? false : true;
		let updatedFiles = files?.map((_file) => {
			return _file.path === file.path && _file.status === DEFAULT
				? changeStatus(_file, SELECT, _file.comment, false)
				: _file.status === SELECT
				? changeStatus(_file, DEFAULT, _file.comment, false)
				: _file.status === APPROVED && _file.path === file.path
				? changeStatus(_file, APPROVED, _file.comment, readOnly ? false : true)
				: _file.status === APPROVED && _file.path !== file.path
				? changeStatus(_file, APPROVED, _file.comment, false)
				: _file.status === REJECTED && _file.path !== file.path
				? changeStatus(_file, REJECTED, _file.comment, false)
				: _file;
		});
		if (hideBtns) currentFile.showBTN = false;
		if (hideBtns) console.log("Hide Btn", currentFile);
		setSelectedFile(currentFile);
		setFiles(updatedFiles);
	};

	const approveSelectedFile = () => {
		setSelectedFile({ ...selectedFile, status: APPROVED });
		let updatedFiles = files?.map((_file) => {
			return _file.path === selectedFile.path ? changeStatus(_file, APPROVED, "") : _file;
		});
		// let updatedProducts = state.products;
		// updatedProducts[state.index].files = updatedFiles;
		// setState({ ...state, products: updatedProducts });
		setFiles(updatedFiles);
		updateFileArray(updatedFiles);
		setDisabled(formData, updatedFiles);
		setApplicationRejectDetails(STATUS.APPROVED, {
			isFile: true,
			name: selectedFile?.name,
			entityId: entityId,
			value: selectedFile?.path && selectedFile?.path.split("/").pop(),
			comment: "",
			entityType: entityType || "",
		});
	};
	const rejectSelectedFile = (comment) => {
		setSelectedFile({ ...selectedFile, status: REJECTED, comment: comment });
		let updatedFiles = files?.map((_file) => {
			return _file.path === selectedFile.path ? changeStatus(_file, REJECTED, comment) : _file;
		});
		// let updatedProducts = state.products;
		// updatedProducts[state.index].files = updatedFiles;
		// setState({ ...state, products: updatedProducts });
		setFiles(updatedFiles);
		updateFileArray(updatedFiles);
		setDisabled(formData, updatedFiles);
		setApplicationRejectDetails(STATUS.REJECTED, {
			isFile: true,
			name: selectedFile?.name,
			entityId: entityId,
			value: selectedFile?.path && selectedFile?.path.split("/").pop(),
			comment: comment,
			entityType: entityType || "",
		});
	};

	const renderFileButtons = (files) => {
		return files.map((file, i) => (
			<FileButtonWrapper
				key={i}
				file={file}
				handleFileClick={handleFileClick}
				approveFile={approveSelectedFile}
				rejectFile={rejectSelectedFile}
				disableAction={hideBtns}
			/>
		));
	};
	return (
		<SectionRow className='w-full justify-around items-center '>
			{files && renderFileButtons(files)}
		</SectionRow>
	);
};

export default FileButtonSet;
