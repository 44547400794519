import React, { useEffect, useState } from "react";
import ReviewWrapperNOCU from "../../../../components/wrappers/reviewWrapper";
import DynamicStepper from "./../../../../components/stepper/dynamicStepper";

import { APPROVED, DEFAULT } from "./../../../../Helpers/constants";
import FileButtonSet from "../../../../components/buttons/FileButtonSet";
import ToggleButtonSet from "./../../../../components/buttons/ToggleButtonSet";
import { navigateSelf } from "../../../../app/router/routerManager";
import { IMPORTER_ROUTES } from "./../../../../app/router/routes";
import {
	getApplicationDetails,
	submitApplicationDetails,
} from "../../../../app/api/NOCU/applicationAPI";
import { getFileURL } from "./../../../../app/api/file_api";
import {
	deleteAllApplicationRejectDetails,
	getApplicationRejectDetailsAll,
	setAllRejections,
} from "../../../../Helpers/rejectionHelpers";
import { STATUS } from "../../../../app/constants";
import { getRejctionsByStep } from "../../../../app/api/NOCU/rejectionAPI";
import {
	appendRejectionsMultipleFiles,
	appendRejectionsMultipleForms,
	validateMultiArray,
} from "../dataManipulation";
import { getApplicationStage } from "../../../../components/table/tableHelpers";

const ImporterStep4 = ({
	stepperData,
	stepperName,
	routeArray,
	setIsLoading,
	nextCustomRoute,
	customIndex,
	readOnly,
	hideBtns,
}) => {
	const [prevProductDetails, setPreviousYearProductDetails] = useState();
	const [detailsArray, setDetailsArray] = useState();
	const [nameArray, setNameArray] = useState();
	const [fileArray, setFileArray] = useState();
	const [currentFiles, setCurrentFiles] = useState();
	const [entityIdsArray, setEntityIdsArray] = useState();
	const [currentEntityId, setCurrentEntityId] = useState();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [rejections, setRejections] = useState();
	const [isDisabled, setDisabled] = useState(true);
	const [reviewStatus, setReviewStatus] = useState();
	const [canApprove, setCanApprove] = useState(true);

	console.log("Step1Data", stepperData);
	console.log("Step1routeArray", routeArray);
	useEffect(() => {
		deleteAllApplicationRejectDetails();
		getApplicationDetails(7)
			.then((res) => {
				console.log("response", res);
				setReviewStatus(res?.stepStatus);
				setPreviousYearProductDetails(res?.previousYearProductRequest);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				getRejctionsByStep(7)
					.then((res) => {
						setRejections(res.data);
						console.log("Rejection ======>", res);
					})
					.then((e) => {
						console.log(e);
					})
					.finally(() => {
						setIsLoading(false);
					});
			});
	}, []);

	const [selectedFile, setSelectedFile] = useState();
	useEffect(() => {
		setAllRejections(rejections?.rejectReasonDetailList || []);
	}, [rejections]);

	useEffect(() => {
		detailsArray &&
			rejections &&
			appendRejectionsMultipleForms(
				getApplicationRejectDetailsAll(),
				detailsArray,
				setDetailsArray,
				currentEntityId,
				currentIndex
			);
		fileArray &&
			rejections &&
			appendRejectionsMultipleFiles(
				getApplicationRejectDetailsAll(),
				fileArray,
				setFileArray,
				currentEntityId,
				currentIndex
			);
		detailsArray && setFormData(detailsArray[currentIndex]);
		fileArray && setCurrentFiles(fileArray[currentIndex]);
	}, [rejections, currentIndex]);
	// const initialFormaData = [
	//     { name: "year", label: "Year", value: "---", isChecked: true },
	//     { name: "type", label: "Type", value: "---", isChecked: true },
	//     {
	//         name: "quantity",
	//         label: "Quantity",
	//         value: "---",
	//         isChecked: true,
	//     },
	//     {
	//         name: "hscode",
	//         label: "HS Code",
	//         value: "---",
	//         isChecked: true,
	//     },
	//     {
	//         name: "productCategory",
	//         label: "Product Category",
	//         value: "---",
	//         isChecked: true,
	//     },
	//     {
	//         name: "reexportCountry",
	//         label: "Reexported Country",
	//         value: "---",
	//         isChecked: true,
	//     },
	//     {
	//         name: "directExportCountries",
	//         label: "Direct Export Countries",
	//         value: "---",
	//         isChecked: true,
	//     },
	//     {
	//         name: "retailShopsInSL",
	//         label: "Name of Retail Shops in Sri Lanka",
	//         value: "---",
	//         isChecked: true,
	//     },
	//     {
	//         name: "brandName",
	//         label: "Brand Name",
	//         value: "---",
	//         isChecked: true,
	//     },

	// ];

	const [formData, setFormData] = useState();

	const mapNameArray = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((product) => {
					return { name: product?.year };
			  })
			: null;
	};

	const mapContactPersons = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((product) => {
					return [
						{
							name: "productName",
							label: "Product Name",
							value: product?.productName,
							isChecked: true,
						},
						{
							name: "year",
							label: "Year",
							value: product?.year,
							isChecked: true,
						},
						{
							name: "type",
							label: "Type",
							value: product?.type,
							isChecked: true,
						},
						{
							name: "quantity",
							label: "Quantity",
							value: product?.quantity,
							isChecked: true,
						},
						{
							name: "unit",
							label: "Quantity Type",
							value: product?.unit,
							isChecked: true,
						},
						{
							name: "hscode",
							label: "HS Code",
							value: product?.hscode,
							isChecked: true,
						},
						{
							name: "productCategory",
							label: "Product Category",
							value: product?.productCategory,
							isChecked: true,
						},
						{
							name: "reexportCountry",
							label: "Reexported Country",
							value: product?.reexportCountry,
							isChecked: true,
						},
						{
							name: "directExportCountries",
							label: "Direct Export Countries",
							value: product?.directExportCountries,
							isChecked: true,
						},
						{
							name: "retailShopsInSL",
							label: "Name of Retail Shops in Sri Lanka",
							value: product?.retailShopsInSL,
							isChecked: true,
						},
						{
							name: "brandName",
							label: "Brand Name",
							value: product?.brandName,
							isChecked: true,
						},
						{
							name: "productDetailRemark",
							label: "Product Details Remark",
							value: product?.productDetailRemark,
							isChecked: true,
						},
					];
			  })
			: null;
	};

	const mapFilesArray = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((person, i) => {
					return [
						{
							name: person?.documentType === "NIC" ? "NIC" : "Passport",
							path: getFileURL(person?.documentFilePath),
							status: reviewStatus === "PENDING" ? DEFAULT : APPROVED,
							comment: "",
						},
					];
			  })
			: null;
	};
	const mapEntityIdsArray = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((person) => {
					return person?.prevYearProductId;
			  })
			: null;
	};

	const handlePerson = (index) => {
		let personArray = detailsArray;
		handleResubmitDisable(personArray[index], fileArray[index]);
		setFormData(personArray[index]);
		setCurrentFiles(fileArray[index]);
		setCurrentEntityId(entityIdsArray[index]);
		setCurrentIndex(index);
		console.log("Index", index);
	};
	const updateFileArray = (updatedFiles) => {
		let _filesArray = fileArray;
		if (_filesArray) {
			_filesArray[currentIndex] = updatedFiles;
		}
		setFileArray(_filesArray);
		console.log(_filesArray);
	};

	useEffect(() => {
		// Execute when updating
		setDetailsArray(mapContactPersons(prevProductDetails));
		setNameArray(mapNameArray(prevProductDetails));
		setFileArray(mapFilesArray(prevProductDetails));
		setEntityIdsArray(mapEntityIdsArray(prevProductDetails));
	}, [prevProductDetails]);

	useEffect(() => {
		entityIdsArray && setCurrentEntityId(entityIdsArray[0]);
	}, [entityIdsArray]);

	// useEffect(() => {
	//     console.log("Files ============>", fileArray);
	//     fileArray && validateMultiArray(fileArray) ? setCanApprove(true) : setCanApprove(false);
	// }, [fileArray]);
	useEffect(() => {
		console.log("Selected File ============>", selectedFile?.path);
	}, [selectedFile]);

	const handleNext = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(IMPORTER_ROUTES.STEP05);
	};
	const handleCUstomNext = (route) => {
		navigateSelf(route);
	};
	const closeApplication = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(IMPORTER_ROUTES.HOME + "/1");
	};
	const handleResubmit = (comments) => {
		setIsLoading(true);
		console.log("Resubmit Comments", comments);
		submitApplicationDetails(
			7,
			STATUS.RESUBMIT,
			comments,
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleSubmit = () => {
		setIsLoading(true);
		submitApplicationDetails(
			7,
			STATUS.APPROVED,
			"",
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleReject = (comments) => {
		setIsLoading(true);
		console.log("Reject Comments", comments);
		submitApplicationDetails(
			7,
			STATUS.REJECTED,
			comments,
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				setTimeout(() => {
					console.log("Waiting");
				}, 2000);
				closeApplication();
			})
			.catch((e) => console.log(e));
	};
	const checkAllFiles = (_filesArray) => {
		return _filesArray
			.map((files) => {
				return files.some((item) => item.status == "REJECTED");
			})
			.some((item) => item == true);
	};
	const checkAllData = (_dataArray) => {
		return _dataArray
			.map((data) => data.some((field) => field?.isChecked === false))
			.some((item) => item === true);
	};

	useEffect(() => {
		if (detailsArray) {
			//const filesFalseValue = currentFiles.some((item) => item.status == "REJECTED");
			if (checkAllData(detailsArray)) setDisabled(false);
			else setDisabled(true);
		}
	}, [detailsArray]);

	const handleResubmitDisable = (data, files) => {
		//const filesFalseValue = files.some((item) => item.status == "REJECTED");

		if (checkAllData(detailsArray)) setDisabled(false);
		else setDisabled(true);
		console.log("detailsArray", detailsArray);
	};

	return (
		<div>
			<ReviewWrapperNOCU
				hideBtns={hideBtns}
				readOnly={readOnly}
				index={customIndex || 3}
				entityId={currentEntityId}
				title={"Contact Personnel"}
				setForm={() => {}}
				stepperName={stepperName}
				//files={currentFiles}
				isDisabled={isDisabled}
				formToggleSwitch={
					nameArray && <ToggleButtonSet data={nameArray} handleClick={handlePerson} />
				}
				topBar={
					stepperData &&
					stepperName && (
						<DynamicStepper name={stepperName} stepperData={stepperData} routeArray={routeArray} />
					)
				}
				formData={formData}
				currentFile={selectedFile}
				// fileButtons={
				//     <FileButtonSet
				//         files={currentFiles}
				//         readOnly={readOnly}
				//         setFiles={setCurrentFiles}
				//         selectedFile={selectedFile}
				//         setSelectedFile={setSelectedFile}
				//         updateFileArray={updateFileArray}
				//         entityId={currentEntityId}
				//         formData={formData}
				//         setDisabled={handleResubmitDisable}
				//     />
				// }
				handleReject={handleReject}
				handleResubmit={handleResubmit}
				handleNext={handleSubmit}
				setDisabled={handleResubmitDisable}
				canApprove={canApprove}
				reviewed={
					nextCustomRoute ? () => handleCUstomNext(nextCustomRoute) : readOnly ? handleNext : null
				}
				isFormEmpty={formData ? false : true}
			/>
		</div>
	);
};

export default ImporterStep4;
