import React from "react";
import Select from 'react-select';
import { customStyleSelect } from "./customStyleSelect";

const SelectionFilter = ({ filters, onChange, title, placeholder, selected }) => {
	return (
		<div className='perPageContainer'>
			{title && (
				<label className='mr-2'>
					{title}
					{""} :
				</label>
			)}
			{/* <select
				className='select-filter  perPage !px-3 '
				name='size'
				placeholder={placeholder}
				id='size'
				onChange={(e) => {
					onChange(e.target.value);
				}}>
				{selected ? (
					<option value={selected} disabled selected>
						All
					</option>
				) : (
					<option value='' disabled selected>
						{placeholder}
					</option>
				)}
				{filters.map((fbb) => (
					<option key={fbb.key} value={fbb.value}>
						{fbb.key}
					</option>
				))}
				;
			</select> */}
			<Select
				className="basic-single"
				classNamePrefix="select"
				isSearchable={true}
				placeholder={placeholder}
				name="color"
				options={filters}
				onChange={(e) => {
					onChange(e?.value);
				}}
				styles={customStyleSelect}
				menuPosition="fixed"
			/>
		</div>
	);
};

export default SelectionFilter;
