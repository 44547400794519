import React, { useEffect, useState } from "react";
import TabHome from "../../components/Tabs/TabHome";
import CertificationBodySummaryView from "./ApplicationViewers/CertificationBodySummaryView";
import { getApplicationNumberDetails } from "../../app/api/NOCU/summaryApi";
import FarmerSummaryView from "./ApplicationViewers/FarmerSummaryView";
import FarmerAssociationSummaryView from "./ApplicationViewers/FarmerAssociationSummaryView";
import ImporterSummaryView from "./ApplicationViewers/ImporterSummaryView";
import ExporterSummaryView from "./ApplicationViewers/ExporterSummaryView";
import LaboratoriesSummaryView from "./ApplicationViewers/LaboratoriesSummaryView";
import ProcessorSummaryView from "./ApplicationViewers/ProcessorSummaryView";
import TraderSummaryView from "./ApplicationViewers/TraderSummaryView";

// const tabArray = [
// 	{
// 		label: "CER37158068",
// 		component: (
// 			<div>
// 				<CertificationBodySummaryView applicationId={"CER37158068"} />
// 			</div>
// 		),
// 	},
// ];

const ApplicationSummaryView = ({ applicationId }) => {
	const [tabArray, setTabArray] = useState();

	const manipulateTabArray = (data) => {
		const mappedArray =
			data?.applicationDetailList &&
			Array.isArray(data?.applicationDetailList) &&
			data?.applicationDetailList.map((application, index) => {
				return {
					label: application?.applicationNumber,
					component: (
						<div>
							{application?.stakeholderType === "CERTIFICATION_BODY" ? (
								<CertificationBodySummaryView applicationId={application?.applicationNumber} />
							) : application?.stakeholderType === "FARMER" ? (
								<FarmerSummaryView applicationId={application?.applicationNumber} />
							) : application?.stakeholderType === "FARMER_ASSOCIATION" ? (
								<FarmerAssociationSummaryView applicationId={application?.applicationNumber} />
							) : application?.stakeholderType === "IMPORTER" ? (
								<ImporterSummaryView applicationId={application?.applicationNumber} />
							) : application?.stakeholderType === "EXPORTER" ? (
								<ExporterSummaryView applicationId={application?.applicationNumber} />
							) : application?.stakeholderType === "LABORATORIES" ? (
								<LaboratoriesSummaryView applicationId={application?.applicationNumber} />
							) : application?.stakeholderType === "PROCESSOR" ? (
								<ProcessorSummaryView applicationId={application?.applicationNumber} />
							) : application?.stakeholderType === "TRADER" ? (
								<TraderSummaryView applicationId={application?.applicationNumber} />
							) : (
								<></>
							)}
						</div>
					),
				};
			});
		setTabArray(mappedArray);
	};

	useEffect(() => {
		applicationId &&
			getApplicationNumberDetails({ mappedApplicationNumber: applicationId }).then((res) => {
				console.log(res.data);
				manipulateTabArray(res.data);
			});
	}, [applicationId]);
	return <div>{tabArray && <TabHome componentArray={tabArray} />}</div>;
};

export default ApplicationSummaryView;
