import CustomAxios from "../customAxios/customAxios";
import { API_BASE_URL } from "./../core_api";

// Get Table Records

// Body
// {
//     "page":0,
//   "size":10,
//   "status":"SUBMITTED",
//   "officerId":10,
//   "isAscending":true,
//   "stakeholderType":"CERTIFICATION_BODY",
//   "applicationNumber": ""
// }

export const getTableRecords = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/stakeholder/v1/get-by-status-and-type`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("getTableRecords", res);
			return res;
		})
		.catch((error) => {
			console.log("getTableCounts error!");
			throw error.response;
		});
};

//Get mapped table records
export const getMapTableRecords = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/workflow/v1/get-mapped-application-by-stage`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("getTableRecords", res);
			return res;
		})
		.catch((error) => {
			console.log("getTableCounts error!");
			throw error.response;
		});
};

//Get mapped Application Details
export const getMapApplicationDetails = async (id) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/workflow/v1/get-details-by-mappedApplication/${id}`,
		headers: {},

	})
		.then((res) => {
			console.log("get Map Application Details", res);
			return res;
		})
		.catch((error) => {
			console.log("get Map Application Details error!");
			throw error.response;
		});
};

//Get counts for Table

export const getTableCounts = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/stakeholder/v1/get-count-by-status-and-type`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("getTableCounts", res);
			return res.data;
		})
		.catch((error) => {
			console.log("getTableCounts error!");
			throw error.response;
		});
};

//Get Map Table Count
export const getMapTableCounts = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/workflow/v1/get-mapped-application-count-by-stage`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("getTableCounts", res);
			return res.data;
		})
		.catch((error) => {
			console.log("getTableCounts error!");
			throw error.response;
		});
};

export const getDistrict = async () => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `master-data/v1/get-all-districtName`,
		headers: {},
	})
		.then((res) => {
			console.log("getDistricts", res);
			return res.data;
		})
		.catch((error) => {
			console.log("getDistricts error!");
			throw error.response;
		});
};

export const getDivisionalSecretariatName = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `master-data/v1/get-all-divisionalSecretariatName`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("getDistricts", res);
			return res.data;
		})
		.catch((error) => {
			console.log("getDistricts error!");
			throw error.response;
		});
};

export const getGramaNiladhariDivisions = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `master-data/v1/get-all-gramaNiladhariDivisionName`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("gramaNiladhariDivisionName", res);
			return res.data;
		})
		.catch((error) => {
			console.log("gramaNiladhariDivisionName error!");
			throw error.response;
		});
};
