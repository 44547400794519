import axios from "axios";
import { AUTH_BASE_URL } from "../core_api";
import { logOutApi } from "../authApi/authApi";
import { navigateSelf } from "../../router/routerManager";

export const GET_ACCESS_TOKEN_API = "/auth/token/refresh";

const CustomAxios = axios.create({});

CustomAxios.interceptors.request.use(
	(req) => {
		req.headers["AUTHORIZATION"] = localStorage.getItem("ACCESSTOKEN");
		return req;
	},
	(err) => {
		return Promise.reject(err);
	}
);

CustomAxios.interceptors.response.use(
	(res) => {
		return res;
	},
	(err) => {
		const originalReq = err.config;
		const status = err.response ? err.response.status : null;
		if (status === 401) {
			return axios({
				method: "GET",
				baseURL: AUTH_BASE_URL,
				url: GET_ACCESS_TOKEN_API,
				headers: {
					AUTHORIZATION: localStorage.getItem("REFRESHTOKEN"),
				},
			})
				.then((response) => {
					localStorage.setItem("REFRESHTOKEN", response.data.refresh_token);
					localStorage.setItem("ACCESSTOKEN", response.data.access_token);
					console.log(response, "*************Get Access Token response*******************");
					return CustomAxios(originalReq);
				})
				.catch((error) => {
					console.log("CUSTOM AXI ---------->", error);
					if (status === 403) {
						logOutApi().then((res) => {
							sessionStorage.clear();
							localStorage.clear();
							navigateSelf("/error/session-error");
						}).catch(e => console.log(e))
						console.log("ERROR 403")
						return err.response;
					} else {
						logOutApi().then((res) => {
							sessionStorage.clear();
							localStorage.clear();
							navigateSelf("/error/session-error");
						}).catch(e => console.log(e))
						return Promise.resolve(err);
					}
				});
		} else if (status === 403) {
			console.log("ERROR 403")
			return err.response;
		} else {
			return Promise.resolve(err);
		}
	}
);

export default CustomAxios;
