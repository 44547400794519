import { SectionRow } from "../../components/section";
import React, { useEffect, useState } from "react";

import { useStore } from "../../app/context/store";
import { useAlert } from "../../app/context/alerts";
import { userRoles } from "../../Helpers/constants";
import OverViewContent from "./components/OverViewContent";
import { getDashBoardCounts } from "../../app/api/NOCU/overviewAPI";
import { getUserId } from "../../app/api/NOCU/helpers/applicationApiHelpers";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const OverView = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const { store, setStore } = useStore();
	const { setAlert } = useAlert();
	const [OverViewData, setOverViewData] = useState();
	const [OverViewDataDC, setOverViewDataDC] = useState();
	const [timeRange, setTimeRange] = useState("ALL");
	const [certification_body, setCertification_body] = useState();
	const [laboratories, setLaboratories] = useState();
	const [farmer, setFarmer] = useState();
	const [farmer_association, setFarmer_association] = useState();
	const [exporter, setExporter] = useState();
	const [importer, setImporter] = useState();
	const [trader, setTrader] = useState();
	const [processor, setProcessor] = useState();
	const [slaab, setSlaab] = useState();
	const [field_visit, setField_visit] = useState();
	const [final_comment, setFinal_comment] = useState();
	const [final_vote, setFinal_vote] = useState();
	const [issue, setIssue] = useState();
	const [ready_to_issue, setReady_to_issue] = useState();
	const [technical_committee, setTechnical_committee] = useState();

	const [param, setParam] = useState({
		userId: getUserId(),
		timePeriodType: timeRange,
	});

	useEffect(() => {
		setParam({
			userId: getUserId(),
			timePeriodType: timeRange,
		});
	}, [timeRange]);

	useEffect(() => {
		getDashBoardCounts(param)
			.then((res) => {
				setCertification_body(res?.data[0]);
				setLaboratories(res?.data[1]);
				setFarmer(res?.data[2]);
				setFarmer_association(res?.data[3]);
				setExporter(res?.data[4]);
				setImporter(res?.data[5]);
				setTrader(res?.data[6]);
				setProcessor(res?.data[7]);
				setSlaab(res?.data[8]);
				setField_visit(res?.data[9]);
				setFinal_comment(res?.data[10]);
				setFinal_vote(res?.data[11]);
				setIssue(res?.data[12]);
				setReady_to_issue(res?.data[13]);
				setTechnical_committee(res?.data[14]);
				setIsLoading(false);

			})
			.catch((e) => {
				console.log(e);
			});
	}, [param]);

	const renderView = () => {
		switch (store.role) {
			case userRoles.ad:
				return <OverViewContent
					certification_body={certification_body}
					laboratories={laboratories}
					farmer={farmer}
					farmer_association={farmer_association}
					exporter={exporter}
					importer={importer}
					trader={trader}
					processor={processor}
					slaab={slaab}
					field_visit={field_visit}
					final_comment={final_comment}
					final_vote={final_vote}
					issue={issue}
					ready_to_issue={ready_to_issue}
					technical_committee={technical_committee}
					setTimeRange={setTimeRange} />;
			default:
				return <OverViewContent
					certification_body={certification_body}
					laboratories={laboratories}
					farmer={farmer}
					farmer_association={farmer_association}
					exporter={exporter}
					importer={importer}
					trader={trader}
					processor={processor}
					slaab={slaab}
					field_visit={field_visit}
					final_comment={final_comment}
					final_vote={final_vote}
					issue={issue}
					ready_to_issue={ready_to_issue}
					technical_committee={technical_committee}
					setTimeRange={setTimeRange} />;
		}
	};

	return <React.Fragment><LoaderComponent isOpen={isLoading} />{renderView()}</React.Fragment>;
};

export default OverView;
