import React, { useEffect, useState } from "react";
import FourContainersLayout from "../../layouts/page/fourContainers";

import ReviewCheckList from "../../components/review/reviewCheckList";
import { Button, TextareaAutosize } from "@mui/material";
import { SectionColumn, SectionRow } from "../../layouts/sections/index";
import ImageUploadCard from "../../components/cards/imageUploadCard";
import ImagesUploadButtonSet from "../../components/inputs/ImagesUploadButtonSet";
import RejectButton from "../../components/buttons/rejectButton";
import NextButton from "../../components/buttons/nextButton";
import AcceptButton from "../../components/buttons/acceptbutton";
import { FIELD_VISIT_ROUTES } from "../../app/router/routes";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import NavBar from "../../components/navbar/NavBar";
import {
	getStakeholderApplicationNumber,
	getUserId,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";
import {
	deleteAllApplicationRejectDetails,
	getApplicationRejectDetailsAll,
	getApplicationRejectDetailsByEntityId,
	getFieldVisitImages,
} from "../../Helpers/rejectionHelpers";
import { setImagesOnServer } from "../../app/api/NOCU/tableAPI";
import { fileServerSave } from "../../app/api/NOCU/filserverAPI";
import {
	submitApplicationFieldVisitReview,
	submitApplicationReview,
} from "../../app/api/NOCU/applicationAPI";
import RejectModel from "../../components/model/RejectModel";
import { CERTIFICATION_BODY_REJECT_REASONS } from "./../../components/model/RejectModel";
import { getStakeholderType } from "./../../app/api/NOCU/helpers/applicationApiHelpers";
import { navigateSelf } from "../../app/router/routerManager";
import { useAlert } from "../../app/context/alerts";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const formData = [
	{ name: "ch1", label: "Is ICS System document available ?", isChecked: true },
	{ name: "ch2", label: "Organic inputs use ?", isChecked: true },
	{
		name: "ch3",
		label: "Seed planting and transplant records ...",
		isChecked: true,
	},
	{ name: "ch4", label: "Soil management activities", isChecked: true },
	{ name: "ch5", label: "Water Use", isChecked: true },
	{
		name: "ch6",
		label: "Organic Integrity (Contamination, Critical Control points",
		isChecked: true,
	},
	{ name: "ch7", label: "Wast Management", isChecked: true },
	{ name: "ch8", label: "Pest Management activities", isChecked: true },
	{ name: "ch9", label: "Labels and labeling", isChecked: true },
	{ name: "ch10", label: "Storage", isChecked: true },
	{ name: "ch11", label: "Complain Record Available ?", isChecked: true },
];

const FieldVisit = () => {
	const { setAlert } = useAlert();
	const [form, setForm] = useState();
	const [isEnable, setIsEnable] = useState(false);
	const [files, setFiles] = useState([]);
	const [uploadingFiles, setUploadingFiles] = useState([]);
	const [remark, setRemark] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		deleteAllApplicationRejectDetails();
	}, []);

	const handleApproved = async () => {
		setIsLoading(true);
		const _files = await uploadAllFiles();
		const res = await submitApplicationFieldVisitReview(
			1,
			"APPROVED",
			remark,
			getApplicationRejectDetailsAll(),
			"FIELD_VISIT",
			_files
		);
		setTimeout(() => {
			afterSubmit();
		}, 1000);
	};

	const uploadAllFiles = async () => {
		let filePaths = [];
		for (let i = 0; i < files.length; i++) {
			const response = await fileServerSave(files[i])
				.then((res) => {
					if (res?.responseCode === "200 OK") {
						setAlert({
							message: "Uploaded Successfully",
							severity: "success",
							show: true,
						});
						return res;
					} else {
						setAlert({
							message: "Uploaded Unsuccessfully",
							severity: "error",
							show: true,
						});
						return null;
					}
				})
				.catch((e) => {
					console.log(e);
				});
			if (response) {
				filePaths.push({
					applicationNumber: getStakeholderApplicationNumber(),
					name: files[i]?.name,
					path: response?.fileId,
				});
			} else {
				return null;
			}
		}
		setUploadingFiles(filePaths);
		// setTimeout(() => {
		// 	setIsEnable(true);
		// }, 1000);
		console.log("Files=====>", filePaths);
		return filePaths;
	};

	const afterSubmit = () => {
		navigateSelf(FIELD_VISIT_ROUTES.HOME + "/1");
	};

	useEffect(() => {
		// && files.length !== 0
		remark && remark !== "" ? setIsEnable(true) : setIsEnable(false);
	}, [remark, files]);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />

			<NavBar
				title={"Field Visit"}
				tableIcon={DASHBOARD_ICONS.fieldVisit}
				tableURL={FIELD_VISIT_ROUTES.HOME}
			/>

			<FourContainersLayout
				topLeft={
					<SectionColumn className='lg:h-[75vh] px-3 lg:overflow-y-auto customScrollBar'>
						<ReviewCheckList data={formData} setForm={setForm}>
							Field Visit Check List
						</ReviewCheckList>
					</SectionColumn>
				}
				topRight={
					<SectionRow className='w-full mt-6  justify-center '>
						<SectionRow className='w-full justify-center min-h-[430px]'>
							<ImagesUploadButtonSet files={files} setFiles={setFiles} />
						</SectionRow>
						<SectionRow className='w-full justify-center'>
							<TextareaAutosize
								className='border shadow-md rounded w-10/12 p-3'
								minRows={4}
								maxLength='255'
								placeholder='Add remarks'
								onChange={(e) => setRemark(e.target.value)}
							/>
						</SectionRow>
					</SectionRow>
				}
				bottomCenter={
					<SectionRow>
						<AcceptButton
							disabled={!isEnable}
							name={"approve"}
							title={"Submit"}
							handleClick={handleApproved}
						/>
					</SectionRow>
				}
			/>
		</div>
	);
};

export default FieldVisit;
