import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";


export const addExtraDocReq = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/extra-doc/v1/add-extra-doc`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("getCountsForCateRegTable", res);
            return res;
        })
        .catch((error) => {
            console.log("getCountsForCateRegTable error!");
            throw error.response;
        });
};
export const getExtraDocReq = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/extra-doc/v1/get-extra-doc-details`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("getCountsForCateRegTable", res);
            return res;
        })
        .catch((error) => {
            console.log("getCountsForCateRegTable error!");
            throw error.response;
        });
};
export const updateExtraDocReq = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/extra-doc/v1/update-status-and-path`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("getCountsForCateRegTable", res);
            return res;
        })
        .catch((error) => {
            console.log("getCountsForCateRegTable error!");
            throw error.response;
        });
};