import { Chip, Grid, Tooltip } from "@mui/material";
import React from "react";

import { Table } from "react-bootstrap";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HistoryIcon from "@mui/icons-material/History";
import Eye from "@mui/icons-material/Visibility";

import { LOADERS } from "../../app/constants";
import { SectionRow } from "../../layouts/sections";
import CommonSelection from "../section/commonSelection";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { stringCrop } from "./../../Helpers/stringCrop";
import {
	getStakeholderApplicationNumber,
	setMappedApplicationNumber,
	setStakeholderApplicationNumber,
	setStakeholderType,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { deleteAllApplicationRejectDetails } from "../../Helpers/rejectionHelpers";
import HistoryPrompt from "../model/HistoryPrompt";

const HomeTable = ({
	useMappedApplicationNumber,
	applicationKey,
	columns,
	rows,
	count,
	view,
	history,
	filters,
	setFilter,
	sort,
	handleSort,
	handleSize,
	page,
	size,
	handlePage,
	tableTitle,
	handleSearchById,
	filterTitle,
	customFilter,
	customViewIcon,
	customSearchPlaceHolder,
	customSearchDropdown,
}) => {
	let pageSize = [
		{ key: 5, value: 5 },
		{ key: 10, value: 10 },
		{ key: 25, value: 25 },
	];
	const renderTableHeading = (headings) => {
		return headings.map((heading, i) => {
			return (
				<th key={i} style={{ verticalAlign: "left" }} className='thBtn !text-left align-bottom'>
					{heading}
				</th>
			);
		});
	};

	const renderRow = (data) => {
		return data.map((row, index) => (
			<tbody key={index} className='tbody'>
				<tr align='left'>
					{row.map((item, i) => (
						<td key={i} className='tdStart !text-left'>
							{/* {item} */}
							{item.length > 17 ? (
								<Tooltip title={<div className='text-base'>{item}</div>}>
									<div>{stringCrop(item, 17)}</div>
								</Tooltip>
							) : (
								item
							)}
						</td>
					))}
					{view && (
						<td className='tdStart '>
							{customViewIcon ? (
								<div
									onClick={() => {
										view(index);
									}}>
									{customViewIcon}
								</div>
							) : (
								<Eye
									onClick={() => {
										useMappedApplicationNumber
											? setMappedApplicationNumber(row[applicationKey])
											: setStakeholderApplicationNumber(row[applicationKey]);
										deleteAllApplicationRejectDetails();
										view(index);
									}}
								/>
							)}
						</td>
					)}
					{history && (
						<td className='tdStart '>
							<HistoryPrompt
								applicationId={
									useMappedApplicationNumber ? row[applicationKey] : row[applicationKey]
								}
								button={
									<HistoryIcon
										onClick={() => {
											history(index);
										}}
									/>
								}
							/>
						</td>
					)}
				</tr>
			</tbody>
		));
	};
	const handleNextPage = () => {
		handlePage(page + 1);
	};
	const handlePrevPage = () => {
		handlePage(page - 1);
	};

	return (
		<div
			className='companyRegTbleContainer  customScrollBar mb-3  overflow-x-auto shadow-xl '
			style={{
				borderTopLeftRadius: "15px",
				borderTopRightRadius: "15px",
				borderBottomLeftRadius: "15px",
				borderBottomRightRadius: "15px",
			}}>
			<Grid
				container
				style={{
					borderTopLeftRadius: "15px",
					borderTopRightRadius: "15px",
				}}
				className=' overflow-hidden min-w-[900px] '>
				<Grid item xs={9} className='tableHeaderItem '>
					<SectionRow className='w-full'>
						{filters && setFilter && (
							<SectionRow className='items-center'>
								<CommonSelection
									adjustable={"20"}
									filters={filters}
									onChange={setFilter}
									title={`Filter By ${filterTitle ? filterTitle : "Status"}`}
								/>
								{/* <span className="ml-4">Count : {count}</span> */}
							</SectionRow>
						)}
						{tableTitle && (
							<SectionRow className='items-center'>
								<Chip icon={<HowToRegIcon />} label={tableTitle} />
							</SectionRow>
						)}
						{customFilter}
					</SectionRow>
				</Grid>

				{!customSearchDropdown ? (
					<Grid item xs={3} className='tableHeaderSearch'>
						<label className='mr-2'> Search By :</label>
						<input
							placeholder={customSearchPlaceHolder ? customSearchPlaceHolder : "Application ID"}
							className='searchIconText w-40'
							onChange={(event) => {
								handleSearchById(event.target.value);
							}}
						/>
					</Grid>
				) : (
					<Grid item xs={3} className='tableHeaderSearch'>
						{customSearchDropdown}
					</Grid>
				)}

				<Grid item xs={12} className='tableHeaderSearch'>
					<Table responsive>
						<thead>
							<tr>
								{renderTableHeading(columns)}
								{view && (
									<th style={{ verticalAlign: "initial" }} className='thBtnStart  !align-bottom'>
										View
									</th>
								)}
								{history && (
									<th style={{ verticalAlign: "initial" }} className='thBtnStart  !align-bottom'>
										History
									</th>
								)}
							</tr>
						</thead>

						{!rows ? (
							<tbody className='tbody'>
								<tr align='center'>
									<td colSpan='12'>
										<br />
										<img src={LOADERS.tableLoader} alt='loading...' />
									</td>
								</tr>
							</tbody>
						) : rows.length === 0 ? (
							<tbody className='tbody'>
								<tr align='center'>
									<td colSpan='12'>
										<br />
										<div style={{ fontSize: "10px", fontWeight: "bold" }}>No Details Found</div>
									</td>
								</tr>
							</tbody>
						) : (
							renderRow(rows)
						)}
					</Table>
				</Grid>
				<Grid item xs={3} style={{ borderBottomLeftRadius: "15px" }} className='paginationItem'>
					{sort && handleSort && (
						<CommonSelection filters={sort} onChange={handleSort} title={"Sort by date"} />
					)}
				</Grid>
				<Grid item xs={3} className='paginationItem'></Grid>
				<Grid item xs={3} className='paginationItem '>
					{handleSize && (
						<CommonSelection
							filters={pageSize}
							defaultValue={size}
							onChange={handleSize}
							title={"Rows per page"}
						/>
					)}
				</Grid>
				<Grid
					item
					xs={3}
					style={{ borderBottomRightRadius: "15px" }}
					className='paginationItem justify-center items-center '>
					<SectionRow className='justify-center items-center perPageContainer'>
						{page <= 0 ? (
							<ArrowBackIosIcon style={{ fontSize: "medium" }} color='disabled' />
						) : (
							<ArrowBackIosIcon
								style={{ fontSize: "medium" }}
								onClick={() => {
									handlePrevPage();
								}}
							/>
						)}
						<Chip
							label={`Page ${page + 1} of ${Math.ceil(count / size)}`}
							sx={{ bgcolor: "#20B15E", color: "#ffffff" }}
							size='small'
						/>
						{(page + 1) * size < count ? (
							<ArrowForwardIosIcon
								style={{ fontSize: "medium" }}
								onClick={() => {
									handleNextPage();
								}}
							/>
						) : (
							<ArrowForwardIosIcon style={{ fontSize: "medium" }} color='disabled' />
						)}
					</SectionRow>
				</Grid>
			</Grid>
		</div>
	);
};

export default HomeTable;
