import React, { useState } from "react";
import { ErrorMessage, useField } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function TextFieldRegistration(props) {
	const [field, meta] = useField(props);
	const [showPassword, setShowPassword] = useState(false);
	return (
		<div>
			{props?.type === "password" && (
				<span className='relative float-right   right-8'>
					{showPassword === false ? (
						<VisibilityIcon
							sx={{ position: "absolute", mt: 1.3, color: "#444" }}
							fontSize='small'
							onClick={() => setShowPassword(true)}
						/>
					) : (
						<VisibilityOffIcon
							sx={{ position: "absolute", mt: 1.3, color: "#444" }}
							fontSize='small'
							onClick={() => setShowPassword(false)}
						/>
					)}
				</span>
			)}
			<input
				{...props}
				className='loginCardInputsField'
				placeholder={props.placeholder}
				type={props.type === "password" ? `${!showPassword ? "password" : "text"}` : props.type}
				name={props.name}
				{...field}
			/>

			<ErrorMessage component='div' name={field.name} className='error-msg !-mt-3 pb-4' />
		</div>
	);
}
