import React, { useEffect, useState } from "react";

import { Route } from "react-router-dom";
import { createStepper, disableAlert, STEPPER_STATUS } from "../../../components/stepper/dynamicStepper";
import { FINAL_COMMENTS_ROUTES } from "../../../app/router/routes";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import NavBar from "../../../components/navbar/NavBar";
import CertificationBodyReviewStep1 from "../../certificationBody/review/flow/certificationBodyReviewStep1";
import CertificationBodyReviewStep4 from "../../certificationBody/review/flow/certificationBodyReviewStep4";
import CertificationBodyReviewStep5 from "../../certificationBody/review/flow/certificationBodyReviewStep5";
import { SectionColumn } from "../../../components/section";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";

export const stepperName = "FCView";

const FinalCBView = () => {
    const stepper = [
        {
            step: "Business Details",
            status: STEPPER_STATUS.COMPLETED,
        },

        {
            step: "Certificates",
            status: STEPPER_STATUS.COMPLETED,
        },
        { step: "Other Documents", status: STEPPER_STATUS.COMPLETED },
    ];
    const [stepperData, setStepperData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setStepperData(createStepper(stepperName, stepper));
        disableAlert();
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    const routeArray = [
        FINAL_COMMENTS_ROUTES.CBSTEP01,
        FINAL_COMMENTS_ROUTES.CBSTEP02,
        FINAL_COMMENTS_ROUTES.CBSTEP03,
    ];
    return (
        <div>
            <LoaderComponent isOpen={isLoading} />
            <NavBar
                stepperName={stepperName}
                title={"Final Comments Application Review"}
                tableIcon={DASHBOARD_ICONS.finalComments}
                tableURL={FINAL_COMMENTS_ROUTES.REVIEW}
            />

            {stepperData && (
                <SectionColumn className='w-full pt-1'>
                    <Route path={FINAL_COMMENTS_ROUTES.CBSTEP01}>
                        <CertificationBodyReviewStep1
                            stepperData={stepperData}
                            stepperName={stepperName}
                            routeArray={routeArray}
                            setIsLoading={setIsLoading}
                            customIndex={0}
                            nextCustomRoute={FINAL_COMMENTS_ROUTES.CBSTEP02}
                            readOnly
                            hideFiles={true}
                        />
                    </Route>

                    <Route path={FINAL_COMMENTS_ROUTES.CBSTEP02}>
                        <CertificationBodyReviewStep4
                            stepperData={stepperData}
                            stepperName={stepperName}
                            routeArray={routeArray}
                            setIsLoading={setIsLoading}
                            customIndex={1}
                            nextCustomRoute={FINAL_COMMENTS_ROUTES.CBSTEP03}
                            readOnly
                        />
                    </Route>
                    <Route path={FINAL_COMMENTS_ROUTES.CBSTEP03}>
                        <CertificationBodyReviewStep5
                            stepperData={stepperData}
                            stepperName={stepperName}
                            routeArray={routeArray}
                            setIsLoading={setIsLoading}
                            customIndex={2}
                            nextCustomRoute={FINAL_COMMENTS_ROUTES.REVIEW}
                            readOnly
                        />
                    </Route>
                </SectionColumn>
            )}
        </div>
    );
};

export default FinalCBView;