import React, { useEffect } from "react";
import { useState } from "react";
import { getFileURL } from "../../app/api/file_api";
import { submitFinalApplication } from "../../app/api/NOCU/applicationAPI";
import { fileServerSave } from "../../app/api/NOCU/filserverAPI";
import {
	getMappedApplicationNumber,
	getStakeholderApplicationNumber,
	getStakeholderType,
	getUserId,
	setStakeholderApplicationNumber,
	setStakeholderType,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getMapApplicationDetails } from "../../app/api/NOCU/tableAPI";
import { navigateSelf } from "../../app/router/routerManager";
import {
	FINAL_COMMENTS_ROUTES,
	FINAL_VOTE_VERIFICATION_ROUTES,
	TECHNICAL_COMMITTEE_ROUTES,
} from "../../app/router/routes";
import AcceptButton from "../../components/buttons/acceptbutton";
import FileUploader from "../../components/buttons/FileUploader";
import NextButton from "../../components/buttons/nextButton";
import RejectButton from "../../components/buttons/rejectButton";
import PieChart from "../../components/Chart/PieChart";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import ConfirmationModel from "../../components/model/ConfirmationModel";
import ConfirmationPopup from "../../components/model/ConfirmationPopup";
import NavBar from "../../components/navbar/NavBar";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import { SectionColumn, SectionRow } from "../../components/section";
import { deleteStepper } from "../../components/stepper/dynamicStepper";
import VotingTable from "../../components/table/VotingTable";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import {
	appendRejectionsFieldVisit,
	getVoteCount,
	getVotingComments,
	getVotingTableData,
} from "../certificationBody/dataManipulation";
import BackButton from "../../components/buttons/BackButton";
import ToggleButtonSet from "../../components/buttons/ToggleButtonSet";
import FourContainersLayout from "../../layouts/page/fourContainers";
import TabHome from "../../components/Tabs/TabHome";
import ReviewCheckList from "../../components/review/reviewCheckList";
import ImageWrapper from "../../components/ImagePreview/ImageWrapper";
import ViewMoreButton from "../../components/buttons/ViewMoreButton";
import { Button } from "@mui/material";
import { useAlert } from "../../app/context/alerts";

const FinalVoteVerficationReview = ({ mappedApplicationNumberProp, readOnly }) => {
	const tableHeaders = ["Committee Member", "Vote Status", "Comments"];

	const [_formData, _setFormData] = useState([
		{ name: "ch1", label: "Is ICS System Document Available ?", isChecked: true },
		{ name: "ch2", label: "Organic Inputs User ?", isChecked: true },
		{
			name: "ch3",
			label: "Seed plating and transplant records ...",
			isChecked: true,
		},
		{ name: "ch4", label: "Soil management activities", isChecked: true },
		{ name: "ch5", label: "Water use", isChecked: true },
		{
			name: "ch6",
			label: "Organic Integrity (Contamination Critical Control points",
			isChecked: true,
		},
		{ name: "ch7", label: "Wast Management", isChecked: true },
		{ name: "ch8", label: "Pest Management Activities", isChecked: true },
		{ name: "ch9", label: "Labels and labelling", isChecked: true },
		{ name: "ch10", label: "Storage", isChecked: true },
		{ name: "ch11", label: "Complain Record Available", isChecked: true },
	]);

	const [param, setParams] = useState({
		mappedApplicationNumber: mappedApplicationNumberProp || getMappedApplicationNumber(),
		remark: "",
		reviewStatus: "",
		checkedBy: getUserId(),
		applicationStage: "FINAL_VOTE",
	});
	const { setAlert } = useAlert();
	const [title, setTitle] = useState("comment_table");
	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [color, setColor] = useState("success");
	const [_response, _setResponse] = useState();
	const [_file, _setFile] = useState();
	const [_votesCount, _setVotesCount] = useState();
	const [_votes, _setVotes] = useState();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [nameArray, setNameArray] = useState();
	const [detailsArray, setDetailsArray] = useState();
	const [rejections, setRejections] = useState();
	const [fieldVisitArray, setFieldVisitArray] = useState();
	const [applicationDetails, setApplicationDetails] = useState();
	const [_images, _setImages] = useState();

	useEffect(() => {
		const mapApplicationKey = mappedApplicationNumberProp || getMappedApplicationNumber();

		getMapApplicationDetails(mapApplicationKey)
			.then((res) => {
				console.log("Comments", res.data);
				_setResponse(res.data?.voteDetail);
				_setVotesCount(getVoteCount(res.data));
				_setVotes(getVotingComments(res.data));
				setDetailsArray(res.data?.detailsResponseList);
				setFieldVisitArray(res.data.detailsResponseList[currentIndex].fieldVisitFileEntities);
				setApplicationDetails(
					res.data.detailsResponseList[currentIndex].stakeholderApplicationEntity
				);
				setStakeholderApplicationNumber(
					res.data.detailsResponseList[currentIndex]?.stakeholderApplicationEntity
						?.applicationNumber
				);
				setStakeholderType(
					res.data.detailsResponseList[currentIndex]?.stakeholderApplicationEntity?.stakeholderType
				);
				setRejections(res.data.detailsResponseList[currentIndex].fieldVisitReviewDetails);
				deleteStepper("FVView");
				setIsLoading(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	useEffect(() => {
		setNameArray(mapMultiForms(detailsArray));
	}, [detailsArray]);

	useEffect(() => {
		// Execute when updating
		let images = [];
		fieldVisitArray?.map((item) =>
			images.push({
				name: item?.name,
				path: getFileURL(item?.path),
			})
		);
		_setImages(images);
	}, [fieldVisitArray]);

	useEffect(() => {
		_formData &&
			rejections &&
			appendRejectionsFieldVisit(rejections?.rejectReasonDetailList, _formData, _setFormData);
	}, [rejections]);

	const mapMultiForms = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((item) => {
					return {
						name: item?.stakeholderApplicationEntity.applicationNumber,
						id: item?.stakeholderApplicationEntity.applicationNumber,
						type: item?.stakeholderApplicationEntity.stakeholderType,
					};
			  })
			: null;
	};

	const handleCertificate = (index) => {
		let detailArray = detailsArray;
		setCurrentIndex(index);
		setStakeholderApplicationNumber(
			detailArray[index]?.stakeholderApplicationEntity?.applicationNumber
		);
		setStakeholderType(detailArray[index]?.stakeholderApplicationEntity?.stakeholderType);
		setRejections(detailArray[index].fieldVisitReviewDetails);
		deleteStepper("FVView");
	};

	const handleSubmit = () => {
		setIsLoading(true);
		submitFinalApplication(param)
			.then((res) => {
				navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.HOME + "/1");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handleFile = (_file) => {
		setTitle("pdfViewer");

		fileServerSave(_file)
			.then((res) => {
				if (res?.responseCode === "200 OK") {
					setAlert({
						message: "Uploaded Successfully",
						severity: "success",
						show: true,
					});
					_setFile(res.fileId);
				} else {
					setAlert({
						message: "Uploaded Unsuccessfully",
						severity: "error",
						show: true,
					});
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleChange = (value) => {
		setParams({ ...param, remark: value });
	};

	const navigateRoutes = () => {
		deleteStepper("FCView");
		console.log("StakeholderType", getStakeholderType());
		if (getStakeholderType() === "CERTIFICATION_BODY") {
			navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.CBSTEP01);
		} else if (getStakeholderType() === "IMPORTER") {
			navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.IMSTEP01);
		} else if (getStakeholderType() === "FARMER") {
			navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.FRSTEP01);
		} else if (getStakeholderType() === "FARMER_ASSOCIATION") {
			navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.FASTEP01);
		} else if (getStakeholderType() === "LABORATORIES") {
			navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP01);
		} else if (getStakeholderType() === "EXPORTER") {
			navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.EXPORTER_STEP01);
		} else if (getStakeholderType() === "PROCESSOR") {
			navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.PROCESSOR_STEP01);
		} else if (getStakeholderType() === "TRADER") {
			navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.TRADER_STEP01);
		}
	};

	const handleClick = () => {
		setTitle("comment_table");
	};

	const renderCheckList = (_formData) => {
		return (
			<SectionColumn className='lg:h-[65vh] px-3 lg:overflow-y-auto customScrollBar'>
				{applicationDetails?.isReferFieldVisit ? (
					<ReviewCheckList
						readOnly={true}
						data={_formData}
						setForm={_setFormData}></ReviewCheckList>
				) : (
					<div className='w-full text-center'>No Field Visit Initiated</div>
				)}
			</SectionColumn>
		);
	};

	const componentArray = [
		{
			label: "Factory Visit Details",
			component: <div>{_formData && renderCheckList(_formData)}</div>,
		},
		{
			label: "Factory Visit Photos",
			component: <ImageWrapper data={_images} />,
		},
	];
	useEffect(() => {
		_response && Array.isArray(_response) && _response.length !== 0
			? setTitle("comment_table")
			: setTitle("field_visit");
	}, [_response]);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			{!readOnly && (
				<NavBar
					mappedApplicationNumber
					title={"Final Vote Verification"}
					tableIcon={DASHBOARD_ICONS.technicalCommittee}
					tableURL={FINAL_VOTE_VERIFICATION_ROUTES.HOME}
				/>
			)}

			<SectionRow className='w-full mt-5'>
				<SectionColumn className='w-full h-fit  lg:w-1/2 min-h-[650px] lg:h-[80vh] items-center lg:overflow-y-auto customScrollBar'>
					<div className=''>
						<ToggleButtonSet data={nameArray} handleClick={handleCertificate} certificate={true} />
					</div>
					<div className='mx-auto pl-24'>
						{_response && Array.isArray(_response) && _response.length !== 0 ? (
							<PieChart value={_votesCount} />
						) : (
							<SectionColumn className='w-full text-center'>
								<span className='text-orange-500 mt-10'>
									This application still not open to vote !
								</span>
							</SectionColumn>
						)}
					</div>

					{!readOnly && (
						<SectionColumn className='w-full justify-center mt-8'>
							<SectionRow className='mb-3 mx-auto'>
								<FileUploader
									handleFile={handleFile}
									tittle='Upload Meeting Minutes'
									fileType='.pdf'
								/>
								{/* <ViewMoreButton
									name={"field_visit"}
									title={"View Field Visit"}
									handleClick={() => {
										setTitle("field_visit");
									}}
								/> */}
								<ViewMoreButton
									name={"viewDetails"}
									title={"View More..."}
									handleClick={() => {
										deleteStepper("FVView");
										navigateRoutes();
									}}
								/>
							</SectionRow>
							<SectionRow className='mx-auto'>
								<RejectButton
									className={""}
									handleClick={() => {
										setColor("error");
										setIsOpen(true);
										setParams({ ...param, reviewStatus: "REJECTED" });
									}}
									name='rejectButton'
									title='Reject'
									disabled={false}
								/>
								<AcceptButton
									className={""}
									handleClick={() => {
										setColor("success");
										setIsOpen(true);
										setParams({ ...param, reviewStatus: "APPROVED" });
									}}
									name='nextButton'
									title='Approve'
									color={""}
								/>
							</SectionRow>
						</SectionColumn>
					)}
				</SectionColumn>
				<SectionColumn className='w-full lg:w-1/2 min-h-[75vh] z-10 pr-10'>
					<SectionRow className='w-full justify-center'>
						{/* {title !== "field_visit" && readOnly && (
							<AcceptButton
								className={" !w-[300px]"}
								handleClick={() => {
									setTitle("field_visit");
								}}
								name='nextButton'
								title='View Field Visit Details'
								color={""}
							/>
						)} */}
						<Button
							disabled={!(_response && Array.isArray(_response) && _response.length !== 0)}
							variant={title === "comment_table" ? "contained" : "text"}
							className='!mx-3 !px-10 !pt-1 !capitalize'
							sx={
								title === "comment_table"
									? {
											backgroundColor: "#20B05E",
											"&:hover": {
												backgroundColor: "#167D43",
												borderColor: "#167D43",
												boxShadow: "none",
											},
									  }
									: {
											color: "#20B05E",
											"&:hover": {
												backgroundColor: "#167D43",
												borderColor: "#167D43",
												boxShadow: "none",
												color: "#ffffff",
											},
									  }
							}
							onClick={() => setTitle("comment_table")}>
							View Vote Table
						</Button>
						<Button
							variant={title === "field_visit" ? "contained" : "text"}
							className='!mx-3  !px-10 !pt-1 !capitalize'
							sx={
								title === "field_visit"
									? {
											backgroundColor: "#20B05E",
											"&:hover": {
												backgroundColor: "#167D43",
												borderColor: "#167D43",
												boxShadow: "none",
											},
									  }
									: {
											color: "#20B05E",
											"&:hover": {
												backgroundColor: "#167D43",
												borderColor: "#167D43",
												boxShadow: "none",
												color: "#ffffff",
											},
									  }
							}
							onClick={() => setTitle("field_visit")}>
							View Field Visit Details
						</Button>
					</SectionRow>
					{title === "comment_table" ? (
						<VotingTable rows={_votes} columns={tableHeaders} />
					) : title === "field_visit" ? (
						<SectionColumn>
							<TabHome componentArray={componentArray} />
						</SectionColumn>
					) : (
						<SectionColumn>
							<IFrameViewer path={getFileURL(_file)} files={true} />
						</SectionColumn>
					)}
				</SectionColumn>
			</SectionRow>

			<ConfirmationModel
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={`Please provide the reason for ${
					color === "error" ? "Rejecting " : "Approving"
				} the application`}
				content={`Provide the reason for ${
					color === "error" ? "Rejecting " : "Approving"
				} the application. If you did not wish to ${
					color === "error" ? "Reject " : "Approve"
				} the application, click Cancel and proceed with the corrected choice.`}
				color={color}
				handleClick={handleSubmit}
				handleChange={handleChange}
			/>
		</div>
	);
};

export default FinalVoteVerficationReview;
