import * as Yup from "yup";

const phoneNumberFormat = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,9}$/im;

/**
 *Certification Bodies Upload Certification Validation
 */
export const CB_UC_Validation = Yup.object({
  titleOfOrganicStandard: Yup.object().nullable().required("Title of Organic Standard is required"),
  nameOfAccreditation: Yup.object().nullable().required("Name of Accreditation is required"),
  countryOfAccreditation: Yup.object().nullable().required("Country of Accreditation is required"),
  addressOfAccreditation: Yup.string().required("Address Line 1 is required"),
  addressLine2: Yup.string().required("Address Line 2 is required"),
  certificateNumber: Yup.string().required("Certificate Number is required"),
  accreditationBodyContactPerson: Yup.string().required("Contact Number is required").matches(phoneNumberFormat, { message: "Invalid contact number" }),
  certApprovedDate: Yup.string().required("Certificate Approved Date is required"),
  certExpirationDate: Yup.string().required("Certificate Expiration Date is required"),
  zipCode: Yup.string().required("Zip Code is required"),
})

/**
 *Laboratories Upload Certification Validation
 */
export const LB_UC_Validation = Yup.object({
  countryOfAccreditation: Yup.object().nullable().required("Country of Certification Body is required"),
  nameOfAccreditation: Yup.object().nullable().required("Name of Certification Body is required"),
  certApprovedDate: Yup.string().required("Certificate Approved Date is required"),
  certExpirationDate: Yup.string().required("Certificate Expiration Date is required"),
  addressOfAccreditation: Yup.string().required("Address Line 1 is required"),
  addressLine2: Yup.string().required("Address Line 2 is required"),
  accreditationBodyContactPerson: Yup.string().required("Contact Number is required"),
  certificateNumber: Yup.string().required("Certificate Number is required"),
  zipCode: Yup.string().required("Zip Code is required"),
})


/**
 * Upload Certification Validation
 */
export const FM_UC_Validation = Yup.object({
  countryOfAccreditation: Yup.object().nullable().required("Country of Certification Body is required"),
  nameOfAccreditation: Yup.object().nullable().required("Name of Certification Body is required"),
  addressOfAccreditation: Yup.string().required("Address Line 1 is required"),
  addressLine2: Yup.string().required("Address Line 2 is required"),
  certificateNumber: Yup.string().required("Certificate Number is required"),
  accreditationBodyContactPerson: Yup.string().required("Contact Number is required").matches(phoneNumberFormat, { message: "Invalid contact number" }),
  certApprovedDate: Yup.string().required("Certificate Approved Date is required"),
  certExpirationDate: Yup.string().required("Certificate Expiration Date is required"),
  titleOfOrganicStandard: Yup.object().nullable().required("Title of Organic Standard is required"),
  zipCode: Yup.string().required("Zip Code is required"),
})

/**
 *Upload Certification other Drop Down values
 */
export const OtherDropDown_Validation = Yup.object({
  nameOfAccreditation: Yup.string().required("Name of Accreditation is required")
})

/**
 *Title of Organic Standards other Drop Down values
 */
export const OtherTitleDropDown_Validation = Yup.object({
  titleOfOrganicStandard: Yup.string().required("Title of Organic Standard is required")
})