import React, { useEffect } from "react";
import { useState } from "react";
import { getFileURL } from "../../app/api/file_api";
import {
	getApplicationDetails,
	submitFinalApplication,
	submitSignedCertificate,
} from "../../app/api/NOCU/applicationAPI";
import {
	getMappedApplicationNumber,
	getStakeholderType,
	getUserId,
	setStakeholderApplicationNumber,
	setStakeholderType,
	setViewOnlyPermission,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getMapApplicationDetails } from "../../app/api/NOCU/tableAPI";
import { navigateSelf } from "../../app/router/routerManager";
import {
	CERTIFICATION_BODY_ROUTES,
	ISSUE_ROUTES,
	TECHNICAL_COMMITTEE_ROUTES,
} from "../../app/router/routes";
import AcceptButton from "../../components/buttons/acceptbutton";
import FileArrayWrapper from "../../components/buttons/FileArrayWrapper";
import FileButtonSet from "../../components/buttons/FileButtonSet";
import NextButton from "../../components/buttons/nextButton";
import RejectButton from "../../components/buttons/rejectButton";
import ToggleButtonSet from "../../components/buttons/ToggleButtonSet";
import ImageWrapper from "../../components/ImagePreview/ImageWrapper";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import ConfirmationPopup from "../../components/model/ConfirmationPopup";
import NavBar from "../../components/navbar/NavBar";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import { SectionColumn, SectionRow } from "../../components/section";
import VotingTable from "../../components/table/VotingTable";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import FourContainersLayout from "../../layouts/page/fourContainers";
import { mapFiles } from "../certificationBody/dataManipulation";
import { deleteStepper } from "../../components/stepper/dynamicStepper";
import ConfirmationModel from "../../components/model/ConfirmationModel";
import FileUploader from "../../components/buttons/FileUploader";
import { fileServerSave } from "../../app/api/NOCU/filserverAPI";
import BackButton from "../../components/buttons/BackButton";
import DownloadButton from "../../components/buttons/DownloadButton";
import { useAlert } from "../../app/context/alerts";
import ViewMoreButton from "../../components/buttons/ViewMoreButton";
import { Button } from "@mui/material";

// ! Wrong aspect of handling upload

const SignatureReview = () => {
	const tableHeaders = ["Committee Member", "Vote Status"];
	const [currentIndex, setCurrentIndex] = useState(0);
	const [nameArray, setNameArray] = useState();
	const [uploadedFile, setUploadedFile] = useState();
	const [detailsArray, setDetailsArray] = useState();
	const [param, setParams] = useState({
		mappedApplicationNumber: getMappedApplicationNumber(),
		remark: "",
		reviewStatus: "APPROVED",
		checkedBy: getUserId(),
		applicationStage: "ISSUE",
		signedCertificatePath: "",
	});
	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [color, setColor] = useState("success");
	const { setAlert } = useAlert();
	const [selectedFile, setSelectedFile] = useState();
	const [_file, _setFile] = useState();
	const [_downloadPDF, _setDownloadPDF] = useState();

	const mapFieldVisit = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((item) => {
					return {
						name: item?.stakeholderApplicationEntity.applicationNumber,
						id: item?.stakeholderApplicationEntity.applicationNumber,
					};
			  })
			: null;
	};

	useEffect(() => {
		const mapApplicationKey = getMappedApplicationNumber();

		getMapApplicationDetails(mapApplicationKey)
			.then((res) => {
				setIsLoading(false);
				setDetailsArray(res.data.detailsResponseList);
				_setDownloadPDF(getFileURL(res?.data.certificatePath));
				setStakeholderApplicationNumber(
					res.data.detailsResponseList[currentIndex].stakeholderApplicationEntity?.applicationNumber
				);
				setStakeholderType(
					res.data.detailsResponseList[currentIndex].stakeholderApplicationEntity?.stakeholderType
				);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	const handleFile = (_file) => {
		setIsLoading(true);
		if (_file.type !== "application/pdf") {
			setAlert({
				message: "Please upload a PDF file",
				severity: "error",
				show: true,
			});
			setIsLoading(false);
		} else {
			_setFile(_file);
			setIsLoading(false);
		}
	};

	const handleDownload = async (path) => {
		setIsLoading(true);
		console.log("pdf", path);
		let _getURL = getFileURL(path);
		const response = await fetch(path);
		const blob = await response.blob();
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = "Certificate.pdf";
		link.click();
		setIsLoading(false);
	};

	useEffect(() => {
		// Execute when updating
		setNameArray(mapFieldVisit(detailsArray));
	}, [detailsArray]);

	const navigateRoutes = () => {
		console.log("StakeholderType", getStakeholderType());
		if (getStakeholderType() === "CERTIFICATION_BODY") {
			navigateSelf(ISSUE_ROUTES.CBSTEP01);
		} else if (getStakeholderType() === "IMPORTER") {
			navigateSelf(ISSUE_ROUTES.IMSTEP01);
		} else if (getStakeholderType() === "FARMER") {
			navigateSelf(ISSUE_ROUTES.FRSTEP01);
		} else if (getStakeholderType() === "FARMER_ASSOCIATION") {
			navigateSelf(ISSUE_ROUTES.FASTEP01);
		} else if (getStakeholderType() === "LABORATORIES") {
			navigateSelf(ISSUE_ROUTES.LBSTEP01);
		} else if (getStakeholderType() === "EXPORTER") {
			navigateSelf(ISSUE_ROUTES.EXPORTER_STEP01);
		} else if (getStakeholderType() === "PROCESSOR") {
			navigateSelf(ISSUE_ROUTES.PROCESSOR_STEP01);
		} else if (getStakeholderType() === "TRADER") {
			navigateSelf(ISSUE_ROUTES.TRADER_STEP01);
		}
	};
	const handleCertificate = (index) => {
		setCurrentIndex(index);
		setStakeholderApplicationNumber(
			detailsArray[index]?.stakeholderApplicationEntity?.applicationNumber
		);
		setStakeholderType(detailsArray[index]?.stakeholderApplicationEntity?.stakeholderType);
	};
	const handlePreview = () => {
		const contentTypeImage = "image/png";
		const contentTypePdf = "application/pdf";
		let reader = new FileReader();
		reader.readAsDataURL(_file);
		reader.onload = (e) => {
			let substringPath = e.target.result.split(";base64,");
			let newSubstringPath = substringPath[0] + ";base64,";

			const byteCharacters = atob(e.target.result.substr(newSubstringPath.length));
			const byteArrays = [];

			for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
				const slice = byteCharacters.slice(offset, offset + 1024);

				const byteNumbers = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);

				byteArrays.push(byteArray);
			}
			const blob = new Blob(byteArrays, {
				type: substringPath[0] === "data:application/pdf" ? contentTypePdf : contentTypeImage,
			});
			const blobUrl = URL.createObjectURL(blob);

			window.open(blobUrl, "_blank");
		};
	};
	const handleSubmit = () => {
		setIsLoading(true);
		if (!_file) {
			setIsLoading(false);
			setAlert({
				message: "Please upload a PDF file",
				severity: "error",
				show: true,
			});
		} else {
			const bodyFormData = new FormData();
			bodyFormData.append("file", _file);
			bodyFormData.append("tagname", "test");
			bodyFormData.append("mappedApplicationNumber", getMappedApplicationNumber());
			bodyFormData.append("checkedBy", getUserId());
			bodyFormData.append("reviewStatus", "APPROVED");
			bodyFormData.append("remark", "");
			submitSignedCertificate(bodyFormData)
				.then((res) => {
					if (res?.data.responseCode === "00") {
						setAlert({
							message: "Successful",
							severity: "success",
							show: true,
						});
						navigateSelf(ISSUE_ROUTES.HOME + "/1");
					} else {
						setAlert({
							message: "There is something wrong. Please try again",
							severity: "error",
							show: true,
						});
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					navigateSelf(ISSUE_ROUTES.HOME + "/1");
					setIsLoading(false);
				});
		}
	};

	return (
		<div className='pb-10'>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				mappedApplicationNumber
				title={"Signature"}
				tableIcon={DASHBOARD_ICONS.technicalCommittee}
				tableURL={ISSUE_ROUTES.HOME}
			/>

			<SectionRow className='w-full mt-5'>
				<SectionColumn className='w-full justify-center h-fit  lg:w-1/2 min-h-[650px] lg:h-[80vh] items-center lg:overflow-y-auto customScrollBar'>
					<SectionRow className='w-full justify-center items-center mt-8'>
						<ToggleButtonSet data={nameArray} handleClick={handleCertificate} certificate={true} />
						<SectionRow className='w-full justify-center items-center mt-1'>
							<ViewMoreButton
								className={"!mr-0"}
								name={"summaryReport"}
								title={"View More..."}
								handleClick={() => {
									deleteStepper("FCView");
									navigateRoutes();
								}}
							/>
						</SectionRow>
					</SectionRow>
					<SectionColumn className='w-full mt-8'>
						<SectionRow className='mb-3 mx-auto'>
							<FileUploader
								handleFile={handleFile}
								tittle='Upload Signed Certificate'
								fileType='.pdf'
							/>
							<Button
								className='!capitalize '
								color='success'
								disabled={_file ? false : true}
								onClick={() => {
									handlePreview();
								}}>
								Preview Uploaded Certificate
							</Button>
						</SectionRow>
						<SectionRow className='mx-auto mt-2'>
							<AcceptButton
								className={""}
								handleClick={() => {
									setIsOpen(true);
									// handleSubmit();
								}}
								name='nextButton'
								title='Approve'
								color={""}
								disabled={_file ? false : true}
							/>
						</SectionRow>
					</SectionColumn>
				</SectionColumn>
				<SectionColumn className='w-full lg:w-1/2 min-h-[75vh] z-10 '>
					<SectionRow className='w-full text-center items-center justify-center'>
						Certificate
					</SectionRow>
					{_downloadPDF && <IFrameViewer path={_downloadPDF} files={true} />}
					<SectionRow className='w-full justify-center'>
						<DownloadButton handleClick={() => handleDownload(_downloadPDF)} title={"Download"} />
					</SectionRow>
				</SectionColumn>
			</SectionRow>
			<ConfirmationPopup
				color={"success"}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={"Certificate Confirmation"}
				content={"Please, verify selected file before submit. Submit process can't be reverted!"}
				handleClick={handleSubmit}
			/>

			{/* <ConfirmationModel
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={"Please verify your conditions before proceeding"}
                content={"Please be aware that this action is irreversible and cannot be undone. Are you sure you want to continue?"}
                color={color}
                handleClick={handleSubmit}
                handleChange={handleChange}
            /> */}
		</div>
	);
};

export default SignatureReview;
