import React, { useEffect, useState } from "react";
import HomeTable from "../../components/table/HomeTable";
import { SectionRow } from "./../../layouts/sections/index";
import StatCard from "./../../components/cards/statCard";
import { STAT_ACCENT_COLORS, STAT_ICON_PATHS, setStakeholderTypes } from "./../../app/constants";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { navigateNewTab, navigateSelf } from "../../app/router/routerManager";
import { CERTIFICATION_BODY_ROUTES } from "../../app/router/routes";
import { deleteStepper } from "./../../components/stepper/dynamicStepper";
import { setApplicationStage, showViewIcon } from "../../components/table/tableHelpers";
import { getTableCounts, getTableRecords } from "./../../app/api/NOCU/tableAPI";
import {
	deleteApplicationStatusOnSession,
	getTableData,
	setApplicationStatusOnSession,
} from "./dataManipulation";
import { setStakeholderType } from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getUserId } from "./../../app/api/NOCU/helpers/applicationApiHelpers";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { deleteIsRenewal, setIsRenewal } from "../../components/navbar/helpers";

const CertificationHome = () => {
	const { id } = useParams();
	const [records, setRecords] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [res, setRes] = useState();
	const [counts, setCounts] = useState({
		pendingCount: 0,
		approvedCount: 0,
		rejectedCount: 0,
		resubmissionCount: 0,
		resubmitPendingCount: 0,
	});
	const [selectedCount, setSelectedCount] = useState();
	const [tableCount, setTableCount] = useState();
	const [selectedTitle, setSelectedTitle] = useState("New Requests");
	const tableHeaders = [
		"Name",
		"Application No.",
		"Com. Reg. No.",
		"Business type",
		"Contact Person",
		"Date & Time",
		"Type",
	];
	const rowsData = [
		[
			"Coco Tech",
			"CER32627703",
			"BT5678",
			"Export",
			"cocotech@gmail.com",
			"Suran Mendis 0778962383",
			"02-12-2022 13.23",
		],
		[
			"Bpoi Trades",
			"CER32627707",
			"BT5324",
			"Import",
			"bophi@gmail.com",
			"Sudantha Perera 0778962383",
			"02-12-2022 13.23",
		],
		[
			"Coco Tech",
			"CER32627708",
			"BT5678",
			"Export",
			"cocotech@gmail.com",
			"Suran Mendis 0778962383",
			"02-12-2022 13.23",
		],
		[
			"Coco Tech",
			"CER32627709",
			"BT5678",
			"Export",
			"cocotech@gmail.com",
			"Suran Mendis 0778962383",
			"02-12-2022 13.23",
		],
	];
	useEffect(() => {
		deleteApplicationStatusOnSession();
		if (!!!id) {
			console.log("No param");
		}
	}, []);
	let filters = [
		{ key: "PENDING", value: "PENDING" },
		{ key: "SUCCESS", value: "SUCCESS" },
		{ key: "SUSPENDED", value: "SUSPENDED" },
		{ key: "APPROVED", value: "APPROVED" },
		{ key: "REJECTED", value: "REJECTED" },
	];
	let sorting = [
		{ key: "Acceding", value: true },
		{ key: "Descending", value: false },
	];
	const [selectedStatCard, setSelectedStatCard] = useState(1);
	const [params, setParams] = useState({
		status: "",
		isAscending: true,
		page: 0,
		size: 5,
		officerId: getUserId(),
		stakeholderType: "CERTIFICATION_BODY",
		applicationStage: "CHECK",
		applicationNumber: "",
	});

	useEffect(() => {
		setSelectedStatCard(parseInt(id));
		if (parseInt(id) === 1) {
			setParams({ ...params, status: "PENDING", applicationStage: "CHECK" });
			setSelectedCount(counts.pendingCount);
			setApplicationStatusOnSession(true);
			setSelectedTitle("New Requests");
		} else if (parseInt(id) === 2) {
			setParams({ ...params, status: "PENDING", applicationStage: "RESUBMISSION_CHECK" });
			setSelectedCount(counts.resubmissionCount);
			setApplicationStatusOnSession(false);
			setSelectedTitle("Received Resubmissions");
		} else if (parseInt(id) === 3) {
			setParams({ ...params, status: "APPROVED", applicationStage: "CHECK", isAscending: false });
			setSelectedCount(counts.approvedCount);
			setApplicationStatusOnSession(false);
			setSelectedTitle("Completed Requests");
		} else if (parseInt(id) === 4) {
			setParams({ ...params, status: "RESUBMIT", applicationStage: "CHECK", isAscending: false });
			setSelectedCount(counts.resubmissionCount);
			setApplicationStatusOnSession(false);
			setSelectedTitle("Sent for Resubmission");
		} else if (parseInt(id) === 5) {
			setParams({ ...params, status: "REJECTED", applicationStage: "CHECK", isAscending: false });
			setSelectedCount(counts.rejectedCount);
			setApplicationStatusOnSession(false);
			setSelectedTitle("Rejected Requests");
		}
	}, [id, counts]);
	const handleFilter = (filter) => {
		setParams({ ...params, status: filter });
	};
	const handleSearchById = (id) => {
		setParams({ ...params, applicationNumber: id });
	};

	const handleSorting = (isAscending) => {
		setParams({ ...params, isAscending: isAscending });
	};
	const handlePage = (page) => {
		setParams({ ...params, page: page });
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size });
	};

	const viewFunction = (index) => {
		console.log("Index Of record=================>", index);
		setIsRenewal(res.applicationList[index]?.isRenewal);
		deleteStepper("CERTIFICATION_BODY");
		setApplicationStage(params.applicationStage);
		navigateSelf(CERTIFICATION_BODY_ROUTES.STEP01);
		// if (id === "1") {
		// 	navigateSelf(CERTIFICATION_BODY_ROUTES.STEP01);
		// } else if (id === "2") {
		// 	navigateSelf(CERTIFICATION_BODY_ROUTES.STEP01);
		// } else if (id === "4") {
		// 	navigateSelf(CERTIFICATION_BODY_ROUTES.FIELD);
		// } else if (id === "7") {
		// 	navigateSelf(CERTIFICATION_BODY_ROUTES.FINAL_COMMENTS);
		// } else {
		// 	return null;
		// }
	};
	const historyFunction = (index) => {
		console.log("Index Of record=================>", index);
	};

	useEffect(() => {
		deleteIsRenewal();
		const params = {
			officerId: getUserId(),
			stakeholderType: "CERTIFICATION_BODY",
		};
		setStakeholderType(setStakeholderTypes.CERTIFICATION_BODY);
		getTableCounts(params).then((res) => {
			setCounts(res);
		});
	}, []);

	useEffect(() => {
		console.log("Params ====>", params);
		if (params.status && params.status !== "") {
			getTableRecords(params)
				.then((res) => {
					console.log("Res ======> ", getTableData(res.data));
					setRecords(getTableData(res.data));
					setRes(res.data);
					setTableCount(res.data.count);
				})
				.then((e) => console.log(e))
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [params]);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0'>
				<span className='overViewTitle'>Certification Body</span>
			</SectionRow>
			<SectionRow className='w-full overflow-x-auto mb-5 customScrollBar'>
				<SectionRow className='w-full !min-w-max mb-2 justify-center items-center'>
					<StatCard
						value={counts.pendingCount}
						text={"Pending New Applications"}
						image={STAT_ICON_PATHS.stat_new_req}
						accent={STAT_ACCENT_COLORS.bluishGray}
						handleStatCard={() => {}}
						num={1}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.resubmissionCount}
						text={"Applications sent for Resubmissions"}
						image={STAT_ICON_PATHS.stat_resubmit}
						accent={STAT_ACCENT_COLORS.yellow}
						handleStatCard={() => {}}
						num={4}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.resubmitPendingCount}
						text={"Pending Resubmitted Applications"}
						image={STAT_ICON_PATHS.stat_cancelation}
						accent={STAT_ACCENT_COLORS.orange}
						handleStatCard={() => {}}
						num={2}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.approvedCount}
						text={"Completed Requests"}
						image={STAT_ICON_PATHS.stat_checkMark}
						accent={STAT_ACCENT_COLORS.lightGreen}
						handleStatCard={() => {}}
						num={3}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>

					<StatCard
						value={counts.rejectedCount}
						text={"Rejected Requests"}
						image={STAT_ICON_PATHS.stat_deleteMark}
						accent={STAT_ACCENT_COLORS.red}
						handleStatCard={() => {}}
						num={5}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					{/* <StatCard
						value={counts?.finalCommentsCount || 0}
						text={"Final Comments"}
						image={STAT_ICON_PATHS.stat_duplication}
						accent={STAT_ACCENT_COLORS.blue}
						handleStatCard={() => {}}
						num={5}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={20}
						text={"Pending SLAAB Requests"}
						image={STAT_ICON_PATHS.stat_resubmit}
						accent={STAT_ACCENT_COLORS.yellow}
						handleStatCard={() => {}}
						num={3}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={5}
						text={"Completed SLAAB Requests"}
						image={STAT_ICON_PATHS.stat_checkMark}
						accent={STAT_ACCENT_COLORS.lightGreen}
						handleStatCard={() => {}}
						num={8}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>

					<StatCard
						value={12}
						text={"Pending Field Visit"}
						image={STAT_ICON_PATHS.stat_upload_verification}
						accent={STAT_ACCENT_COLORS.darkGreen}
						handleStatCard={() => {}}
						num={4}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={30}
						text={"Completed Field Visit"}
						image={STAT_ICON_PATHS.stat_checkMark}
						accent={STAT_ACCENT_COLORS.lightGreen}
						handleStatCard={() => {}}
						num={9}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/> */}
				</SectionRow>
			</SectionRow>
			<HomeTable
				applicationKey={1}
				columns={tableHeaders}
				tableTitle={selectedTitle}
				handleSearchById={handleSearchById}
				rows={records}
				view={showViewIcon(["1", "2"], id) ? viewFunction : null}
				history={historyFunction}
				// filters={filters}
				setFilter={handleFilter}
				// sort={sorting}
				handleSort={handleSorting}
				handleSize={handleSize}
				count={tableCount}
				page={params.page}
				size={params.size}
				handlePage={handlePage}
			/>
		</div>
	);
};

export default CertificationHome;
