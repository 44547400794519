export const showViewIcon = (array, id) => {
	return Array.isArray(array) ? (array.includes(id) ? true : null) : null;
};

export const setApplicationStage = (applicationStage) => {
	sessionStorage.setItem("APPLICATION_STAGE", applicationStage);
};

export const getApplicationStage = () => {
	return sessionStorage.getItem("APPLICATION_STAGE")
		? sessionStorage.getItem("APPLICATION_STAGE")
		: null;
};
