import "./styles/global.css";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import WebPortal from "./WebPortal";
import Loader from "./components/Loader/Loader";
import Login from "./features/auth/login";
import Registration from "./features/auth/registration";
import TempPage from "./features/temp/TempPage";
import {
	CERTIFICATION_BODY_ROUTES,
	ERROR_ROUTES,
	EXPORTER_ROUTES,
	FARMER_ASSOCIATION_ROUTES,
	FARMER_ROUTES,
	FIELD_VISIT_ROUTES,
	FINAL_COMMENTS_ROUTES,
	FINAL_VOTE_VERIFICATION_ROUTES,
	IMPORTER_ROUTES,
	ISSUE_ROUTES,
	LABORATORIES_ROUTES,
	PROCESSOR_ROUTES,
	SLAAB_ROUTES,
	TECHNICAL_COMMITTEE_ROUTES,
	TRADER_ROUTES,
} from "./app/router/routes";
import CertificationReviewHome from "./features/certificationBody/review/certificationReviewHome";
import FieldVisit from "./features/fieldVisit/fieldVisit";
import SLAAB from "./features/slaab/SLAAB";
import FinalComments from "./features/finalComments/finalComments";
import TechnicalCommitteeReview from "./features/technicalCommittee/TechnicalCommitteeReview";
import FinalVote from "./features/finalVote/finalVote";
import FinalVoteVerficationReview from "./features/finalVote/FinalVoteVerficationReview";
import TCViewHome from "./features/technicalCommittee/TCView/TCViewHome";
import FcViewHome from "./features/finalVote/FcView/FcViewHome";
import FinalCommentApplicationView from "./features/finalComments/FinalCommentApplicationView";
import SignatureReview from "./features/Signature/SignatureReview";
import LabReviewHome from "./features/labs/review/LabReviewHome";
import FarmerReviewHome from "./features/farmer/review/FarmerReviewHome";
import FarmerAssociationReviewHome from "./features/farmerAssociation/review/FarmerAssociationReviewHome";
import ImpoterReviewHome from "./features/importer/review/ImpoterReviewHome";
import FinalCBView from "./features/finalComments/Viewer/CBViewer";
import FinalFAView from "./features/finalComments/Viewer/FAView";
import FinalFarmerView from "./features/finalComments/Viewer/FarmerView";
import FinalImporterView from "./features/finalComments/Viewer/ImporterView";
import FinalLabView from "./features/finalComments/Viewer/LabView";
import TCCBView from "./features/technicalCommittee/Viewer/CBViewer";
import TCFAView from "./features/technicalCommittee/Viewer/FAView";
import TCFarmerView from "./features/technicalCommittee/Viewer/FarmerView";
import TCImporterView from "./features/technicalCommittee/Viewer/ImporterView";
import TCLabView from "./features/technicalCommittee/Viewer/LabView";
import FinalVoteCBView from "./features/finalVote/Viewer/CBViewer";
import FinalVoteFAView from "./features/finalVote/Viewer/FAView";
import FinalVoteFarmerView from "./features/finalVote/Viewer/FarmerView";
import FinalVoteImporterView from "./features/finalVote/Viewer/ImporterView";
import FinalVoteLabView from "./features/finalVote/Viewer/LabView";
import ExpoterReviewHome from "./features/exporter/review/ExpoterReviewHome";
import ExporterView from "./features/finalComments/Viewer/ExporterView";
import TC_ExporterViewer from "./features/technicalCommittee/Viewer/TC_ExporterViewer";
import FV_ExporterViewer from "./features/finalVote/Viewer/FV_ExporterView";
import ProcessorReviewHome from "./features/processor/review/ProcessorReviewHome";
import FC_ProcessorView from "./features/finalComments/Viewer/FC_ProcessorView";
import TC_ProcessorView from "./features/technicalCommittee/Viewer/TC_ProcessorView";
import FV_ProcessorView from "./features/finalVote/Viewer/FV_ProcessorView";
import TraderReviewHome from "./features/trader/review/TraderReviewHome";
import FC_TraderView from "./features/finalComments/Viewer/FC_TraderView";
import TC_TraderView from "./features/technicalCommittee/Viewer/TC_TraderView";
import FV_TraderView from "./features/finalVote/Viewer/FV_TraderView";
import SignCBView from "./features/Signature/Viewer/S_CBViewer";
import SignFarmerView from "./features/Signature/Viewer/S_FarmerView";
import SignFAView from "./features/Signature/Viewer/S_FAView";
import SignImporterView from "./features/Signature/Viewer/S_ImporterView";
import SignLabView from "./features/Signature/Viewer/S_LabView";
import SignProcessorView from "./features/Signature/Viewer/S_ProcessorView";
import SignExporterView from "./features/Signature/Viewer/S_ExporterView";
import SignTraderView from "./features/Signature/Viewer/S_TraderView";
import NewTabError from "./features/errorPages/NewTabError";
import SessionErrorPage from "./features/errorPages/SessionErrorPage";
import { checkUserLogin, getIsActiveSession, getIsCurrentTabActive, setLoggingTime } from "./features/auth/checkUserLogin";
import { navigateSelf } from "./app/router/routerManager";

function App() {

	useEffect(() => {
		if (
			window.location.pathname !== "/login" &&
			window.location.pathname !== "/registration" &&
			window.location.pathname !== "/" &&
			window.location.pathname !== ERROR_ROUTES.newTabError&&
			window.location.pathname !== ERROR_ROUTES.sessionError
		) {
			if (!checkUserLogin() || !getIsActiveSession()) {
				localStorage.clear();
				navigateSelf("/login");
			} else {
				if (getIsCurrentTabActive()) {
					setLoggingTime();
				} else {
					navigateSelf(ERROR_ROUTES.sessionError);
				}
			}
		}
	}, []);

	return (
		<Suspense fallback={Loader()}>
			<Router forceRefresh={true}>
				<Switch>
					<Route path={"/web"} component={WebPortal} />
					<Route exact path={"/login"} component={Login} />
					<Route exact path={"/registration"} component={Registration} />
					<Route exact path={"/"} component={Login} />
					<Route exact path={"/temp"} component={TempPage} />
					<Route path={CERTIFICATION_BODY_ROUTES.REVIEW} component={CertificationReviewHome} />
					<Route path={FARMER_ROUTES.REVIEW} component={FarmerReviewHome} />
					<Route path={FARMER_ASSOCIATION_ROUTES.REVIEW} component={FarmerAssociationReviewHome} />
					<Route path={LABORATORIES_ROUTES.REVIEW} component={LabReviewHome} />
					<Route path={PROCESSOR_ROUTES.REVIEW} component={ProcessorReviewHome} />
					<Route path={TRADER_ROUTES.REVIEW} component={TraderReviewHome} />
					<Route path={FIELD_VISIT_ROUTES.REVIEW} component={FieldVisit} />
					<Route path={SLAAB_ROUTES.REVIEW} component={SLAAB} />
					<Route path={FINAL_COMMENTS_ROUTES.REVIEW} component={FinalComments} />
					<Route path={TECHNICAL_COMMITTEE_ROUTES.REVIEW} component={TechnicalCommitteeReview} />
					{/* <Route path={TECHNICAL_COMMITTEE_ROUTES.APPLICATION_VIEW} component={TCViewHome} /> */}
					<Route path={FINAL_VOTE_VERIFICATION_ROUTES.REVIEW} component={FinalVote} />
					{/* <Route path={FINAL_VOTE_VERIFICATION_ROUTES.APPLICATION_VIEW} component={FcViewHome} /> */}
					<Route path={ISSUE_ROUTES.REVIEW} component={SignatureReview} />
					<Route path={IMPORTER_ROUTES.REVIEW} component={ImpoterReviewHome} />
					<Route path={EXPORTER_ROUTES.REVIEW} component={ExpoterReviewHome} />
					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.VOTEREVIEW}
						component={FinalVoteVerficationReview}
					/>
					{/* <Route
						path={FINAL_COMMENTS_ROUTES.APPLICATION_VIEW}
						component={FinalCommentApplicationView}
					/> */}
					<Route path={FINAL_COMMENTS_ROUTES.CBAPPLICATION_VIEW} component={FinalCBView} />
					<Route path={FINAL_COMMENTS_ROUTES.CBAPPLICATION_VIEW} component={FinalCBView} />
					<Route path={FINAL_COMMENTS_ROUTES.FRAPPLICATION_VIEW} component={FinalFarmerView} />
					<Route path={FINAL_COMMENTS_ROUTES.FAAPPLICATION_VIEW} component={FinalFAView} />
					<Route path={FINAL_COMMENTS_ROUTES.EXPORTER_APPLICATION_VIEW} component={ExporterView} />
					<Route
						path={FINAL_COMMENTS_ROUTES.PROCESSOR_APPLICATION_VIEW}
						component={FC_ProcessorView}
					/>
					<Route
						path={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_APPLICATION_VIEW}
						component={TC_ExporterViewer}
					/>
					<Route
						path={TECHNICAL_COMMITTEE_ROUTES.PROCESSOR_APPLICATION_VIEW}
						component={TC_ProcessorView}
					/>
					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.EXPORTER_APPLICATION_VIEW}
						component={FV_ExporterViewer}
					/>
					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.PROCESSOR_APPLICATION_VIEW}
						component={FV_ProcessorView}
					/>
					<Route path={FINAL_COMMENTS_ROUTES.IMAPPLICATION_VIEW} component={FinalImporterView} />
					<Route path={FINAL_COMMENTS_ROUTES.LBAPPLICATION_VIEW} component={FinalLabView} />
					<Route path={FINAL_COMMENTS_ROUTES.TRADER_APPLICATION_VIEW} component={FC_TraderView} />
					<Route
						path={TECHNICAL_COMMITTEE_ROUTES.TRADER_APPLICATION_VIEW}
						component={TC_TraderView}
					/>
					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.TRADER_APPLICATION_VIEW}
						component={FV_TraderView}
					/>

					<Route path={TECHNICAL_COMMITTEE_ROUTES.CBAPPLICATION_VIEW} component={TCCBView} />
					<Route path={TECHNICAL_COMMITTEE_ROUTES.FRAPPLICATION_VIEW} component={TCFarmerView} />
					<Route path={TECHNICAL_COMMITTEE_ROUTES.FAAPPLICATION_VIEW} component={TCFAView} />
					<Route path={TECHNICAL_COMMITTEE_ROUTES.IMAPPLICATION_VIEW} component={TCImporterView} />
					<Route path={TECHNICAL_COMMITTEE_ROUTES.LBAPPLICATION_VIEW} component={TCLabView} />

					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.CBAPPLICATION_VIEW}
						component={FinalVoteCBView}
					/>
					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.FRAPPLICATION_VIEW}
						component={FinalVoteFarmerView}
					/>
					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.FAAPPLICATION_VIEW}
						component={FinalVoteFAView}
					/>
					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.IMAPPLICATION_VIEW}
						component={FinalVoteImporterView}
					/>
					<Route
						path={FINAL_VOTE_VERIFICATION_ROUTES.LBAPPLICATION_VIEW}
						component={FinalVoteLabView}
					/>


					<Route path={ISSUE_ROUTES.CBAPPLICATION_VIEW} component={SignCBView} />
					<Route path={ISSUE_ROUTES.FRAPPLICATION_VIEW} component={SignFarmerView} />
					<Route path={ISSUE_ROUTES.FAAPPLICATION_VIEW} component={SignFAView} />
					<Route path={ISSUE_ROUTES.IMAPPLICATION_VIEW} component={SignImporterView} />
					<Route path={ISSUE_ROUTES.LBAPPLICATION_VIEW} component={SignLabView} />
					<Route path={ISSUE_ROUTES.PROCESSOR_APPLICATION_VIEW} component={SignProcessorView} />
					<Route path={ISSUE_ROUTES.EXPORTER_APPLICATION_VIEW} component={SignExporterView} />
					<Route path={ISSUE_ROUTES.TRADER_APPLICATION_VIEW} component={SignTraderView} />

					<Route path={ERROR_ROUTES.newTabError} component={NewTabError} />
					<Route path={ERROR_ROUTES.sessionError} component={SessionErrorPage} />
				</Switch>
			</Router>
		</Suspense>
	);
}

export default App;
