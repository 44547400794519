import React, { useEffect, useState } from "react";

//images
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { stringCrop } from "../../Helpers/stringCrop";
import LoaderComponent from "../Loader/LoaderComponent";
import FileUploadModal from "../model/FileUploadModal";
import { SectionColumn, SectionRow } from "../../layouts/sections";
import { API_BASE_URL } from "../../app/api/core_api";
import { eyeOpen, removeUploadIcon } from "../../Helpers/ICONS";
import { fileDelete } from "../../app/api/NOCU/filserverAPI";

const UploadButton = (props) => {
  const [currentFiles, setCurrentFiles] = useState();
  const [filePath, setfilepath] = useState("");
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [loaderText, setLoaderText] = useState("");
  const [value, setValue] = useState("");
  const [backFilePath, setBackFilePath] = useState(props.backFilePath)
  const classes = classNames(
    props.className,
    "grid justify-items-center rounded-md align-middle p-2 px-7 font-bold text-sm border-2 border-dashed"
  );

  useEffect(() => {
    setBackFilePath(props.backFilePath);
  }, [props.backFilePath])

  useEffect(() => {
    props?.setExtraDocPath && props?.setExtraDocPath(filePath)
  }, [filePath])

  useEffect(() => {
    if (props.isDeleteFile) {
      setfilepath("");
    }
  }, [props.isDeleteFile])

  useEffect(() => {
    if (props.uploadType !== "businessDetailsFileUpload") {
      if (filePath !== "" || filePath !== undefined || props?.setfilepath !== undefined) {
        if (props.backFilePath === "" || props.backFilePath === undefined) {
          props?.setfilepath(filePath);
        }
      } else {
        props?.setfilepath({ path: props.backFilePath, name: props.name });
      }
    } else {
      if (props.backFilePath === "" || props.backFilePath === undefined) {
        if (props?.setfilepath !== undefined) {
          props?.setfilepath({ path: filePath, name: props.name });
        }
      } else {
        props?.setfilepath({ path: props.backFilePath, name: props.name });
      }
    }
  }, [filePath, backFilePath, props?.setfilepath, props?.backFilePath]);

  useEffect(() => {
    if (value) {
      setValue(value);
    }
    if (props.getValue) props.getValue(value);
  }, [props, value]);

  const handleDelete = () => {
    setBackFilePath("")
    if (props.backFilePath !== undefined) {
      props.setbackFilePath("");
    }
    if (props.setRemoveFileName !== undefined) {
      props.setRemoveFileName("");
      props.setRemoveFileName(props.name);
    }
    setLoaderText("Removing...");
    setfilepath("");
    setLoaderOpen(true);
    fileDelete(filePath)
      .then((res) => {
        setLoaderOpen(false);
        setfilepath("");
        setCurrentFiles(null);
        return res;
      })
      .catch((error) => {
        setLoaderOpen(false);
        return error;
      });
  };

  const fileName = stringCrop(filePath ? currentFiles?.[0].name : null, 10);

  return (
    <div {...props}>
      <Tooltip title={props.tooltip ? props.tooltip : ""}>
        <div>
          <SectionColumn className="grid justify-items-center">
            {backFilePath === "" || backFilePath === null || backFilePath === undefined ? (
              <div onClick={(e) => (filePath ? null : props.readOnly ? null : setFileUploadOpen(true))}>
                <button className={classes} type="button">
                  {!fileName ? (
                    <span className="text-xs"> {props.btnname} </span>
                  ) : (
                    <SectionRow className="-mx-3 mt-1 flex-nowrap">
                      <div className="text-stone-500 text-center w-full">
                        {fileName}{" "}
                      </div>
                      {filePath && (
                        <div
                          className="-mt-1 flex flex-col items-center"
                          onClick={handleDelete}
                        >
                          <img
                            src={removeUploadIcon}
                            width={14}
                            height={14}
                            alt="Bin"
                          />
                        </div>
                      )}
                    </SectionRow>
                  )}
                </button>
              </div>
            ) : (
              <div className={classes}>
                <SectionRow className="mt-1 flex-nowrap gap-x-4">
                  <div
                    className="-mt-2 flex flex-col items-center"
                    onClick={() => {
                      window.open(`${API_BASE_URL}/file/${props.backFilePath}`)
                    }}
                  >
                    <img
                      src={eyeOpen}
                      width={23}
                      height={24}
                      alt="Bin"
                    />
                  </div>
                  <div
                    className="-mt-2 flex flex-col items-center"
                    onClick={props.readOnly ? undefined : handleDelete}
                  >
                    <img
                      src={removeUploadIcon}
                      width={22}
                      height={24}
                      alt="Bin"
                    />
                  </div>
                </SectionRow>
              </div>
            )}
          </SectionColumn>
        </div>
      </Tooltip>
      <FileUploadModal
        title={props.title}
        name={props.name}
        setFile={(file) => setCurrentFiles(file)}
        currentFiles={currentFiles}
        multiple={false}
        setfilepath={setfilepath}
        fileUploadOpen={fileUploadOpen}
        setFileUploadOpen={setFileUploadOpen}
      />
      <LoaderComponent isOpen={loaderOpen} />
    </div>
  );
};

export default UploadButton;
