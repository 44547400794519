import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";
import { getStakeholderApplicationNumber } from "./helpers/applicationApiHelpers";

export const getRejctionsByStep = async (stepId) => {
  return CustomAxios({
    method: "GET",
    baseURL: API_BASE_URL,
    url: `/review/v1/get-rejection-detail/${getStakeholderApplicationNumber()}/${stepId}`,
    headers: {},
  })
    .then((res) => {
      console.log("getRejctionsByStep (res) ====>", res);
      return res;
    })
    .catch((error) => {
      console.log("getRejctionsByStep (error) ====>", error);
    });
};
