import * as React from "react";
import { ErrorMessage, useField } from "formik";
import { useEffect, useState } from "react";
import Select from "react-select";
import OtherDropDownModal from "../../../components/model/OtherDropDownModal";
import { ModalOpenCloseContext } from "../../../app/context/modalOpenCloseContext";

const customStyles = {
  option: (provided) => ({
    // borderBottom: "1px dotted #444",
    ...provided,
    padding: 10,
  }),
  control: () => ({
    display: "flex",
    borderWidth: 0,
    justifyContent: "center",
    // padding: 0,
  }),
  menu: (base) => ({ ...base, width: 240, backgroundColor: "white" }),
  dropdownIndicator: (base) => ({ ...base, cursor: "pointer" }),
};

const SelectOtherDropDown = ({
  onChange,
  ...props
}) => {
  // hooks
  const [error, setError] = useState("");
  const [value, setValue] = useState(undefined);
  const [otherValue, setOtherValue] = useState(undefined);
  const [field, meta] = useField(props);

  // contexts
  const { setOtherDropDownPopupModal } = React.useContext(ModalOpenCloseContext);

  useEffect(() => {
    if (props.value?.value === "OTHER") {
      setOtherDropDownPopupModal(true);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
    if (props.getValue) props.getValue(value);
  }, [props, value]);

  useEffect(() => {
    setError("");
  }, [value]);

  useEffect(() => {
    setError(props.error ? props.error : "");
  }, [props]);

  useEffect(() => {
    if (props.value?.value === "OTHER" && otherValue) {
      props.setValue(otherValue);
      setValue(otherValue);
      if (props.getValue) props.getValue(value);
    } else {
      props.setValue(props.value);
      setValue(props.value);
      setOtherValue(null);
      if (props.getValue) props.getValue(value);
    }
  }, [props, props.value, value, otherValue, setOtherValue]);

  const handleChange = (event) => {
    if (onChange) onChange(event);
  };

  return (
    <div className="pt-2">
      <Select
        {...field}
        className={`inputBaseInput ${props.className}  ${meta.touched && meta.error && "validationErrorBorder"
          }`}
        name={props.name}
        styles={customStyles}
        options={props.options}
        placeholder={props.placeholder}
        value={props.value?.value === "OTHER" ? otherValue : props.value}
        onChange={handleChange}
        maxMenuHeight={props.maxLength}
        components={props.components}
        isDisabled={props.isDisabled}
      />
      <ErrorMessage
        component="div"
        name={field.name}
        className="validationError"
      />
      <OtherDropDownModal setOtherDropDownValue={setOtherValue} />
    </div>
  );
};

export default SelectOtherDropDown;
