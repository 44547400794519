import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { navigateSelf } from "../../app/router/routerManager";
import { ERROR_ROUTES } from "../../app/router/routes";
import { SectionRow } from "../../components/section/index";
import { logOutApi } from "../../app/api/authApi/authApi";

const SessionErrorPage = () => {
  const [count, setCount] = useState(10);

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => setCount(count - 1), 1000);
    } else {
      // logOutApi()
      //   .then((res) => {
      //     sessionStorage.clear();
      //     localStorage.clear();
      //     navigateSelf("/login");
      //   })
      //   .catch((e) => console.log(e));
      sessionStorage.clear();
      localStorage.clear();
      window.open("https://www.google.com/", "_self");
    }
  }, [count]);

  return (
    <div className="w-screen h-screen absolute flex justify-center items-center bg-slate-600">
      <div className="w-[500px]  bg-white rounded-md ">
        <SectionRow className="justify-center items-center w-full h-28">
          <img src="/icons/error_icon.png" alt="error" width={80} />
        </SectionRow>
        <SectionRow className="w-full justify-center  text-center font-bold">
          <span className="text-red-500 font-bold text-2xl">
            Session Expired !
          </span>
        </SectionRow>
        <SectionRow className="w-full justify-center text-gray-500 text-center text-lg font-bold">
          We're sorry, but it seems that your session has expired.
        </SectionRow>
        <SectionRow className="w-full justify-center text-gray-500 text-center text-lg font-bold">
          For security reasons, we automatically log you out of your account if
          there has been no activity for a certain period of time.To continue
          using our web application, please log in again
        </SectionRow>
        <SectionRow className="w-full justify-center  text-center font-bold">
          <span className="text-red-500 font-bold text-lg">
            Automatically Redirect to home page in
          </span>
        </SectionRow>
        <SectionRow className="w-full justify-center text-gray-500 text-center text-lg font-bold">
          <span className="text-red-500 font-extrabold text-[30px]">
            {count}s
          </span>
        </SectionRow>

        <SectionRow className="w-full justify-end py-4 px-10">
          {/* <Button
					color='warning'
					onClick={() => {
						window.close();
						console.log("Close");
					}}>
					Close
				</Button> */}
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              logOutApi()
                .then((res) => {
                  sessionStorage.clear();
                  localStorage.clear();
                  navigateSelf("/login");
                })
                .catch((e) => console.log(e));
            }}
          >
            Log Out
          </Button>
        </SectionRow>
      </div>
    </div>
  );
};

export default SessionErrorPage;
