import eyeOpen from "../assets/EDB/UploadCertificate/eyeOpen.svg";
import eyeClose from "../assets/EDB/UploadCertificate/eyeClose.svg";
import removeUploadIcon from "../assets/EDB/UploadCertificate/removeUpload.svg";
import closePopupIcon from "../assets/EDB/UploadCertificate/closePopupIcon.svg";
import forwardIcon from "../assets/EDB/UploadCertificate/forwardIcon.svg";
import downIcon from "../assets/EDB/UploadCertificate/downIcon.svg";
import upIcon from "../assets/EDB/UploadCertificate/upIcon.svg";

export { eyeOpen, eyeClose, removeUploadIcon, closePopupIcon, forwardIcon, downIcon, upIcon };

export const DASHBOARD_ICONS = {
	overView: "/EDB/Drawer/OverView.png",
	certificationBody: "/EDB/Drawer/CertificationBody.png",
	farmer: "/EDB/Drawer/Farmer.png",
	farmerAssociation: "/EDB/Drawer/FarmerAssociation.png",
	exporter: "/EDB/Drawer/Exporter.png",
	importer: "/EDB/Drawer/Imposter.png",
	processor: "/EDB/Drawer/Processor.png",
	trader: "/EDB/Drawer/Trader.png",
	slaab: "/EDB/Drawer/slaab.png",
	fieldVisit: "/EDB/Drawer/field-visit.png",
	certificateUpload: "/EDB/Drawer/certificateUpload.png",
	finalComments: "/EDB/Drawer/Final_comments.png",
	companyProfile: "/EDB/Drawer/Company_Profile.png",
	inquiry: "/EDB/Drawer/Inquiry.png",
	summary: "/EDB/Drawer/Summary.png",
	report: "/EDB/Drawer/Report.png",
	user: "/EDB/Drawer/User.png",
	logout: "/EDB/Drawer/Logout.png",
	labs: "/EDB/Drawer/labs.png",
	technicalCommittee: "/EDB/Drawer/technicalCommittee.png",
	productCategory: "/EDB/Drawer/product-category.png",
	payment: "/EDB/Drawer/Payment.png",
	sign: "/EDB/Drawer/sign.png",
	vote: "/EDB/Drawer/vote.png",
	userManagement: "/EDB/Drawer/Admin-settings.png",
};
