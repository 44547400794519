import { async } from "q";
import * as Yup from "yup";

export function validateEmail(mail) {
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
		return "";
	} else {
		return "Please enter correct email";
	}
}

const validationSchema = Yup.object({
	fullName: Yup.string().required("Name is required"),
	email: Yup.string().email("Email is invalid").required("Email is required"),
	mobile: Yup.string().required("Mobile No is required"),
	password: Yup.string()
		.min(6, "Password must be 6 characters long")
		.matches(/[0-9]/, "Password requires a number")
		.matches(/[a-z]/, "Password requires a lowercase letter")
		.matches(/[A-Z]/, "Password requires an uppercase letter")
		.matches(/[^\w]/, "Password requires a symbol")
		.required("Password is required"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Please make sure your passwords match")
		.required("Confirm Password is required"),
});

const passwordValidation = Yup.object({
	password: Yup.string()
		.min(6, "Password must be 6 characters long")
		.matches(/[0-9]/, "Password requires a number")
		.matches(/[a-z]/, "Password requires a lowercase letter")
		.matches(/[A-Z]/, "Password requires an uppercase letter")
		.matches(/[^\w]/, "Password requires a symbol")
		.required("Password is required"),
});

export const validatePassword = async (password) => {
	if (password === "") {
		return "Password is required";
	}
	return await passwordValidation
		.validate({ password: password })
		.then((res) => false)
		.catch((e) => e.message);
};
