import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { navigateSelf } from "../../app/router/routerManager";
import { ERROR_ROUTES } from "../../app/router/routes";
import { SectionRow } from "../../components/section/index";
import { logOutApi } from "../../app/api/authApi/authApi";

const NewTabError = () => {
	const [count, setCount] = useState(10);

	useEffect(() => {
		if (count > 0) {
			setTimeout(() => setCount(count - 1), 1000);
		} else {
			logOutApi().then((res)=>{

				sessionStorage.clear();
				localStorage.clear();
				navigateSelf("/login");
			}).catch(e=>console.log(e))
		}
	}, [count]);

	return (
		<div className='w-screen h-screen absolute flex justify-center items-center bg-slate-600'>
			<div className='w-[500px]  bg-white rounded-md '>
				<SectionRow className='justify-center items-center w-full h-28'>
					<img src='/icons/error_icon.png' alt='error' width={80} />
				</SectionRow>
				<SectionRow className='w-full justify-center text-gray-500 text-center text-lg font-bold'>
					You are not allowed to open multiple tabs/ windows !
				</SectionRow>
				<SectionRow className='w-full justify-center text-gray-500 text-center text-lg font-bold'>
					If you not using multiple tabs/windows, you can logout and login again to the system.
				</SectionRow>
				<SectionRow className='w-full justify-center  text-center font-bold'>
					<span className='text-red-500 font-bold text-lg'>
						Automatically Redirect to home page in
					</span>
				</SectionRow>
				<SectionRow className='w-full justify-center text-gray-500 text-center text-lg font-bold'>
					<span className='text-red-500 font-extrabold text-[30px]'>{count}s</span>
				</SectionRow>

				<SectionRow className='w-full justify-end py-4 px-10'>
					{/* <Button
					color='warning'
					onClick={() => {
						window.close();
						console.log("Close");
					}}>
					Close
				</Button> */}
					<Button
						color='error'
						variant='outlined'
						onClick={() => {
							logOutApi().then((res)=>{

								sessionStorage.clear();
								localStorage.clear();
								navigateSelf("/login");
							}).catch(e=>console.log(e))
						}}>
						Log Out
					</Button>
				</SectionRow>
			</div>
		</div>
	);
};

export default NewTabError;
