import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import PersonIcon from "@mui/icons-material/Person";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CachedIcon from "@mui/icons-material/Cached";

export default function TabCardSet({ setSelectedPage }) {
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const handleListItemClick = (event, index) => {
		setSelectedIndex(index);
		setSelectedPage(index);
	};

	return (
		<Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
			<List component='nav' aria-label='main mailbox folders'>
				<ListItemButton
					selected={selectedIndex === 0}
					onClick={(event) => handleListItemClick(event, 0)}>
					<ListItemIcon>
						<PersonIcon />
					</ListItemIcon>
					<ListItemText primary='User' />
				</ListItemButton>
				<Divider />
				<ListItemButton
					selected={selectedIndex === 1}
					onClick={(event) => handleListItemClick(event, 1)}>
					<ListItemIcon>
						<CachedIcon />
					</ListItemIcon>
					<ListItemText primary='Reassign' />
				</ListItemButton>
				<Divider />
				<ListItemButton
					selected={selectedIndex === 2}
					onClick={(event) => handleListItemClick(event, 2)}>
					<ListItemIcon>
						<DoDisturbIcon />
					</ListItemIcon>
					<ListItemText primary='Meeting Mode' />
				</ListItemButton>
				<Divider />

				<ListItemButton
					selected={selectedIndex === 3}
					onClick={(event) => handleListItemClick(event, 3)}>
					<ListItemIcon>
						<DisplaySettingsIcon />
					</ListItemIcon>
					<ListItemText primary='Permission' />
				</ListItemButton>
			</List>
		</Box>
	);
}
