import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { TECHNICAL_COMMITTEE_ROUTES } from "../../../app/router/routes";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import NavBar from "../../../components/navbar/NavBar";
import { SectionColumn } from "../../../components/section";
import { createStepper, disableAlert, STEPPER_STATUS } from "../../../components/stepper/dynamicStepper";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";
import FarmerAssociationReviewStep1 from "../../farmerAssociation/review/flow/FarmerAssociationReviewStep1";
import FarmerAssociationReviewStep4 from "../../farmerAssociation/review/flow/FarmerAssociationReviewStep4";
import FarmerAssociationReviewStep5 from "../../farmerAssociation/review/flow/FarmerAssociationReviewStep5";

export const stepperName = "TCView";

const TCFAView = () => {
    const stepper = [
        {
            step: "Business Details",
            status: STEPPER_STATUS.COMPLETED,
        },

        {
            step: "Certificates",
            status: STEPPER_STATUS.COMPLETED,
        },
        { step: "Other Documents", status: STEPPER_STATUS.COMPLETED },
    ];
    const [stepperData, setStepperData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setStepperData(createStepper(stepperName, stepper));
        disableAlert();
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    const routeArray = [
        TECHNICAL_COMMITTEE_ROUTES.FASTEP01,
        TECHNICAL_COMMITTEE_ROUTES.FASTEP02,
        TECHNICAL_COMMITTEE_ROUTES.FASTEP03,
    ];
    return (
        <div>
            <LoaderComponent isOpen={isLoading} />
            <NavBar
                stepperName={stepperName}
                title={"Technical Committee Review"}
                tableIcon={DASHBOARD_ICONS.technicalCommittee}
                tableURL={TECHNICAL_COMMITTEE_ROUTES.REVIEW}
            />

            {stepperData && (
                <SectionColumn className='w-full pt-1'>
                    <Route path={TECHNICAL_COMMITTEE_ROUTES.FASTEP01}>
                        <FarmerAssociationReviewStep1
                            stepperData={stepperData}
                            stepperName={stepperName}
                            routeArray={routeArray}
                            setIsLoading={setIsLoading}
                            customIndex={0}
                            nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.FASTEP02}
                            readOnly
                            hideFiles={true}
                        />
                    </Route>

                    <Route path={TECHNICAL_COMMITTEE_ROUTES.FASTEP02}>
                        <FarmerAssociationReviewStep4
                            stepperData={stepperData}
                            stepperName={stepperName}
                            routeArray={routeArray}
                            setIsLoading={setIsLoading}
                            customIndex={1}
                            nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.FASTEP03}
                            readOnly
                        />
                    </Route>
                    <Route path={TECHNICAL_COMMITTEE_ROUTES.FASTEP03}>
                        <FarmerAssociationReviewStep5
                            stepperData={stepperData}
                            stepperName={stepperName}
                            routeArray={routeArray}
                            setIsLoading={setIsLoading}
                            customIndex={2}
                            nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.REVIEW}
                            readOnly
                        />
                    </Route>
                </SectionColumn>
            )}
        </div>
    );
};

export default TCFAView;