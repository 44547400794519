import React, { useEffect, useState } from "react";
import HomeTable from "../../components/table/HomeTable";
import { SectionRow } from "../../layouts/sections";
import { APP_COLORS, STAKEHOLDERS, STAT_ACCENT_COLORS, STAT_ICON_PATHS } from "../../app/constants";
import CommonSelection from "../../components/section/commonSelection";
import {
	getApplicationSummaryRecords,
	getSummaryCount,
} from "../../app/api/summaryAPI/summaryTableApi";
import TableCount from "../../components/section/tableCount";

import LoaderComponent from "../../components/Loader/LoaderComponent";
import StatCard from "../../components/cards/statCard";
import { useParams } from "react-router";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import { Tooltip } from "@mui/material";
import { getTableData } from "./dataManipiulation";

let allFilters = [
	{ key: "ALL", value: "ALL" },
	{ key: "PENDING", value: "PENDING" },
	{ key: "APPROVED", value: "APPROVED" },
	{ key: "REJECTED", value: "REJECTED" },
	{ key: "RESUBMIT", value: "RESUBMIT" },
	{ key: "DISCARD", value: "DISCARD" },
];
let finalCommentFilters = [
	{ key: "ALL", value: "ALL" },
	{ key: "PENDING", value: "PENDING" },
	{ key: "COMPLETED", value: "APPROVED" },
];
let techFilters = [
	{ key: "ALL", value: "ALL" },
	{ key: "PENDING", value: "PENDING" },
	{ key: "APPROVED", value: "APPROVED" },
	{ key: "REJECTED", value: "REJECTED" },
	{ key: "UNVOTED", value: "DISCARD" },
];
let finalVoteFilters = [
	{ key: "ALL", value: "ALL" },
	{ key: "PENDING", value: "PENDING" },
	{ key: "REJECTED", value: "REJECTED" },
	{ key: "APPROVED", value: "APPROVED" },
];
let fieldVisitFilters = [
	{ key: "ALL", value: "ALL" },
	{ key: "PENDING", value: "PENDING" },
	{ key: "COMPLETED", value: "APPROVED" },
];
let slaabFilters = [
	{ key: "ALL", value: "ALL" },
	{ key: "PENDING", value: "PENDING" },
	{ key: "REJECTED", value: "REJECTED" },
	{ key: "APPROVED", value: "APPROVED" },
];
let signatureFilters = [
	{ key: "ALL", value: "ALL" },
	{ key: "PENDING", value: "PENDING" },
	{ key: "COMPLETED", value: "APPROVED" },
];
let sorting = [
	{ key: "Acceding", value: true },
	{ key: "Descending", value: false },
];

const SummaryHome = () => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [records, setRecords] = useState();
	const [tableCount, setTableCount] = useState(0);
	const [selectedFilters, setSelectedFilters] = useState();
	const [counts, setCounts] = useState({
		allApplicationsCount: 0,
		fieldVisitCount: 0,
		slaabCount: 0,
		technicalCommitteeCount: 0,
		finalVoteCount: 0,
		readyToIssueCount: 0,
		issueCount: 0,
	});
	const [selectedStatCard, setSelectedStatCard] = useState(1);
	const [searchBy, setSearchBy] = useState("applicationId");
	const [params, setParams] = useState({
		page: 0,
		size: 5,
		status: "ALL",
		stakeholderType: "ALL",
		applicationStage: "",
		applicationNumber: "",
		isAscending: true,
		registrationId: "",
	});
	const handleSearchById = (id) => {
		setParams({ ...params, applicationNumber: id });
	};
	const handleFilter = (filter) => {
		setParams({ ...params, status: filter });
	};
	const handleSorting = (isAscending) => {
		setParams({ ...params, isAscending: isAscending });
	};
	const handlePage = (page) => {
		setParams({ ...params, page: page });
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size });
	};

	const setStakeHolderParam = (type) => {
		setParams({ ...params, stakeholderType: type });
	};

	const historyFunction = () => { };

	const renderCustomSearch = () => {
		return (
			<div className='flex  justify-end items-center'>
				{/* <label className='mr-2'> Search By :</label> */}
				<select
					className='perPage !w-4 hover:!w-40 mr-2'
					name='search'
					id='search'
					onChange={(e) => setSearchBy(e.target.value)}>
					<option value={"applicationId"}>Application No</option>;
					<option value={"registrationNo"}>Registration No</option>;
				</select>
				{searchBy === "applicationId" && (
					<input
						placeholder='Application No'
						className='searchIconText   w-11/12 '
						value={params.applicationNumber}
						onChange={(event) => {
							setParams({ ...params, applicationNumber: event.target.value, registrationId: "" });
						}}
					/>
				)}
				{searchBy === "registrationNo" && (
					<input
						placeholder='Registration No'
						value={params.registrationId}
						className='searchIconText w-11/12 '
						onChange={(event) => {
							setParams({ ...params, registrationId: event.target.value, applicationNumber: "" });
						}}
					/>
				)}
			</div>
		);
	};
	const tableHeaders = [
		"Name",
		id === "1" || id === "2" || id === "3" ? (
			"Application No"
		) : (
			<Tooltip title={<div className='p-2 text-sm'>System Generated Mapping Number</div>}>
				<div>System No.</div>
			</Tooltip>
		),
		<Tooltip
			title={
				<div className='p-2 text-sm'>
					Other Application numbers that combined with the application
				</div>
			}>
			<div>Application List</div>
		</Tooltip>,
		"Registration No",
		"Contact Person",
		"Date & Time",
		"Type",
	];

	useEffect(() => {
		setSelectedStatCard(parseInt(id));
		if (parseInt(id) === 1) {
			setSelectedFilters(allFilters);
			setParams({ ...params, applicationStage: "ALL" });
		} else if (parseInt(id) === 2) {
			setSelectedFilters(fieldVisitFilters);
			setParams({ ...params, applicationStage: "FIELD_VISIT" });
		} else if (parseInt(id) === 3) {
			setSelectedFilters(slaabFilters);
			setParams({ ...params, applicationStage: "SLAAB" });
		} else if (parseInt(id) === 4) {
			setSelectedFilters(techFilters);
			setParams({ ...params, applicationStage: "TECHNICAL_COMMITTEE" });
		} else if (parseInt(id) === 5) {
			setSelectedFilters(finalCommentFilters);
			setParams({ ...params, applicationStage: "FINAL_COMMENT" });
		} else if (parseInt(id) === 6) {
			setSelectedFilters(finalVoteFilters);
			setParams({ ...params, applicationStage: "FINAL_VOTE" });
		} else if (parseInt(id) === 7) {
			setSelectedFilters(signatureFilters);
			setParams({ ...params, applicationStage: "READY_TO_ISSUE" });
		}
	}, [id]);

	useEffect(() => {
		getSummaryCount()
			.then((res) => {
				setCounts(res.data);
			})
			.catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		params.applicationStage &&
			getApplicationSummaryRecords(params)
				.then((res) => {
					console.dir(res);
					console.log("Mapped Data ==>", getTableData(res.data));
					setTableCount(res.data?.count);
					setRecords(getTableData(res.data));
					setIsLoading(false);
				})
				.catch((e) => console.dir(e));
	}, [params]);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0 pb-5'>
				<span className='overViewTitle'>Application Summary</span>
			</SectionRow>
			<SectionRow className='w-full overflow-x-auto mb-5 customScrollBar'>
				<SectionRow className='w-full !min-w-max mb-2 justify-center items-center'>
					<StatCard
						value={counts.allApplicationsCount || 0}
						text={"All Applications"}
						image={DASHBOARD_ICONS.overView}
						accent={APP_COLORS.green_dark}
						handleStatCard={() => { }}
						num={1}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.fieldVisitCount || 0}
						text={"Field Visit Applications"}
						image={DASHBOARD_ICONS.fieldVisit}
						accent={APP_COLORS.green_dark}
						handleStatCard={() => { }}
						num={2}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.slaabCount || 0}
						text={"SLAB Applications"}
						image={DASHBOARD_ICONS.slaab}
						accent={APP_COLORS.green_dark}
						handleStatCard={() => { }}
						num={3}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.finalCommentCount || 0}
						text={"Technical Committee Recommendations"}
						image={DASHBOARD_ICONS.finalComments}
						accent={APP_COLORS.green_dark}
						handleStatCard={() => { }}
						num={5}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.technicalCommitteeCount || 0}
						text={"Technical Committee"}
						image={DASHBOARD_ICONS.technicalCommittee}
						accent={APP_COLORS.green_dark}
						handleStatCard={() => { }}
						num={4}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.finalVoteCount || 0}
						text={"Final Vote Applications"}
						image={DASHBOARD_ICONS.vote}
						accent={APP_COLORS.green_dark}
						handleStatCard={() => { }}
						num={6}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts.readyToIssueCount || 0}
						text={"Signature Applications"}
						image={DASHBOARD_ICONS.sign}
						accent={APP_COLORS.green_dark}
						handleStatCard={() => { }}
						num={7}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
				</SectionRow>
			</SectionRow>

			<div className='w-full'>
				<HomeTable
					applicationKey={1}
					columns={tableHeaders}
					// handleSearchById={handleSearchById}
					customSearchDropdown={renderCustomSearch()}
					rows={records}
					view={null}
					history={historyFunction}
					filters={selectedFilters}
					setFilter={handleFilter}
					sort={sorting}
					handleSort={handleSorting}
					handleSize={handleSize}
					count={tableCount}
					page={params.page}
					size={params.size}
					handlePage={handlePage}
					customFilter={
						<SectionRow className=' '>
							<CommonSelection
								adjustable={"20"}
								filters={STAKEHOLDERS}
								onChange={setStakeHolderParam}
								title={`Filter By Stakeholder`}
							/>
							<TableCount count={tableCount} />
						</SectionRow>
					}
				/>
			</div>
		</div>
	);
};

export default SummaryHome;
