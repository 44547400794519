import React, { useEffect, useState } from "react";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import { setStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { SectionRow } from "../../../layouts/sections";
import FarmerReviewStep1 from "../../farmer/review/flow/FarmerReviewStep1";
import FarmerReviewStep2 from "./../../farmer/review/flow/FarmerReviewStep2";
import FarmerReviewStep3 from "./../../farmer/review/flow/FarmerReviewStep3";
import FarmerReviewStep4 from "./../../farmer/review/flow/FarmerReviewStep4";
import TopButtonArray from "./components/TopButtonArray";
import LoaderComponent from "../../../components/Loader/LoaderComponent";

const FarmerSummaryView = ({ applicationId }) => {
	const [selectedPage, setSelectedPage] = useState(0);
	const [statusArray, setStatusArray] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setStakeholderApplicationNumber(applicationId);
		getStepStatusByApplicationId(applicationId)
			.then((res) => {
				setStatusArray([res.data[0], res.data[1], res.data[3], res.data[4]]);
			})
			.finally(() => {
				setTimeout(() => setLoading(false), 1000);
			});
	}, [applicationId]);

	const btnNames = ["Business Details", "Contact Personnel", "Certificates", "Other Documents"];

	return (
		<div>
			<SectionRow className='w-full justify-center'>
				{statusArray && (
					<TopButtonArray
						nameArray={btnNames}
						selectedPage={selectedPage}
						setSelectedPage={setSelectedPage}
						statusArray={statusArray}
					/>
				)}
			</SectionRow>
			{!loading && selectedPage === 0 ? (
				<FarmerReviewStep1
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 1 ? (
				<FarmerReviewStep2
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 2 ? (
				<FarmerReviewStep3
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 3 ? (
				<FarmerReviewStep4
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : (
				<></>
			)}
			<LoaderComponent isOpen={loading} />
		</div>
	);
};

export default FarmerSummaryView;
