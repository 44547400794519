import { API_BASE_URL, AUTH_BASE_URL } from "./core_api";
import { getUserIDFromLocal } from "../../Helpers/loginHelper";
// import axios from "axios";
import CustomAxios from "./customAxios/customAxios";

export const getInquiriesByStatus = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: AUTH_BASE_URL,
		url: "/eligibility-check/v1/get-by-status",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("response", res);
			return res;
		})
		.catch((error) => {
			console.log("API error");
		});
};
