import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { submitFinalApplication } from "../../app/api/NOCU/applicationAPI";
import {
	getMappedApplicationNumber,
	getUserId,
	setStakeholderType,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getMapTableCounts, getMapTableRecords } from "../../app/api/NOCU/tableAPI";
import { STAKEHOLDERS, STAT_ACCENT_COLORS, STAT_ICON_PATHS } from "../../app/constants";
import { navigateSelf } from "../../app/router/routerManager";
import { FINAL_COMMENTS_ROUTES, FINAL_VOTE_VERIFICATION_ROUTES } from "../../app/router/routes";
import StatCard from "../../components/cards/statCard";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import ConfirmationPopup from "../../components/model/ConfirmationPopup";
import HomeTable from "../../components/table/HomeTable";
import { setApplicationStage, showViewIcon } from "../../components/table/tableHelpers";
import { SectionRow } from "../../layouts/sections";
import { getMapTableData } from "./dataManipulation";
import { deleteIsRenewal, setIsRenewal } from "../../components/navbar/helpers";
import { Tooltip } from "@mui/material";

const FinalVoteHome = () => {
	const { id } = useParams();
	const [records, setRecords] = useState();
	const [counts, setCounts] = useState({
		pendingCount: 0,
		approvedCount: 0,
		rejectedCount: 0,
		resubmissionCount: 0,
	});
	const [selectedCount, setSelectedCount] = useState();
	const [selectedTitle, setSelectedTitle] = useState("New Requests");
	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [color, setColor] = useState("success");
	const [pendingCount, setPendingCount] = useState(0);
	const [finalVoteCount, setFinalVoteCount] = useState(0);
	const [finalVoteRejectedCount, setFinalVoteRejectedCount] = useState(0);
	const [signatureApprovalCount, setSignatureApprovalCount] = useState(0);
	const [tableCount, setTableCount] = useState();
	const [res, setRes] = useState();

	const tableHeaders = [
		"Name",
		<Tooltip title={<div className='p-2 text-sm'>System Generated Mapping Number</div>}>
			<div>System No.</div>
		</Tooltip>,
		"Application No.",
		"Contact Person",
		"Date & Time",
		"Application Type",
	];

	let filters = [
		{ key: "PENDING", value: "PENDING" },
		{ key: "SUCCESS", value: "SUCCESS" },
		{ key: "SUSPENDED", value: "SUSPENDED" },
		{ key: "APPROVED", value: "APPROVED" },
		{ key: "REJECTED", value: "REJECTED" },
	];
	let sorting = [
		{ key: "Acceding", value: true },
		{ key: "Descending", value: false },
	];
	const [selectedStatCard, setSelectedStatCard] = useState(1);
	const [params, setParams] = useState({
		page: 0,
		size: 5,
		applicationNumber: "",
		stakeholderType: "ALL",
		applicationStage: "TECHNICAL_COMMITTEE",
		status: "",
		officerId: null,
		isAscending: true,
	});

	useEffect(() => {
		setSelectedStatCard(parseInt(id));
		if (parseInt(id) === 1) {
			setParams({ ...params, status: "PENDING" });
			setSelectedCount(counts.pendingCount);
			setSelectedTitle("Pending Requests");
		} else if (parseInt(id) === 2) {
			setParams({
				...params,
				status: "PENDING",
				applicationStage: "FINAL_VOTE",
				officerId: getUserId(),
			});
			setSelectedCount(counts.resubmissionCount);
			setSelectedTitle("Resubmitted Requests");
		} else if (parseInt(id) === 3) {
			setParams({
				...params,
				status: "PENDING",
				applicationStage: "READY_TO_ISSUE",
				officerId: getUserId(),
				isAscending: false,
			});
			setSelectedCount(counts.approvedCount);
			setSelectedTitle("Completed Requests");
		} else if (parseInt(id) === 4) {
			setParams({
				...params,
				status: "REJECTED",
				applicationStage: "FINAL_VOTE",
				isAscending: false,
			});
			setSelectedCount(counts.rejectedCount);
			setSelectedTitle("Rejected Requests");
		}
	}, [id]);
	const handleFilter = (filter) => {
		setParams({ ...params, status: filter });
	};

	const handleStakeHolderType = (filter) => {
		setParams({ ...params, stakeholderType: filter });
	};

	const handleSearchById = (id) => {
		setParams({ ...params, applicationNumber: id });
	};

	const handleSorting = (isAscending) => {
		setParams({ ...params, isAscending: isAscending });
	};
	const handlePage = (page) => {
		setParams({ ...params, page: page });
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size });
	};

	const viewFunction = (index) => {
		setStakeholderType(res.mappedApplicationList[index]?.stakeholderType);
		setApplicationStage(params.applicationStage);
		setIsRenewal(res.mappedApplicationList[index]?.isRenewal);
		if (selectedStatCard === 1) navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.REVIEW);
		else if (selectedStatCard === 2) navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.VOTEREVIEW);
		else setIsOpen(true);
	};
	const historyFunction = (index) => {
		console.log("Index Of record=================>", index);
	};

	// useEffect(() => {
	// 	const pendingParams = {
	// 		officerId: 486,
	// 		stakeholderType: params.stakeholderType,
	// 		applicationNumber: params.applicationNumber,
	// 		applicationStage: "TECHNICAL_COMMITTEE",
	// 	};
	// 	const finalVoteParams = {
	// 		officerId: getUserId(),
	// 		stakeholderType: params.stakeholderType,
	// 		applicationNumber: params.applicationNumber,
	// 		applicationStage: "FINAL_VOTE",
	// 	};
	// 	const signatureParams = {
	// 		officerId: getUserId(),
	// 		stakeholderType: params.stakeholderType,
	// 		applicationNumber: params.applicationNumber,
	// 		applicationStage: "READY_TO_ISSUE",
	// 	};
	// 	setStakeholderType(params.stakeholderType);
	// 	getMapTableCounts(pendingParams).then((res) => {
	// 		setPendingCount(res?.pendingCount);
	// 	});
	// 	getMapTableCounts(finalVoteParams).then((res) => {
	// 		setFinalVoteCount(res?.pendingCount);
	// 	});
	// 	getMapTableCounts(signatureParams).then((res) => {
	// 		setSignatureApprovalCount(res?.pendingCount);
	// 	});
	// }, []);

	useEffect(() => {
		!!!selectedCount && setSelectedCount(counts.pendingCount);
	}, [counts]);

	useEffect(() => {
		console.log("Params ====>", params);
		if (params.status && params.status !== "") {
			getMapTableRecords(params)
				.then((res) => {
					console.log("Res ======> ", getMapTableData(res.data));
					setRecords(getMapTableData(res.data));
					setTableCount(res?.data.count);
					setRes(res.data);
					setIsLoading(false);
				})
				.then((e) => console.log(e));
		}
	}, [params]);

	useEffect(() => {
		const pendingParams = {
			// officerId: getUserId(),
			stakeholderType: params.stakeholderType,
			applicationNumber: params.applicationNumber,
			applicationStage: "TECHNICAL_COMMITTEE",
		};
		const finalVoteParams = {
			officerId: getUserId(),
			stakeholderType: params.stakeholderType,
			applicationNumber: params.applicationNumber,
			applicationStage: "FINAL_VOTE",
		};
		const signatureParams = {
			officerId: getUserId(),
			stakeholderType: params.stakeholderType,
			applicationNumber: params.applicationNumber,
			applicationStage: "READY_TO_ISSUE",
		};
		setStakeholderType(params.stakeholderType);
		getMapTableCounts(pendingParams).then((res) => {
			setPendingCount(res?.pendingCount);
		});
		getMapTableCounts(finalVoteParams).then((res) => {
			setFinalVoteCount(res?.pendingCount);
			setFinalVoteRejectedCount(res?.rejectedOnlyCount);
		});
		getMapTableCounts(signatureParams).then((res) => {
			setSignatureApprovalCount(res?.pendingCount);
		});
		deleteIsRenewal();
	}, []);

	const handleSubmit = () => {
		setIsLoading(true);
		const payload = {
			mappedApplicationNumber: getMappedApplicationNumber(),
			remark: "",
			reviewStatus: "APPROVED",
			checkedBy: getUserId(),
			applicationStage: "READY_TO_ISSUE",
		};

		submitFinalApplication(payload)
			.then((res) => {
				navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.HOME + "/3");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0'>
				<span className='overViewTitle'>Final Vote Verification</span>
			</SectionRow>
			<SectionRow className='w-full overflow-x-auto mb-5 customScrollBar'>
				<SectionRow className='w-full !min-w-max mb-2 justify-center items-center'>
					<StatCard
						value={pendingCount ? pendingCount : 0}
						text={"Pending Applications With Technical Committee"}
						image={STAT_ICON_PATHS.stat_duplication}
						accent={STAT_ACCENT_COLORS.blue}
						handleStatCard={() => {}}
						num={1}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={finalVoteCount ? finalVoteCount : 0}
						text={"Final Vote Verification"}
						image={STAT_ICON_PATHS.stat_resubmit}
						accent={STAT_ACCENT_COLORS.lightGreen}
						handleStatCard={() => {}}
						num={2}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>

					<StatCard
						value={signatureApprovalCount ? signatureApprovalCount : 0}
						text={"Pending Signature Approval"}
						image={STAT_ICON_PATHS.stat_checkMark}
						accent={STAT_ACCENT_COLORS.lightGreen}
						handleStatCard={() => {}}
						num={3}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>

					<StatCard
						value={finalVoteRejectedCount ? finalVoteRejectedCount : 0}
						text={"Rejected Final Vote Applications"}
						image={STAT_ICON_PATHS.stat_deleteMark}
						accent={STAT_ACCENT_COLORS.red}
						handleStatCard={() => {}}
						num={4}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
				</SectionRow>
			</SectionRow>
			<HomeTable
				useMappedApplicationNumber
				applicationKey={1}
				columns={tableHeaders}
				// tableTitle={selectedTitle}
				rows={records}
				handleSearchById={handleSearchById}
				view={showViewIcon(["1", "2", "3"], id) ? viewFunction : null}
				history={historyFunction}
				filters={STAKEHOLDERS}
				setFilter={handleStakeHolderType}
				// sort={sorting}
				handleSort={handleSorting}
				handleSize={handleSize}
				count={tableCount}
				page={params.page}
				size={params.size}
				handlePage={handlePage}
				filterTitle='Stakeholder'
			/>

			<ConfirmationPopup
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={"Send the application for signature"}
				content={"Please confirm to send the selected application for signature process !"}
				color={color}
				handleClick={handleSubmit}
				// handleChange={handleChange}
			/>
		</div>
	);
};

export default FinalVoteHome;
