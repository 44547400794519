import * as React from "react";
import { SectionRow } from "../section";
import PersonIcon from "@mui/icons-material/Person";
import { Button } from "@mui/material";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

export default function ToggleButtonSet({ data, handleClick, certificate, customIcon, maxWith }) {
	const [selectedButton, setSelectedButton] = React.useState(0);
	const select = (i) => {
		setSelectedButton(i);
		handleClick(i);
	};
	const renderButton = (array) => {
		return array?.map((item, i) => {
			return (
				<Button
					key={i}
					variant={i === selectedButton ? "contained" : "text"}
					className='!rounded-none !px-4 !capitalize'
					sx={
						i === selectedButton
							? {
									backgroundColor: "#20B05E",
									"&:hover": {
										backgroundColor: "#167D43",
										borderColor: "#167D43",
										boxShadow: "none",
									},
							  }
							: {
									color: "#20B05E",
									"&:hover": {
										backgroundColor: "#167D43",
										borderColor: "#167D43",
										boxShadow: "none",
										color: "#ffffff",
									},
							  }
					}
					startIcon={
						customIcon ? customIcon : certificate ? <CardMembershipIcon /> : <PersonIcon />
					}
					onClick={() => select(i)}>
					{item.name}
				</Button>
			);
		});
	};

	return (
		<div
			className={`mt-3 mb-5 pb-2 overflow-x-auto max-w-[${
				maxWith ? maxWith : "600px"
			}]   customScrollBar`}>
			<SectionRow className='min-w-max overflow-hidden rounded-xl border '>
				{renderButton(data)}
			</SectionRow>
		</div>
	);
}
