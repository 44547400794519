// stakeholderApplicationNumber

export const setStakeholderApplicationNumber = (stakeholderApplicationNumber) => {
	sessionStorage.setItem("stakeholderApplicationNumber", stakeholderApplicationNumber);
};

export const getStakeholderApplicationNumber = () => {
	return sessionStorage.getItem("stakeholderApplicationNumber");
};

//set Mapped Application No
export const setMappedApplicationNumber = (stakeholderApplicationNumber) => {
	sessionStorage.setItem("mappedApplicationNumber", stakeholderApplicationNumber);
};

export const getMappedApplicationNumber = () => {
	return sessionStorage.getItem("mappedApplicationNumber");
};

// userId
export const getUserId = () => {
	const store = localStorage.getItem("STORE");
	return store && JSON.parse(store)?.userId ? parseInt(JSON.parse(store)?.userId) : null;
};

//  stakeholderType
export const setStakeholderType = (stakeholderType) => {
	sessionStorage.setItem("stakeholderType", stakeholderType);
};
export const getStakeholderType = () => {
	return sessionStorage.getItem("stakeholderType");
};
// entityId
// export const setEntityId = (entityId) => {
// 	sessionStorage.setItem("entityId", entityId);
// };
// export const getEntityId = () => {
// 	return sessionStorage.getItem("entityId");
// };

//set Mapped Spplication No
export const setViewOnlyPermission = (value) => {
	sessionStorage.setItem("viewOnly", value);
};

export const getViewOnlyPermission = () => {
	return sessionStorage.getItem("viewOnly");
};
