import { UpperCasetoDashSeparated, hyphenSeparatedtoSpaceSeparated } from "./ObjectsCreateHelper";

/**
 * Selection dropDown functions
 */
export const selectionDropDownGetValue = (value) => {
  return value?.value;
};

export const selectionDropDownGetLable = (value) => {
  return value?.label;
};

export const selectionDropDownPutValues = (value) => {
  let options = value?.map((value) => ({
    value: value,
    label: hyphenSeparatedtoSpaceSeparated(value),
  }));
  return options;
};

export const selectionDropDownPutValuesNew = (value) => {
  let options = { value: value, label: hyphenSeparatedtoSpaceSeparated(value) };
  return options;
};

export const selectionOtherDropDownPutValues = (value) => {
  let options = { value: UpperCasetoDashSeparated(value), label: hyphenSeparatedtoSpaceSeparated(value) };
  return options;
};

export const selectionDropDownPutValuesNewCapital = (value) => {
  let options = { value: value, label: value };
  return options;
};

export const selectionDropDownTagValues = (value) => {
  let options = value?.map((value) => value.value);
  return options;
};

export const uploadPutValues = (value) => {
  let options = { value: value, path: value };
  return options;
};

/**
 * Register Selection drop down options
 */
export const StakeholderOptions = [
  { value: "certificationBody", label: "Certification Body" },
  { value: "laboratories", label: "Laboratories" },
  { value: "farmer", label: "Farmer" },
  { value: "farmerAssociation", label: "Farmer Association" },
  { value: "exporter", label: "Exporter" },
  { value: "importer", label: "Importer" },
  { value: "processor", label: "Processor" },
  { value: "trader", label: "Trader" },
];

/**
 * Stakeholder drop down options
 */
export const CB_BD_typeOfBusinessOptions = [
  { value: "PROPRIETOR", label: "Proprietor" },
  { value: "PARTNERSHIP", label: "Partnership" },
  { value: "PUBLIC_QUOTED", label: "Public Quoted" },
  { value: "STATE_OWNED", label: "State Owned" },
  { value: "PRIVATE_LIMITED", label: "Private Limited" },
  { value: "OTHER", label: "Other" },
];

/** */
export const CB_CP_nationality = [
  { value: "SRI_LANKAN", label: "Sri lankan" },
  { value: "OTHER", label: "Other" },
];

export const CB_CP_documentType = [
  { value: "NIC", label: "NIC" },
  { value: "PASSPORT", label: "Passport" },
];

/** */
export const CB_CP_passportDocumentType = [
  { value: "PASSPORT", label: "Passport" },
];

/** */
export const CB_UC_countryOfAccreditation = [
  { value: "Germany", label: "Germany" },
  { value: "Australia", label: "Australia" },
  { value: "UNITED_STATES_OF_AMERICA", label: "United States Of America" },
  { value: "Japan", label: "Japan" },
  { value: "Russia", label: "Russia" },
  { value: "CANADA", label: "Canada" },
  { value: "SOUTH_AFRICA", label: "South Africa" },
];

/** */
export const CB_UC_nameOfAccreditation = [
  {
    value: "DAkkS (Deutsche Akkreditierungsstelle)",
    label: "DAkkS (Deutsche Akkreditierungsstelle)",
  },
  {
    value: "UKAS (United Kingdom Accreditation Service)",
    label: "UKAS (United Kingdom Accreditation Service)",
  },
  {
    value: "RVA (Dutch Accreditation Council)",
    label: "RVA (Dutch Accreditation Council)",
  },
  {
    value: "ANAB (ANSI National Accreditation Board)",
    label: "ANAB (ANSI National Accreditation Board)",
  },
  {
    value: "IOAS (International Organic Accreditation Service)",
    label: "IOAS (International Organic Accreditation Service)",
  },
  {
    value: "COFRAC",
    label: "COFRAC",
  },
  {
    value: "SLAB ( Sri Lanka Accreditation Board)",
    label: "SLAB ( Sri Lanka Accreditation Board)",
  },
  {
    value: "USDA",
    label: "USDA",
  },
  {
    value: "Ministry of Agriculture Forestry and Fisheries",
    label: "Ministry of Agriculture Forestry and Fisheries",
  },
  {
    value: "OTHER",
    label: "Other"
  },
];

/** */
export const CB_UC_nameOfAccreditation2 = [
  // {
  //   value: "Bioagricert",
  //   label: "Bioagricert",
  // },
  // {
  //   value: "Ecocert Japan Ltd.",
  //   label: "Ecocert Japan Ltd.",
  // },
  // {
  //   value: "Bureau Veritas Japan Co., Ltd.",
  //   label: "Bureau Veritas Japan Co., Ltd.",
  // },
  // {
  //   value: "AINOU.",
  //   label: "AINOU.",
  // },
  // {
  //   value: "Organic Certification Organization Co. Ltd.",
  //   label: "Organic Certification Organization Co. Ltd."
  // },
  {
    value: "OTHER",
    label: "Other"
  },
];

/** */
export const CB_UC_titleOfOrganicStandard = [
  {
    value: "EU",
    label: "EU",
  },
  {
    value: "USDA-NOP",
    label: "USDA-NOP",
  },
  {
    value: "JAS",
    label: "JAS",
  },
  {
    value: "Demeter",
    label: "Demeter",
  },
  {
    value: "Bio Suisse",
    label: "Bio Suisse",
  },
  {
    value: "Naturland",
    label: "Naturland",
  },
  {
    value: "SLS 1324:2018",
    label: "SLS 1324:2018",
  },
  {
    value: "Agriculture Biologique",
    label: "Agriculture Biologique",
  },
  {
    value: "OTHER",
    label: "Other",
  }
];

export const TR_BD_traderType = [
  { value: "COLLECTOR", label: "Collector" },
  { value: "LOCAL_SALES", label: "Local sales" },
];

/**mock districts */
export const BD_districts = [
  { value: "COLOMBO", label: "Colombo" },
  { value: "GAMPAHA", label: "Gampaha" },
  { value: "KALUTARA", label: "Kalutara" },
];

/**mock divisionalSecretariat */
export const BD_divisionalSecretariat = [
  { value: "JA_ELA", label: "Ja Ela" },
  { value: "MALABE", label: "Malabe" },
  { value: "WATTALA", label: "Wattala" },
];

/**mock gramaNiladariDivision */
export const BD_gramaNiladariDivision = [
  { value: "JAELA_NORTH", label: "Jaela North" },
  { value: "JAELA_SOUTH", label: "Jaela South" },
  { value: "WATTALA_NORTH", label: "Wattala North" },
  { value: "WATTALA_SOUTH", label: "Wattala South" },
  { value: "MALABE_NORTH", label: "Malabe North" },
  { value: "MALABE_SOUTH", label: "Malabe South" },
];

export const EX_SP_typeOfProcess = [
  { value: "OWN_PROCESSING_PLANT", label: "Own Processing Plant" },
  { value: "SUBCONTRACT", label: "Subcontract" },
];



export const EX_CY_type = [
  { value: "INPUT", label: "Input" },
  { value: "PRODUCT", label: "Product" },
];

export const EX_CY_Category = [
  { value: "COCONUT_AND_COCONUT_BASED_PRODUCTS", label: "Coconut And Coconut Based Products" },
  { value: "Tea", label: "Tea" },
  { value: "Spices", label: "Spices" },
  { value: "Rice", label: "Rice" },
  { value: "Herbs", label: "Herbs" },
  { value: "FRUITS_AND_VEGETABLES", label: "Fruits And Vegetables" },
  { value: "Other", label: "Other" },
];

export const EX_CY_FormOfProduct = [
  { value: "Solid", label: "Solid" },
  { value: "Liquid", label: "Liquid" },
  { value: "Gas", label: "Gas" },
];

export const test_product_categories = [
  { value: "Coconut", label: "Coconut" },
  { value: "Spices", label: "Spices" },
  { value: "Tea", label: "Tea" },
];