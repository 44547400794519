import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const getApplicationNumberDetails = async (params) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/stakeholder/v1/get-application-Detail`,
        headers: {},
        params: params,
    })
        .then((res) => {
            console.log("getRecordsForPaymentTable", res);
            return res;
        })
        .catch((error) => {
            console.log("getRecordsForPaymentTable error!");
            throw error.response;
        });
};