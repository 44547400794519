import React, { useEffect, useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";
import { LABORATORIES_ROUTES } from "../../../app/router/routes";
import { SectionColumn } from "../../../layouts/sections";
import { Route } from "react-router-dom";

import { createStepper } from "../../../components/stepper/dynamicStepper";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import { getStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { convertStepperRes } from "./dataManipulation";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import LabReviewStep1 from "./flow/LabStep1";
import LabReviewStep2 from "./flow/LabStep2";
import LabReviewStep3 from "./flow/LabStep3";
import LabReviewStep4 from "./flow/LabStep4";
import LabReviewStep5 from "./flow/LabStep5";
import { getApplicationStatusOnSession } from "../../certificationBody/dataManipulation";

export const stepperName = "LABORATORIES";

const LabReviewHome = () => {
	const [res, setRes] = useState();
	const [stepper, setStepper] = useState();
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getStepStatusByApplicationId(getStakeholderApplicationNumber())
			.then((res) => {
				setRes(res.data);
				console.log("Data", res.data);
				console.log("Stepper=====>", convertStepperRes(res.data));
				setStepper(convertStepperRes(res.data));
			})
			.catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		stepper && setStepperData(createStepper(stepperName, stepper));
	}, [stepper]);

	const routeArray = [
		LABORATORIES_ROUTES.STEP01,
		LABORATORIES_ROUTES.STEP02,
		LABORATORIES_ROUTES.STEP03,
		LABORATORIES_ROUTES.STEP04,
		LABORATORIES_ROUTES.STEP05,
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Laboratories"}
				tableIcon={DASHBOARD_ICONS.labs}
				tableURL={LABORATORIES_ROUTES.HOME}
			/>

			{res && stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={LABORATORIES_ROUTES.STEP01}>
						<LabReviewStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[0]?.reviewStatus === "APPROVED" || res[0]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={LABORATORIES_ROUTES.STEP02}>
						<LabReviewStep2
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[1]?.reviewStatus === "APPROVED" || res[1]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={LABORATORIES_ROUTES.STEP03}>
						<LabReviewStep3
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[2]?.reviewStatus === "APPROVED" || res[2]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={LABORATORIES_ROUTES.STEP04}>
						<LabReviewStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[3]?.reviewStatus === "APPROVED" || res[3]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={LABORATORIES_ROUTES.STEP05}>
						<LabReviewStep5
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[4]?.reviewStatus === "APPROVED" || res[4]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default LabReviewHome;
