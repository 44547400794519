import React from "react";
import { Grid } from "@mui/material";
import UploadCertificationFormCard from "../../features/uploadCertificateDetails/UploadCertificateFormCard";
import UploadCertificationLabFormCard from "../../features/uploadCertificateDetails/Forms/UploadCertificateLabFormCard";
import UploadCertificationCBFormCard from "../../features/uploadCertificateDetails/Forms/UploadCertificationCBFormCard";

const UploadCertificateCard = ({
  handleSearchById,
  customSearchPlaceHolder,
  displayBusinessDetails,
  stakeholderApplicationNumber,
  responseCode,
  applicationId
}) => {

  const renderFormElement = () => {
    if (displayBusinessDetails) {
      return displayBusinessDetails.map((detail, i) =>
        detail.value ? (
          <div className='Rectangle-1 flex-row flex items-start' key={detail.id}>
            <div className=' w-4/12  m-1 Business-Name'>{detail.label}</div>
            <div className=' w-1/12  m-1 Business-Name'>:</div>
            <div className=' w-7/12  m-1 Business-Name'>{detail.value}</div>
          </div>
        ) : null
      );
    }
  };

  const renderNoApplicationDetails = () => {
    let displayMsg = ""
    if (responseCode === "12") {
      displayMsg = "No application found !";
    } else if (responseCode === "13") {
      displayMsg = "The application is not in frist review stage !"
    }

    return (
      <div className="flex flex-row justify-center my-10">
        <div className="applicationNotFoundMessage mr-2">{displayMsg}</div>
      </div>
    )
  };

  return (
    <div
      className='companyRegTbleContainer customScrollBar mb-3 overflow-x-auto shadow-xl '
      style={{
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px",
      }}>
      <Grid
        container
        style={{
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        className='overflow-hidden min-w-[900px]'>
        <Grid item xs={12} className='uploadCertificateSearch pb-3'>
          <label className='mr-2'> Search By Application Id :</label>
          <input
            placeholder={customSearchPlaceHolder ? customSearchPlaceHolder : "Application ID"}
            className='searchIconText w-40'
            onChange={(event) => {
              handleSearchById(event.target.value);
            }}
          />
        </Grid>
        {displayBusinessDetails?.length > 0 && (
          <>
            <Grid item xs={12} className='uploadCertificateDetailsCard pb-3'>
              <p className="uploadCertificateDetailsHeading">Business Details</p>
              {displayBusinessDetails && renderFormElement()}
            </Grid>
            <Grid item xs={12} className='uploadCertificateDetailsCard pb-3'>
              {applicationId.startsWith('LAB') ? (
                <UploadCertificationLabFormCard
                  applicationNumber={stakeholderApplicationNumber}
                />
              ) : applicationId.startsWith('CER') ? (
                <UploadCertificationCBFormCard
                  applicationNumber={stakeholderApplicationNumber}
                />
              ) : (
                <UploadCertificationFormCard
                  applicationNumber={stakeholderApplicationNumber}
                />
              )}
            </Grid>
          </>
        )}
        {displayBusinessDetails?.length === 0 && applicationId !== "" && (
          <Grid item xs={12} className='uploadCertificateDetailsCard pb-3'>
            {renderNoApplicationDetails()}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default UploadCertificateCard;
