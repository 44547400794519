import * as React from "react";
import { AiFillWarning } from "react-icons/ai";
import { SectionRow } from "../section";

export default function WarningModel({ message, ...props }) {

    return (
        <SectionRow className='warning-container'>
            <div className="my-auto">
                <AiFillWarning className="warning-icon" />
            </div>
            <div className="">
                {message}
            </div>
        </SectionRow>
    );
}