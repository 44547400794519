import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import FileUpload from "../../features/auth/components/FileUpload";
import { SectionColumn } from "../../layouts/sections";
import { closePopupIcon } from "../../Helpers/ICONS";
import { fileSaveCertUpload } from "../../app/api/NOCU/filserverAPI";
import LoaderComponent from "../Loader/LoaderComponent";

const FileUploadModal = (props) => {
  const [open, setOpen] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [loaderText, setLoaderText] = useState("");

  useEffect(() => {
    setOpen(props.fileUploadOpen);
  }, [props.fileUploadOpen]);

  const handleClose = () => props.setFileUploadOpen(false);

  const handleUploadConfirm = (values) => {
    values.file = props.currentFiles;
    values.tagname = "test";
    setLoaderText("Uploading...");
    setLoaderOpen(true);
    fileSaveCertUpload(values)
      .then((res) => {
        setLoaderOpen(false);
        props.setfilepath(res.fileId);

        return res;
      })
      .catch((error) => {
        setLoaderOpen(false);
        console.log(error);
        return error;
      });

    handleClose();
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBackDrop"
      >
        <div className="addStakeholderWrapper">
          <div className="fileUploadPopupCard flex justify-center">
            <div className="">
              <span className="cursor-pointer absolute -top-2 -right-2 border-2 border-white rounded-full h-7">
                <img
                  src={closePopupIcon}
                  alt={"Close"}
                  width={24}
                  height={24}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </span>
            </div>
            <SectionColumn className="items-center">
              <span className="pt-2 font-bold text-center">File Upload </span>
              <span className="pb-2 font-medium text-gray-400 text-xs">
                {props.title}
              </span>
              <div className="grid grid-cols-1 -mt-6 place-content-center h-full">
                <FileUpload
                  name={props.name}
                  type="PDF"
                  setFile={props.setFile}
                  currentFiles={props.currentFiles}
                  handleUploadConfirm={handleUploadConfirm}
                  multiple={props.multiple}
                  maxFiles={props.maxFiles}
                />
              </div>
            </SectionColumn>
          </div>
        </div>
      </Modal>
      <LoaderComponent isOpen={loaderOpen} />
    </React.Fragment>
  );
};

export default FileUploadModal;
