import { useEffect, useState } from "react";
import { APPROVED, REJECTED, SELECT } from "../../Helpers/constants";
import { SectionColumn } from "../section/index";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const selectStyle = "fileUploadButton relative border-4 border-x-blue-500";
const approveStyle = "fileUploadButton relative border-4 border-x-green-500";
const rejectStyle = "fileUploadButton relative border-4 border-x-red-500";
const defaultStyle = "fileUploadButton relative";

const FileUploadButton = ({ image, btnName, subBtnName, state, ...props }) => {
	const [isSelected, setisSelected] = useState("");
	const { onClick } = props;

	useEffect(() => {
		setisSelected(
			state.isApprove === APPROVED
				? approveStyle
				: state.isApprove === SELECT
				? selectStyle
				: state.isApprove === REJECTED
				? rejectStyle
				: defaultStyle
		);
	}, [state]);

	const handleClick = () => {
		onClick(state);
	};
	return (
		<div>
			<button
				onClick={() => {
					handleClick();
				}}
				className={props.className}>
				<SectionColumn className='grid justify-items-center'>
					<div>
						<div className={isSelected || defaultStyle}>
							{/* <div className=' absolute top-0 right-0'></div> */}
							<div className='flex justify-center items-center h-full'>
								{/* <img src={image} alt='author' width={21} height={21} /> */}
								<InsertDriveFileIcon sx={{ color: "#1fae5c", fontSize: 28 }} />
							</div>
						</div>
					</div>
					<span className='fileUploadButtonText'> {btnName} </span>
					{subBtnName && <span className='fileUploadButtonText !mt-0 '> ({subBtnName}) </span>}
				</SectionColumn>
			</button>
		</div>
	);
};

export default FileUploadButton;
