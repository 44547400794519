import React, { useEffect, useState } from "react";
import CertificationBodyReviewStep1 from "../../certificationBody/review/flow/certificationBodyReviewStep1";
import { setStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { SectionRow } from "../../../layouts/sections";
import { Button, Stack } from "@mui/material";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import TopButtonArray from "./components/TopButtonArray";
import CertificationBodyReviewStep2 from "../../certificationBody/review/flow/certificationBodyReviewStep2";
import CertificationBodyReviewStep3 from "../../certificationBody/review/flow/certificationBodyReviewStep3";
import CertificationBodyReviewStep4 from "../../certificationBody/review/flow/certificationBodyReviewStep4";
import CertificationBodyReviewStep5 from "../../certificationBody/review/flow/certificationBodyReviewStep5";
import FarmerAssociationReviewStep1 from "./../../farmerAssociation/review/flow/FarmerAssociationReviewStep1";
import FarmerAssociationReviewStep2 from "./../../farmerAssociation/review/flow/FarmerAssociationReviewStep2";
import FarmerAssociationReviewStep3 from "./../../farmerAssociation/review/flow/FarmerAssociationReviewStep3";
import FarmerAssociationReviewStep4 from "./../../farmerAssociation/review/flow/FarmerAssociationReviewStep4";
import FarmerAssociationReviewStep5 from "./../../farmerAssociation/review/flow/FarmerAssociationReviewStep5";
import LoaderComponent from "../../../components/Loader/LoaderComponent";

const FarmerAssociationSummaryView = ({ applicationId }) => {
	const [selectedPage, setSelectedPage] = useState(0);
	const [statusArray, setStatusArray] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setStakeholderApplicationNumber(applicationId);
		getStepStatusByApplicationId(applicationId)
			.then((res) => {
				setStatusArray(res.data);
			})
			.finally(() => {
				setTimeout(() => setLoading(false), 1000);
			});
	}, [applicationId]);

	const btnNames = [
		"Business Details",
		"Contact Personnel",
		"Management Personnel",
		"Certificates",
		"Other Documents",
	];

	return (
		<div>
			<SectionRow className='w-full justify-center'>
				{statusArray && (
					<TopButtonArray
						nameArray={btnNames}
						selectedPage={selectedPage}
						setSelectedPage={setSelectedPage}
						statusArray={statusArray}
					/>
				)}
				{/* <Stack direction={"row"} spacing={2}>
					{statusArray && renderButtonStack()}
				</Stack> */}
			</SectionRow>
			{!loading && selectedPage === 0 ? (
				<FarmerAssociationReviewStep1
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 1 ? (
				<FarmerAssociationReviewStep2
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 2 ? (
				<FarmerAssociationReviewStep3
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 3 ? (
				<FarmerAssociationReviewStep4
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : selectedPage === 4 ? (
				<FarmerAssociationReviewStep5
					hideBtns={true}
					stepperData={undefined}
					stepperName={undefined}
					routeArray={undefined}
					setIsLoading={setLoading}
					readOnly={true}
				/>
			) : (
				<></>
			)}
			<LoaderComponent isOpen={loading} />
		</div>
	);
};

export default FarmerAssociationSummaryView;
