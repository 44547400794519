// import CustomAxios from "CustomAxios";
import { API_BASE_URL } from "./core_api";
import CustomAxios from "./customAxios/customAxios";

const FILE_BASE = API_BASE_URL;
export const initFileID = "5406f8eb-a765-43bc-8921-d7a5860908f5";

export const getFileAPI = async (fileID) => {
	return CustomAxios({
		method: "GET",
		baseURL: FILE_BASE,
		url: `/file/${fileID}`,
		headers: {},
	})
		.then((res) => {
			console.log("response", res);
			return res.data;
		})
		.catch((error) => {
			throw error.response;
		});
};

export const getFileURL = (fileID) => {
	return `${FILE_BASE}/file/${fileID}`;
};
