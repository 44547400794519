import React from "react";
import EndStep from "./components/end";
import NodeStep from "./components/node";
import StartStep from "./components/start";

//Status
//Upcoming steps (Disabled clicks)(Default with empty props)

//COMPLETED -already completed(Green)
//ACTIVE - currently viewing step(Blue)
//REJECTED - rejected (Red)

//CURRENT- show down indicator (Currently Selected)

// [
// 	{step:"Product Information",status:"ACTIVE"},
// 	{step:"Business Registration",status:""},
// 	{step:"Management Info",status:""},
// 	{step:"Other Personnel Info",status:""},
// 	{step:"Process Flow",status:""},
//
// ]

export const STEPPER_STATUS = {
	COMPLETED: "COMPLETED",
	ACTIVE: "ACTIVE",
	REJECTED: "REJECTED",
	CURRENT: "CURRENT",
	RESUBMIT: "RESUBMIT",
};

const storage = sessionStorage;

export const createStepper = (name, stepsArray) => {
	const stepperDataObject = {
		currentStep: 0,
		stepsArray: stepsArray,
	};

	if (storage.getItem(`${name}StepperDataObject`) === null || "") {
		storage.setItem(`${name}StepperDataObject`, JSON.stringify(stepperDataObject));
	}
	return JSON.parse(storage.getItem(`${name}StepperDataObject`));
};

export const getStepperDataObject = (name) => {
	return JSON.parse(storage.getItem(`${name}StepperDataObject`));
};

export const completeStep = (name, index) => {
	try {
		const stepsObject = JSON.parse(storage.getItem(`${name}StepperDataObject`));
		stepsObject.stepsArray[index].status = "COMPLETED";
		storage.setItem(`${name}StepperDataObject`, JSON.stringify(stepsObject));
		return JSON.parse(storage.getItem(`${name}StepperDataObject`));
	} catch (error) {
		console.log(error);
	}
};

export const activeStep = (name, index) => {
	if (getStepStatus(name, index) !== "") {
		console.log("Not COMPLETED or REJECTED", getStepStatus());
		return JSON.parse(storage.getItem(`${name}StepperDataObject`));
	} else {
		const stepsObject = JSON.parse(storage.getItem(`${name}StepperDataObject`));
		stepsObject.stepsArray[index].status = "ACTIVE";
		storage.setItem(`${name}StepperDataObject`, JSON.stringify(stepsObject));
		return JSON.parse(storage.getItem(`${name}StepperDataObject`));
	}
};

export const rejectStep = (name, index) => {
	try {
		const stepsObject = JSON.parse(storage.getItem(`${name}StepperDataObject`));
		stepsObject.stepsArray[index].status = "REJECTED";
		storage.setItem(`${name}StepperDataObject`, JSON.stringify(stepsObject));
		return JSON.parse(storage.getItem(`${name}StepperDataObject`));
	} catch (error) {
		console.log(error);
	}
};
export const resubmitStep = (name, index) => {
	try {
		const stepsObject = JSON.parse(storage.getItem(`${name}StepperDataObject`));
		stepsObject.stepsArray[index].status = "RESUBMIT";
		storage.setItem(`${name}StepperDataObject`, JSON.stringify(stepsObject));
		return JSON.parse(storage.getItem(`${name}StepperDataObject`));
	} catch (error) {
		console.log(error);
	}
};

export const setCurrentStep = (name, index) => {
	try {
		const stepsObject = JSON.parse(storage.getItem(`${name}StepperDataObject`));
		stepsObject.currentStep = index;
		storage.setItem(`${name}StepperDataObject`, JSON.stringify(stepsObject));
		return JSON.parse(storage.getItem(`${name}StepperDataObject`));
	} catch (error) {
		console.log(error);
	}
};

export const getCurrentStep = (name) => {
	try {
		return JSON.parse(storage.getItem(`${name}StepperDataObject`)).currentStep;
	} catch (error) {
		console.log(error);
	}
};

export const getStepStatus = (name, index) => {
	try {
		const stepsObject = JSON.parse(storage.getItem(`${name}StepperDataObject`));
		return stepsObject.stepsArray[index].status;
	} catch (error) {
		console.log(error);
	}
};
export const deleteStepper = (name) => {
	storage.removeItem(`${name}StepperDataObject`);
};

export const reduce1Step = (name) => {
	const currentStep = getCurrentStep(name);
	setCurrentStep(name, parseInt(currentStep) - 1);
};

export const disableAlert = () => {
	sessionStorage.setItem("isDisableAlert", true);
};
export const enableAlert = () => {
	sessionStorage.setItem("isDisableAlert", false);
};

export const getIsDisableAlert = () => {
	return sessionStorage.getItem("isDisableAlert");
};

const DynamicStepper = ({ stepperData, name, routeArray }) => {
	const stepsArray = stepperData.stepsArray;
	const currentStep = stepperData.currentStep;

	console.log("StepperData", stepperData)
	console.log("StepperName", name)
	console.log("StepperArray", routeArray)
	return (
		<div className='mx-auto'>
			<div className='flex flex-row '>
				{stepsArray.map((step, index) =>
					index === 0 ? (
						index === currentStep ? (
							<StartStep
								key={index}
								title={step.step}
								status={step.status}
								focus
								index={index}
								name={name}
								route={routeArray[index]}
							/>
						) : (
							<StartStep
								key={index}
								title={step.step}
								status={step.status}
								index={index}
								name={name}
								route={routeArray[index]}
							/>
						)
					) : index === stepsArray.length - 1 ? (
						index === currentStep ? (
							<EndStep
								key={index}
								title={step.step}
								status={step.status}
								focus
								index={index}
								name={name}
								route={routeArray[index]}
							/>
						) : (
							<EndStep
								key={index}
								title={step.step}
								status={step.status}
								index={index}
								name={name}
								route={routeArray[index]}
							/>
						)
					) : index === currentStep ? (
						<NodeStep
							key={index}
							title={step.step}
							status={step.status}
							focus
							index={index}
							name={name}
							route={routeArray[index]}
						/>
					) : (
						<NodeStep
							key={index}
							title={step.step}
							status={step.status}
							index={index}
							name={name}
							route={routeArray[index]}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default DynamicStepper;
