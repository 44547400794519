import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";
import { TECHNICAL_COMMITTEE_ROUTES } from "../../../app/router/routes";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import NavBar from "../../../components/navbar/NavBar";
import { SectionColumn } from "../../../components/section";
import {
	STEPPER_STATUS,
	createStepper,
	disableAlert,
} from "../../../components/stepper/dynamicStepper";
import ExporterStep1 from "../../exporter/review/flow/ExporterStep1";
import ExporterStep4 from "../../exporter/review/flow/ExporterStep4";
import ExporterStep5 from "../../exporter/review/flow/ExporterStep5";
import ExporterStep6 from "../../exporter/review/flow/ExporterStep6";
import ExporterStep7 from "../../exporter/review/flow/ExporterStep7";
import ExporterStep8 from "../../exporter/review/flow/ExporterStep8";

export const stepperName = "FCView";

const TC_ExporterViewer = () => {
	const stepper = [
		{
			step: "Business Details",
			status: STEPPER_STATUS.COMPLETED,
		},
		{
			step: "Source of Product",
			status: STEPPER_STATUS.COMPLETED,
		},
		{
			step: "Previous Product Details",
			status: STEPPER_STATUS.COMPLETED,
		},

		{
			step: "Current Product Details",
			status: STEPPER_STATUS.COMPLETED,
		},

		{
			step: "Certificates",
			status: STEPPER_STATUS.COMPLETED,
		},
		{ step: "Other Documents", status: STEPPER_STATUS.COMPLETED },
	];
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setStepperData(createStepper(stepperName, stepper));
		disableAlert();
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}, []);

	const routeArray = [
		TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP01,
		TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP02,
		TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP03,
		TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP04,
		TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP05,
		TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP06,
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Technical Committee Review"}
				tableIcon={DASHBOARD_ICONS.technicalCommittee}
				tableURL={TECHNICAL_COMMITTEE_ROUTES.REVIEW}
			/>

			{stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP01}>
						<ExporterStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={0}
							nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP02}
							readOnly
							hideFiles={true}
						/>
					</Route>

					<Route path={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP02}>
						<ExporterStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={1}
							nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP03}
							readOnly
						/>
					</Route>
					<Route path={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP03}>
						<ExporterStep5
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={2}
							nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP04}
							readOnly
						/>
					</Route>
					<Route path={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP04}>
						<ExporterStep6
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={3}
							nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP05}
							readOnly
						/>
					</Route>
					<Route path={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP05}>
						<ExporterStep7
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={4}
							nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP06}
							readOnly
						/>
					</Route>
					<Route path={TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP06}>
						<ExporterStep8
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={5}
							nextCustomRoute={TECHNICAL_COMMITTEE_ROUTES.REVIEW}
							readOnly
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default TC_ExporterViewer;
