import { STATUS } from "../app/constants";

const _data = {
	isFile: false,
	name: "mobileNumber",
	entityId: 1569,
	value: "0365588774",
	comment: "neeee",
};

export const setAllRejections = (rejections) => {
	sessionStorage.setItem("REJECTIONS", JSON.stringify(rejections));
};




export const setApplicationRejectDetails = (status, data) => {
	let rejections = [];
	rejections = sessionStorage.getItem("REJECTIONS")
		? JSON.parse(sessionStorage.getItem("REJECTIONS"))
		: [];

	rejections = rejections.filter((obj) => {
		return obj?.name !== data?.name || obj?.entityId !== data?.entityId;
	});
	console.log("Filtered Array ====>", rejections);
	if (status === STATUS.REJECTED) {
		rejections.push(data);
	}
	console.log("Added Array ====>", rejections);
	sessionStorage.setItem("REJECTIONS", JSON.stringify(rejections));
};

export const deleteAllApplicationRejectDetails = () => {
	sessionStorage.removeItem("REJECTIONS");
};

export const getApplicationRejectDetailsByEntityId = (entityId) => {
	let rejections = [];
	rejections = sessionStorage.getItem("REJECTIONS")
		? JSON.parse(sessionStorage.getItem("REJECTIONS"))
		: [];

	rejections = rejections.filter((obj) => {
		return obj?.entityId === entityId;
	});
	console.log("Rejections by entityId ==>", rejections);
	return rejections;
};
export const getApplicationRejectDetailsAll = () => {
	let rejections = [];
	rejections = sessionStorage.getItem("REJECTIONS")
		? JSON.parse(sessionStorage.getItem("REJECTIONS"))
		: [];
	console.log("Rejections by entityId ==>", rejections);
	return rejections;
};
