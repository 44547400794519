import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";


//Get Table records
export const getRecordsForCateRegTable = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/product/v1/get-by-status-and-officer`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("getRecordsForCateRegTable", res);
            return res;
        })
        .catch((error) => {
            console.log("getRecordsForCateRegTable error!");
            throw error.response;
        });
};
//Get table Counts
export const getCountsForCateRegTable = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/product/v1/get-counts-by-status-and-officer`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("getCountsForCateRegTable", res);
            return res;
        })
        .catch((error) => {
            console.log("getCountsForCateRegTable error!");
            throw error.response;
        });
};
//Submit Category review
export const submitProductCategoryReview = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/product/v1/category/review`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("submitProductCategoryReview", res);
            return res;
        })
        .catch((error) => {
            console.log("submitProductCategoryReview error!");
            throw error.response;
        });
};
//Category details
export const getCategoryDetails = async (id) => {
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `/product/v1/category/get-by-id/${id}`,
        headers: {},

    })
        .then((res) => {
            console.log("getCategoryDetails", res);
            return res;
        })
        .catch((error) => {
            console.log("getCategoryDetails error!");
            throw error.response;
        });
};