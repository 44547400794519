export const getTableData = (records) => {
    const applicationList = records?.paymentDetails;
    return applicationList
        ? applicationList.map((item) => [
            item?.paymentReceiptNumber ? item.paymentReceiptNumber : "-",
            item?.referenceId ? item.referenceId : "-",
            item?.paymentType ? item.paymentType : "-",
            item?.paymentMethod ? item.paymentMethod : "-",
            item?.paymentDate ?
                `${item?.paymentDate.split("T")[0] ? item.paymentDate.split("T")[0] : ""} ${item?.paymentDate.split("T")[1].split(".")[0]
                    ? item.paymentDate.split("T")[1].split(".")[0]
                    : ""
                }` : "-"
        ])
        : [];
};

export const getPaymentForm = (data) => {
    return [
        { label: "Payment receipt number", value: data?.paymentReceiptNumber },
        { label: "Reference Id", value: data?.referenceId },
        { label: "Payment Type", value: data?.paymentType },
        { label: "Payment Method", value: data?.paymentMethod },
        { label: "Payment Amount", value: data?.paymentAmount },
        { label: "Payment Remark", value: data?.paymentRemark },
        {
            label: "Payment Date", value: data?.paymentDate ?
                `${data?.paymentDate.split("T")[0] ? data.paymentDate.split("T")[0] : ""} ${data?.paymentDate.split("T")[1].split(".")[0]
                    ? data.paymentDate.split("T")[1].split(".")[0]
                    : ""
                }` : "-"
        },
        { label: "Payment Status", value: data?.paymentStatus },

    ];
};
