import React from "react";
import { SectionColumn, SectionRow } from "../../../components/section";

const UserDetailsCard = ({ userData }) => {
	return (
		<SectionRow className='w-full items-center px-2 pt-2 pb-8'>
			<SectionColumn className='pl-2'>
				<SectionRow className='w-full font-semibold'>
					{userData?.fullName} ({userData?.designation})
				</SectionRow>
				<SectionRow className='w-full '>{userData?.email}</SectionRow>
				<SectionRow className='w-full'>{userData?.contactNo}</SectionRow>
			</SectionColumn>
		</SectionRow>
	);
};

export default UserDetailsCard;
