import { Tooltip } from '@mui/material';
import React from 'react';
import { stringCrop } from '../../Helpers/stringCrop';

const StringTooltip = ({ text, length = 17 }) => {
    return (
        text.length > length ? (
            <Tooltip title={<div className='text-base'>{text}</div>}>
                <div>{stringCrop(text, length)}</div>
            </Tooltip>
        ) : (
            text
        )
    );
};

export default StringTooltip;