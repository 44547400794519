import React, { useEffect, useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";
import { FARMER_ROUTES } from "../../../app/router/routes";
import { SectionColumn } from "../../../layouts/sections";
import { Route } from "react-router-dom";

import { createStepper } from "../../../components/stepper/dynamicStepper";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import { getStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { convertStepperRes } from "./dataManipulation";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import FarmerReviewStep1 from "./flow/FarmerReviewStep1";
import FarmerReviewStep2 from "./flow/FarmerReviewStep2";
import FarmerReviewStep3 from "./flow/FarmerReviewStep3";
import FarmerReviewStep4 from "./flow/FarmerReviewStep4";
import { getApplicationStatusOnSession } from "../../certificationBody/dataManipulation";

export const stepperName = "FARMER";

const FarmerReviewHome = () => {
	const [res, setRes] = useState();
	const [stepper, setStepper] = useState();
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getStepStatusByApplicationId(getStakeholderApplicationNumber())
			.then((res) => {
				setRes(res.data);
				console.log("Data", res.data);
				console.log("Stepper=====>", convertStepperRes(res.data));
				setStepper(convertStepperRes(res.data));
			})
			.catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		stepper && setStepperData(createStepper(stepperName, stepper));
	}, [stepper]);

	const routeArray = [
		FARMER_ROUTES.STEP01,
		FARMER_ROUTES.STEP02,
		FARMER_ROUTES.STEP03,
		FARMER_ROUTES.STEP04,
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Farmer"}
				tableIcon={DASHBOARD_ICONS.farmer}
				tableURL={FARMER_ROUTES.HOME}
			/>

			{res && stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={FARMER_ROUTES.STEP01}>
						<FarmerReviewStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[0]?.reviewStatus === "APPROVED" || res[0]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={FARMER_ROUTES.STEP02}>
						<FarmerReviewStep2
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[1]?.reviewStatus === "APPROVED" || res[1]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={FARMER_ROUTES.STEP03}>
						<FarmerReviewStep3
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[3]?.reviewStatus === "APPROVED" || res[3]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={FARMER_ROUTES.STEP04}>
						<FarmerReviewStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[4]?.reviewStatus === "APPROVED" || res[4]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default FarmerReviewHome;
