import React from "react";
import { SectionColumn, SectionRow } from "../../../components/section";
import { Button, Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import {
	getUserAllocation,
	updateUserMeetingModeSettings,
	updateUserPermission,
} from "../../../app/api/NOCU/userManagementApi";
import { getUserIDFromLocal } from "../../../Helpers/loginHelper";
import { getUserId } from "./../../../app/api/NOCU/helpers/applicationApiHelpers";
import { useAlert } from "../../../app/context/alerts";

const PermissionPage = ({ userData, refresh, handleRefresh, setIsLoading }) => {
	const [permissions, setPermissions] = useState([]);
	const [isEnableUpdate, setIsEnableUpdate] = useState(false);
	const { setAlert } = useAlert();

	useEffect(() => {
		setIsLoading(true);
		userData &&
			getUserAllocation(userData?.userId, { isOnlyPermission: true })
				.then((res) => {
					setPermissions(res.data);
				})
				.catch((e) => console.log(e))
				.finally(() => {
					setIsLoading(false);
				});
	}, [userData, refresh]);
	const handleChange = (event, id) => {
		setIsEnableUpdate(true);
		console.log("Permission Change ======>", event.target.name, event.target.checked, id);
		setPermissions((prevState) => {
			return prevState.map((checkbox, index) => {
				return id === index ? { ...checkbox, isMeetingMode: event.target.checked } : checkbox;
			});
		});
	};
	useEffect(() => {
		console.table(permissions);
	}, [permissions]);

	const handleUpdate = () => {
		setIsLoading(true);
		const payload = {
			officerId: userData?.userId,
			modifiedUserId: getUserId(),
			permission: permissions,
		};
		updateUserMeetingModeSettings(payload)
			.then((res) => {
				console.log(res.data);
				setAlert({
					message: res.data?.responseMessage
						? res.data?.responseMessage
						: res.data?.responseCode === "00"
						? "Updated Successfully"
						: "Updated Error",
					severity: res.data?.responseCode === "00" ? "success" : "error",
					show: true,
				});
			})
			.catch((e) => console.log(e))
			.finally(() => {
				setTimeout(() => {
					handleRefresh();
				}, 1500);
			});
	};

	return (
		<SectionColumn className='w-full overflow-auto h-[80vh]'>
			<SectionRow className='border-b pb-2 border-gray-200 w-full'>
				Edit Meeting-Mode settings
			</SectionRow>
			<SectionRow className='w-full pt-10 px-5  h-[70vh]'>
				<FormGroup>
					<SectionRow className='w-full min-w-[55vw] '>
						{permissions.length === 0 ? (
							<div>No Meeting mode permissions found !</div>
						) : (
							permissions.map((permission, index) => (
								<div className='w-6/12'>
									<FormControlLabel
										checked={permission?.isMeetingMode}
										key={index}
										onChange={(e) => handleChange(e, index)}
										name={permission?.permission}
										control={<Checkbox />}
										label={
											permission?.permission ? permission?.permission.replaceAll("_", " ") : ""
										}
									/>
								</div>
							))
						)}
					</SectionRow>
				</FormGroup>
			</SectionRow>
			<SectionRow className='w-full flex-row-reverse px-10'>
				<Button
					variant='contained'
					color='error'
					className='!mx-3 !capitalize'
					onClick={() => {
						handleRefresh();
					}}>
					Reset
				</Button>
				<Button
					onClick={() => {
						handleUpdate();
					}}
					disabled={!isEnableUpdate}
					variant='contained'
					className='!mx-3 !capitalize'>
					Update
				</Button>
			</SectionRow>
		</SectionColumn>
	);
};

export default PermissionPage;
