export const checkUserLogin = () => {
	const store = localStorage.getItem("STORE");
	const accessToken = localStorage.getItem("ACCESSTOKEN");
	const refreshToken = localStorage.getItem("REFRESHTOKEN");
	if (store && accessToken && refreshToken) {
		const user = JSON.parse(store);

		if (
			user?.userId &&
			user?.userName &&
			user?.email &&
			user?.role &&
			user?.userRoleID &&
			user?.roleId
		) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const setLoggingTime = () => {
	const h = 1000 * 60 * 60 * 1;
	const loggedTime = Date.now();
	const expireTime = loggedTime + h;
	localStorage.setItem("loggedExpire", expireTime);
};
export const getIsActiveSession = () => {
	const expireTime = parseInt(
		localStorage.getItem("loggedExpire") ? localStorage.getItem("loggedExpire") : "0"
	);
	const dateNow = Date.now();
	const diff = Math.floor((expireTime - dateNow) / 1000 / 60);
	const isLogged = diff > 0 ? true : false;
	console.log("Time Is Logged======>", isLogged);
	return isLogged;
};

export const setCurrentTabActive = () => {
	sessionStorage.setItem("CurrentTabActive", true);
};

export const getIsCurrentTabActive = () => {
	return sessionStorage.getItem("CurrentTabActive");
};

export const setInitialLoadingPrompt = (isClosed) => {
	sessionStorage.setItem("InitialLoadingPrompt", isClosed);
};
export const getInitialLoadingPrompt = () => {
	const isClosed = sessionStorage.getItem("InitialLoadingPrompt");
	return isClosed;
};
