import React from "react";
import { FaArrowLeft } from 'react-icons/fa';

function BackButton({ handleClick, title }) {
    return (
        <button className="back-button" onClick={handleClick}>
            <FaArrowLeft />
            {title}
        </button>
    );
}

export default BackButton;