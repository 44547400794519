import React from "react";
import Chart from "react-apexcharts";

export default function PieChart({ value, ...props }) {
    console.log("count22", value)

    const chartOptions = {
        colors: ['#FF9900', '#2e7d32', '#d32f2f'],
        labels: ['Pending', 'Approved', 'Rejected'],
        dataLabels: {
            enabled: true,
            value: true,
            formatter: function (val, opts) {
                // const name = opts.w.globals.labels[opts.seriesIndex];
                const value = opts.w.globals.series[opts.seriesIndex];
                // return `${name}: ${value}`;
                return value;
            }
        }
    };

    const chartData = {
        series: value,
    };
    return (
        <Chart
            options={chartOptions}
            series={chartData.series}
            type="donut"
            width={450}
        />
    );
}
