import React, { useEffect, useState } from "react";
import FinalVoteVerficationReview from "../finalVote/FinalVoteVerficationReview";
import { getApplicationNumberDetails } from "../../app/api/NOCU/summaryApi";

const VotingInfo = ({ applicationId }) => {
	const [appId, setAppId] = useState();
	useEffect(() => {
		applicationId && applicationId.toString().includes("SH-")
			? setAppId(applicationId)
			: getApplicationNumberDetails({ applicationNumber: applicationId }).then((res) => {
					setAppId(res.data?.mappedApplicationNumber);
			  });
	}, [applicationId]);
	return (
		<div>
			{appId && <FinalVoteVerficationReview mappedApplicationNumberProp={appId} readOnly={true} />}
		</div>
	);
};

export default VotingInfo;
