import { createContext, useState } from "react";

const ModalOpenCloseContext = createContext({
  otherDropDownPopupModal: false,
  setOtherDropDownPopupModal: (count) => { },

  otherTitleDropDownPopupModal: false,
  setOtherTitleDropDownPopupModal: (count) => { },
});

const ModalOpenCloseContextProvider = (props) => {
  const [otherDropDownPopupModal, setOtherDropDownPopupModal] = useState(false);
  const [otherTitleDropDownPopupModal, setOtherTitleDropDownPopupModal] = useState(false);

  return (
    <ModalOpenCloseContext.Provider
      value={{
        otherDropDownPopupModal, setOtherDropDownPopupModal,
        otherTitleDropDownPopupModal, setOtherTitleDropDownPopupModal
      }}
    >
      {props.children}
    </ModalOpenCloseContext.Provider>
  );
}

export { ModalOpenCloseContext };
export default ModalOpenCloseContextProvider;
