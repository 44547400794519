import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { FINAL_VOTE_VERIFICATION_ROUTES } from "../../../app/router/routes";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import NavBar from "../../../components/navbar/NavBar";
import { SectionColumn } from "../../../components/section";
import {
	createStepper,
	disableAlert,
	STEPPER_STATUS,
} from "../../../components/stepper/dynamicStepper";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";
import LabReviewStep1 from "../../labs/review/flow/LabStep1";
import LabReviewStep4 from "../../labs/review/flow/LabStep4";
import LabReviewStep5 from "../../labs/review/flow/LabStep5";

export const stepperName = "FVView";

const FinalVoteLabView = () => {
	const stepper = [
		{
			step: "Business Details",
			status: STEPPER_STATUS.COMPLETED,
		},

		{
			step: "Certificates",
			status: STEPPER_STATUS.COMPLETED,
		},
		{ step: "Other Documents", status: STEPPER_STATUS.COMPLETED },
	];
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setStepperData(createStepper(stepperName, stepper));
		disableAlert();
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}, []);

	const routeArray = [
		FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP01,
		FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP02,
		FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP03,
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Final Vote Verification Review"}
				tableIcon={DASHBOARD_ICONS.technicalCommittee}
				tableURL={FINAL_VOTE_VERIFICATION_ROUTES.VOTEREVIEW}
			/>

			{stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP01}>
						<LabReviewStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={0}
							nextCustomRoute={FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP02}
							readOnly
							hideFiles={true}
						/>
					</Route>

					<Route path={FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP02}>
						<LabReviewStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={1}
							nextCustomRoute={FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP03}
							readOnly
						/>
					</Route>
					<Route path={FINAL_VOTE_VERIFICATION_ROUTES.LBSTEP03}>
						<LabReviewStep5
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={2}
							nextCustomRoute={FINAL_VOTE_VERIFICATION_ROUTES.VOTEREVIEW}
							readOnly
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default FinalVoteLabView;
