export const stringCrop = (str, charCount) => {
	if (str !== null) {
		if (str.length > charCount) {
			return str.substring(0, charCount) + "...";
		} else {
			return str;
		}
	} else {
		return null;
	}
};

export const convertConstNames = (str) => {
	return str.replaceAll("_", " ");
};

export const currentDate = () => {
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0");
	const yyyy = today.getFullYear();
	return yyyy + "-" + mm + "-" + dd;
};