// import CustomAxios from "CustomAxios";
import { getRoleIDFromLocal, getUserIDFromLocal } from "../../Helpers/loginHelper";
import CustomAxios from "./customAxios/customAxios";
import { step01 } from "./mock/registration";

export const SUFFIX = "/ecoo-core";
export const AUTH_BASE_URL = "https://nocu.lk/nocu-core";
export const API_BASE_URL = AUTH_BASE_URL;

// "https://192.168.20.21:8080/nocu-core"
// https://kvvf4z8ux2.execute-api.us-east-1.amazonaws.com

export const getRegRequestAPI = () => {
	CustomAxios.get("/api/");
};

export const getFromStep = () => {
	CustomAxios.get("/api/");
};

export const getFromStep1API = async (id) => {
	console.log("Id >>>>>>>>>>", id);
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/cp-product/find/${id}`,
		headers: {},
	})
		.then((res) => {
			console.log("response", res);
			return res.data;
		})
		.catch((error) => {
			const mock = JSON.parse(JSON.stringify(step01));
			console.log("API error", mock);
			throw error.response;
		});
};

export const getFromStep2API = async (id) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/company/find/${id}`,
		headers: {},
	})
		.then((res) => {
			console.log("response", res);
			return res.data;
		})
		.catch((error) => {
			const mock = JSON.parse(JSON.stringify(step01));
			console.log("API error", mock);
			throw error.response;
		});
};

export const getFromStep3API = async (id) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/management/find/${id}`,
		headers: {},
	})
		.then((res) => {
			console.log("response", res);
			return res.data;
		})
		.catch((error) => {
			const mock = JSON.parse(JSON.stringify(step01));
			console.log("API error", mock);
			throw error.response;
		});
};

export const getFromStep4API = async (id) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/person/find/${id}`,
		headers: {},
	})
		.then((res) => {
			console.log("response", res);
			return res.data;
		})
		.catch((error) => {
			const mock = JSON.parse(JSON.stringify(step01));
			console.log("API error", mock);
			throw error.response;
		});
};

export const getFromStep5API = async (id) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/factory/find/${id}`,
		headers: {},
	})
		.then((res) => {
			console.log("response", res);
			return res.data;
		})
		.catch((error) => {
			const mock = JSON.parse(JSON.stringify(step01));
			console.log("API error", mock);
			throw error.response;
		});
};

export const getCompanyRegDetailsByStatus = async (status, params) => {
	const userID = getUserIDFromLocal();
	if ("COMPLETED" === status && userID === 1) {
		status = "APPROVED";
	} else if ("COMPLETED" === status && userID === 2) {
		status = "APPROVED";
	} else if ("COMPLETED" === status && userID === 3) {
		status = "APPROVED";
	}

	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: "/company/status/" + status + "/" + userID,
		params: params,
		headers: {},
	})
		.then((res) => {
			console.log("response", res);
			return res;
		})
		.catch((error) => {
			console.log("API error");
		});
};

export const getCompanyRegStatus = async () => {
	const userID = getUserIDFromLocal();
	console.log("getCompanyRegStatus USER:", userID);
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: "ecoo-core/company/get/status/count/" + userID,
		headers: {},
	})
		.then((res) => {
			console.log("getCompanyRegStatus :", res);
			let count = 0;
			if (userID === 1) {
				count = res?.data?.pendingCount;
			} else if (userID === 2) {
				count = res?.data?.checkedCount;
			} else {
				count = res?.data?.recommendedCount;
			}
			return count;
		})
		.catch((error) => {
			console.log("API error");
		});
};

export const getApprovedCountOfDG = async () => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: "/company/get/approval/count",
		headers: {},
	})
		.then((res) => {
			console.log("getCompanyRegStatusDG :", res);
			let count = 0;
			count = res?.data?.approvalCount;
			return count;
		})
		.catch((error) => {
			console.log("API error");
		});
};

export const getApprovedAndRejectedCount = async () => {
	const userID = getRoleIDFromLocal();
	console.log("getCompanyRegStatus USER:", userID);
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: "ecoo-core/application/get/status/count/" + userID,
		headers: {},
	})
		.then((res) => {
			console.log("getStatus :", res);
			return res;
		})
		.catch((error) => {
			console.log("API error");
		});
};

export const finalApprovalAPI = async (data, userID) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/comment/add/" + userID,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("response", res);
			return res;
		})
		.catch((error) => {
			console.log("API error", error);
		});
};

export const finalApprovalStatusUpdateAPI = async (data, cpID) => {
	console.log("<><><><><>< finalApprovalStatusUpdateAPI request::><><><><>", data);
	return CustomAxios({
		method: "PUT",
		baseURL: API_BASE_URL,
		url: "/company/status/update/" + cpID,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("finalApprovalStatusUpdateAPI response", res);
			return res;
		})
		.catch((error) => {
			console.log("finalApprovalStatusUpdateAPI error", error);
		});
};

////////////////////////////////////////////////////////////////////////////////////////
// TODO: AUTH

// export async function authenticateUser(payload) {
// 	console.log("authenticateUser", payload);
// 	try {
// 		const response = await CustomAxios.post(API_BASE_URL + "/auth/manageUser", payload);
// 		console.log("authenticateUser :", response);
// 		return response;
// 	} catch (error) {
// 		throw error;
// 	}
// }
export async function authenticateUser(payload) {
	console.log("authenticateUser", payload);
	return CustomAxios({
		method: "POST",
		baseURL: AUTH_BASE_URL,
		url: "/login",
		headers: {},
		data: payload,
	})
		.then((res) => {
			console.log("response ---------------->", res);
			if (res !== 403) {
				localStorage.setItem("REFRESHTOKEN", res.data.refresh_token);
				localStorage.setItem("ACCESSTOKEN", res.data.access_token);
			}
			return res;
		})
		.catch((error) => {
			console.log("API error------------>", error);
		});
}

export const postTrial = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/application/add/record",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("response", res);
			return res;
		})
		.catch((error) => {
			console.log("API error", error);
		});
};

export const getTrial = async (companyID) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/application/get/${companyID}`,
		headers: {},
	})
		.then((res) => {
			console.log("getTrial response", res);
			return res;
		})
		.catch((error) => {
			console.log("getTrial API error", error);
		});
};

export const getSummary = async (status, params) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: "/company/status/" + status,
		params: params,
		headers: {},
	})
		.then((res) => {
			console.log("getSummary :", res);
			return res;
		})
		.catch((error) => {
			console.log("API error");
		});
};

export const getAllCount = async (customId) => {
	const userID = customId ? customId : getRoleIDFromLocal();
	console.log("getCompanyRegStatus USER:", userID);
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: "/dashboard/summery/" + userID,
		headers: {},
	})
		.then((res) => {
			console.log("getCompanyRegStatus :", res);
			return res;
		})
		.catch((error) => {
			console.log("API error");
		});
};

export const getAllCountForDC = async (dateRange) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: "/dashboard/summery/DC/" + dateRange,
		headers: {},
	})
		.then((res) => {
			console.log("getCompanyRegStatus :", res);
			return res;
		})
		.catch((error) => {
			console.log("API error");
		});
};

export const submitApprovalAPI = async (data) => {
	console.log("submitApprovalAPI request:", data);
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/application/cp-reg/approval",
		data: data,
	})
		.then((res) => {
			console.log("submitApprovalAPI :", res);
			return res;
		})
		.catch((error) => {
			console.log("submitApprovalAPI error");
		});
};

export const getSummaryByCompanyID = async (CompanyID) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: "/company/get/summary/" + CompanyID,
		headers: {},
	})
		.then((res) => {
			console.log("getSummaryByCompanyID :", res);
			return res;
		})
		.catch((error) => {
			console.log("API error");
		});
};
