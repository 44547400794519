import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { setUserStatus } from "../../../app/api/NOCU/userManagementApi";
import { useAlert } from "../../../app/context/alerts";
import { SectionColumn, SectionRow } from "../../../components/section";
import { getUserId } from "../../../app/api/NOCU/helpers/applicationApiHelpers";

const UserSettingsUpdatePage = ({ userData, setIsLoading }) => {
	const [status, setStatus] = useState("");
	const { setAlert } = useAlert();
	const handleChange = (event) => {
		setStatus(event.target.value);
	};
	const handleUpdate = () => {
		setIsLoading(true);
		setUserStatus(userData?.userId, { userStatus: status, modifiedOfficer: getUserId() })
			.then((res) => {
				console.log(res.data);
				setAlert({
					message: res.data?.responseMessage
						? res.data?.responseMessage
						: res.data?.responseCode === "00"
						? "Updated Successfully"
						: "Updated Error",
					severity: res.data?.responseCode === "00" ? "success" : "error",
					show: true,
				});
			})
			.catch((e) => console.log(e))
			.finally(() => {
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			});
	};
	useEffect(() => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}, [userData]);

	return (
		<SectionColumn className='w-full overflow-auto h-[80vh]'>
			<SectionRow className='border-b pb-2 border-gray-200 w-full'>Edit User Settings </SectionRow>
			<SectionRow className='w-full pt-10  h-[70vh]'>
				<SectionRow className='w-full h-14 items-center'>
					<SectionColumn className='w-3/12 items-end px-5'>User Status</SectionColumn>
					<SectionColumn className='w-2/12 px-5  '>{userData?.userStatus}</SectionColumn>
					<SectionColumn className='w-7/12 px-5'>
						<FormControl sx={{ m: 1, width: 200 }} size='small'>
							<InputLabel id='demo-select-small-label'>Status</InputLabel>
							<Select
								labelId='demo-select-small-label'
								id='demo-select-small'
								value={status}
								label='Status'
								onChange={handleChange}>
								<MenuItem value={"APPROVED"}>APPROVED</MenuItem>
								<MenuItem value={"REJECTED"}>REJECTED</MenuItem>
								<MenuItem value={"VACATION"}>VACATION</MenuItem>
								<MenuItem value={"DISABLED"}>DISABLED</MenuItem>
							</Select>
						</FormControl>
					</SectionColumn>
				</SectionRow>
			</SectionRow>
			<SectionRow className='w-full flex-row-reverse px-10'>
				<Button
					variant='contained'
					color='error'
					className='!mx-3 !capitalize'
					onClick={() => {
						setStatus("");
					}}>
					Reset
				</Button>
				<Button
					onClick={() => {
						handleUpdate();
					}}
					disabled={!status}
					variant='contained'
					className='!mx-3 !capitalize'>
					Update
				</Button>
			</SectionRow>
		</SectionColumn>
	);
};

export default UserSettingsUpdatePage;
