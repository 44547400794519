import React, { useEffect } from "react";
import { useState } from "react";
import {
	getCategoryDetails,
	submitProductCategoryReview,
} from "../../app/api/NOCU/productCategoryAPI";
import { getProductCategoryID, getProductFormData } from "./dataHelper";
import ReviewWrapperNOCU from "../../components/wrappers/reviewWrapper";
import ReviewForm from "../../components/review/reviewForm";
import { SectionColumn, SectionRow } from "../../layouts/sections";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import { getFileURL } from "../../app/api/file_api";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import RejectButton from "../../components/buttons/rejectButton";
import AcceptButton from "../../components/buttons/acceptbutton";
import RejectModel, { CERTIFICATION_BODY_REJECT_REASONS } from "../../components/model/RejectModel";
import ConfirmationPopup from "../../components/model/ConfirmationPopup";
import { getUserId } from "../../app/api/NOCU/helpers/applicationApiHelpers";

const ProductCategoryReview = () => {
	const [response, setResponse] = useState();
	const [formData, setFormData] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isReject, setIsReject] = useState(false);
	const [isApprove, setIsApprove] = useState(false);

	useEffect(() => {
		getCategoryDetails(getProductCategoryID())
			.then((res) => {
				setResponse(res.data);
			})
			.catch((e) => console.log(e))
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		console.log(response);
		setFormData(getProductFormData(response));
	}, [response]);

	const handleSubmit = (comment, status) => {
		setIsLoading(true);
		console.log("Submit", comment, status);
		const payload = {
			status: status,
			productCategoryId: getProductCategoryID(),
			reviewedBy: "",
			officerId: getUserId(),
			remark: comment,
		};
		submitProductCategoryReview(payload)
			.then((res) => {
				console.log(res.data);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setIsLoading(false);
				window.location.reload();
			});
	};

	return (
		<div>
			<RejectModel
				type={"REJECT"}
				title={"Reject selected Product Category Registration"}
				reasonsType={CERTIFICATION_BODY_REJECT_REASONS}
				handleReject={(comment) => {
					handleSubmit(comment, "REJECTED");
				}}
				isOpen={isReject}
				setIsOpen={setIsReject}
			/>
			<ConfirmationPopup
				title={"Approve selected Product Category Registration"}
				content={"Please confirm to submit application as approved category registration"}
				isOpen={isApprove}
				setIsOpen={setIsApprove}
				handleClick={() => {
					handleSubmit("", "APPROVED");
				}}
			/>
			<SectionRow className='w-full pt-10'>
				<SectionColumn className='w-1/2 pl-5 pt-10 items-center'>
					{formData && <ReviewForm data={formData} />}
					<SectionRow className='w-full pt-10 items-center justify-center'>
						<AcceptButton
							className={""}
							handleClick={() => {
								setIsApprove(true);
							}}
							name='nextButton'
							title='Approve & Submit'
							color={""}
							disabled={false}
						/>
						<RejectButton
							className={""}
							handleClick={() => {
								setIsReject(true);
							}}
							name='rejectButton'
							title='Reject'
							disabled={false}
						/>
					</SectionRow>
				</SectionColumn>
				<SectionColumn className='w-1/2 pr-5'>
					<SectionRow className='w-full justify-center '>
						<span>Product Catalogue</span>
					</SectionRow>
					<IFrameViewer
						path={getFileURL(response?.productList[0]?.productCataloguePath) || ""}
						files={true}
					/>
				</SectionColumn>
			</SectionRow>
			<LoaderComponent isOpen={isLoading} />
		</div>
	);
};

export default ProductCategoryReview;
