import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TrailTable from "../table/trialTable";
import { useEffect } from "react";
import {
	getApplicationHistory,
	getMappedApplicationHistory,
} from "../../app/api/summaryAPI/summaryTableApi";
import TabHome from "../Tabs/TabHome";
import FullScreenDialog from "./FullScreenDialog";
import ViewMorePage from "../../features/summery/ViewMorePage";
import {
	setMappedApplicationNumber,
	setStakeholderApplicationNumber,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: "white",
					}}>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const manipulateData = (data) => {
	return data && Array.isArray(data)
		? data.map((application, index) => {
				return {
					label: application?.applicationNumber,
					component: (
						<div>
							<TrailTable trailData={application?.applicationSummeryResponse} />
						</div>
					),
				};
		  })
		: [];
};

export default function HistoryPrompt({ button, applicationId }) {
	const [open, setOpen] = React.useState(false);
	const [trailData, setTrailData] = React.useState();
	const [mappedTrailData, setMappedTrailData] = React.useState();
	const [tabs, setTabs] = React.useState();

	const handleClickOpen = () => {
		if (applicationId.toString().includes("SH-")) {
			getMappedApplicationHistory(applicationId)
				.then((res) => {
					console.log("History Prompt data", res.data);
					setMappedTrailData(manipulateData(res.data?.applicationDtoList));
				})
				.catch((e) => console.log(e));
		} else {
			getApplicationHistory(applicationId)
				.then((res) => {
					console.log("History Prompt data", res.data);
					setTrailData(res.data);
				})
				.catch((e) => console.log(e));
		}
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const componentArray = [
		{
			label: "Factory Visit Details",
			component: <div>{"Test"}</div>,
		},
	];

	return (
		<div>
			<div className='' onClick={handleClickOpen}>
				{button}
			</div>
			{/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
			<BootstrapDialog
				maxWidth='lg'
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}>
				<BootstrapDialogTitle
					className='bg-[#20b15e] text-white'
					id='customized-dialog-title'
					onClose={handleClose}>
					History Trail
				</BootstrapDialogTitle>
				<DialogContent dividers>
					{/* <Typography gutterBottom>
						Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
						in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
					</Typography> */}
					{applicationId.toString().includes("SH-") ? (
						<div className='min-w-[750px]'>
							{manipulateData && <TabHome componentArray={mappedTrailData} />}
						</div>
					) : (
						<TrailTable trailData={trailData} />
					)}
				</DialogContent>
				<DialogActions>
					<FullScreenDialog
						button={
							<Button variant='outlined' color='success'>
								View More
							</Button>
						}
						children={<ViewMorePage applicationId={applicationId} />}
						title={`Application Details`}
						onClose={() => {
							setMappedApplicationNumber();
							setStakeholderApplicationNumber(" ");
						}}
					/>

					<Button color='error' className='!mx-10' onClick={handleClose}>
						Close
					</Button>
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
}
