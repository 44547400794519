export const filterByTypeId = (data, entityType, entityId, isFile) => {
	const _data = data
		? data.filter((value) => {
				return (
					value.entityId === entityId && value.entityType === entityType && value.isFile === isFile
				);
		  })
		: [];

	return _data.length > 0 ? _data[0].comment : "";
};
