import * as React from "react";
import Modal from "@mui/material/Modal";
import { Form, Formik } from "formik";
import { SectionColumn, SectionRow } from "../../layouts/sections";
import RoundedButton from "../buttons/RoundedButtons";
import TextField2 from "../../features/auth/components/TextField2";
import { OtherTitleDropDown_Validation } from "../../Helpers/formikValidation";
import { selectionDropDownPutValuesNewCapital } from "../../Helpers/selectionDropDownHelper";
import { closePopupIcon, forwardIcon } from "../../Helpers/ICONS";
import { ModalOpenCloseContext } from "../../app/context/modalOpenCloseContext";

const OtherTitleDropDownModal = (props) => {

  //  Contexts
  const { otherTitleDropDownPopupModal, setOtherTitleDropDownPopupModal } = React.useContext(ModalOpenCloseContext);

  const handleClose = () => {
    props.setOtherDropDownValue(selectionDropDownPutValuesNewCapital("Other"));
    setOtherTitleDropDownPopupModal(false);
  }

  const handleSubmit = (values) => {
    console.log('values BBBBB:>> ', values);
    props.setOtherDropDownValue(selectionDropDownPutValuesNewCapital(values.titleOfOrganicStandard));
    setOtherTitleDropDownPopupModal(false);
  };

  const handleValidate = (values) => { };

  return (
    <div>
      <Modal
        hideBackdrop
        open={otherTitleDropDownPopupModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBackDrop"
      >
        <div className="addStakeholderWrapper">
          <div className="addOtherDropdownCard flex justify-center">
            <div className="">
              <span className="cursor-pointer absolute -top-2 -right-2 border-2 border-white rounded-full h-7">
                <img
                  src={closePopupIcon}
                  alt={"Close"}
                  width={24}
                  height={24}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </span>
            </div>
            <SectionColumn>
              <span className="pt-7 font-bold text-center">
                Enter Other Title of Organic Standard
              </span>

              <SectionRow className="justify-center gap-3 pb-4">
                <Formik
                  initialValues={{ titleOfOrganicStandard: "" }}
                  onSubmit={handleSubmit}
                  validate={handleValidate}
                  validationSchema={OtherTitleDropDown_Validation}
                  validator={() => ({})}
                >
                  {(formik) => (
                    <Form>
                      <SectionColumn>
                        <TextField2
                          heading=""
                          formType="certificationForm"
                          type="text"
                          name={`titleOfOrganicStandard`}
                          className={"certificationBodyInputsField w-1/2"}
                          placeholder="Enter Title of Organic Standard"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.titleOfOrganicStandard}
                          maxLength={25}
                        />

                        <div className="pt-4 flex justify-end">
                          <RoundedButton
                            ref={undefined}
                            onClick={undefined}
                            className="primaryButton certificationFormButton"
                            type="submit"
                          >
                            <SectionRow>
                              <span className="pr-3">Submit</span>
                              <img
                                src={forwardIcon}
                                alt="Picture of the author"
                              // width={12}
                              // height={12}
                              />
                            </SectionRow>
                          </RoundedButton>
                        </div>
                      </SectionColumn>
                    </Form>
                  )}
                </Formik>
              </SectionRow>
            </SectionColumn>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OtherTitleDropDownModal;
