import React, { useEffect, useState } from "react";
import ReviewWrapperNOCU from "../../../../components/wrappers/reviewWrapper";
import ToggleButtonSet from "../../../../components/buttons/ToggleButtonSet";
import DynamicStepper from "../../../../components/stepper/dynamicStepper";
import { APPROVED, DEFAULT } from "../../../../Helpers/constants";
import { navigateSelf } from "../../../../app/router/routerManager";
import { FARMER_ASSOCIATION_ROUTES, SLAAB_ROUTES } from "../../../../app/router/routes";

import FileButtonSet from "../../../../components/buttons/FileButtonSet";
import NextButton from "../../../../components/buttons/nextButton";
import {
	getApplicationDetails,
	submitApplicationDetails,
	submitApplicationReview,
} from "../../../../app/api/NOCU/applicationAPI";
import { getFileURL } from "../../../../app/api/file_api";
import { SectionRow } from "../../../../layouts/sections";
import { classNames } from "classnames";

import { Alert, Button, Checkbox, TextareaAutosize } from "@mui/material";
import {
	deleteAllApplicationRejectDetails,
	getApplicationRejectDetailsAll,
	setAllRejections,
} from "../../../../Helpers/rejectionHelpers";
import { STATUS } from "../../../../app/constants";
import { getRejctionsByStep } from "../../../../app/api/NOCU/rejectionAPI";
import {
	appendRejectionsMultipleFiles,
	appendRejectionsMultipleForms,
	validateMultiArray,
} from "../dataManipulation";
import { getApplicationStage } from "../../../../components/table/tableHelpers";
import { AlertTitle } from "@mui/material";
import ConfirmationModel from "../../../../components/model/ConfirmationModel";
import { SectionColumn } from "../../../../components/section";
import WarningModel from "../../../../components/alerts/warningModel";

const initialFormData = [
	{
		name: "countryOfAccreditation",
		label: "Country of Accreditation",
		value: "---",
		isChecked: true,
	},
	{
		name: "nameOfAccreditationBody",
		label: "Name of Accreditation Body",
		value: "---",
		isChecked: true,
	},
	{
		name: "addressOfAccreditationBody",
		label: "Address of Accreditation Body",
		value: "---",
		isChecked: true,
	},
	{ name: "contactNumber", label: "Contact Number", value: "---", isChecked: true },
	{ name: "approvedDate", label: "Approved Date", value: "---", isChecked: true },
	{
		name: "titleOfOrganicStandard",
		label: "Title of Organic Standard",
		value: "---",
		isChecked: true,
	},
];

const FarmerAssociationReviewStep4 = ({
	slaabReview,
	stepperData,
	stepperName,
	routeArray,
	setIsLoading,
	nextCustomRoute,
	customIndex,
	readOnly,
	hideBtns,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [certificateRequest, setCertificateRequest] = useState();
	const [detailsArray, setDetailsArray] = useState();
	const [nameArray, setNameArray] = useState();
	const [fileArray, setFileArray] = useState();
	const [formData, setFormData] = useState(initialFormData);
	const [currentFiles, setCurrentFiles] = useState();
	const [entityIdsArray, setEntityIdsArray] = useState();
	const [currentEntityId, setCurrentEntityId] = useState();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [rejections, setRejections] = useState();
	const [isDisabled, setDisabled] = useState(true);
	const [reviewStatus, setReviewStatus] = useState();
	const [slaabRemark, setSlaabRemark] = useState("");

	const [canApprove, setCanApprove] = useState(false);

	useEffect(() => {
		deleteAllApplicationRejectDetails();
		slaabReview
			? getApplicationDetails(4, { isReferSlaab: true })
				.then((res) => {
					console.log("Review Status", res?.stepStatus);
					setReviewStatus(res?.stepStatus);
					setCertificateRequest(res?.certificateRequest);
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					getRejctionsByStep(4)
						.then((res) => {
							setRejections(res.data);
							console.log("Rejection ======>", res);
						})
						.then((e) => {
							console.log(e);
						})
						.finally(() => {
							setIsLoading(false);
						});
				})
			: getApplicationDetails(4)
				.then((res) => {
					console.log(res);
					setReviewStatus(res?.stepStatus);
					setCertificateRequest(res?.certificateRequest);
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					getRejctionsByStep(4)
						.then((res) => {
							setRejections(res.data);
							console.log("Rejection ======>", res);
						})
						.then((e) => {
							console.log(e);
						})
						.finally(() => {
							setIsLoading(false);
						});
				});
	}, []);
	useEffect(() => {
		setAllRejections(rejections?.rejectReasonDetailList || []);
	}, [rejections]);

	useEffect(() => {
		detailsArray &&
			rejections &&
			appendRejectionsMultipleForms(
				getApplicationRejectDetailsAll(),
				detailsArray,
				setDetailsArray,
				currentEntityId,
				currentIndex
			);
		fileArray &&
			rejections &&
			appendRejectionsMultipleFiles(
				getApplicationRejectDetailsAll(),
				fileArray,
				setFileArray,
				currentEntityId,
				currentIndex
			);
		detailsArray && setFormData(detailsArray[currentIndex]);
		fileArray && setCurrentFiles(fileArray[currentIndex]);
	}, [rejections, currentIndex, currentEntityId]);
	const mapCertificate = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((certificate) => {
				return {
					name: certificate?.certificateNumber,
					id: certificate?.id,
					isReferSlaab: certificate?.isReferSlaab,
				};
			})
			: null;
	};

	const mapCertificatesDetails = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((certificate) => {
				return [
					{
						name: "countryOfAccreditation",
						label: "Country of Certification Body",
						value: certificate?.countryOfAccreditation,
						isChecked: true,
					},
					{
						name: "nameOfAccreditationBody",
						label: "Name of Certification Body",
						value: certificate?.nameOfAccreditation,
						isChecked: true,
					},
					{
						name: "addressOfAccreditationBody",
						label: "Address Line 1",
						value: certificate?.addressOfAccreditation,
						isChecked: true,
					},
					{
						name: "addressLine2",
						label: "Address Line 2",
						value: certificate?.addressLine2,
						isChecked: true,
					},
					{
						name: "certificateNumber",
						label: "Certificate Number",
						value: certificate?.certificateNumber,
						isChecked: true,
					},
					{
						name: "contactNumber",
						label: "Contact Number of Certification Body",
						value: certificate?.accreditationBodyContactPerson,
						isChecked: true,
					},
					{
						name: "approvedDate",
						label: "Approved Date",
						value: certificate?.certApprovedDate,
						isChecked: true,
					},
					{
						name: "certExpirationDate",
						label: "Expiration Date",
						value: certificate?.certExpirationDate,
						isChecked: true,
					},
					{
						name: "titleOfOrganicStandard",
						label: "Title of Organic Standard",
						value: certificate?.titleOfOrganicStandard,
						isChecked: true,
					},
					{
						name: "zipCode",
						label: "Zip Code",
						value: certificate?.zipCode,
						isChecked: true,
					},
				];
			})
			: null;
	};

	const mapFilesArray = (data) => {
		return slaabReview
			? Array.isArray(data) && data.length !== 0
				? data.map((selectedFile) => {
					return [
						{
							name: "Certificate",
							path: getFileURL(selectedFile?.certificateFilePath),
							status: reviewStatus === "PENDING" ? DEFAULT : APPROVED,
							comment: "",
						},
					];
				})
				: null
			: Array.isArray(data) && data.length !== 0
				? data.map((selectedFile) => {
					return [
						{
							name: "Certificate",
							path: getFileURL(selectedFile?.certificateFilePath),
							status: reviewStatus === "PENDING" ? DEFAULT : APPROVED,
							comment: "",
						},
					];
				})
				: null;
	};
	const mapEntityIdsArray = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((item) => {
				return item?.id;
			})
			: null;
	};

	// const [_files, _setFiles] = useState([
	// 	{ name: "Certificate ", path: "f8dc76f1-f890-4dd5-b0b0-490df6c8311b", status: DEFAULT },
	// 	{ name: "Audit Report", path: "f8db8078-793c-4e2a-95ff-4298bd4b241c", status: DEFAULT },
	// ]);
	const [selectedFile, setSelectedFile] = useState();
	// const nameArray = [
	// 	{ name: "Certificate 1" },
	// 	{ name: "Certificate 2" },
	// 	{ name: "Certificate 3" },
	// ];

	useEffect(() => {
		entityIdsArray && setCurrentEntityId(entityIdsArray[0]);
	}, [entityIdsArray]);

	// useEffect(() => {
	// 	detailsArray && setFormData(detailsArray[0]);
	// }, [detailsArray]);

	const getFilesFromSelectedCertificate = (index) => {
		const selectedFile = certificateRequest[index];
		console.log("Selected File", selectedFile);
		return [
			{
				name: "Certificate",
				path: getFileURL(selectedFile?.certificateFilePath),
				status: DEFAULT,
			},
			{
				name: "Audit Report",
				path: getFileURL(selectedFile?.auditReportFilePath),
				status: DEFAULT,
			},
		];
	};
	useEffect(() => {
		console.log("Name Array", nameArray);
	}, [nameArray]);

	useEffect(() => {
		// Execute when updating
		setDetailsArray(mapCertificatesDetails(certificateRequest));
		setNameArray(mapCertificate(certificateRequest));
		setFileArray(mapFilesArray(certificateRequest));
		setEntityIdsArray(mapEntityIdsArray(certificateRequest));
	}, [certificateRequest]);
	// useEffect(() => {
	// 	// Execute when updating
	// 	fileArray && setCurrentFiles(fileArray[0]);
	// }, [fileArray]);

	const updateFileArray = (updatedFiles) => {
		let _filesArray = fileArray;
		if (_filesArray) {
			_filesArray[currentIndex] = updatedFiles;
		}
		setFileArray(_filesArray);
		console.log(_filesArray);
	};

	const handlePerson = (index) => {
		let personArray = detailsArray;
		handleResubmitDisable(personArray[index], fileArray[index]);
		setFormData(personArray[index]);
		setCurrentFiles(fileArray[index]);
		setCurrentEntityId(entityIdsArray[index]);
		setCurrentIndex(index);
		console.log("Index", index);
	};
	const handleSLAAB = (value) => {
		console.log("Value===>", value);
		let _nameArray = nameArray;
		let _currentCert = nameArray[currentIndex];
		_currentCert.isReferSlaab = value;
		_nameArray[currentIndex] = _currentCert;
		console.log(_nameArray);
		setNameArray(_nameArray);
	};
	const renderSLAAB = (nA, index) => {
		const componentArray = nA.map((cert, i) => {
			return (
				i === index && (
					<SectionColumn>
						<SectionRow className='w-full items-center px-1 mb-5'>
							<span className='Business-Name mr-10'>Refer to SLAB</span>
							<span className='Business-Name mr-8'>:</span>
							{!!!cert.isReferSlaab ? (
								readOnly ? (
									<Checkbox
										name={cert.name}
										disabled
										className='mx-5'
										onClick={(e) => {
											handleSLAAB(e.target.checked);
										}}
									/>
								) : (
									<Checkbox
										name={cert.name}
										className='mx-5'
										onClick={(e) => {
											handleSLAAB(e.target.checked);
										}}
									/>
								)
							) : readOnly ? (
								<Checkbox
									name={cert.name}
									disabled={true}
									defaultChecked
									className='mx-5'
									onClick={(e) => {
										handleSLAAB(e.target.checked);
									}}
								/>
							) : (
								<Checkbox
									name={cert.name}
									disabled={
										reviewStatus === "PENDING" || reviewStatus === "RESUBMITTED" ? false : true
									}
									defaultChecked
									className='mx-5'
									onClick={(e) => {
										handleSLAAB(e.target.checked);
									}}
								/>
							)}
						</SectionRow>

						{!readOnly && (
							<WarningModel
								message={
									<p className='Business-Name'>
										Certificates will not be sent to SLAB if this application stage is <br />{" "}
										resubmitted.
									</p>
								}
							/>
						)}
					</SectionColumn>
				)
			);
		});
		return componentArray;
	};

	useEffect(() => {
		fileArray && validateMultiArray(fileArray) ? setCanApprove(true) : setCanApprove(false);
		console.log("Files ============>", fileArray);
	}, [fileArray]);
	useEffect(() => {
		console.log("Selected File ============>", selectedFile?.path);
	}, [selectedFile]);

	const handleNext = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(FARMER_ASSOCIATION_ROUTES.STEP05);
	};
	const handleCUstomNext = (route) => {
		navigateSelf(route);
	};
	const closeApplication = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(FARMER_ASSOCIATION_ROUTES.HOME + "/1");
	};
	const getSLAABCertificates = () => {
		let certArray = [];
		nameArray.map((cert) => {
			cert.isReferSlaab === true && certArray.push(cert.id);
		});
		return certArray;
	};

	const handleResubmit = (comments) => {
		setIsLoading(true);
		console.log("Resubmit Comments", comments);
		submitApplicationDetails(
			4,
			STATUS.RESUBMIT,
			comments,
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleSubmit = () => {
		setIsLoading(true);
		submitApplicationDetails(
			4,
			STATUS.APPROVED,
			"",
			getApplicationRejectDetailsAll(),
			false,
			getSLAABCertificates(),
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleReject = (comments) => {
		setIsLoading(true);
		console.log("Reject Comments", comments);
		submitApplicationDetails(
			4,
			STATUS.REJECTED,
			comments,
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				setTimeout(() => {
					console.log("Waiting");
				}, 2000);
				closeApplication();
			})
			.catch((e) => console.log(e));
	};

	//SLAAB API
	const slaabSubmit = (reviewStatus) => {
		setIsLoading(true);

		submitApplicationReview(
			1,
			reviewStatus,
			slaabRemark,
			getApplicationRejectDetailsAll(),
			getApplicationStage()
		)
			.then((res) => {
				deleteAllApplicationRejectDetails();
				navigateSelf(SLAAB_ROUTES.HOME + "/1");
			})
			.catch((e) => console.log(e));
	};

	const checkAllFiles = (_filesArray) => {
		return _filesArray
			.map((files) => {
				return files.some((item) => item.status == "REJECTED");
			})
			.some((item) => item == true);
	};
	const checkAllData = (_dataArray) => {
		return _dataArray
			.map((data) => data.some((field) => field?.isChecked === false))
			.some((item) => item === true);
	};

	useEffect(() => {
		if (detailsArray && currentFiles && fileArray) {
			const filesFalseValue = currentFiles.some((item) => item.status == "REJECTED");
			if (checkAllData(detailsArray) || filesFalseValue || checkAllFiles(fileArray))
				setDisabled(false);
			else setDisabled(true);

			console.log("FalseValueD", checkAllData(detailsArray));
			console.log("FalseValueF", filesFalseValue);
			console.log("FalseValueT", isDisabled);
		}
	}, [detailsArray, currentFiles, fileArray]);

	const handleResubmitDisable = (data, files) => {
		const filesFalseValue = files.some((item) => item.status == "REJECTED");

		if (checkAllData(detailsArray) || filesFalseValue || checkAllFiles(fileArray))
			setDisabled(false);
		else setDisabled(true);
		console.log("detailsArray", detailsArray);
	};

	return (
		<div>
			<ReviewWrapperNOCU
				hideBtns={hideBtns}
				index={customIndex || 3}
				readOnly={readOnly}
				entityId={currentEntityId}
				title={"Certificates"}
				setForm={() => { }}
				stepperName={stepperName}
				files={currentFiles}
				isDisabled={isDisabled}
				additionalButtons={
					!slaabReview && readOnly && rejections && rejections?.applicationStage === "SLAAB" ? (
						<div>
							<Alert severity={rejections?.reviewStatus === APPROVED ? "success" : "error"}>
								<AlertTitle>{`SLAAB Review : ${rejections?.reviewStatus}`}</AlertTitle>
								{rejections?.remark}
							</Alert>
						</div>
					) : slaabReview ? (
						<SectionRow className='w-full justify-center'>
							<TextareaAutosize
								className='border shadow-md rounded w-9/12 p-3 ml-4'
								minRows={2}
								maxLength='255'
								placeholder='Add remarks'
								onChange={(e) => setSlaabRemark(e.target.value)}
							/>
						</SectionRow>
					) : (
						<></>
					)
				}
				formToggleSwitch={
					nameArray && (
						<ToggleButtonSet data={nameArray} handleClick={handlePerson} certificate={true} />
					)
				}
				topBar={
					slaabReview ? (
						<div></div>
					) : (
						stepperData &&
						stepperName && (
							<DynamicStepper
								name={stepperName}
								stepperData={stepperData}
								routeArray={routeArray}
							/>
						)
					)
				}
				formData={formData}
				currentFile={selectedFile}
				fileButtons={
					<div className='w-full'>
						{/* <NextButton name={"SLAAB"} title='Refer to SLAB' color={"secondary"} /> */}
						{nameArray && !slaabReview && renderSLAAB(nameArray, currentIndex)}
						<FileButtonSet
							hideBtns={hideBtns}
							files={currentFiles}
							readOnly={readOnly}
							setFiles={setCurrentFiles}
							selectedFile={selectedFile}
							setSelectedFile={setSelectedFile}
							updateFileArray={updateFileArray}
							entityId={currentEntityId}
							formData={formData}
							setDisabled={handleResubmitDisable}
						/>
					</div>
				}
				handleReject={!slaabReview ? handleReject : null}
				handleResubmit={!slaabReview ? handleResubmit : null}
				handleNext={!slaabReview ? handleSubmit : null}
				slaabSubmit={slaabReview ? slaabSubmit : null}
				slaabSubmitDisable={slaabRemark === "" ? true : false}
				setDisabled={handleResubmitDisable}
				canApprove={fileArray && validateMultiArray(fileArray)}
				reviewed={
					nextCustomRoute
						? () => handleCUstomNext(nextCustomRoute)
						: readOnly && !slaabReview
							? handleNext
							: null
				}
			/>
		</div>
	);
};

export default FarmerAssociationReviewStep4;
