import { TABS } from "../../constants";
import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const getPermissions = async (userId) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/allocation/v1/get-by-user/${userId}`,
		headers: {},
	})
		.then((res) => {
			console.log("getPermissions (res) ====>", res);
			return res;
		})
		.catch((error) => {
			console.log("getPermissions (error) ====>", error);
		});
};

const testData = [
	{
		stakeholderType: "CERTIFICATION_BODY",
		applicationStages: ["CHECK", "SLAAB", "FIELD_VISIT", "RESUBMISSION_CHECK"],
	},
	{
		stakeholderType: "LABORATORIES",
		applicationStages: ["CHECK", "SLAAB", "FIELD_VISIT"],
	},
	{
		stakeholderType: "FARMER",
		applicationStages: ["CHECK", "SLAAB", "FIELD_VISIT"],
	},
	{
		stakeholderType: "FARMER_ASSOCIATION",
		applicationStages: ["CHECK", "SLAAB", "FIELD_VISIT"],
	},
];

//Helpers

export const setTabs = (data) => {
	let tabs = [];
	tabs.push(TABS.OVERVIEW);
	data.map((stakeholder) => {
		tabs.push(stakeholder.stakeholderType);
	});

	let reviewers = [];
	data.forEach((stakeholder) => {
		reviewers = reviewers.concat(stakeholder.applicationStages);
	});

	if (reviewers.includes(TABS.SLAAB)) {
		tabs.push(TABS.SLAAB);
	}
	if (reviewers.includes(TABS.FIELD_VISIT)) {
		tabs.push(TABS.FIELD_VISIT);
	}
	if (reviewers.includes(TABS.CERTIFICATE_UPLOAD)) {
		tabs.push(TABS.CERTIFICATE_UPLOAD);
	}
	if (reviewers.includes(TABS.FINAL_COMMENT)) {
		tabs.push(TABS.FINAL_COMMENT);
	}
	if (reviewers.includes(TABS.TECHNICAL_COMMITTEE)) {
		tabs.push(TABS.TECHNICAL_COMMITTEE);
	}
	if (reviewers.includes(TABS.FINAL_VOTE)) {
		tabs.push(TABS.FINAL_VOTE);
	}
	if (reviewers.includes(TABS.ISSUE)) {
		tabs.push(TABS.ISSUE);
	}
	if (reviewers.includes(TABS.PRODUCT_CATEGORY_REVIEW)) {
		tabs.push(TABS.PRODUCT_CATEGORY_REVIEW);
	}
	if (reviewers.includes(TABS.PAYMENT)) {
		tabs.push(TABS.PAYMENT);
	}
	if (reviewers.includes(TABS.USER_MANAGEMENT)) {
		tabs.push(TABS.USER_MANAGEMENT);
	}
	if (reviewers.includes(TABS.STAKEHOLDERS_MANAGEMENT)) {
		tabs.push(TABS.STAKEHOLDERS_MANAGEMENT);
	}
	if (reviewers.includes(TABS.SUMMARY)) {
		tabs.push(TABS.SUMMARY);
	}
	if (reviewers.includes(TABS.REPORT_GENERATION)) {
		tabs.push(TABS.REPORT_GENERATION);
	}



	tabs.push(TABS.PROFILE);
	tabs.push(TABS.LOG_OUT);
	console.log("ALL TABS", tabs);
	localStorage.setItem("TABS", JSON.stringify(tabs));
	return tabs;
};

export const getTabs = () => {
	return localStorage.getItem("TABS") ? JSON.parse(localStorage.getItem("TABS")) : null;
};

export const checkTabPermission = (permission) => {
	const list = localStorage.getItem("TABS") ? JSON.parse(localStorage.getItem("TABS")) : null;
	return list ? list.includes(permission) : false;
};
