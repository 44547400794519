import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import RoundedButton from "../../components/buttons/RoundedButtons";
import { APPROVED, AVAILABLE, PENDING, REJECTED, VACATION } from "../../Helpers/constants";
import { updateDocUserStatus } from "../../app/api/userProfileAPI/userProfileAPI";
import { useAlert } from "../../app/context/alerts";
import { SectionColumn, SectionRow } from "../../components/section";

const UserApproval = ({ isOpen, handleDismiss, title, user, data, type, ...props }) => {
	const [open, setOpen] = useState(isOpen);
	const { setAlert } = useAlert();
	const [status, setStatus] = useState({
		userStatus: "",
	});

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);
	const handleClose = () => {
		handleDismiss();
		setOpen(false);
	};

	const [approveStatus, setApproveStatus] = useState({
		userStatus: APPROVED,
	});

	const [rejectStatus, setRejectStatus] = useState({
		userStatus: REJECTED,
	});

	const [availableStatus, setAvailableStatus] = useState({
		userStatus: AVAILABLE,
	});

	const [vacationStatus, setVacationStatus] = useState({
		userStatus: VACATION,
	});

	const handleUserApprove = (id) => {
		console.log("ID ===========================>", id);
		updateDocUserStatus(id, approveStatus)
			.then((res) => {
				window.location.reload();
				setAlert({
					message: "User APPROVED successfully!",
					severity: "success",
					show: true,
				});
			})
			.catch((error) => {
				console.log(error);
				setAlert({
					message: "User status not updated successfully!",
					severity: "error",
					show: true,
				});
			});
	};

	const handleUserReject = (id) => {
		updateDocUserStatus(id, rejectStatus)
			.then((res) => {
				window.location.reload();
				setAlert({
					message: "User REJECTED successfully!",
					severity: "success",
					show: true,
				});
			})
			.catch((error) => {
				console.log(error);
				setAlert({
					message: "User status not updated successfully!",
					severity: "error",
					show: true,
				});
			});
	};

	const handleUserVacation = (id) => {
		updateDocUserStatus(id, vacationStatus)
			.then((res) => {
				window.location.reload();
				setAlert({
					message: "User updated successfully!",
					severity: "success",
					show: true,
				});
			})
			.catch((error) => {
				console.log(error);
				setAlert({
					message: "User status not updated successfully!",
					severity: "error",
					show: true,
				});
			});
	};

	const handleUserAvailable = (id) => {
		updateDocUserStatus(id, availableStatus)
			.then((res) => {
				window.location.reload();
				setAlert({
					message: "User updated successfully!",
					severity: "success",
					show: true,
				});
			})
			.catch((error) => {
				console.log(error);
				setAlert({
					message: "User status not updated successfully!",
					severity: "error",
					show: true,
				});
			});
	};

	return (
		<div>
			<Dialog
				className={"prompt"}
				open={open}
				scroll={"paper"}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				sx={{
					backdropFilter: "blur(10px)",
				}}>
				<DialogTitle id='alert-dialog-title'>{title || "..."}</DialogTitle>
				<DialogContent>
					{data ? (
						<div>
							<SectionRow className='w-full'>
								<SectionColumn className='w-1/2'>
									<h1 className='ml-6 font-bold text-slate-600'>
										<span className='ml-0 font-normal text-neutral-800'>Full Name : </span>
										{data?.fullName}
									</h1>
									<h1 className='ml-6 font-bold text-slate-600'>
										<span className='ml-0 font-normal text-neutral-800'>Designation : </span>
										{data?.designation}
									</h1>
									<h1 className='ml-6 font-bold text-slate-600'>
										<span className='ml-0 font-normal text-neutral-800'>Contact No : </span>
										{data?.contactNo}
									</h1>
								</SectionColumn>
								<SectionColumn className='w-1/2'>
									<h1 className='ml-6 font-bold text-slate-600'>
										<span className='ml-0 font-normal text-neutral-800'>Email : </span>
										{data?.email}
									</h1>
									<h1 className='ml-6 font-bold text-slate-600'>
										<span className='ml-0 font-normal text-neutral-800'>Status : </span>
										{data?.userStatus}
									</h1>
								</SectionColumn>
								{(() => {
									switch (true) {
										case data?.userStatus === VACATION:
											return (
												<SectionColumn className='mt-3'>
													<RoundedButton
														className={`availableVacationButton`}
														onClick={() => handleUserAvailable(user)}>
														AVAILABLE
													</RoundedButton>
												</SectionColumn>
											);
										case data?.userStatus === APPROVED || data?.userStatus === AVAILABLE:
											return (
												<SectionColumn className='items-center justify-center mt-3'>
													<RoundedButton
														className={`availableVacationButton`}
														onClick={() => handleUserVacation(user)}>
														VACATION
													</RoundedButton>
												</SectionColumn>
											);
										case data?.userStatus === PENDING:
											return (
												<>
													<SectionColumn className='items-center justify-center mt-3'>
														<RoundedButton
															className={`approveButton`}
															onClick={() => handleUserApprove(user)}>
															APPROVE
														</RoundedButton>
													</SectionColumn>
													<SectionColumn className='items-center justify-center mt-3'>
														<RoundedButton
															className={`rejectButton`}
															onClick={() => handleUserReject(user)}>
															REJECT
														</RoundedButton>
													</SectionColumn>
												</>
											);
										default:
											return <></>;
									}
								})()}
							</SectionRow>
						</div>
					) : (
						<div className='!w-full flex flex-col items-center  justify-center text-center font-bold'>
							<span className='!w-16 overflow-clip justify-center'>
								<img src='https://img.icons8.com/external-outline-lafs/64/000000/external-ic_history-menu-outline-lafs.png' />
							</span>
							No history records found ...!
						</div>
					)}
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} autoFocus variant='contained'>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default UserApproval;
