export const getTableData = (records) => {
    const applicationList = records?.categoryResponses;
    return applicationList
        ? applicationList.map((item) => [
            item?.productCategoryName ? item.productCategoryName : "-",
            item?.fullName ? item.fullName : "-",
            item?.contactNo ? item.contactNo : "-",
            item?.email ? item.email : "-",
            item?.lastModifiedDate ?
                `${item?.lastModifiedDate.split("T")[0] ? item.lastModifiedDate.split("T")[0] : ""} ${item?.lastModifiedDate.split("T")[1].split(".")[0]
                    ? item.lastModifiedDate.split("T")[1].split(".")[0]
                    : ""
                }` : "-"
        ])
        : [];
};


export const setProductCategoryId = (id) => {
    sessionStorage.setItem("PROD_CATE_ID", id);
};
export const getProductCategoryID = () => {
    return sessionStorage.getItem("PROD_CATE_ID");
};
export const deleteProductCategoryID = () => {
    sessionStorage.removeItem("PROD_CATE_ID");
};


export const getProductFormData = (data) => {
    return [
        { label: "Category", value: data?.productCategoryName },
        { label: "Type", value: data?.productList[0]?.type },
        { label: "HS Code", value: data?.productList[0]?.hscode },
        { label: "Product Name", value: data?.productList[0]?.name },
        { label: "Product Detail Remark", value: data?.productList[0]?.productDetailRemark },
        { label: "Country of Origin", value: data?.productList[0]?.countryOfOrigin },
        { label: "Nutritional Composition", value: data?.productList[0]?.nutritionalComposition },
        { label: "Supplier", value: data?.productList[0]?.supplier },
        { label: "Source of Product", value: data?.productList[0]?.sourceOfProduct },
        { label: "Form of Product", value: data?.productList[0]?.formOfProduct },
        { label: "Quantity", value: `${data?.productList[0]?.quantity} ${data?.productList[0]?.unit}` },
    ];
};
