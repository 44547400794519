import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { CB_UC_nameOfAccreditation2, CB_UC_titleOfOrganicStandard, selectionDropDownGetValue } from "../../Helpers/selectionDropDownHelper";
import SelectionDropDown2 from "../auth/components/SelectDropDown2";
import { getFilePathValue } from "../../Helpers/ObjectsCreateHelper";
import { FM_UC_Validation } from "../../Helpers/formikValidation";
import { useAlert } from "../../app/context/alerts";
import { SectionColumn, SectionRow } from "../../layouts/sections";
import RoundedButton from "../../components/buttons/RoundedButtons";
import { currentDate } from "../../Helpers/stringCrop";
import { getAllCountriesApi, uploadCertificatesApi } from "../../app/api/NOCU/applicationAPI";
import SelectOtherDropDown from "../auth/components/SelectOtherDropDown";
import SelectOtherTitleDropDown from "../auth/components/SelectOtherTitleDropDown";
import UploadButton from "../../components/buttons/UploadButton";
import TextField2 from "../auth/components/TextField2";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const UploadCertificationFormCard = (applicationNumber) => {

  // Hooks
  const { setAlert } = useAlert();
  const [loaderOpen, setLoaderOpen] = React.useState(false);
  const [countries, setCountries] = useState([]);
  const [isDeleteFile, setIsDeleteFile] = useState(false);

  const uploadCertificaionValuesInitIM = {
    countryOfAccreditation: "",
    nameOfAccreditation: "",
    addressOfAccreditation: "",
    addressLine2: "",
    certificateNumber: "",
    accreditationBodyContactPerson: "",
    certApprovedDate: "",
    certExpirationDate: "",
    certificateFilePath: "",
    titleOfOrganicStandard: "",
    zipCode: "",
    labType: "",
    auditReportFilePath: "",
    isReferSlaab: false
  };

  useEffect(() => {
    console.log('applicationNumber', applicationNumber)
    getAllCountriesApi().then((res) => {
      let countryOptionsArray = res?.map((value) => {
        let options = { value: value, label: value };
        return options;
      })
      setCountries(countryOptionsArray)
    }
    )
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    console.log("values", values);
    setLoaderOpen(true);

    if (values.certificateFilePath.path !== undefined) {
      values.certificateFilePath = getFilePathValue(values.certificateFilePath);
    }

    if (!values.certificateFilePath) {
      setAlert({
        message: "Please upload the document",
        severity: "error",
        show: true,
      });
      setLoaderOpen(false);
    } else {
      values.nameOfAccreditation = selectionDropDownGetValue(values.nameOfAccreditation);
      values.countryOfAccreditation = selectionDropDownGetValue(values.countryOfAccreditation);
      values.titleOfOrganicStandard = selectionDropDownGetValue(values.titleOfOrganicStandard);
      values.labType = "";
      values.auditReportFilePath = "";
      values.isReferSlaab = false;

      let submitValues = {
        stakeholderApplicationNumber: applicationNumber.applicationNumber,
        certificateDetail: values
      }

      console.log('submitValues', submitValues)

      uploadCertificatesApi(submitValues)
        .then((res) => {
          if (res?.responseCode === "00") {
            resetForm();
            setIsDeleteFile(true);
            setTimeout(() => setLoaderOpen(false), 1000);
            setAlert({
              message: "Successfully submitted",
              severity: "success",
              show: true,
            });
          } else {
            setAlert({
              message: res?.responseMessage || "Error!",
              severity: "error",
              show: true,
            });
            setTimeout(() => setLoaderOpen(false), 1000);
          }
          return res;
        })
        .catch((error) => {
          console.log(error);
          setAlert({
            message: error,
            severity: "error",
            show: true,
          });
          setTimeout(() => setLoaderOpen(false), 1000);
          return error;
        });
    };
  };

  const handleValidate = (values) => { };

  return (
    <div className="w-full !pb-20">
      <SectionColumn className="relative w-full h-full bg-white">
        <div className="grid px-10 py-10">
          <div className="certificationBodyCardFormWrapper">
            <Formik
              initialValues={uploadCertificaionValuesInitIM}
              onSubmit={handleSubmit}
              validationSchema={FM_UC_Validation}
              validate={handleValidate}
              validator={() => ({})}
            >
              {(formik) => (
                <Form>
                  <div>
                    <div className="sm:pt-5 pb-6 font-bold -mr-20">
                      <span>Details of Certificates</span>
                    </div>
                    <SectionColumn>
                      <SectionRow className="w-full flex justify-between pb-3">
                        <SectionColumn className="">
                          <SectionRow>
                            <span className="certificationFormTextColor pb-1 text-xs font-bold">
                              Country of Certification Body
                            </span>
                          </SectionRow>
                          <div className="-mt-6">
                            <SelectionDropDown2
                              placeholder="Select Country"
                              className={"certificationBodyInputsFieldDropDown"}
                              maxLength={200}
                              type="dropDown"
                              name={`countryOfAccreditation`}
                              options={countries}
                              value={formik.values.countryOfAccreditation}
                              onChange={(value) => {
                                formik.setFieldValue(`countryOfAccreditation`, value);
                              }}
                              onBlur={formik.handleBlur}
                              components={{ IndicatorSeparator: () => null }}
                            />
                          </div>
                        </SectionColumn>

                        <SectionColumn className="">
                          <SectionRow>
                            <span className="certificationFormTextColor pb-1 text-xs font-bold">
                              Name of Certification Body
                            </span>
                          </SectionRow>
                          <div className="-mt-6">
                            <SelectOtherDropDown
                              placeholder="Select Name of Certification Body"
                              className={"certificationBodyInputsFieldDropDown"}
                              maxLength={200}
                              type="dropDown"
                              options={CB_UC_nameOfAccreditation2}
                              name={`nameOfAccreditation`}
                              value={formik.values.nameOfAccreditation}
                              setValue={(value) => {
                                if (value && value?.value !== "OTHER") {
                                  formik.values.nameOfAccreditation = value;
                                }
                              }}
                              onChange={(value) => {
                                formik.setFieldValue(`nameOfAccreditation`, value);
                              }}
                              onBlur={formik.handleBlur}
                              components={{ IndicatorSeparator: () => null }}
                            />
                          </div>
                        </SectionColumn>
                      </SectionRow>

                      <SectionRow className="w-full flex justify-between pb-3">
                        <TextField2
                          heading="Address Line 1"
                          formType="certificationForm"
                          type="text"
                          name={`addressOfAccreditation`}
                          className={"certificationBodyInputsField"}
                          placeholder="Enter Address Line 1"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addressOfAccreditation}
                          maxLength={300}
                        />

                        <TextField2
                          heading="Address Line 2"
                          formType="certificationForm"
                          type="text"
                          name={`addressLine2`}
                          className={"certificationBodyInputsField"}
                          placeholder="Enter Address Line 2"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addressLine2}
                          maxLength={300}
                        />
                      </SectionRow>

                      <SectionRow className="w-full flex justify-between pb-3">
                        <TextField2
                          heading="Zip Code"
                          formType="certificationForm"
                          type="text"
                          name={`zipCode`}
                          className={"certificationBodyInputsField"}
                          placeholder="Enter Zip Code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.certificateNumber}
                          maxLength={10}
                        />
                      </SectionRow>

                      <SectionRow className="w-full flex justify-between pb-3">
                        <TextField2
                          heading="Certificate Number"
                          formType="certificationForm"
                          type="text"
                          name={`certificateNumber`}
                          className={"certificationBodyInputsField"}
                          placeholder="Enter Certificate Number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.certificateNumber}
                          maxLength={20}
                        />

                        <TextField2
                          heading="Contact Number of Certification Body"
                          formType="certificationForm"
                          type="text"
                          name={`accreditationBodyContactPerson`}
                          className={"certificationBodyInputsField"}
                          placeholder="Enter Contact Number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.accreditationBodyContactPerson}
                          maxLength={30}
                        />
                      </SectionRow>

                      <SectionRow className="w-full flex justify-between pb-3">
                        <SectionColumn className="">
                          <SectionRow>
                            <span className="certificationFormTextColor pb-1 text-xs font-bold">
                              Title of Organic Standard{" "}
                            </span>
                          </SectionRow>
                          <div className="-mt-6">
                            <SelectOtherTitleDropDown
                              placeholder="Select Title of Organic Standard"
                              className={"certificationBodyInputsFieldDropDown"}
                              maxLength={200}
                              type="dropDown"
                              options={CB_UC_titleOfOrganicStandard}
                              name={`titleOfOrganicStandard`}
                              value={formik.values.titleOfOrganicStandard}
                              setValue={(value) => {
                                if (value && value?.value !== "OTHER") {
                                  formik.values.titleOfOrganicStandard = value;
                                }
                              }}
                              onChange={(value) => {
                                formik.setFieldValue(`titleOfOrganicStandard`, value);
                              }}
                              onBlur={formik.handleBlur}
                              components={{ IndicatorSeparator: () => null }}
                            />
                          </div>
                        </SectionColumn>

                        <div className="pt-0">
                          <SectionRow>
                            <span className="certificationFormTextColor pb-1 text-xs font-bold">
                              Upload Certificate and Scope
                            </span>
                          </SectionRow>
                          <SectionRow>
                            <UploadButton
                              title="Upload Certificate and Scope"
                              name={`certificateFilePath`}
                              btnname="Upload Certificate and Scope"
                              className={"certificationBodyUploadButton certificationBodyInputsFieldWidth uploadButton"}
                              type="button"
                              setfilepath={(filePath) => {
                                formik.values.certificateFilePath = filePath;
                              }}
                              backFilePath={""}
                              setbackFilePath={(backfilePath) => {
                                console.log('backfilePath', backfilePath)
                              }}
                              uploadType="businessDetailsFileUpload"
                              isDeleteFile={isDeleteFile}
                            />
                          </SectionRow>
                        </div>
                      </SectionRow>

                      <SectionRow className="w-full flex justify-between pb-3">
                        <TextField2
                          heading="Approved Date"
                          formType="certificationForm"
                          type="date"
                          name={`certApprovedDate`}
                          className={"certificationBodyInputsFieldDate w-1/2"}
                          placeholder="Select Date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.certApprovedDate}
                          max={currentDate()}
                        />
                        <TextField2
                          heading="Expiration Date"
                          formType="certificationForm"
                          type="date"
                          name={`certExpirationDate`}
                          className={"certificationBodyInputsFieldDate w-1/2"}
                          placeholder="Select Date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.certExpirationDate}
                        />{" "}
                      </SectionRow>
                    </SectionColumn>
                  </div>
                  <div className="absolute sm:right-20 pb-10 pt-5">
                    <SectionRow className="sm:gap-x-8 gap-y-2 sm:gap-y-0">
                      <RoundedButton
                        ref={undefined}
                        onClick={undefined}
                        className="primaryButton certificationFormButton"
                        type="submit"
                      >
                        <SectionRow>
                          <span className="pr-3">Save</span>
                        </SectionRow>
                      </RoundedButton>
                    </SectionRow>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </SectionColumn>
      <LoaderComponent isOpen={loaderOpen} />

    </div>
  );
};

export default UploadCertificationFormCard;
