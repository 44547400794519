import React from "react";
import { className } from "classnames";
import { SectionRow } from "../../layouts/sections";
import ResetButton from "../buttons/resetButton";
import SearchButton from "../buttons/searchButton";
import { FIELD_VISIT_ROUTES } from "../../app/router/routes";

const FilterCard = ({
	className,
	rightTop,
	rightBottom,
	leftTop,
	leftBottom,
	handleSearchParams,
}) => {
	return (
		<div
			className={`!bg-transparent rounded-xl !h-[110px] statCard relative  mx-2 mb-1 mt-3 !w-[700px] ${className}`}>
			<SectionRow className='w-full h-1/3'>
				<div className='w-1/2  px-1 items-center  justify-end flex'>{leftTop}</div>
				<div className='w-1/2   px-1 items-center justify-end  flex'>{rightTop}</div>
			</SectionRow>
			<SectionRow className='w-full h-1/3'>
				<div className='w-1/2 px-1 items-center  justify-end flex '>{leftBottom}</div>
				<div className='w-1/2  px-1 items-center  justify-end flex'>
					<ResetButton
						className={"search-btn !mx-3"}
						path={FIELD_VISIT_ROUTES.HOME + "/1"}
						name='Reset'
						title='Reset'
						color={""}
						disabled={false}
					/>
					<SearchButton
						className={"search-btn !mx-3"}
						handleClick={handleSearchParams}
						name='Search'
						title='Search'
						color={""}
						disabled={false}
					/>
				</div>
			</SectionRow>

		</div>
	);
};

export default FilterCard;
