import React, { useEffect, useState } from "react";
import { SectionRow } from "./../../layouts/sections/index";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import UploadCertificateCard from "../../components/cards/uploadCertificateCard";
import { getApplicationDataById } from "../../app/api/NOCU/applicationAPI";
import { getStakeholderTypeFromApplicationId } from "../../Helpers/stakeholderHelper";

const UploadCertificateDetailsHome = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [displayBusinessDetails, setDisplayBusinessDetails] = useState([]);
  const [stakeholderApplicationNumber, setStakeholderApplicationNumber] = useState("");
  const [responseCode, setResponseCode] = useState("");

  const handleSearchById = (id) => {
    setApplicationId(id);
  };

  useEffect(() => {
    console.log("applicationId ====>", applicationId);
    if (applicationId !== "") {
      getApplicationDataById(applicationId)
        .then((res) => {
          console.log("Res ======> ", res);
          console.log("getStakeholderTypeFromApplicationId ======> ", getStakeholderTypeFromApplicationId(applicationId));
          if (res.responseCode === "00") {
            let businessDetails = [
              {
                id: 1,
                label: "Business Name",
                value: res.businessDetailRequest.companyName || res.businessDetailRequest.importerName
              },
              {
                id: 2,
                label: "Application Id",
                value: res.stakeholderApplicationNumber
              },
              {
                id: 3,
                label: "Stakeholder Type",
                value: getStakeholderTypeFromApplicationId(res.stakeholderApplicationNumber)
              },
            ]
            setStakeholderApplicationNumber(res.stakeholderApplicationNumber);
            setDisplayBusinessDetails(businessDetails);
          } else {
            setDisplayBusinessDetails([]);
          }
          setResponseCode(res.responseCode)
          setIsLoading(false);
        })
        .then((e) => console.log(e));
    }
  }, [applicationId]);

  return (
    <div>
      <LoaderComponent isOpen={isLoading} />
      <SectionRow className='mb-4'>
        <span className='overViewTitle'>Upload Certificate Details</span>
      </SectionRow>
      <UploadCertificateCard
        handleSearchById={handleSearchById}
        displayBusinessDetails={displayBusinessDetails}
        stakeholderApplicationNumber={stakeholderApplicationNumber}
        responseCode={responseCode}
        applicationId={applicationId}
      />
    </div>
  );
};

export default UploadCertificateDetailsHome;
