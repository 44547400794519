import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";
import {
	getStakeholderApplicationNumber,
	getStakeholderType,
	getUserId,
} from "./helpers/applicationApiHelpers";
// Get Application Details
/*
{
	"stakeholderApplicationNumber": "CER83180971",
	"userId": 47,
	"stakeholderType": "CERTIFICATION_BODY",
	"stepId": 1
}
stepId:1 - business details
stepId:2 - contact person details
stepId:3 - company management details
stepId:4 - certificate details
stepId:5 - other documents details

*/

export const getApplicationDetails = async (stepId, additionalProperties) => {
	const _data = {
		stakeholderApplicationNumber: getStakeholderApplicationNumber(),
		userId: getUserId(),
		stakeholderType: getStakeholderType(),
		stepId: stepId,
	};
	const data = additionalProperties ? { ..._data, ...additionalProperties } : _data;
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/stakeholder/v1/application/get`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("getApplicationDetails", res);
			return res.data;
		})
		.catch((error) => {
			console.log("getApplicationDetails");
			throw error.response;
		});
};

export const submitApplicationDetails = async (
	stepId,
	reviewStatus,
	remark,
	applicationRejectDetails,
	isReferFieldVisit,
	certificateIds,
	applicationStage,
	remarkForSkippingFieldVisit,
	reasonId
) => {
	const data = {
		applicationNumber: getStakeholderApplicationNumber(),
		applicationStage: applicationStage,
		checkedBy: getUserId(),
		stakeholderType: getStakeholderType(),
		stepId: stepId,
		remark: remark,
		reviewStatus: reviewStatus,
		applicationRejectDetails: applicationRejectDetails ? applicationRejectDetails : [],
		isReferFieldVisit: isReferFieldVisit ? isReferFieldVisit : false,
		certificateIds: certificateIds ? certificateIds : [],
		remarkForSkippingFieldVisit: remarkForSkippingFieldVisit ? remarkForSkippingFieldVisit : "",
		reasonEntityId: reasonId,
	};
	console.log("Payload =====>", data);

	return await CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/stakeholder/v1/check-application`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("submitApplicationDetails", res);
			return res;
		})
		.catch((error) => {
			console.log("submitApplicationDetails");
			throw error;
		});
};
export const submitApplicationReview = async (
	stepId,
	reviewStatus,
	remark,
	applicationRejectDetails,
	applicationStage
) => {
	const data = {
		applicationNumber: getStakeholderApplicationNumber(),
		applicationStage: applicationStage,
		checkedBy: getUserId(),
		stakeholderType: getStakeholderType(),
		stepId: stepId,
		remark: remark,
		reviewStatus: reviewStatus,
		applicationRejectDetails: applicationRejectDetails ? applicationRejectDetails : [],
	};
	console.log("Payload =====>", data);

	return await CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/workflow/v1/review-application`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("submitApplicationDetails", res);
			return res;
		})
		.catch((error) => {
			console.log("submitApplicationDetails");
			throw error;
		});
};
export const submitApplicationFieldVisitReview = async (
	stepId,
	reviewStatus,
	remark,
	applicationRejectDetails,
	applicationStage,
	fieldVisitFileList
) => {
	const uid = getUserId();
	const data = {
		applicationNumber: getStakeholderApplicationNumber(),
		applicationStage: applicationStage,
		checkedBy: uid,
		stakeholderType: getStakeholderType(),
		stepId: stepId,
		remark: remark,
		reviewStatus: reviewStatus,
		applicationRejectDetails: applicationRejectDetails ? applicationRejectDetails : [],
		fieldVisitFileList: fieldVisitFileList ? fieldVisitFileList : [],
	};
	console.log("Payload data =====>", data);

	return await CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/workflow/v1/review-application`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("submitApplicationDetails", res);
			return res;
		})
		.catch((error) => {
			console.log("submitApplicationDetails");
			throw error;
		});
};

//Submit Final Comment Application
export const submitFinalApplication = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/workflow/v1/review-application`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("submitApplicationDetails", res);
			return res;
		})
		.catch((error) => {
			console.log("submitApplicationDetails error!");
			throw error.response;
		});
};

//Get Stepper status api
export const getStepStatusByApplicationId = async (applicationId) => {
	return CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/review/v1/get-steps-status/${applicationId}`,
		headers: {},
	})
		.then((res) => {
			console.log("getStepStatusByApplicationId (res) ====>", res);
			return res;
		})
		.catch((error) => {
			console.log("getStepStatusByApplicationId (error) ====>", error);
			return error;
		});
};


//Signature File replace Api
export const submitSignedCertificate = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/workflow/v1/upload-officer-singed-doc-with-file-replace`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("submitSignedCertificate", res);
			return res;
		})
		.catch((error) => {
			console.log("submitSignedCertificate error!");
			throw error.response;
		});
};

export const getApplicationDataById = async (applicationId) => {
	const data = {
		applicationNumber: applicationId,
	};
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/stakeholder/v1/get-application-by-applicationNumber`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("getApplicationDataById", res);
			return res.data;
		})
		.catch((error) => {
			console.log("getApplicationDataById");
			throw error.response;
		});
};

/**
 * Gell All Countries
 */
export const getAllCountriesApi = async () => {
	return await CustomAxios({
		method: "GET",
		baseURL: API_BASE_URL,
		url: `/master-data/v1/get-country-list`,
	})
		.then((response) => {
			console.log(response, "*************Gell All Countries Response*******************");
			return response.data;
		})
		.catch((error) => {
			console.log(error);
		});
};

/**
 * Upload Certificates
*/
export const uploadCertificatesApi = async (values) => {
	console.log(values, "**************Upload Certificates Request***************");
	return await CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/stakeholder/v1/submit-Certificate-detail-by-applicationNumber",
		data: values
	})
		.then((response) => {
			console.log(response, "*************Upload Certificates Response*******************");
			return response.data;
		})
		.catch((error) => {
			console.log(error);
		});
};