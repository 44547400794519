import FileUploadButton from "../buttons/FileUploadButton";
import viewButton from "../../assets/icons/upload-doc.svg";
import { useEffect, useState } from "react";
import { APPROVED, SELECT, REJECTED } from "../../Helpers/constants";
import Tick from "@mui/icons-material/CheckCircle";
import Cross from "@mui/icons-material/Cancel";
import { SectionColumn, SectionRow } from "../section";
import { useAlert } from "../../app/context/alerts";
import { Tooltip } from "@mui/material";
import { stringCrop } from "./../../Helpers/stringCrop";
import RejectModel, { CERTIFICATION_BODY_REJECT_REASONS } from "../model/RejectModel";
import { setApplicationRejectDetails } from "../../Helpers/rejectionHelpers";
import { STATUS } from "../../app/constants";
import { getViewOnlyPermission } from "../../app/api/NOCU/helpers/applicationApiHelpers";

const CostFileButton = ({
	file,
	id,
	name,
	handleClick,
	status,
	approveFile,
	rejectFile,
	showBTN,
	subBtnName,
	disableAction,
	_comment,
}) => {
	const [_status, setStatue] = useState(status);
	const { setAlert } = useAlert();
	const [isOpen, setIsOpen] = useState(false);
	const [comment, setComment] = useState();
	const [viewOnly, setViewOnly] = useState();

	useEffect(() => {
		setStatue(status);
	}, [status]);

	useEffect(() => {
		setViewOnly(getViewOnlyPermission())
		console.log("view Only", getViewOnlyPermission())
	}, []);
	const handleFileClick = (_props) => {
		handleClick(file);
	};
	const renderLable = (name, status) => {
		return (
			<>
				<div className=''>
					<p>{name}</p>
					{status === APPROVED ? (
						<Tick style={{ color: "#5fc65f" }} />
					) : status === REJECTED ? (
						<Cross style={{ color: "#e74437" }} />
					) : (
						""
					)}
				</div>
			</>
		);
	};
	const handleApprove = () => {
		// setComment("");
		approveFile();
		setStatue(APPROVED);
		setAlert({
			message: `${name} Approved!`,
			severity: "success",
			show: true,
		});
	};
	const handleReject = (comment) => {
		// setComment(comment);
		rejectFile(comment);
		setStatue(REJECTED);
		setAlert({
			message: `${name} Rejected !`,
			severity: "error",
			show: true,
		});
	};
	const handleOpenRejectPrompt = () => {
		setIsOpen(true);
	};

	return (
		<SectionColumn className='items-center mb-5 relative'>
			<RejectModel
				title='Test Doc'
				reasonsType={CERTIFICATION_BODY_REJECT_REASONS}
				handleReject={handleReject}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
			<FileUploadButton
				image={viewButton}
				btnName={name}
				subBtnName={subBtnName}
				className='mx-6 pb-0'
				onClick={handleFileClick}
				isSelected={"TIN"}
				state={{ isApprove: _status }}
			/>
			{/* {!disableAction && (status === SELECT || showBTN) && ( */}
			{!viewOnly && <SectionRow
				className={!disableAction && (status === SELECT || showBTN) ? "visible" : "invisible"}>
				<Tick style={{ color: "#5fc65f" }} className='mx-2' onClick={handleApprove} />
				<Cross style={{ color: "#e74437" }} className='mx-2' onClick={handleOpenRejectPrompt} />
			</SectionRow>
			}
			{/* )} */}
			{_comment && (
				<Tooltip title={<div className='text-base p-2'>{_comment}</div>}>
					<div className='!absolute !italic  -bottom-4 error-msg'>{stringCrop(_comment, 10)}</div>
				</Tooltip>
			)}
		</SectionColumn>
	);
};

export default CostFileButton;
