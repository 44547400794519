import { Chip } from "@mui/material";

export const getMapTableData = (records) => {
    const mappedApplicationList = records?.mappedApplicationList;
    return mappedApplicationList
        ? mappedApplicationList.map((item) => [
            item?.companyName ? item.companyName : "-",
            // item?.companyRegNumber ? item.companyRegNumber : "-",
            // item?.typeOfBusiness ? item.typeOfBusiness : "-",
            // item?.email ? item.email : "-",
            item?.mappedApplicationNumber ? item.mappedApplicationNumber : "-",
            item?.applicationNumberList ? item.applicationNumberList : "-",
            item?.mappedApplicationType ? item.mappedApplicationType : "-",
            // `${item?.contactPersonName ? item.contactPersonName : ""} ${
            // 	item?.contactPersonMobile ? item.contactPersonMobile : ""
            // }`,
            `${item?.createdDate ? item.createdDate.split("T")[0] : ""} ${item?.createdDate ? item.createdDate.split("T")[1].split(".")[0] : ""
            }`,
            item?.isRenewal === "true" ? (
                <Chip
                    label={`${item?.mappedApplicationType === "SINGLE"
                        ? "S - "
                        : item?.mappedApplicationType === "MULTIPLE"
                            ? "M - "
                            : ""
                        }Renewal`}
                    color='success'
                    size='small'
                    className='!text-xs'
                />
            ) : (
                <Chip
                    label={`${item?.mappedApplicationType === "SINGLE"
                        ? "S- "
                        : item?.mappedApplicationType === "MULTIPLE"
                            ? "M- "
                            : ""
                        }New`}
                    color='primary'
                    size='small'
                    className='!text-xs'
                />
            ),
        ])
        : [];
};


export const getTableData = (records) => {
    const applicationList = records?.applicationList;
    return applicationList
        ? applicationList.map((item) => [
            item?.companyName ? item.companyName : "-",
            item?.applicationNumber ? item.applicationNumber : "-",
            // item?.companyRegNumber ? item.companyRegNumber : "-",
            // item?.typeOfBusiness ? item.typeOfBusiness : "-",
            // item?.email ? item.email : "-",
            // `${item?.contactPersonName ? item.contactPersonName : ""} ${item?.contactPersonMobile ? item.contactPersonMobile : ""
            // }`,
            `${item?.createdDate.split("T")[0] ? item.createdDate.split("T")[0] : ""} ${item?.createdDate.split("T")[1].split(".")[0]
                ? item.createdDate.split("T")[1].split(".")[0]
                : ""
            }`,
            item?.isRenewal === "true" ? (
                <Chip
                    label={`${item?.mappedApplicationType === "SINGLE"
                        ? "S - "
                        : item?.mappedApplicationType === "MULTIPLE"
                            ? "M - "
                            : ""
                        }Renewal`}
                    color='success'
                    size='small'
                    className='!text-xs'
                />
            ) : (
                <Chip
                    label={`${item?.mappedApplicationType === "SINGLE"
                        ? "S- "
                        : item?.mappedApplicationType === "MULTIPLE"
                            ? "M- "
                            : ""
                        }New`}
                    color='primary'
                    size='small'
                    className='!text-xs'
                />
            ),
        ])
        : [];
};