import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

//Get Table records
export const getRecordsForPaymentTable = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/payment/v1/get-details-by-status-and-type`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("getRecordsForPaymentTable", res);
            return res;
        })
        .catch((error) => {
            console.log("getRecordsForPaymentTable error!");
            throw error.response;
        });
};