import { Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { SectionRow } from "../../../components/section";
import { ICON_PATHS } from "../../../app/constants";
import { useEffect } from "react";

const DocInputList = ({ setCanSubmit, setDocsList }) => {
	const [list, setList] = useState([""]);
	const addNewRow = (index) => {
		setList([...list, ""]);
	};
	const removeRow = (index) => {
		let _li = list.filter((element, i) => {
			return i !== index;
		});

		setList(_li);
	};
	const changeValue = (value, index) => {
		const _list = [...list];
		_list[index] = value;
		setList(_list);
	};

	useEffect(() => {
		setCanSubmit(list.includes("") ? false : true);
		setDocsList(
			list.map((item) => {
				return {
					fileName: item,
				};
			})
		);
	}, [list.toString()]);

	const renderList = (list = []) => {
		return list.map((element, index) => {
			return (
				<SectionRow className='w-full justify-center'>
					<TextField
						size='small'
						value={element}
						className='!min-w-[400px]'
						id={"outlined-basic-test"}
						label='Requested document title'
						variant='outlined'
						onChange={(e) => {
							changeValue(e.target.value, index);
						}}
					/>

					{index === list.length - 1 && list[index] !== "" && (
						<img
							width={40}
							className='ml-3 cursor-pointer'
							src={ICON_PATHS.addIcon}
							alt='Add'
							onClick={() => addNewRow(index)}
						/>
					)}
					{(index !== list.length - 1 || list[index] === "") && list.length > 1 && (
						<img
							width={40}
							className='ml-3 cursor-pointer'
							src={ICON_PATHS.modal_close}
							alt='Add'
							onClick={() => removeRow(index)}
						/>
					)}
				</SectionRow>
			);
		});
	};

	return (
		<div>
			<Stack className='p-10 min-w-[500px] ' spacing={2}>
				{renderList(list)}
			</Stack>
		</div>
	);
};

export default DocInputList;
