import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "tailwindcss/tailwind.css";
import "./styles/styles.scss";
import { StoreProvider } from "./app/context/store";
import { AlertProvider } from "./app/context/alerts";
import ModalOpenCloseContextProvider from "./app/context/modalOpenCloseContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<StoreProvider>
			<AlertProvider>
				<ModalOpenCloseContextProvider>
					<App />
				</ModalOpenCloseContextProvider>
			</AlertProvider>
		</StoreProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
