export const getFilePathValue = (value) => {
  return value.path;
};

export const UpperCasetoDashSeparated = (value) => {
  return value.toLowerCase().split("_").join("-")
};

export const hyphenSeparatedtoSpaceSeparated = (value) => {
  if (value !== "" && value !== null && value !== undefined) {
    return value
      .toLowerCase()
      .split("_")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  } else {
    return value
  }
};