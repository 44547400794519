import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const getApplicationSummaryRecords = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/stakeholder/v1/get-by-status-and-type-for-summery`,
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("getApplicationSummaryRecords", res);
			return res;
		})
		.catch((error) => {
			console.log("getApplicationSummaryRecords error!");
			throw error.response;
		});
};
export const getApplicationHistory = async (applicationId) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/summery/v1/get-summery-by-applicationNumber/${applicationId}`,
		headers: {},
	})
		.then((res) => {
			console.log("getApplicationSummaryRecords", res);
			return res;
		})
		.catch((error) => {
			console.log("getApplicationSummaryRecords error!");
			throw error.response;
		});
};
export const getMappedApplicationHistory = async (applicationId) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/summery/v1/get-summery-by-mappedApplicationNumber/${applicationId}`,
		headers: {},
	})
		.then((res) => {
			console.log("getApplicationSummaryRecords", res);
			return res;
		})
		.catch((error) => {
			console.log("getApplicationSummaryRecords error!");
			throw error.response;
		});
};
export const getApplicationHistoryByApplicationStage = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/summery/v1/get-history-by-stage-and-application-number`,
		headers: {},
		data
	})
		.then((res) => {
			console.log("getApplicationHistoryByApplicationStage", res);
			return res;
		})
		.catch((error) => {
			console.log("getApplicationHistoryByApplicationStage error!");
			throw error.response;
		});
};

export const getSummaryCount = async () => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: `/stakeholder/v1/get-counts-by-status-and-type-for-summery`,
		headers: {},

	})
		.then((res) => {
			console.log("getSummaryCount", res);
			return res;
		})
		.catch((error) => {
			console.log("getSummaryCount error!");
			throw error.response;
		});
};
