import { Chip, Grid, Tooltip } from "@mui/material";
import React from "react";
import { Table } from "react-bootstrap";
import { LOADERS } from "../../app/constants";
import { stringCrop } from "./../../Helpers/stringCrop";

const VotingTable = ({
    columns,
    rows,

}) => {

    const renderTableHeading = (headings) => {
        return headings.map((heading, i) => {
            return (
                <th
                    key={i}
                    style={{ verticalAlign: "left" }}
                    className="thBtn !text-left align-bottom"
                >
                    {heading}
                </th>
            );
        });
    };

    const renderRow = (data) => {
        return data.map((row, index) => (
            <tbody key={index} className="tbody">
                <tr align="left">
                    {row.map((item, i) => (
                        <td key={i} className="tdStart !text-left">
                            {/* {item} */}
                            {item.length > 30 ? (
                                <Tooltip title={<div className="text-base">{item}</div>}>
                                    <div>{stringCrop(item, 30)}</div>
                                </Tooltip>
                            ) : (
                                item
                            )}
                        </td>
                    ))}
                </tr>
            </tbody>
        ));
    };

    return (
        <div
            className="companyRegTbleContainer  customScrollBar mb-3  overflow-x-auto shadow-xl "
            style={{
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
            }}
        >
            <Grid
                container
                style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                }}
                className=" overflow-hidden"
            >


                <Grid item xs={12} className="tableHeaderSearch">
                    <Table responsive>
                        <thead>
                            <tr>
                                {renderTableHeading(columns)}
                            </tr>
                        </thead>

                        {!rows ? (
                            <tbody className="tbody">
                                <tr align="center">
                                    <td colSpan="12">
                                        <br />
                                        <img src={LOADERS.tableLoader} alt="loading..." />
                                    </td>
                                </tr>
                            </tbody>
                        ) : rows.length === 0 ? (
                            <tbody className="tbody">
                                <tr align="center">
                                    <td colSpan="12">
                                        <br />
                                        <div style={{ fontSize: "10px", fontWeight: "bold" }}>
                                            No Details Found
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            renderRow(rows)
                        )}
                    </Table>
                </Grid>
            </Grid>
        </div>
    );
};

export default VotingTable;