import React from "react";
import { SectionColumn } from "../section";
import { Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { navigateNewTab } from "../../app/router/routerManager";

export default function ImageViewer({ data }) {
    return data?.length !== 0 ? (
        data?.map((item) => (
            <SectionColumn className='relative   hover:z-20  w-[150px] mx-4 my-4  hover:bg-gray-200 bg-gray-100 h-[200px] rounded-3xl shadow-lg text-center items-center justify-center overflow-clip'>
                <div className='absolute bg-white top-2 right-2'>
                    <Tooltip title={"View New Tab"}>
                        <OpenInNewIcon
                            color='success'
                            onClick={() => {
                                navigateNewTab(item?.path);
                            }}
                        />
                    </Tooltip>
                </div>
                <SectionColumn
                    //onClick={handleOpenImage(item?.path)}
                    className='w-full h-[160px] items-center justify-center pt-5'>
                    <div className=''>
                        <iframe
                            title='Test'
                            allowFullScreen='allowFullScreen'
                            frameborder={0}
                            src={item?.path}
                            width='110px'
                            height='135px'
                        />
                    </div>
                </SectionColumn>
                <SectionColumn className='w-full h-[40px] bg-white text-center text-xs justify-center'>
                    <div>{item.name}</div>
                </SectionColumn>
            </SectionColumn>
        ))
    ) : (
        <div className='h-[450px]'>
            <div className="my-auto">
                No images
            </div>
        </div>
    );
};