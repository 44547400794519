import React, { useEffect, useState } from "react";
import { Chip, Grid } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { SectionColumn, SectionRow } from "../../components/section";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import {
	applicationCountReportExcel,
	applicationCountReportPDF,
	getCompanyDetail,
	getEndUserDetailReportExcel,
	getEndUserDetailReportPDF,
	getEndUserDetailReportTable,
	getEndUserFullDetailReportPDF,
} from "../../app/api/reportApi/reportAPI";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import { renderYears } from "../../Helpers/datehelper";
import { saveAs } from "file-saver";
import { getApplcationTableData, getEndUserTableData } from "./dataManipulation";
import EndUserReport from "./repots/endUserRepor";
import ApplicationCountReport from "./repots/countReport";
import EndUserAllDetails from "./repots/EndUserAllDetails";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const ReportGeneration = () => {
	const [searchBy, setSearchBy] = useState("registrationNo");
	const [byteRecord, setByteRecord] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [res, setRes] = useState();
	const [records, setRecords] = useState();
	const [activeTab, setActiveTab] = useState(0);
	const [filterType, setFilterType] = useState("Daily");
	const [listOfYears, setlistYears] = useState([]);
	const [month, setMonth] = useState();
	const [year, setYear] = useState("");
	const [pdfByteArray, setPDFByteArray] = useState();
	const [excelByteArray, setExcelByteArray] = useState();
	const [reportCount, setReportCount] = useState(0);
	const [reportDetails, setReportDetails] = useState([]);
	const [expandedAccordion, setExpandedAccordion] = useState(null);

	useEffect(() => {
		setlistYears(renderYears());
	}, []);

	const [params, setParams] = useState({
		fromDate: "",
		toDate: "",
		stakeholderType: "ALL",
		type: "endUser",
		page: 0,
		size: 5,
		nocuCertNo: "",
		applicationNumber: "",
	});

	const setStakeHolderParam = (type) => {
		setParams({ ...params, stakeholderType: type });
	};

	const handlePage = (page) => {
		setParams({ ...params, page: page });
		FilterByPagination(page, params.size);
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size });
		FilterByPagination(params.page, size);
	};

	const handleAccordionClick = (value) => {
		setParams({
			...params,
			type: value,
			fromDate: "",
			toDate: "",
			stakeholderType: "ALL",
			nocuCertNo: "",
			applicationNumber: "",
		});
		setReportDetails([]);
		setReportCount(0);
		if (expandedAccordion === value) {
			setExpandedAccordion(null);
		} else {
			setExpandedAccordion(value);
		}
		console.log("------------------->>>", value);
	};

	const setMonthYearParam = (value) => {
		const selectedMonth = value;
		const [fromDate, toDate] = selectedMonth.split("|");

		// Check if it's a leap year
		const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

		// Set the date based on whether it's a leap year and the selected month is February
		let fromDateValue = fromDate;
		let toDateValue = toDate;
		if (selectedMonth.includes("02") && isLeapYear) {
			fromDateValue = year + "-02-01";
			toDateValue = year + "-02-29";
		} else {
			fromDateValue = year + "-" + fromDate;
			toDateValue = year + "-" + toDate;
		}

		setMonth(value.split("|"));
		setParams({ ...params, fromDate: fromDateValue, toDate: toDateValue });
	};

	const setYearandMonthParam = (value) => {
		const selectedYear = value;
		setYear(selectedYear);

		// Check if it's a leap year
		const isLeapYear =
			(selectedYear % 4 === 0 && selectedYear % 100 !== 0) || selectedYear % 400 === 0;

		// Set the date based on whether it's a leap year and the selected month is February
		let fromDateValue = month[0];
		let toDateValue = month[1];
		if (month[0].includes("02") && isLeapYear) {
			fromDateValue = selectedYear + "-02-01";
			toDateValue = selectedYear + "-02-29";
		} else {
			fromDateValue = selectedYear + "-" + month[0];
			toDateValue = selectedYear + "-" + month[1];
		}
		setParams({ ...params, fromDate: fromDateValue, toDate: toDateValue });
	};

	const setYearOnlyParam = (value) => {
		setYear(value);
		setParams({ ...params, fromDate: value + "-01" + "-31", toDate: value + "-12" + "-31" });
	};

	const handleTabChange = (tabIndex) => {
		const currentYear = new Date().getFullYear();

		setActiveTab(tabIndex);
		if (tabIndex === 0) {
			setFilterType("Daily");
			setParams({ ...params, fromDate: "", toDate: "" });
			// setParams({ ...params, fromDate: dateFormatter(new Date()), toDate: dateFormatter(new Date()) })
		} else if (tabIndex === 1) {
			setFilterType("Monthly");
			setParams({
				...params,
				fromDate: currentYear + "-01" + "-01",
				toDate: currentYear + "-01" + "-31",
			});
			setMonth("");
			setYear(currentYear);
		} else if (tabIndex === 2) {
			setFilterType("Yearly");
			setParams({
				...params,
				fromDate: currentYear + "-01" + "-01",
				toDate: currentYear + "-12" + "-31",
			});
			setYear(currentYear);
		} else {
			setFilterType("Range");
			setParams({ ...params, fromDate: "", toDate: "" });
		}
	};

	const SearchByFilters = async () => {
		setIsLoading(true);
		setParams({ ...params, page: 0 });

		try {
			const tableData = await getEndUserDetailReportTable(
				params.fromDate,
				params.toDate,
				params.stakeholderType,
				0,
				params.size,
				params.type
			);

			switch (params.type) {
				case "endUser":
					setReportDetails(getEndUserTableData(tableData?.data));
					setReportCount(tableData?.data.endUserDetailTotalCount);
					break;
				case "applicationCount":
					setReportDetails(getApplcationTableData(tableData?.data));
					// setReportDetails(tableData?.data.endUserDetailsForReportDtos);
					setReportCount(tableData?.data.applicationCountDetailTotalCount);
					break;
				default:
					break;
			}
			setIsLoading(false);
		} catch (error) {
			console.log(error.message);
			setIsLoading(false);
		}
	};

	const FilterByPagination = async (page, size) => {
		setIsLoading(true);

		try {
			const tableData = await getEndUserDetailReportTable(
				params.fromDate,
				params.toDate,
				params.stakeholderType,
				page,
				size,
				params.type
			);
			switch (params.type) {
				case "endUser":
					setReportDetails(getEndUserTableData(tableData?.data));
					setReportCount(tableData?.data.endUserDetailTotalCount);
					break;
				case "applicationCount":
					setReportDetails(getApplcationTableData(tableData?.data));
					// setReportDetails(tableData?.data.endUserDetailsForReportDtos);
					setReportCount(tableData?.data.applicationCountDetailTotalCount);
					break;
				default:
					break;
			}

			setIsLoading(false);
		} catch (error) {
			console.log(error.message);
			setIsLoading(false);
		}
	};

	const downloadPDF = async () => {
		let pdfReport;
		setIsLoading(true);

		// Check if params.fromDate is empty, use current date if true
		const fromDate = params.fromDate || new Date().toISOString().slice(0, 10);

		// Check if params.toDate is empty, use current date if true
		const toDate = params.toDate || new Date().toISOString().slice(0, 10);

		if (params.type === "endUser") {
			pdfReport = await getEndUserDetailReportPDF(
				params.fromDate,
				params.toDate,
				params.stakeholderType
			);
			setPDFByteArray(pdfReport?.data.bytesReport);
			setIsLoading(false);
		} else if (params.type === "applicationCount") {
			pdfReport = await applicationCountReportPDF(
				params.fromDate,
				params.toDate
				//params.stakeholderType
			);
			setPDFByteArray(pdfReport?.data.bytesReport);
			setIsLoading(false);
		} else {
			pdfReport = await getEndUserFullDetailReportPDF(
				params.nocuCertNo,
				params.applicationNumber
				//params.stakeholderType
			);
			setPDFByteArray(pdfReport?.data.bytesReport);
			setIsLoading(false);
		}

		const byteArray = new Uint8Array(
			atob(pdfReport?.data.bytesReport)
				.split("")
				.map((char) => char.charCodeAt(0))
		);
		const blob = new Blob([byteArray], { type: "application/pdf" });
		saveAs(blob, fromDate + " to " + toDate + ".pdf");
	};

	const downloadExcel = async () => {
		let excelReport;
		setIsLoading(true);

		if (params.type === "endUser") {
			excelReport = await getEndUserDetailReportExcel(
				params.fromDate,
				params.toDate,
				params.stakeholderType
			);
			setExcelByteArray(excelReport?.data);
			setIsLoading(false);
		} else {
			excelReport = await applicationCountReportExcel(
				params.fromDate,
				params.toDate
				//params.stakeholderType
			);
			setExcelByteArray(excelReport?.data);
			setIsLoading(false);
		}

		saveAs(excelReport?.data, params.fromDate + " to " + params.toDate + ".xlsx");
	};

	const renderCustomSearch = () => {
		return (
			<div className='flex items-center'>
				{/* <label className='mr-2'> Search By :</label> */}
				<select
					className='perPage !w-4 hover:!w-40 mr-2'
					name='search'
					id='search'
					onChange={(e) => {
						setSearchBy(e.target.value);
						setParams({ ...params, applicationNumber: "", nocuCertNo: "" });
					}}>
					<option value={"applicationId"}>Application No</option>;
					<option value={"registrationNo"}>Registration No</option>;
				</select>
				{searchBy === "applicationId" && (
					<input
						placeholder='Application No'
						className='searchIconText   w-5/12 '
						value={params.applicationNumber}
						onChange={(event) => {
							setParams({ ...params, applicationNumber: event.target.value, registrationId: "" });
						}}
					/>
				)}
				{searchBy === "registrationNo" && (
					<input
						placeholder='Registration No'
						value={params.nocuCertNo}
						className='searchIconText w-5/12 '
						onChange={(event) => {
							setParams({ ...params, nocuCertNo: event.target.value, applicationNumber: "" });
						}}
					/>
				)}
			</div>
		);
	};

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />

			<SectionRow className='mb-5'>
				<span className='heading-1'>Report</span>
			</SectionRow>
			<div className='paymentTbleContainer'>
				<Accordion expanded={expandedAccordion === "endUser"}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel2a-content'
						id='panel2a-header'
						onClick={() => {
							handleAccordionClick("endUser");
						}}>
						<Typography>End User Detail Report</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormGroup>
							<EndUserReport
								setParams={setParams}
								params={params}
								setYearOnlyParam={setYearOnlyParam}
								setYearandMonthParam={setYearandMonthParam}
								setMonthYearParam={setMonthYearParam}
								listOfYears={listOfYears}
								filterType={filterType}
								reportDetails={reportDetails}
								reportCount={reportCount}
								downloadPDF={downloadPDF}
								downloadExcel={downloadExcel}
								setStakeHolderParam={setStakeHolderParam}
								handleTabChange={handleTabChange}
								activeTab={activeTab}
								SearchByFilters={SearchByFilters}
								handlePage={handlePage}
								handleSize={handleSize}
								stakeholder
							/>
						</FormGroup>
					</AccordionDetails>
				</Accordion>

				<Accordion expanded={expandedAccordion === "applicationCount"}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel2a-content'
						id='panel2a-header'
						onClick={() => {
							handleAccordionClick("applicationCount");
						}}>
						<Typography>Application Count Report</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormGroup>
							<ApplicationCountReport
								setParams={setParams}
								params={params}
								setYearOnlyParam={setYearOnlyParam}
								setYearandMonthParam={setYearandMonthParam}
								setMonthYearParam={setMonthYearParam}
								listOfYears={listOfYears}
								filterType={filterType}
								reportDetails={reportDetails}
								reportCount={reportCount}
								downloadPDF={downloadPDF}
								downloadExcel={downloadExcel}
								setStakeHolderParam={setStakeHolderParam}
								handleTabChange={handleTabChange}
								activeTab={activeTab}
								SearchByFilters={SearchByFilters}
								handlePage={handlePage}
								handleSize={handleSize}
							/>
						</FormGroup>
					</AccordionDetails>
				</Accordion>
				<Accordion expanded={expandedAccordion === "endalldetails"}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel2a-content'
						id='panel2a-header'
						onClick={() => {
							handleAccordionClick("endalldetails");
						}}>
						<Typography>End User Full Detail Report</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormGroup>
							<EndUserAllDetails
								setParams={setParams}
								params={params}
								setYearOnlyParam={setYearOnlyParam}
								setYearandMonthParam={setYearandMonthParam}
								setMonthYearParam={setMonthYearParam}
								listOfYears={listOfYears}
								filterType={filterType}
								reportDetails={reportDetails}
								reportCount={reportCount}
								downloadPDF={downloadPDF}
								downloadExcel={downloadExcel}
								setStakeHolderParam={setStakeHolderParam}
								handleTabChange={handleTabChange}
								activeTab={activeTab}
								SearchByFilters={SearchByFilters}
								handlePage={handlePage}
								handleSize={handleSize}
								customSearchDropdown={renderCustomSearch()}
							/>
						</FormGroup>
					</AccordionDetails>
				</Accordion>
				{byteRecord && (
					<SectionColumn className='mt-6 '>
						<IFrameViewer path={`data:application/pdf;base64,${byteRecord}`} files={true} />
					</SectionColumn>
				)}
			</div>
		</div>
	);
};

export default ReportGeneration;
