export const getTableRecord = (data) => {
    return data && Array.isArray(data) && data.map((row) => {
        return [
            row?.username || "-",
            row?.companyName || "-",
            row?.mobileNumber || "-",
            row?.userStatus || "-",
        ];
    });
};

export const setStakeholder = (stakeholder) => {
    sessionStorage.setItem("STAKEHOLDER", JSON.stringify(stakeholder));
};

export const getStakeholder = () => {
    return sessionStorage.getItem("STAKEHOLDER");
};

export const deleteStakeholder = () => {
    return sessionStorage.removeItem("STAKEHOLDER");
};

export const getStakeholderFormData = (data) => {
    const parsedData = JSON.parse(data);
    return [
        { label: "Username", value: parsedData?.username || "-" },
        { label: "Company Name", value: parsedData?.companyName || "-" },
        { label: "Mobile No", value: parsedData?.mobileNumber || "-" },
        { label: "Status", value: parsedData?.userStatus || "-" },
    ];
};
