export const setSelectedStatCardOnSession = (num) => {
	sessionStorage.setItem("SELECTED_STAT_CARD", num);
};
export const getSelectedStatCardFromSession = () => {
	return sessionStorage.getItem("SELECTED_STAT_CARD") || 1;
};


export const setIsRenewal = (isRenewal) => {
	sessionStorage.setItem("IS_RENEWAL", isRenewal);
};

export const deleteIsRenewal = () => {
	sessionStorage.removeItem("IS_RENEWAL");
};
export const getIsRenewal = () => {
	const _state = sessionStorage.getItem("IS_RENEWAL");
	return _state ? _state === "true" ? true : false : false;
}

