import { APPROVED, DEFAULT, REJECTED, SELECT, userRoles } from "./constants";
import { removeGlobalKey } from "./storeHelper";

export const updateRole = (roleId, userId, designation, fullName, email, store, setStore) => {
	setStore({
		role: designation,
		roleId: userId,
		userId: userId,
		userName: fullName,
		userRoleID: roleId,
		email: email,
	});
	return true;
};

export const getUserEmail = () => {
	try {
		let store = window.localStorage.getItem("STORE");
		return JSON.parse(store).email;
	} catch (error) {
		console.log(error);
	}
};

export const getUserIDFromLocal = () => {
	try {
		let store = window.localStorage.getItem("STORE");
		return JSON.parse(store).userId;
	} catch (error) {
		console.log(error);
	}
};
export const getUserRoleIDFromLocal = () => {
	try {
		let store = window.localStorage.getItem("STORE");
		return JSON.parse(store).userRoleID;
	} catch (error) {
		console.log(error);
	}
};
export const getRoleIDFromLocal = () => {
	try {
		let store = window.localStorage.getItem("STORE");
		return JSON.parse(store).roleId;
	} catch (error) {
		console.log(error);
	}
};

export const getUserNameFromLocal = () => {
	try {
		let store = window.localStorage.getItem("STORE");
		return JSON.parse(store).userName;
	} catch (error) {
		console.log(error);
	}
};

export const getUserRoleFromLocal = () => {
	try {
		let store = window.localStorage.getItem("STORE");
		return store ? JSON.parse(store).role : "";
	} catch (error) {
		console.log(error);
	}
};

export const logOutClearLocal = () => {
	try {
		window.localStorage.clear();
	} catch (error) {
		console.log(error);
	}
};

export const getCompanyIdFromLocal = () => {
	try {
		return localStorage.getItem("ID");
	} catch (error) {
		console.log(error);
	}
};

export const setApprovalsOnLocal = (_key, _value) => {
	if (_value === APPROVED) {
		let coms = getCommenstOnLocal();
		coms = removeAllCommentsByStep(coms, _key);
		window.localStorage.setItem("COMMENTS", JSON.stringify(coms));
	}
	// let state = [
	//   { key: "step01", value: DEFAULT },
	//   { key: "step02", value: DEFAULT },
	//   { key: "step03", value: DEFAULT },
	//   { key: "step04", value: DEFAULT },
	//   { key: "step05", value: DEFAULT },
	// ];
	let current = getApprovalsOnLocal();
	let update = "";
	if (!!current && current.length > 0) {
		if (current.find((crr) => crr.key === _key)) {
			update = current.map((crr) => (crr.key === _key ? { ...crr, value: _value } : crr));
		} else {
			update = [...current, { key: _key, value: _value }];
		}
	} else {
		update = [{ key: _key, value: _value }];
	}
	try {
		window.localStorage.setItem("APPROVALS", JSON.stringify(update));
	} catch (error) {
		console.log(error);
	}
};
export const getApprovalsOnLocal = () => {
	try {
		let app = window.localStorage.getItem("APPROVALS");
		return JSON.parse(app);
	} catch (error) {
		console.log(error);
	}
};

export const getCommenstOnLocal = () => {
	try {
		let coms = window.localStorage.getItem("COMMENTS");
		return JSON.parse(coms);
	} catch (error) {
		console.log(error);
	}
};

const removeAllCommentsByStep = (comments, step) => {
	if (step && comments && Array.isArray(comments))
		return comments.filter((obj) => obj.step !== step);
	return comments;
};

export const setCommenstOnLocal = (comments) => {
	let step = !!comments && Array.isArray(comments) ? comments[0].step : null;
	try {
		let coms = getCommenstOnLocal();
		coms = removeAllCommentsByStep(coms, step);
		let array = !!coms && coms.length ? [...coms, ...comments] : comments;
		window.localStorage.setItem("COMMENTS", JSON.stringify(array));
	} catch (error) {
		console.log(error);
	}
};

export const clearApproval = () => {
	removeGlobalKey("APPROVALS");
	removeGlobalKey("COMMENTS");
	removeGlobalKey("ID");
	window?.sessionStorage?.setItem("completedSteps", 0);
};

export const startApprovalAction = (id) => {
	// sessionStorage.clear();
	clearApproval();
	localStorage.setItem("ID", id);
	localStorage.setItem("REJECT", []);
};

export const clearProductId = () => {
	removeGlobalKey("PRODUCT_ID");
};

export const setProductId = (id) => {
	clearProductId();
	localStorage.setItem("PRODUCT_ID", id);
};
export const setIsResubmission = (bool) => {
	sessionStorage.setItem("isResubmission", bool);
};
export const getIsResubmission = () => {
	const bool = sessionStorage.getItem("isResubmission");
	return bool ? JSON.parse(bool) : false;
};
export const removeIsResubmission = () => {
	sessionStorage.removeItem("isResubmission");
};

export const clearAffidavitId = () => {
	removeGlobalKey("AFFIDAVIT_ID");
};

export const setAffidavitId = (id) => {
	clearAffidavitId();
	localStorage.setItem("AFFIDAVIT_ID", id);
};

export const clearCooCertNumber = () => {
	removeGlobalKey("COO_CERT_NUMBER");
};

export const setCooCertNumber = (id) => {
	clearCooCertNumber();
	localStorage.setItem("COO_CERT_NUMBER", id);
};
export const getCooCertNumber = () => {
	return localStorage.getItem("COO_CERT_NUMBER");
};

export const getAffidavitIdFromLocal = () => {
	return localStorage.getItem("AFFIDAVIT_ID");
};

export const getProductId = () => {
	return localStorage.getItem("PRODUCT_ID");
};

export const setCompanyDCOM = (id) => {
	clearCompanyDCOM();
	localStorage.setItem("COMPANY_DCOM", id);
};

export const clearCompanyDCOM = () => {
	removeGlobalKey("COMPANY_DCOM");
};

export const setCostStatementID = (id) => {
	clearCostStatementID();
	localStorage.setItem("COST_STATEMENT_ID", id);
};

export const clearCostStatementID = () => {
	removeGlobalKey("COST_STATEMENT_ID");
};

export const setCompanyID = (id) => {
	clearCompanyID();
	localStorage.setItem("COMPANY_ID", id);
};

export const clearCompanyID = () => {
	removeGlobalKey("COMPANY_ID");
};

export const setPaymentID = (id) => {
	clearPaymentID();
	localStorage.setItem("PAYMENT_ID", id);
};

export const clearPaymentID = () => {
	removeGlobalKey("PAYMENT_ID");
};

export const getPaymentIdFromLocal = () => {
	return localStorage.getItem("PAYMENT_ID");
};

export const getCostStatementID = () => {
	return localStorage.getItem("COST_STATEMENT_ID");
};
export const getCompanyDCom = () => {
	return localStorage.getItem("COMPANY_DCOM");
};

export const getCompanyID = () => {
	const cpId = localStorage.getItem("COMPANY_ID")
		? JSON.parse(localStorage.getItem("COMPANY_ID"))
		: "";
	return cpId;
};

export const clearAllLocalStorage = () => {
	try {
		window.localStorage.clear();
	} catch (error) {
		alert(error);
	}
};

export const getAllCommentsByStep = (step) => {
	const comments = getCommenstOnLocal();
	if (step && comments && Array.isArray(comments))
		return comments.filter((obj) => obj.step === step);
	return [];
};
