import React, { useEffect, useState } from "react";
import NavBar from "./../../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "./../../../Helpers/ICONS";
import { CERTIFICATION_BODY_ROUTES } from "../../../app/router/routes";
import { SectionColumn } from "../../../layouts/sections";
import { Route } from "react-router-dom";
import CertificationBodyReviewStep1 from "./flow/certificationBodyReviewStep1";
import CertificationBodyReviewStep2 from "./flow/certificationBodyReviewStep2";
import CertificationBodyReviewStep3 from "./flow/certificationBodyReviewStep3";
import CertificationBodyReviewStep4 from "./flow/certificationBodyReviewStep4";
import CertificationBodyReviewStep5 from "./flow/certificationBodyReviewStep5";
import { createStepper } from "../../../components/stepper/dynamicStepper";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import { getStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { convertStepperRes, getApplicationStatusOnSession } from "../dataManipulation";
import LoaderComponent from "../../../components/Loader/LoaderComponent";

export const stepperName = "CERTIFICATION_BODY";

const CertificationReviewHome = () => {
	const [res, setRes] = useState();
	const [stepper, setStepper] = useState();
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getStepStatusByApplicationId(getStakeholderApplicationNumber())
			.then((res) => {
				setRes(res.data);
				console.log("Data", res.data);
				console.log("Stepper=====>", convertStepperRes(res.data));
				setStepper(convertStepperRes(res.data));
			})
			.catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		stepper && setStepperData(createStepper(stepperName, stepper));
	}, [stepper]);

	const routeArray = [
		CERTIFICATION_BODY_ROUTES.STEP01,
		CERTIFICATION_BODY_ROUTES.STEP02,
		CERTIFICATION_BODY_ROUTES.STEP03,
		CERTIFICATION_BODY_ROUTES.STEP04,
		CERTIFICATION_BODY_ROUTES.STEP05,
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Certification Body"}
				tableIcon={DASHBOARD_ICONS.certificationBody}
				tableURL={CERTIFICATION_BODY_ROUTES.HOME}
			/>

			{res && stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={CERTIFICATION_BODY_ROUTES.STEP01}>
						<CertificationBodyReviewStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[0]?.reviewStatus === "APPROVED" || res[0]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={CERTIFICATION_BODY_ROUTES.STEP02}>
						<CertificationBodyReviewStep2
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[1]?.reviewStatus === "RESUBMIT" || res[1]?.reviewStatus === "APPROVED"
									? true
									: false
							}
						/>
					</Route>
					<Route path={CERTIFICATION_BODY_ROUTES.STEP03}>
						<CertificationBodyReviewStep3
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[2]?.reviewStatus === "RESUBMIT" || res[2]?.reviewStatus === "APPROVED"
									? true
									: false
							}
						/>
					</Route>
					<Route path={CERTIFICATION_BODY_ROUTES.STEP04}>
						<CertificationBodyReviewStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[3]?.reviewStatus === "RESUBMIT" || res[3]?.reviewStatus === "APPROVED"
									? true
									: false
							}
						/>
					</Route>
					<Route path={CERTIFICATION_BODY_ROUTES.STEP05}>
						<CertificationBodyReviewStep5
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[4]?.reviewStatus === "RESUBMIT" || res[4]?.reviewStatus === "APPROVED"
									? true
									: false
							}
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default CertificationReviewHome;
