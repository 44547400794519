import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const getStakeholderManagementTableRecords = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/stakeholder-management/v1/get-detail`,
        headers: {},
        data: data
    })
        .then((res) => {
            console.log("getStakeholderManagementTableRecords (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("getStakeholderManagementTableRecords (error) ====>", error);
        });
};

export const updateStakeholderManagementStatus = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/stakeholder-management/v1/update`,
        headers: {},
        data: data
    })
        .then((res) => {
            console.log("updateStakeholderManagementStatus (res) ====>", res);
            return res;
        })
        .catch((error) => {
            console.log("updateStakeholderManagementStatus (error) ====>", error);
        });
};