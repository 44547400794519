import { useEffect } from "react";
import { useState } from "react";
import { SectionColumn, SectionRow } from "../section";
import { Switch } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { ICON_PATHS } from "../../app/constants";

const ReviewForm = ({ data, children }) => {
  const [_data, setData] = useState(data);

  useEffect(() => {
    setData(data);
    console.log("FORM :", data);
  }, [data]);

  const renderFormElement = (array) => {
    if (array) {
      return array.map((field, i) =>
        field.value ? (
          <div
            className="Rectangle-1 items-center flex-row flex items-start"
            key={i}
          >
            <div className=" w-4/12  m-1 Business-Name">{field.label}</div>
            <div className=" w-1/12  m-1 Business-Name">:</div>
            <div className=" w-7/12  m-1 Business-Name">{field.value}</div>
          </div>
        ) : null
      );
    }
  };

  return (
    <>
      <div>
        <SectionColumn>
          {children}
          {renderFormElement(_data)}
        </SectionColumn>
      </div>
    </>
  );
};

export default ReviewForm;
