import React, { useEffect, useState } from "react";
import { useAlert } from "../../../app/context/alerts";
import { useStore } from "../../../app/context/store";
import { updateRole } from "../../../Helpers/loginHelper";
import RoundedButton from "./RoundedButtons";
import TextField from "./TextField";
import { useHistory } from "react-router-dom";
import { authenticateUser } from "../../../app/api/core_api";
import { overview } from "../../../app/router/routes";
import { sendResetReq } from "../../../app/api/authApi/authApi";
import ResetPassword from "./ResetPassword";
import { validateEmail } from "../../../Helpers/validationHelpers";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import { setCurrentTabActive, setLoggingTime } from "../checkUserLogin";
import { getPermissions, getTabs, setTabs } from "../../../app/api/NOCU/permissionAPI";
import { setDrawerItemsList } from "../../../app/config";

function LoginCard() {
	const { store, setStore } = useStore();
	const { setAlert } = useAlert();
	const [email, setEmail] = useState();
	const [emailError, setEmailError] = useState("");
	const [password, setPassword] = useState();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [isSendReq, setIsSendReq] = useState(false);
	const [isDisableSendEmail, setIsDisableSendEmail] = useState(true);
	const [msg, setMsg] = useState("");
	const [showPassword, setShowPassword] = useState("password");
	const [errorMsg, setErrorMsg] = useState("");
	const [resetData, setResetData] = useState({
		tempPassword: "",
		newPassword: "",
		confirmPassword: "",
		email: "",
	});
	const handleClick = () => {
		if (typeof window !== 'undefined') {
			localStorage.clear();
			console.log('Local storage cleared');
		}

		setIsLoading(true);
		let payload = {
			username: email,
			password: password,
		};

		authenticateUser(payload)
			.then((response) => {
				console.log('Login response', response)
				let userId = response.data.userId;
				console.log('userId------', userId)
				if (response !== 403) {
					console.log("res", response);
					const status = response?.status;
					console.log("status", status);
					const designation = response?.data?.designation;
					console.log("designation", designation);

					if (status === 200 && designation !== "User") {
						console.log("res", response);
						if (
							// response.data.designation && response.data.designation !== "EXPORTER"
							true
						) {
							if (
								response.data?.status === "APPROVED" ||
								response.data?.status === "VACATION" ||
								response.data?.status === "AVAILABLE"
							) {
								const isUpdate = updateRole(
									response.data.roleId,
									response.data.userId,
									response.data.designation,
									response.data.fullName,
									response.data.email,
									store,
									setStore
								);

								if (isUpdate) {
									setLoggingTime();
									setCurrentTabActive();
									getPermissions(userId)
										.then((res) => {
											console.log('getPermissions API res---->>>>', res)
											setDrawerItemsList(setTabs(res.data));
											setTimeout(() => {
												history.push(overview);
												setAlert({
													message: "Login Successful!",
													severity: "success",
													show: true,
												});
											}, 2000);
										})
										.catch((e) => console.log(e))
									// history.push(overview);

									// setAlert({
									// 	message: "Login Successful!",
									// 	severity: "success",
									// 	show: true,
									// });
								}
							} else if (response.data?.status === "REJECTED" || response.data?.status === "DISABLED") {
								setAlert({
									message: "User has no access !",
									severity: "error",
									show: true,
								});
							} else if (response.data?.status === "PENDING") {
								setAlert({
									message: "User signup is pending for approval",
									severity: "error",
									show: true,
								});
							} else if (response.data?.status === "SUSPENDED") {
								setAlert({
									message: "User was suspended !",
									severity: "error",
									show: true,
								});
							} else {
								setAlert({
									message: "Login error !",
									severity: "error",
									show: true,
								});
							}
						} else {
							setAlert({
								message: "Invalid User !",
								severity: "error",
								show: true,
							});
						}
					} else if (status === 200 && designation === "User") {
						setAlert({
							message: "Email or Password does not match",
							severity: "error",
							show: true,
						});
					}
					else if (status === 403) {
						console.log("LOGIN ERROR=================>", response)
						setAlert({
							message: `Wrong Password! You have only ${response.data?.pendingAdept} attempts !`,
							severity: "error",
							show: true,
						});
						//alert("ERROR");
					}
				} else {
					setAlert({
						message: "Email or Password does not match",
						severity: "error",
						show: true,
					});
				}
			})
			.catch((error) => {
				console.log("Login Error =======>", error);
				setAlert({
					message: "Email or Password does not match",
					severity: "error",
					show: true,
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
		// const isUpdate = updateRole(email, password, store, setStore);
		// if (isUpdate) {
		//   // window.open(overview, "_self");
		//   history.push(overview);
		// } else {
		//   setAlert({message:"Email or Password does not match",severity:"error",show:true})
		// }
	};
	const handelResetReq = () => {
		setIsLoading(true);
		let data = {
			username: email,
		};
		if (email === "") {
			setAlert({
				message: "Please enter the email",
				severity: "error",
				show: true,
			});
		} else {
			sendResetReq(data)
				.then((res) => {
					if (res.data?.responseCode === "02") {
						setAlert({
							message: "Incorrect email",
							severity: "error",
							show: true,
						});
					} else {
						setIsSendReq(true);
						setMsg(res.data.responseMsg);
						setAlert({
							message: res.data.responseMsg,
							severity: "success",
							show: true,
						});
					}
				})
				.catch((error) => {
					console.log("Reset error", error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}, []);

	return (
		<div className='grid col-1 justify-items-center mb-5' onKeyDown={(e) => {
			if (e.key === "Enter") {
				handleClick();
			}
		}} >
			<LoaderComponent isOpen={isLoading} />
			{!forgotPassword ? (
				<div>
					<div className='flex-col flex'>
						<span className='loginText'>Login</span>
						<span className='docSubText pb-5'>
							Enter your credentials to access
							<br />
							your account.
						</span>
					</div>
					<TextField
						placeholder='Email'
						autoComplete='new-email'
						type='email'
						value={email}
						onBlur={(e) => setEmailError(validateEmail(e.target.value))}
						onChange={(e) => setEmail(e.target.value)}></TextField>
					{emailError && (
						<div className='text-red-500 text-xs -mt-3  text-center mb-3'>{emailError}</div>
					)}
					<div className='flex flex-row items-baseline '>
						<TextField
							placeholder='Password'
							autoComplete='new-password'
							type={showPassword}
							onChange={(e) => setPassword(e.target.value)}></TextField>
						<div className=' float-right relative right-7'>
							{showPassword === "password" ? (
								<VisibilityIcon
									sx={{ width: 18, color: "#444" }}
									onClick={() => {
										setShowPassword("text");
									}}
								/>
							) : (
								<VisibilityOffIcon
									sx={{ width: 18, color: "#444" }}
									onClick={() => {
										setShowPassword("password");
									}}
								/>
							)}
						</div>
					</div>
					<div className='flex items-center w-full flex-col'>
						<RoundedButton className='loginBTN' onClick={handleClick}>
							Login
						</RoundedButton>
						<div
							className='forgotPassword hover:cursor-pointer'
							onClick={() => {
								setForgotPassword(true);
								setEmail("");
							}}>
							Forgot Password?
						</div>
					</div>
				</div>
			) : (
				<div>
					<div className='flex-col flex'>
						<span className='loginText'>Reset Password</span>
						<span className='docSubText pb-5'>
							Enter your email to reset
							<br />
							your password.
						</span>
					</div>

					<div className='flex items-center w-full flex-col'>
						<TextField
							autoComplete='off'
							placeholder='Email'
							type='email'
							value={email}
							onBlur={(e) => setEmailError(validateEmail(e.target.value))}
							onChange={(e) => setEmail(e.target.value)}></TextField>
						{emailError && <span className='text-red-500 text-xs -mt-3 mb-3'>{emailError}</span>}

						{!isSendReq && (
							<RoundedButton
								autoComplete='off'
								className='loginBTN'
								onClick={() => {
									handelResetReq();
								}}>
								Send Email
							</RoundedButton>
						)}
						{msg && <span className='font-thin text-xs mb-6 text-teal-600'>{msg}</span>}
						{errorMsg && <span className='font-thin text-xs mb-6 text-red-600'>{errorMsg}</span>}
						{isSendReq && (
							<div>
								<ResetPassword setResetData={setResetData} resetData={resetData} email={email} />
							</div>
						)}
						{/* {isSendReq && (
							<RoundedButton
								className='loginBTN'
								onClick={() => {
									handleReset();
								}}>
								Reset
							</RoundedButton>
						)} */}

						<div
							className='forgotPassword hover:cursor-pointer'
							onClick={() => {
								setMsg("");
								setErrorMsg("");
								setEmailError("");
								setResetData({
									tempPassword: "",
									newPassword: "",
									confirmPassword: "",
									email: "",
								});
								setIsSendReq(false);
								setForgotPassword(false);
							}}>
							Cancel
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default LoginCard;
