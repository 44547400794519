import { ErrorMessage, useField } from "formik";
import React, { useEffect, useState } from "react";
import { SectionRow } from "../../../layouts/sections";
import { downIcon, eyeClose, eyeOpen, upIcon } from "../../../Helpers/ICONS";

const TextField2 = (props) => {
  const [error, setError] = useState("");
  const [value, setValue] = useState();
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [dropDown, setDropDown] = useState(false);
  const [calendar, setCalendar] = useState(false);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }

    if (props.getValue) props.getValue(value);
  }, [props, value]);

  useEffect(() => {
    setError("");
  }, [value]);

  useEffect(() => {
    setError(props.error ? props.error : "");
  }, [props]);

  const showPasswordHandler = (e) => {
    setShowPassword(!showPassword);

    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
    e.preventDefault();
  };

  const dropDownhandler = (e) => {
    setDropDown(!dropDown);
  };

  const calendarhandler = (e) => {
    setCalendar(!calendar);
  };

  return (
    <div className="pb-2">
      {props.type === "password" ? (
        <div className={props.formType === "certificationForm" ? "-mr-5" : ""}>
          {props.formType === "certificationForm" ? (
            <div className="certificationFormTextColor -mb-3 text-xs font-bold">
              {props.heading}
            </div>
          ) : (
            ""
          )}
          <label className="field">
            <input
              {...field}
              placeholder={props.placeholder}
              type={passwordType}
              autoComplete="off"
              name={props.name}
              readOnly={props.readOnly}
              className={`inputBaseInput field__input ${props.className} ${meta.touched && meta.error && "validationErrorBorder"
                }`}
            />
          </label>

          <button
            // className="mt-5 !relative right-5"
            className={
              props.formType === "certificationForm"
                ? "mt-5 !relative right-8 top-1"
                : "mt-5 !relative right-5 top-1"
            }
            onClick={(e) => showPasswordHandler(e)}
            type="button"
          >
            <img
              src={showPassword === false ? eyeOpen : eyeClose}
              alt="Picture of the author"
              width={18}
              height={18}
            />
          </button>
        </div>
      ) : props.type === "dropDown" ? (
        <div className={props.formType === "certificationForm" ? "-mr-5" : ""}>
          {props.formType === "certificationForm" ? (
            <div className="certificationFormTextColor -mb-3 text-xs font-bold">
              {props.heading}
            </div>
          ) : (
            ""
          )}

          <label className="field">
            <input
              {...field}
              placeholder={props.placeholder}
              type={props.type}
              name={props.name}
              readOnly={props.readOnly}
              className={`inputBaseInput field__input ${props.className} ${meta.touched && meta.error && "validationErrorBorder"
                }`}
            />
          </label>
          <button
            // className="mt-5 !relative right-5"
            className={
              props.formType === "certificationForm"
                ? "mt-5 !relative right-8 top-1"
                : "mt-5 !relative right-5 top-1"
            }
            onClick={(e) => dropDownhandler(e)}
            type="button"
          >
            <img
              src={dropDown === false ? downIcon : upIcon}
              alt="Picture of the author"
              width={20}
              height={16}
            />
          </button>
        </div>
      ) : props.type === "date" ? (
        <div>
          <div
            className={props.formType === "certificationForm" ? "-mr-0" : ""}
          >
            {props.formType === "certificationForm" ? (
              <SectionRow className="h-2">
                <div className="certificationFormTextColor -mb-3 text-xs font-bold">
                  {props.heading}
                </div>
              </SectionRow>
            ) : (
              ""
            )}

            <label className="field">
              <input
                {...field}
                placeholder={props.placeholder}
                type={props.type}
                name={props.name}
                max={props.max}
                min={props.min}
                id={props.id}
                readOnly={props.readOnly}
                className={`inputBaseInput field__input ${props.className} ${meta.touched && meta.error && "validationErrorBorder"
                  }`}
              />
            </label>
            <button
              className={
                props.formType === "certificationForm"
                  ? "mt-5 !relative cursor-pointer right-8"
                  : "mt-5 !relative cursor-pointer right-5"
              }
              onClick={(e) => calendarhandler(e)}
            ></button>
          </div>
        </div>
      ) : (
        <div>
          {props.formType === "certificationForm" ? (
            <SectionRow className="h-2">
              <div className="certificationFormTextColor -mb-3 text-xs font-bold">
                {props.heading}
              </div>
            </SectionRow>
          ) : (
            ""
          )}

          <label className="field">
            <input
              {...field}
              placeholder={props.placeholder}
              type={props.type}
              name={props.name}
              maxLength={props.maxLength}
              max={props.max}
              min={props.min}
              id={props.id}
              readOnly={props.readOnly}
              className={`inputBaseInput field__input  ${props.className}  ${meta.touched && meta.error && "validationErrorBorder"
                }`}
              onKeyDown={props.onKeyDown}
            />
          </label>
          {props.placeholderIcon ? (
            <button className="mt-6 !relative right-5 cursor-auto" type="button">
              <img
                src={props.placeholderIcon}
                alt="Picture of the author"
                width={18}
                height={14}
              />
            </button>
          ) : (
            ""
          )}
        </div>
      )}
      <ErrorMessage
        component="div"
        name={field.name}
        className="validationError"
      />
    </div>
  );
};

export default TextField2;
