import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MenuItem, Select, TextareaAutosize } from "@material-ui/core";
import LoaderComponent from "../Loader/LoaderComponent";

export const CERTIFICATION_BODY_REJECT_REASONS = [
	{ key: "Document uploaded is incorrect", value: "Document uploaded is incorrect" },
	{
		key: "Input does not match uploaded document",
		value: "Input does not match uploaded document",
	},
	{ key: "Incorrect data provided", value: "Incorrect data provided" },
	{ key: "Missing information", value: "Missing information" },
	{ key: "Spelling error/s in data", value: "Spelling error/s in data" },
	{ key: "Not Applicable", value: "Not Applicable" },
];

export default function RejectModel({
	isOpen,
	setIsOpen,
	handleReject,
	title,
	type,
	button,
	reasonsType,
}) {
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState();

	React.useEffect(() => {
		setValue("");
	}, [open]);
	React.useEffect(() => {
		isOpen && setOpen(isOpen);
	}, [isOpen]);

	const handleClose = () => {
		setOpen(false);
		setIsOpen && setIsOpen(false);
	};

	const handleChange = (val) => {
		setValue(val);
	};
	const handleSelect = (event) => {
		setValue(event.target.value);
	};
	const renderMenuItems = (type) => {
		return type === CERTIFICATION_BODY_REJECT_REASONS ? (
			CERTIFICATION_BODY_REJECT_REASONS.map((reason, i) => (
				<MenuItem key={i} value={reason.value}>
					{reason.key}
				</MenuItem>
			))
		) : (
			<MenuItem value={"Common Reason"}>Common Reason</MenuItem>
		);
	};

	return (
		<div>
			<div onClick={() => setOpen(true)}>{button}</div>
			<Dialog open={open} aria-labelledby='draggable-dialog-title'>
				<DialogTitle
					className={`${type === "RESUBMIT" ? "bg-[#F57C00]" : "bg-red-600"} text-white `}
					style={{ cursor: "move" }}
					id='draggable-dialog-title'>
					{`${type === "RESUBMIT" ? "Resubmitting Step :" : "Rejecting :"}`} {title}
				</DialogTitle>
				<DialogContent>
					<Select
						className='mb-5 pt-3'
						labelId='demo-simple-select-label'
						id='demo-select-small'
						defaultValue={"default"}
						onChange={handleSelect}>
						<MenuItem disabled value='default'>
							<em>Select reject reason</em>
						</MenuItem>
						{renderMenuItems(reasonsType)}
					</Select>
					<DialogContentText>
						<TextareaAutosize
							aria-label='minimum height'
							minRows={3}
							placeholder={`Please mention the reason for ${
								type === "RESUBMIT" ? "resubmitting" : "rejecting"
							} this application as a comment.  `}
							style={{ width: 450 }}
							value={value}
							maxLength={100}
							onChange={(e) => handleChange(e.target.value)}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					{type === "RESUBMIT" ? (
						<Button
							disabled={!!!value}
							variant={"contained"}
							color='warning'
							onClick={() => {
								// rejectStep(stepperName, getCurrentStep(stepperName));
								// handleReject(value);
								handleReject(value);
								setTimeout(() => handleClose(), 1000);
							}}>
							Resubmit
						</Button>
					) : (
						<Button
							disabled={!!!value}
							variant={"contained"}
							color={"error"}
							onClick={() => {
								// rejectStep(stepperName, getCurrentStep(stepperName));
								// handleReject(value);
								handleReject(value);
								setTimeout(() => handleClose(), 1000);
							}}>
							Reject
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}
