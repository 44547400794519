import { API_BASE_URL, AUTH_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const getCompanyDetail = async (params) => {
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `/report/company`,
        headers: {},
        params: params,
    })
        .then((res) => {
            console.log("Get Company Detail Report (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get Company Detail Report (error) ===>", error);
        });
};


export const getEndUserDetailReportPDF = async (fromDate, toDate, stakeholderType) => {
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `/report/v1/get-end-user-detail-report?fromDate=${fromDate}&toDate=${toDate}&stakeholderType=${stakeholderType}`,
        headers: {},
    })
        .then((res) => {
            console.log("Get End User Detail Report PDF (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get End User Detail Report PDF (error) ===>", error);
        });
};


export const getEndUserDetailReportExcel = async (fromDate, toDate, stakeholderType) => {
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `/report/v1/get-end-user-detail-report/download-excel?fromDate=${fromDate}&toDate=${toDate}&stakeholderType=${stakeholderType}`,
        headers: {},
        responseType: 'blob',

    })
        .then((res) => {
            console.log("Get End User report Excel (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get End User report Excel (error) ===>", error);
        });
};

export const getEndUserDetailReportTable = async (fromDate, toDate, stakeholderType, page, size, type) => {
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `report/v1/get-end-user-detail-table?fromDate=${fromDate}&toDate=${toDate}&stakeholderType=${stakeholderType}&page=${page}&size=${size}&type=${type}`,
        headers: {},
    })
        .then((res) => {
            console.log("Get End User Report Table (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get End User Report Table (error) ===>", error);
        });
};


export const applicationCountReportPDF = async (fromDate, toDate, stakeholderType) => {
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `/report/v1/get-application-count-report?fromDate=${fromDate}&toDate=${toDate}`,
        headers: {},
    })
        .then((res) => {
            console.log("Get Application Count Report PDF (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get Application Count Report PDF (error) ===>", error);
        });
};


export const applicationCountReportExcel = async (fromDate, toDate) => {
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `/report/v1/get-application-count-report/download-excel?fromDate=${fromDate}&toDate=${toDate}`,
        headers: {},
        responseType: 'blob',

    })
        .then((res) => {
            console.log("Get Application Count Report Excel (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get Application Count Report Excel (error) ===>", error);
        });
};


export const getEndUserFullDetailReportPDF = async (nocuCertNo, applicationNumber) => {
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `report/v1/get-end-user-full-detail-report?nocuCertNo=${nocuCertNo}&applicationNumber=${applicationNumber}`,
        headers: {},
    })
        .then((res) => {
            console.log("Get End User Full Detail Report PDF (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get End User Full Detail Report PDF (error) ===>", error);
        });
};

