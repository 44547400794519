import React from "react";
import { SectionColumn, SectionRow } from "../../layouts/sections";
import ReviewForm from "../../components/review/reviewForm";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import { getFileURL } from "../../app/api/file_api";
import { getPaymentForm } from "./dataHelper";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const ViewPayment = ({ currentPayment }) => {
	return (
		<div>
			<SectionRow className='w-full pt-10'>
				<SectionColumn className='w-1/2 pl-5 pt-10 items-center'>
					{currentPayment && <ReviewForm data={getPaymentForm(currentPayment)} />}
				</SectionColumn>
				<SectionColumn className='w-1/2 pr-5'>
					<SectionRow className='w-full justify-center '>
						<span>Payment Receipt</span>
					</SectionRow>
					<IFrameViewer path={getFileURL(currentPayment?.paymentReceiptPath) || ""} files={true} />
				</SectionColumn>
			</SectionRow>
		</div>
	);
};

export default ViewPayment;
