import React, { useEffect, useState } from "react";
import { getMapApplicationDetails } from "../../app/api/NOCU/tableAPI";
import { getFileURL } from "../../app/api/file_api";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import { SectionColumn, SectionRow } from "../../layouts/sections";

const CertificateView = ({ applicationId }) => {
	const [_downloadPDF, _setDownloadPDF] = useState();
	const [signedCert, setSignedCert] = useState();
	const [response, setResponse] = useState();
	useEffect(() => {
		getMapApplicationDetails(applicationId)
			.then((res) => {
				setResponse(res.data);
				_setDownloadPDF(res?.data.certificatePath ? getFileURL(res?.data.certificatePath) : null);
				setSignedCert(
					res?.data.signedCertificatePath ? getFileURL(res?.data.signedCertificatePath) : null
				);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);
	return (
		<SectionRow className='w-full'>
			<SectionColumn className='w-1/2 text-center justify-center'>
				Generated Certificate
				{_downloadPDF ? (
					<IFrameViewer path={_downloadPDF} files={true} />
				) : (
					<span className='text-orange-500 mt-10'>
						This application has no generated Certificate yet !
					</span>
				)}
			</SectionColumn>
			<SectionColumn className='w-1/2 text-center justify-center'>
				Signed Certificate
				{signedCert ? (
					<IFrameViewer path={signedCert} files={true} />
				) : (
					<span className='text-orange-500 mt-10'>
						This application has no signed Certificate yet !
					</span>
				)}
			</SectionColumn>
		</SectionRow>
	);
};

export default CertificateView;
