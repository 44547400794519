import React from "react";

const TableCount = ({ count }) => {
	return (
		<div className='perPageContainer  !pt-1  '>
			<label className='mr-2  pl-3'>Count : {count}</label>
		</div>
	);
};

export default TableCount;
