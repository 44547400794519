import { SectionRow } from "./../section/index";
import { SectionColumn } from "../../layouts/sections";
import { ICON_PATHS } from "../../app/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import { useHistory } from "react-router";
import { navigateSelf } from "../../app/router/routerManager";
import { getIsRenewal, getSelectedStatCardFromSession } from "./helpers";
import { reduce1Step } from "../stepper/dynamicStepper";
import {
	getMappedApplicationNumber,
	getStakeholderApplicationNumber,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";

const NavBar = ({ tableIcon, tableURL, title, stepperName, mappedApplicationNumber }) => {
	const history = useHistory();
	const selectedStatCard = getSelectedStatCardFromSession();
	const handleBack = () => {
		reduce1Step(stepperName);
		history.goBack();
	};
	const goToTable = () => {
		navigateSelf(tableURL + "/" + selectedStatCard);
	};
	return (
		<nav className='flex flex-row  z-10 top-0 left-0  flex-wrap pb-3 !mt-0 !pt-2 justify-between mb-4 lg:mb-0 bg-[#20B05E]'>
			<div className='flex flex-row justify-start w-5/12'>
				<div className='flex flex-row justify-center ' onClick={() => goToTable()}>
					<SectionRow className='w-full justify-start items-center cursor-pointer  hover:opacity-70'>
						<div className='pl-3 cursor-pointer  '>
							<ArrowBackIcon
								sx={{
									color: "#FFFFFF",
								}}
								fontSize='large'
							/>
						</div>
						{tableIcon && tableURL && (
							<div className='pl-3 cursor-pointer '>
								<img src={tableIcon} alt='table Icon' width={32} className='' />
							</div>
						)}
						<div className='fontPoppins font-bold !text-white mx-4'>{title}</div>
					</SectionRow>
				</div>
			</div>
			<div className='w-2/12 flex flex-col justify-center items-center'>
				<div className='fontPoppins font-bold !text-white mx-4'>
					{mappedApplicationNumber
						? getMappedApplicationNumber()
						: getStakeholderApplicationNumber()}
				</div>
				<div className='text-white text-xs border px-2 rounded-2xl'>
					{getIsRenewal() ? "Renewal" : "New"}
				</div>
			</div>
			<div className='w-5/12 pr-10'>
				<SectionRow className=' justify-end '>
					<img
						src={ICON_PATHS.logo}
						alt=''
						className='SriLankaNationalLogo !mx-0 text-center hidden sm:block'
					/>
					<SectionColumn className='grid justify-self-center ml-8'>
						<span className='docText !text-white'>Export Development Board</span>
						<span className='docSubText !text-white'>National Organic Control Unit</span>
					</SectionColumn>
				</SectionRow>
			</div>
		</nav>
	);
};

export default NavBar;
