import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import TextField2 from "../../auth/components/TextField2";
import SelectionDropDown2 from "../../auth/components/SelectDropDown2";
import { getAllCountriesApi, uploadCertificatesApi } from "../../../app/api/NOCU/applicationAPI";
import { getFilePathValue } from "../../../Helpers/ObjectsCreateHelper";
import { CB_UC_nameOfAccreditation, CB_UC_titleOfOrganicStandard, selectionDropDownGetValue } from "../../../Helpers/selectionDropDownHelper";
import { CB_UC_Validation } from "../../../Helpers/formikValidation";
import { useAlert } from "../../../app/context/alerts";
import { SectionColumn, SectionRow } from "../../../layouts/sections";
import RoundedButton from "../../../components/buttons/RoundedButtons";
import UploadButton from "../../../components/buttons/UploadButton";
import { currentDate } from "../../../Helpers/stringCrop";
import SelectOtherDropDown from "../../auth/components/SelectOtherDropDown";
import SelectOtherTitleDropDown from "../../auth/components/SelectOtherTitleDropDown";

const UploadCertificationCBFormCard = (applicationNumber) => {

  const { setAlert } = useAlert();
  const [loaderOpen, setLoaderOpen] = React.useState(false);
  const [countries, setCountries] = useState([]);
  const [isDeleteFile, setIsDeleteFile] = useState(false);

  const uploadCertificaionValuesInit = {
    countryOfAccreditation: "",
    nameOfAccreditation: "",
    addressOfAccreditation: "",
    addressLine2: "",
    certificateNumber: "",
    accreditationBodyContactPerson: "",
    certApprovedDate: "",
    certExpirationDate: "",
    certificateFilePath: "",
    auditReportFilePath: "",
    titleOfOrganicStandard: "",
    labType: "",
    zipCode: "",
  };

  useEffect(() => {
    getAllCountriesApi().then((res) => {
      let countryOptionsArray = res?.map((value) => {
        let options = { value: value, label: value };
        return options;
      })
      setCountries(countryOptionsArray)
    }
    )
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    setLoaderOpen(true);

    if (values.certificateFilePath.path !== undefined) {
      values.certificateFilePath = getFilePathValue(values.certificateFilePath);
      values.auditReportFilePath = getFilePathValue(values.auditReportFilePath);
    }

    if (!values.certificateFilePath) {
      setAlert({
        message: "Please upload the document",
        severity: "error",
        show: true,
      });
      setLoaderOpen(false);
    } else {
      values.labType = "";

      values.titleOfOrganicStandard = selectionDropDownGetValue(values.titleOfOrganicStandard);
      values.countryOfAccreditation = selectionDropDownGetValue(values.countryOfAccreditation);
      values.nameOfAccreditation = selectionDropDownGetValue(values.nameOfAccreditation);
      values.isReferSlaab = false;

      let submitValues = {
        stakeholderApplicationNumber: applicationNumber.applicationNumber,
        certificateDetail: values
      }

      console.log('submitValues', submitValues)

      uploadCertificatesApi(submitValues)
        .then((res) => {
          if (res?.responseCode === "00") {
            resetForm();
            setIsDeleteFile(true);
            setTimeout(() => setLoaderOpen(false), 1000);
            setAlert({
              message: "Successfully submitted",
              severity: "success",
              show: true,
            });
          } else {
            setAlert({
              message: "Error!",
              severity: "error",
              show: true,
            });
            setTimeout(() => setLoaderOpen(false), 1000);
          }
          return res;
        })
        .catch((error) => {
          console.log(error);
          setAlert({
            message: error,
            severity: "error",
            show: true,
          });
          setTimeout(() => setLoaderOpen(false), 1000);
          return error;
        });
    }
  };

  const handleValidate = (values) => { };

  return (
    <React.Fragment>
      <div className="w-full !pb-20">
        <SectionColumn className="relative w-full h-full bg-white">
          <div className="grid px-10 py-10">
            <div className="certificationBodyCardFormWrapper">
              <Formik
                initialValues={uploadCertificaionValuesInit}
                onSubmit={handleSubmit}
                validationSchema={CB_UC_Validation}
                validate={handleValidate}
                validator={() => ({})}
              >
                {(formik) => (
                  <Form>
                    <div>
                      <div className="sm:pt-5 pb-6 font-bold -mr-20">
                        <span>Details of Accreditation</span>
                      </div>
                      <SectionColumn>
                        <SectionRow className="w-full flex justify-between pb-3">
                          <SectionColumn className="">
                            <SectionRow>
                              <span className="certificationFormTextColor pb-1 text-xs font-bold">
                                Title of Organic Standard
                              </span>
                            </SectionRow>
                            <div className="-mt-6">
                              <SelectOtherTitleDropDown
                                placeholder="Select Title of Organic Standard"
                                className="certificationBodyInputsFieldDropDown"
                                maxLength={200}
                                type="dropDown"
                                options={CB_UC_titleOfOrganicStandard}
                                name={`titleOfOrganicStandard`}
                                value={formik.values?.titleOfOrganicStandard}
                                setValue={(value) => {
                                  if (value && value?.value !== "OTHER") {
                                    formik.values.titleOfOrganicStandard = value;
                                  }
                                }}
                                onChange={(value) => {
                                  formik.setFieldValue(`titleOfOrganicStandard`, value);
                                }}
                                onBlur={formik.handleBlur}
                                components={{ IndicatorSeparator: () => null }}
                              />
                            </div>
                          </SectionColumn>

                          <SectionColumn className="">
                            <SectionRow>
                              <span className="certificationFormTextColor pb-1 text-xs font-bold">
                                Name of Accreditation Body{" "}
                              </span>
                            </SectionRow>
                            <div className="-mt-6">
                              <SelectOtherDropDown
                                placeholder="Select Name of Accreditation Body"
                                className="certificationBodyInputsFieldDropDown"
                                maxLength={200}
                                type="dropDown"
                                options={CB_UC_nameOfAccreditation}
                                name={`nameOfAccreditation`}
                                value={
                                  formik.values?.nameOfAccreditation
                                }
                                setValue={(value) => {
                                  if (value && value?.value !== "OTHER") {
                                    formik.values.nameOfAccreditation = value;
                                  }
                                }}
                                onChange={(value) => {
                                  formik.setFieldValue(
                                    `nameOfAccreditation`,
                                    value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                components={{ IndicatorSeparator: () => null }}
                              />
                            </div>
                          </SectionColumn>
                        </SectionRow>

                        <SectionRow className="w-full flex justify-between pb-3">
                          <SectionColumn className="">
                            <SectionRow>
                              <span className="certificationFormTextColor pb-1 text-xs font-bold">
                                Country of Accreditation
                              </span>
                            </SectionRow>
                            <div className="-mt-6">
                              <SelectionDropDown2
                                placeholder="Select Country"
                                className="certificationBodyInputsFieldDropDown"
                                maxLength={200}
                                type="dropDown"
                                name={`countryOfAccreditation`}
                                options={countries}
                                value={formik.values?.countryOfAccreditation}
                                onChange={(value) => {
                                  formik.setFieldValue(`countryOfAccreditation`, value);
                                }}
                                onBlur={formik.handleBlur}
                                components={{ IndicatorSeparator: () => null }}
                              />
                            </div>
                          </SectionColumn>

                          <TextField2
                            heading="Contact Number of Accreditation Body"
                            formType="certificationForm"
                            type="text"
                            name={`accreditationBodyContactPerson`}
                            className="certificationBodyInputsField"
                            placeholder="Enter Contact Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.accreditationBodyContactPerson}
                            maxLength={30}
                          />
                        </SectionRow>

                        <SectionRow className="w-full flex justify-between pb-3">
                          <TextField2
                            heading="Address Line 1"
                            formType="certificationForm"
                            type="text"
                            name={`addressOfAccreditation`}
                            className="certificationBodyInputsField"
                            placeholder="Enter Street"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.addressOfAccreditation}
                            maxLength={300}
                          />
                          <TextField2
                            heading="Address Line 2"
                            formType="certificationForm"
                            type="text"
                            name={`addressLine2`}
                            className="certificationBodyInputsField"
                            placeholder="Enter City"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.addressLine2}
                            maxLength={300}
                          />
                        </SectionRow>

                        <SectionRow className="w-full flex justify-between pb-3">
                          <TextField2
                            heading="Zip Code"
                            formType="certificationForm"
                            type="text"
                            name={`zipCode`}
                            className="certificationBodyInputsField"
                            placeholder="Enter Zip Code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.certificateNumber}
                            maxLength={10}
                          />

                          <TextField2
                            heading="Certificate Number"
                            formType="certificationForm"
                            type="text"
                            name={`certificateNumber`}
                            className="certificationBodyInputsField"
                            placeholder="Enter Certificate Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.certificateNumber}
                            maxLength={20}
                          />
                        </SectionRow>

                        <SectionRow className="w-full flex justify-between pb-3">
                          <TextField2
                            heading="Approved Date"
                            formType="certificationForm"
                            type="date"
                            name={`certApprovedDate`}
                            className="certificationBodyInputsFieldDate w-1/2"
                            placeholder="Select Date"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.certApprovedDate}
                            max={currentDate()}
                          />
                          <TextField2
                            heading="Expiration Date"
                            formType="certificationForm"
                            type="date"
                            name={`certExpirationDate`}
                            className="certificationBodyInputsFieldDate w-1/2"
                            placeholder="Select Date"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.certExpirationDate}
                          />
                        </SectionRow>

                        <SectionRow className="w-full flex justify-between pb-3">
                          <div className="pt-0">
                            <SectionRow>
                              <span className="certificationFormTextColor pb-1 text-xs font-bold">
                                Upload Certificate
                              </span>
                            </SectionRow>

                            <SectionRow>
                              <UploadButton
                                title="Add Certificate"
                                name={`certificateFilePath`}
                                btnname="Upload Certificate"
                                className="certificationBodyUploadButton certificationBodyInputsFieldWidth uploadButton"
                                type="button"
                                setfilepath={(filePath) => {
                                  formik.values.certificateFilePath = filePath;
                                }}
                                backFilePath={""}
                                setbackFilePath={(backfilePath) => {
                                  console.log('backfilePath', backfilePath)
                                }}
                                uploadType="businessDetailsFileUpload"
                                isDeleteFile={isDeleteFile}
                              />
                            </SectionRow>
                          </div>
                          <div className="pt-0">
                            <SectionRow>
                              <span className="certificationFormTextColor pb-1 text-xs font-bold">
                                Upload Recent Annual Organic Audit Report (Optional)
                              </span>
                            </SectionRow>
                            <SectionRow>
                              <UploadButton
                                title="Add Recent Annual Organic Audit Report"
                                name={`auditReportFilePath`}
                                btnname="Upload Recent Annual Organic Audit Report (Optional)"
                                className="certificationBodyUploadButton certificationBodyInputsFieldWidth uploadButton"
                                type="button"
                                setfilepath={(filePath) => {
                                  formik.values.auditReportFilePath = filePath;
                                }}
                                backFilePath={""}
                                setbackFilePath={(backfilePath) => {
                                  console.log('backfilePath', backfilePath)
                                }}
                                uploadType="businessDetailsFileUpload"
                                isDeleteFile={isDeleteFile}
                              />
                            </SectionRow>
                          </div>
                        </SectionRow>
                      </SectionColumn>
                    </div>
                    <div className="absolute sm:right-20 pb-10 pt-12">
                      <SectionRow className="sm:gap-x-8 gap-y-2 sm:gap-y-0">
                        <RoundedButton
                          ref={undefined}
                          onClick={undefined}
                          className="primaryButton certificationFormButton"
                          type="submit"
                        >
                          <SectionRow>
                            <span className="pr-3">Save</span>
                          </SectionRow>
                        </RoundedButton>
                      </SectionRow>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </SectionColumn>
      </div>
      <LoaderComponent isOpen={loaderOpen} />
    </React.Fragment>
  );
};

export default UploadCertificationCBFormCard;
