import axios from "axios";
import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const sendResetReq = async (data, id) => {
	return axios({
		method: "PUT",
		baseURL: API_BASE_URL,
		url: "/auth/forget-password-send-email",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("Submit step 3 response", res);
			return res;
		})
		.catch((error) => {
			console.log("Submit step 3 error", error);
		});
};
export const resetPassword = async (data) => {
	return axios({
		method: "PUT",
		baseURL: API_BASE_URL,
		url: "/auth/forget-password",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("RESET", res);
			return res;
		})
		.catch((error) => {
			console.log("RESET", error);
		});
};
export const changePassword = async (data) => {
	return CustomAxios({
		method: "PUT",
		baseURL: API_BASE_URL,
		url: "/auth/change-password",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("CHANGE", res);
			return res;
		})
		.catch((error) => {
			console.log("CHANGE", error);
		});
};
export const createDocAccount = async (data) => {
	return CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/user/add",
		headers: {},
		data: data,
	})
		.then((res) => {
			console.log("Create Doc Account (res) ======>", res);
			return res;
		})
		.catch((error) => {
			console.log("Create Doc Account (error) ======>", error);
		});
};
export const logOutApi = async () => {
	return axios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/auth/logout",
		headers: {
			AUTHORIZATION: localStorage.getItem("REFRESHTOKEN"),
		},
		
	})
		.then((res) => {
			console.log("logOutApi", res);
			return res;
		})
		.catch((error) => {
			console.log("logOutApi", error);
		});
};
