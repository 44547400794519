import React from "react";
import { Button } from "@mui/material";

function ResubmitButton({ name, icon, handleClick, className, title, disabled }) {
	const handleReject = () => {
		handleClick();
	};
	return (
		<Button
			disabled={disabled}
			name={name}
			variant={"contained"}
			className={` !px-4 !capitalize ${className}`}
			sx={{
				backgroundColor: "#f57c00",
				"&:hover": {
					backgroundColor: "#bb4d00",
					borderColor: "#bb4d00",
					boxShadow: "none",
				},
				width: 180,
				borderRadius: 3,
				mx: 2,
				py: 1,
			}}
			startIcon={icon}
			onClick={handleReject}>
			{title}
		</Button>
	);
}

export default ResubmitButton;
