import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { getCompanyTrailAPI_adapter } from "../../app/api/adapter";
import { callAPI } from "../../app/hooks/useAPI";
import TrialTable from "../table/trialTable";

export default function PromptBase({ isOpen, handleDismiss, data, companyID, ...props }) {
	const [open, setOpen] = useState(isOpen);
	const [trailData, setTrailData] = useState();

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		handleDismiss();
		setOpen(false);
	};

	useEffect(() => {
		callAPI(() => getCompanyTrailAPI_adapter(companyID), setTrailData);
	}, [companyID]);

	useEffect(() => {
		console.log("Company Trail >>>>>>>> :", trailData);
	}, [trailData]);

	const renderList = () => {
		return (
			<>
				<div className='pt-2 w-full'>
					<span className='w-1/12'>Application Status</span>
					<span className='w-1/12'> : </span>
					<span className='w-1/12'>{data.applicationStatus}</span>
				</div>
				<hr />
				<div className='pt-2 w-full'>
					<span className='w-1/12'>Application Type</span>
					<span className='w-1/12'> : </span>
					<span className='w-1/12'>{data.applicationType}</span>
				</div>
				<hr />
				<div className='pt-2 w-full'>
					<span className='w-1/12'>Approval Level</span>
					<span className='w-1/12'> : </span>
					<span className='w-1/12'>{data.approvalLevel}</span>
				</div>
				<div className='pt-2 w-full'>
					<span className='w-1/12'>Officer</span>
					<span className='w-1/12'> : </span>
					<span className='w-1/12'>{data.officer}</span>
				</div>
				<hr />
				<div className='pt-2 w-full'>
					<span className='w-1/12'>Remarks</span>
					<span className='w-1/12'> : </span>
					<span className='w-1/12'>{data.remarks}</span>
				</div>
			</>
		);
	};

	return (
		<div>
			<Dialog
				className={props.className || "prompt"}
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				sx={{
					backdropFilter: "blur(10px)",
				}}>
				<DialogTitle id='alert-dialog-title'>{`Application Trail - ${trailData?.companyDetails?.companyName}`}</DialogTitle>
				<DialogContent>
					<h1 className='ml-6 font-bold text-slate-600'>
						<span className='ml-0 font-normal text-neutral-800'>Application Code: </span>
						{trailData?.applicationNumber}
					</h1>

					<DialogContentText id='alert-dialog-description'>
						<TrialTable company={trailData} />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus variant='contained'>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
