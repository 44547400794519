import { Avatar } from "@material-ui/core";
import React, { Component } from "react";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import { RoundContainer } from "../section";
import { Chip, Tooltip } from "@mui/material";
import { stringCrop } from "../../Helpers/stringCrop";

export default function CompRegRow({ title, value, color, icon, ...props }) {
	return (
		<div>
			<div className='flex flex-wrap items-center  merchant-data'>
				<div className='w-2/12 flex items-center opacity-40 justify-center'>
					{icon || <AssignmentTwoToneIcon />}
				</div>
				<div className='w-8/12'>
					<div className='flex flex-wrap '>
						<div className='tran-heading-1 opacity-90'>
							{title.length > 10 ? (
								<Tooltip title={<div className='text-base'>{title}</div>}>
									<div>{stringCrop(title, 30)}</div>
								</Tooltip>
							) : (
								title
							)}
						</div>
					</div>
				</div>
				<div className='w-2/12 flex items-center justify-end pr-2'>
					<div className='tran-heading-1'>
						<Chip label={value} style={{ backgroundColor: `${color}` }} />
					</div>
				</div>
			</div>
			<hr />
		</div>
	);
}
