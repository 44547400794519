import { width } from "@mui/system";
import React, { Component, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { setSelectedStatCardOnSession } from "../navbar/helpers";

export default function StatCard({
	image,
	value,
	text,
	presentage,
	accent,
	handleStatCard,
	num,
	selected,
	setSelectStatCard,
}) {
	const location = useLocation();
	const history = useHistory();
	const { params } = useParams();
	const presentageView = () => {
		if (presentage) {
			return <span className='StatCardPresentage ml-1'>{presentage}%</span>;
		} else {
			return <span></span>;
		}
	};

	const selection = num !== undefined ? (num === selected ? "3px solid #1FAE5C" : "none") : "none";

	const getPath = () => {
		let pathNameArray = location.pathname.split("/");
		pathNameArray[pathNameArray.length - 1] = num;
		let url = pathNameArray.toString().replaceAll(",", "/");
		history.push(url);
	};

	return (
		<div
			onClick={() => {
				setSelectStatCard(num);
				setSelectedStatCardOnSession(num);
				handleStatCard();
				getPath();
			}}
			className=' bg-white rounded-xl  overflow-hidden statCard relative hover:scale-110  mx-2 mb-1 mt-3 '
			style={{
				cursor: "pointer",
				borderBottom: selection,
				borderTop: selection,
				width: "150px",
			}}>
			<div className='grid grid-cols-12 items-center spending-box '>
				<div className='col-span-1 '></div>
				<div className='col-span-10 '>
					<div>
						<div className='grid grid-cols-12 items-center'>
							<div className='col-span-3 '>
								<div
									className='tran-icon relative   rounded-xl'
									style={{ backgroundColor: accent }}>
									<img src={image} className='h-5 w-5 tran-icon-2 ' alt='' />
								</div>
							</div>
							<div className='col-span-1'></div>
							<div className='col-span-8 '>
								<span className='StatCardValue'>{value}</span>
								{/* <span className='StatCardPresentage ml-1'>{presentage}%</span> */}
								{presentageView()}
								<div className='StatCardText '>{text}</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-span-1'></div>
			</div>
		</div>
	);
}
