import React, { Component, useState } from "react";
import { SectionRow } from "../section";

export default function TimePeriodSelection({ title, value, setTimeRange, ...props }) {
	const [clicked, setClicked] = useState(0);

	const handleClick = (n) => {
		setClicked(n);
		n === 0
			? setTimeRange("ALL")
			: n === 1
			? setTimeRange("Today")
			: n === 2
			? setTimeRange("Last Week")
			: setTimeRange("Last Month");
	};

	return (
		<React.Fragment>
			<SectionRow className='timePeriodSelectionMainDiv mb-2'>
				<div className={clicked === 0 ? "timePeriodSelectionDivCliked" : "timePeriodSelectionDiv"}>
					<button
						className={clicked === 0 ? "timePeriodSelectionTxtCliked" : "timePeriodSelectionTxt"}
						onClick={(e) => handleClick(0)}>
						ALL
					</button>
				</div>
				<div className={clicked === 1 ? "timePeriodSelectionDivCliked" : "timePeriodSelectionDiv"}>
					<button
						className={clicked === 1 ? "timePeriodSelectionTxtCliked" : "timePeriodSelectionTxt"}
						onClick={(e) => handleClick(1)}>
						Today
					</button>
				</div>
				<div className={clicked === 2 ? "timePeriodSelectionDivCliked" : "timePeriodSelectionDiv"}>
					<button
						className={clicked === 2 ? "timePeriodSelectionTxtCliked" : "timePeriodSelectionTxt"}
						onClick={(e) => handleClick(2)}>
						Last Week
					</button>
				</div>
				<div className={clicked === 3 ? "timePeriodSelectionDivCliked" : "timePeriodSelectionDiv"}>
					<button
						className={clicked === 3 ? "timePeriodSelectionTxtCliked" : "timePeriodSelectionTxt"}
						onClick={(e) => handleClick(3)}>
						Last Month
					</button>
				</div>
			</SectionRow>
		</React.Fragment>
	);
}
