import CostFileButton from "./../review/costFileBTN";

const FileButtonWrapper = ({
	index,
	file,
	handleFileClick,
	rejectFile,
	approveFile,
	disableAction,
}) => {
	return (
		<div className=''>
			<CostFileButton
				file={file}
				id={file.fileID}
				name={file.name}
				subBtnName={file.productName}
				status={file.status}
				_comment={file?.comment}
				handleClick={(file) => handleFileClick(file, index)}
				rejectFile={rejectFile}
				approveFile={approveFile}
				showBTN={file.showBTN}
				disableAction={disableAction}
			/>
			{/* {file.productName && <span className='fileUploadButtonText'>({file.productName})</span>} */}
		</div>
	);
};

export default FileButtonWrapper;
