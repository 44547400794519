import { DEFAULT, SELECT } from "../../../Helpers/constants";
import { setApplicationRejectDetails } from "../../../Helpers/rejectionHelpers";
import { STATUS } from "../../../app/constants";

export const getTableData = (records) => {
	const applicationList = records?.applicationList;
	return applicationList
		? applicationList.map((item) => [
				item?.companyName ? item.companyName : "-",
				item?.applicationNumber ? item.applicationNumber : "-",
				item?.companyRegNumber ? item.companyRegNumber : "-",
				item?.typeOfBusiness ? item.typeOfBusiness : "-",
				item?.email ? item.email : "-",
				`${item?.contactPersonName ? item.contactPersonName : ""} ${
					item?.contactPersonMobile ? item.contactPersonMobile : ""
				}`,
				`${item?.createdDate.split("T")[0] ? item.createdDate.split("T")[0] : ""} ${
					item?.createdDate.split("T")[1].split(".")[0]
						? item.createdDate.split("T")[1].split(".")[0]
						: ""
				}`,
		  ])
		: [];
};

export const getMapTableData = (records) => {
	const mappedApplicationList = records?.mappedApplicationList;
	return mappedApplicationList
		? mappedApplicationList.map((item) => [
				item?.companyName ? item.companyName : "-",
				item?.companyRegNumber ? item.companyRegNumber : "-",
				item?.typeOfBusiness ? item.typeOfBusiness : "-",
				item?.email ? item.email : "-",
				item?.mappedApplicationNumber ? item.mappedApplicationNumber : "-",
				item?.mappedApplicationType ? item.mappedApplicationType : "-",
				`${item?.contactPersonName ? item.contactPersonName : ""} ${
					item?.contactPersonMobile ? item.contactPersonMobile : ""
				}`,
				`${item?.createdDate ? item.createdDate.split("T")[0] : ""} ${
					item?.createdDate ? item.createdDate.split("T")[1].split(".")[0] : ""
				}`,
		  ])
		: [];
};

export const getVotingComments = (records) => {
	const mappedVotingList = records?.voteDetail;
	return mappedVotingList
		? mappedVotingList.map((item) => [
				item?.fullName ? item.fullName : "-",
				item?.remark ? item.remark : "-",
		  ])
		: [];
};

export const getVotingTableData = (records) => {
	const mappedVotingList = records?.voteDetail;
	return mappedVotingList
		? mappedVotingList.map((item) => [
				item?.fullName ? item.fullName : "-",
				item?.reviewStatus && item.reviewStatus !== "PENDING" ? "VOTED" : item.reviewStatus,
		  ])
		: [];
};

export const appendRejectionsSingleForm = (rejections, form, setForm, entityId) => {
	let _form = form.map((item, i) => {
		let rejection = [];
		rejection = rejections.filter((_rejection, i) => {
			return _rejection.name === item.name && _rejection.entityId === entityId;
		});
		const reObj =
			rejection.length !== 0
				? {
						name: item.name,
						label: item.label,
						value: item.value,
						isChecked: false,
						comment: rejection[0]?.comment,
				  }
				: item;
		const _data = {
			isFile: false,
			name: item.name,
			entityId: entityId,
			value: item.value,
			comment: rejection[0]?.comment,
		};
		if (rejection.length !== 0) {
			setApplicationRejectDetails(STATUS.REJECTED, _data);
		}
		return reObj;
	});
	console.log(_form);
	setForm(_form);
};
export const appendRejectionsMultipleForms = (
	rejections,
	form,
	setForm,
	entityId,
	currentIndex
) => {
	let _form = form[currentIndex].map((item, i) => {
		let rejection = [];
		rejection = rejections.filter((_rejection, i) => {
			return _rejection.name === item.name && _rejection.entityId === entityId;
		});
		const reObj =
			rejection.length !== 0
				? {
						name: item.name,
						label: item.label,
						value: item.value,
						isChecked: false,
						comment: rejection[0]?.comment,
				  }
				: item;
		const _data = {
			isFile: false,
			name: item.name,
			entityId: entityId,
			value: item.value,
			comment: rejection[0]?.comment,
		};
		if (rejection.length !== 0) {
			setApplicationRejectDetails(STATUS.REJECTED, _data);
		}
		return reObj;
	});
	console.log("_Form ====>", _form);

	let forms = form;
	forms[currentIndex] = _form;
	setForm(forms);

	// setForm(_form);
};

export const appendRejectionsFiles = (rejections, files, setFiles, entityId) => {
	let _files = files.map((item, i) => {
		let rejection = [];
		rejection = rejections.filter((_rejection, i) => {
			return _rejection.name === item.name && _rejection.entityId === entityId;
		});
		const reObj =
			rejection.length !== 0
				? {
						name: item.name,
						path: item.path,
						status: STATUS.REJECTED,
						comment: rejection[0]?.comment,
				  }
				: item;
		const _data = {
			isFile: true,
			name: item.name,
			entityId: entityId,
			value: rejection[0]?.path,
			comment: rejection[0]?.comment,
		};
		if (rejection.length !== 0) {
			setApplicationRejectDetails(STATUS.REJECTED, _data);
		}
		return reObj;
	});
	console.log(_files);
	setFiles(_files);
};

export const appendRejectionsMultipleFiles = (
	rejections,
	files,
	setFiles,
	entityId,
	currentIndex
) => {
	let _files = files[currentIndex].map((item, i) => {
		let rejection = [];
		rejection = rejections.filter((_rejection, i) => {
			return _rejection.name === item.name && _rejection.entityId === entityId;
		});
		const reObj =
			rejection.length !== 0
				? {
						name: item.name,
						path: item.path,
						status: STATUS.REJECTED,
						comment: rejection[0]?.comment,
				  }
				: item;
		const _data = {
			isFile: true,
			name: item.name,
			entityId: entityId,
			value: rejection[0]?.path,
			comment: rejection[0]?.comment,
		};
		if (rejection.length !== 0) {
			setApplicationRejectDetails(STATUS.REJECTED, _data);
		}
		return reObj;
	});
	console.log("_Form ====>", _files);

	let returnFiles = files;
	returnFiles[currentIndex] = _files;
	setFiles(returnFiles);

	// setForm(_form);
};

export const appendRejectionsFieldVisit = (rejections, form, setForm) => {
	let _form = form.map((item, i) => {
		let rejection = [];
		rejection =
			rejections !== null
				? rejections.filter((_rejection, i) => {
						return _rejection.name === item.name;
				  })
				: [];

		const reObj =
			rejection.length !== 0
				? {
						name: item.name,
						label: item.label,
						value: item.label,
						isChecked: false,
						comment: rejection[0]?.comment,
				  }
				: item;
		const _data = {
			isFile: false,
			name: item.name,
			value: item.value,
			comment: rejection[0]?.comment,
		};
		if (rejection.length !== 0) {
			setApplicationRejectDetails(STATUS.REJECTED, _data);
		}
		return reObj;
	});
	console.log(_form);
	setForm(_form);
};

export const validateFiles = (files) => {
	const _files = files.map((file) => {
		return file.status !== DEFAULT && file.status !== SELECT ? true : false;
	});
	return _files.includes(false) ? false : true;
};

export const validateMultiArray = (files) => {
	const _m_files = files.map((_files) => {
		return validateFiles(_files);
	});
	return _m_files.includes(false) ? false : true;
};

export const convertStepperRes = (data) => {
	const getReviewStatus = (status) => {
		return status === "RESUBMIT" || status === "RESUBMITTED"
			? "RESUBMIT"
			: status === "APPROVED"
			? "COMPLETED"
			: status === "REJECTED"
			? "REJECTED"
			: "";
	};
	console.log("Stepper123", data);
	return [
		{
			step: "Business Details",
			status:
				getReviewStatus(data[0]?.reviewStatus) === ""
					? "ACTIVE"
					: getReviewStatus(data[0]?.reviewStatus),
		},
		{
			step: "Contact Personnel",
			status: getReviewStatus(data[1]?.reviewStatus),
		},
		{
			step: "Management Details",
			status: getReviewStatus(data[2]?.reviewStatus),
		},

		{
			step: "Current Product Details",
			status: getReviewStatus(data[8]?.reviewStatus),
		},
		{
			step: "Certificates",
			status: getReviewStatus(data[3]?.reviewStatus),
		},
		{ step: "Other Documents", status: getReviewStatus(data[4]?.reviewStatus) },
	];
};

export const mapFiles = (certificates, _setAcceptedFiles) => {
	// Execute when updating
	let _files = [];
	certificates?.map((item) =>
		_files.push({
			path: item?.certificateFilePath,
		})
	);
	_setAcceptedFiles(_files);
};

export const getVoteCount = (records) => {
	let count = [];
	count.push(records.totalPendingVoteCount);
	count.push(records.totalApprovedVoteCount);
	count.push(records.totalRejectedVoteCount);

	console.log("count", count);
	return count;
};
