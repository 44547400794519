export const APPROVED = "APPROVED";
export const SELECT = "SELECT";
export const DEFAULT = "DEFAULT";
export const PENDING = "PENDING";
export const REJECTED = "REJECTED";
export const EXPIRED = "EXPIRED";
export const RESUBMISSION = "RESUBMISSION";
export const RECOMMENDED = "RECOMMENDED";
export const READY_TO_COLLECT = "READY_TO_COLLECT";
export const READY_TO_ISSUE = "READY_TO_ISSUE";
export const WHOLLY_PRODUCT = "Wholly products";
export const ELIGIBLE = "ELIGIBLE";
export const NOT_ELIGIBLE = "NOT_ELIGIBLE";
export const INVOICE = "INVOICE";
export const DOCUMENT = "DOCUMENT";
export const CST_AFI = "CST_AFI";
export const VACATION = "VACATION";
export const AVAILABLE = "AVAILABLE";

export const COO_SPICES = "COO_SPICES";
export const COO = "COO";

export const REQ_LETTER = "REQ_LETTER";
export const BLANK_PAPER_REQUEST = "BLANK_PAPER_REQUEST";
export const BLANK_PAPER_ISSUE = "BLANK_PAPER_ISSUE";
export const BLANK_PAPER_SUMMARY = "BLANK_PAPER_SUMMARY";

//Summary types
export const ECOO = "ECOO";
export const CP_REG = "CP_REG";
export const CST = "CST";
export const AFFIDAVIT = "AFFIDAVIT";

export const ACTORS = { CHAIRMAN: "CHAiMAN", MD: "MANAGING_DIRECTORE", CONTRACT: "contact" };
export const statusText = { APPROVED, SELECT, DEFAULT, PENDING };
export const userRoles = {
	cro: "CRO",
	ad: "AD",
	dg: "DG",
	mso: "MSO",
	do: "DO",
	io: "IO",
	accountant: "ACCOUNTANT",
	dc: "DC",
};
export const permissions = {
	overView: "overView",
	companyRegistration: "companyRegistration",
	coo: "coo",
	cooSpices: "cooSpices",
	trqc: "trqc",
	costStatement: "costStatement",
	affidavit: "affidavit",
	inquiry: "inquiry",
	summary: "summary",
};
