import React from "react";
import { SectionColumn, SectionRow } from "../../components/section";
import ChangePasswordCard from "./Components/ChangePassword";

const ProfileHome = () => {
	return (
		<div className='flex-1'>
			<SectionRow className='w-full mb-10'>
				<span className='heading-1'>Profile</span>
			</SectionRow>
			{/* <SectionRow className='w-full'>
				<span className='heading-2'>User Details</span>
			</SectionRow> */}
			<SectionRow className='w-full'>
				<span className='heading-2'>Change Password</span>
			</SectionRow>
			<SectionColumn className='w-full'>
				<ChangePasswordCard />
			</SectionColumn>
		</div>
	);
};

export default ProfileHome;
