import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getUserId, setStakeholderType } from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getMapTableCounts, getMapTableRecords } from "../../app/api/NOCU/tableAPI";
import { STAKEHOLDERS, STAT_ACCENT_COLORS, STAT_ICON_PATHS } from "../../app/constants";
import { navigateSelf } from "../../app/router/routerManager";
import { FINAL_COMMENTS_ROUTES, TECHNICAL_COMMITTEE_ROUTES } from "../../app/router/routes";
import StatCard from "../../components/cards/statCard";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import HomeTable from "../../components/table/HomeTable";
import { setApplicationStage, showViewIcon } from "../../components/table/tableHelpers";
import { SectionRow } from "../../layouts/sections";

import { deleteIsRenewal, setIsRenewal } from "../../components/navbar/helpers";
import { getMapTableData } from "./dataManipulation";
import { Tooltip } from "@mui/material";

const TechnicalCommitteeHome = () => {
	const { id } = useParams();
	const [records, setRecords] = useState();
	const [counts, setCounts] = useState();
	const [selectedCount, setSelectedCount] = useState();
	const [selectedTitle, setSelectedTitle] = useState("New Requests");
	const [isLoading, setIsLoading] = useState(true);
	const [tableCount, setTableCount] = useState();
	const [res, setRes] = useState();

	const tableHeaders = [
		"Name",
		// "Com. Reg. No.",
		// "Business type",
		<Tooltip title={<div className='p-2 text-sm'>System Generated Mapping Number</div>}>
			<div>System No.</div>
		</Tooltip>,
		"Application No.",

		// "Contact Person",
		"Date & Time",
		"Type",
	];

	let filters = [
		{ key: "PENDING", value: "PENDING" },
		{ key: "SUCCESS", value: "SUCCESS" },
		{ key: "SUSPENDED", value: "SUSPENDED" },
		{ key: "APPROVED", value: "APPROVED" },
		{ key: "REJECTED", value: "REJECTED" },
	];
	let sorting = [
		{ key: "Acceding", value: true },
		{ key: "Descending", value: false },
	];
	const [selectedStatCard, setSelectedStatCard] = useState(1);
	const [params, setParams] = useState({
		page: 0,
		size: 5,
		applicationNumber: "",
		stakeholderType: "ALL",
		applicationStage: "TECHNICAL_COMMITTEE",
		status: "",
		officerId: getUserId(),
		isAscending: true,
	});

	useEffect(() => {
		setSelectedStatCard(parseInt(id));
		if (parseInt(id) === 1) {
			setParams({ ...params, status: "PENDING" });
			setSelectedCount(counts?.pendingCount);
			setSelectedTitle("Pending Requests");
		} else if (parseInt(id) === 2) {
			setParams({ ...params, status: "RESUBMIT" });
			setSelectedCount(counts?.resubmissionCount);
			setSelectedTitle("Resubmitted Requests");
		} else if (parseInt(id) === 3) {
			setParams({ ...params, status: "APPROVED", isAscending: false });
			setSelectedCount(counts?.approvedOnlyCount);
			setSelectedTitle("Completed Requests");
		} else if (parseInt(id) === 4) {
			setParams({ ...params, status: "REJECTED", isAscending: false });
			setSelectedCount(counts?.rejectedOnlyCount);
			setSelectedTitle("Rejected Requests");
		} else if (parseInt(id) === 5) {
			setParams({ ...params, status: "DISCARD", isAscending: false });
			setSelectedCount(counts?.discardOnlyCount);
			setSelectedTitle("Rejected Requests");
		}
	}, [id]);
	const handleFilter = (filter) => {
		setParams({ ...params, status: filter });
	};

	const handleStakeHolderType = (filter) => {
		setParams({ ...params, stakeholderType: filter });
	};

	const handleSearchById = (id) => {
		setParams({ ...params, applicationNumber: id });
	};

	const handleSorting = (isAscending) => {
		setParams({ ...params, isAscending: isAscending });
	};
	const handlePage = (page) => {
		setParams({ ...params, page: page });
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size });
	};

	const viewFunction = (index) => {
		setStakeholderType(res.mappedApplicationList[index]?.stakeholderType);
		setIsRenewal(res.mappedApplicationList[index]?.isRenewal);
		setApplicationStage(params.applicationStage);
		navigateSelf(TECHNICAL_COMMITTEE_ROUTES.REVIEW);
	};
	const historyFunction = (index) => {
		console.log("Index Of record=================>", index);
	};

	useEffect(() => {
		deleteIsRenewal();
		const cParams = {
			officerId: getUserId(),
			stakeholderType: params.stakeholderType,
			applicationNumber: params.applicationNumber,
			applicationStage: "TECHNICAL_COMMITTEE",
		};
		setStakeholderType(params.stakeholderType);
		getMapTableCounts(cParams).then((res) => {
			setCounts(res);
			setSelectedCount(res?.pendingCount);
		});
	}, []);

	// useEffect(() => {
	// 	!!!selectedCount && setSelectedCount(counts?.pendingCount);
	// }, [counts]);

	useEffect(() => {
		console.log("Params ====>", params);
		if (params.status && params.status !== "") {
			getMapTableRecords(params)
				.then((res) => {
					console.log("Res ======> ", getMapTableData(res.data));
					setRecords(getMapTableData(res.data));
					setTableCount(res?.data.count);
					setRes(res.data);
					setIsLoading(false);
				})
				.then((e) => console.log(e));
		}
	}, [params]);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0'>
				<span className='overViewTitle'>Technical Committee</span>
			</SectionRow>
			<SectionRow className='w-full overflow-x-auto mb-5 customScrollBar'>
				<SectionRow className='w-full !min-w-max mb-2 justify-center items-center'>
					<StatCard
						value={counts ? counts.pendingCount : 0}
						text={"Pending Application"}
						image={STAT_ICON_PATHS.stat_duplication}
						accent={STAT_ACCENT_COLORS.blue}
						handleStatCard={() => {}}
						num={1}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts ? counts.approvedOnlyCount : 0}
						text={"Approved Application"}
						image={STAT_ICON_PATHS.stat_checkMark}
						accent={STAT_ACCENT_COLORS.lightGreen}
						handleStatCard={() => {}}
						num={3}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts ? counts.rejectedOnlyCount : 0}
						text={"Rejected Application"}
						image={STAT_ICON_PATHS.stat_cancelMark}
						accent={STAT_ACCENT_COLORS.red}
						handleStatCard={() => {}}
						num={4}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
					<StatCard
						value={counts ? counts.discardOnlyCount : 0}
						text={"Unvoted Application"}
						image={STAT_ICON_PATHS.stat_cancelation}
						accent={STAT_ACCENT_COLORS.yellow}
						handleStatCard={() => {}}
						num={5}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
				</SectionRow>
			</SectionRow>
			<HomeTable
				useMappedApplicationNumber
				applicationKey={1}
				columns={tableHeaders}
				rows={records}
				handleSearchById={handleSearchById}
				view={showViewIcon(["1"], id) ? viewFunction : null}
				// history={historyFunction}
				filters={STAKEHOLDERS}
				setFilter={handleStakeHolderType}
				// sort={sorting}
				handleSort={handleSorting}
				handleSize={handleSize}
				count={tableCount}
				page={params.page}
				size={params.size}
				handlePage={handlePage}
				filterTitle='Stakeholder'
			/>
		</div>
	);
};

export default TechnicalCommitteeHome;
