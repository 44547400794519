import React, { useEffect, useState } from "react";
import FourContainersLayout from "../../layouts/page/fourContainers";
import { SectionColumn } from "../../components/section";
import { SectionRow } from "../../layouts/sections/index";
import { Box, Tab, Tabs, TextareaAutosize, ThemeProvider, Tooltip } from "@mui/material";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import NextButton from "../../components/buttons/nextButton";
import AcceptButton from "../../components/buttons/acceptbutton";
import PreviewIcon from "@mui/icons-material/Preview";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { stringCrop } from "../../Helpers/stringCrop";
import {
	getMappedApplicationNumber,
	getStakeholderApplicationNumber,
	getStakeholderType,
	getUserId,
	setStakeholderApplicationNumber,
	setStakeholderType,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getMapApplicationDetails } from "../../app/api/NOCU/tableAPI";
import NavBar from "../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import { FIELD_VISIT_ROUTES, FINAL_COMMENTS_ROUTES } from "../../app/router/routes";
import ToggleButtonSet from "../../components/buttons/ToggleButtonSet";
import { getFileURL } from "../../app/api/file_api";
import { navigateNewTab, navigateSelf } from "../../app/router/routerManager";
import { submitFinalApplication } from "../../app/api/NOCU/applicationAPI";
import { useAlert } from "../../app/context/alerts";
import { renderAlert } from "../../Helpers/uiHelper";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { deleteStepper } from "./../../components/stepper/dynamicStepper";
import TabHome from "../../components/Tabs/TabHome";
import ReviewCheckList from "../../components/review/reviewCheckList";
import {
	appendRejectionsFieldVisit,
	appendRejectionsSingleForm,
} from "../certificationBody/dataManipulation";
import ViewMoreButton from "../../components/buttons/ViewMoreButton";

const FinalComments = () => {
	const [title, setTitle] = useState("Summery Report");
	const [detailsArray, setDetailsArray] = useState();
	const [nameArray, setNameArray] = useState();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [fieldVisitArray, setFieldVisitArray] = useState();
	const [formData, setFormData] = useState();
	const [files, _setFiles] = useState();
	const [response, setResponse] = useState();
	const [applicationDetails, setApplicationDetails] = useState();
	const { setAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(true);
	const [value, setValue] = React.useState(0);
	const [form, setForm] = useState();
	const [rejections, setRejections] = useState();

	const [_formData, _setFormData] = useState([
		{ name: "ch1", label: "Is ICS System Document Available ?", isChecked: true },
		{ name: "ch2", label: "Organic Inputs User ?", isChecked: true },
		{
			name: "ch3",
			label: "Seed plating and transplant records ...",
			isChecked: true,
		},
		{ name: "ch4", label: "Soil management activities", isChecked: true },
		{ name: "ch5", label: "Water use", isChecked: true },
		{
			name: "ch6",
			label: "Organic Integrity (Contamination Critical Control points",
			isChecked: true,
		},
		{ name: "ch7", label: "Wast Management", isChecked: true },
		{ name: "ch8", label: "Pest Management Activities", isChecked: true },
		{ name: "ch9", label: "Labels and labelling", isChecked: true },
		{ name: "ch10", label: "Storage", isChecked: true },
		{ name: "ch11", label: "Complain Record Available", isChecked: true },
	]);

	const [param, setParams] = useState({
		mappedApplicationNumber: getMappedApplicationNumber(),
		remark: "",
		reviewStatus: "APPROVED",
		checkedBy: getUserId(),
		applicationStage: "FINAL_COMMENT",
	});

	useEffect(() => {
		const mapApplicationKey = getMappedApplicationNumber();

		getMapApplicationDetails(mapApplicationKey)
			.then((res) => {
				setResponse(res.data);
				setDetailsArray(res.data.detailsResponseList);
				setFormData(res.data.detailsResponseList[currentIndex]);
				setFieldVisitArray(res.data.detailsResponseList[currentIndex].fieldVisitFileEntities);
				setApplicationDetails(
					res.data.detailsResponseList[currentIndex].stakeholderApplicationEntity
				);
				setStakeholderApplicationNumber(
					res.data.detailsResponseList[currentIndex].stakeholderApplicationEntity?.applicationNumber
				);
				setStakeholderType(
					res.data.detailsResponseList[currentIndex].stakeholderApplicationEntity?.stakeholderType
				);
				setRejections(res.data.detailsResponseList[currentIndex].fieldVisitReviewDetails);
				setIsLoading(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	useEffect(() => {
		_formData &&
			rejections &&
			appendRejectionsFieldVisit(rejections?.rejectReasonDetailList, _formData, _setFormData);
	}, [rejections]);

	const mapFieldVisit = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((item) => {
					return {
						name: item?.stakeholderApplicationEntity.applicationNumber,
						id: item?.stakeholderApplicationEntity.applicationNumber,
					};
			  })
			: null;
	};

	useEffect(() => {
		// Execute when updating
		setNameArray(mapFieldVisit(detailsArray));
	}, [detailsArray]);

	useEffect(() => {
		// Execute when updating
		let _files = [];
		fieldVisitArray?.map((item) =>
			_files.push({
				name: item?.name,
				path: getFileURL(item?.path),
			})
		);
		_setFiles(_files);
	}, [fieldVisitArray]);

	const renderImagePrivew = (data) => {
		return data?.length !== 0 ? (
			data?.map((item) => (
				<SectionColumn className='relative   hover:z-20  w-[150px] mx-4 my-4  hover:bg-gray-200 bg-gray-100 h-[200px] rounded-3xl shadow-lg text-center items-center justify-center overflow-clip'>
					<div className='absolute bg-white top-2 right-2'>
						<Tooltip title={"View New Tab"}>
							<OpenInNewIcon
								color='success'
								onClick={() => {
									navigateNewTab(item?.path);
								}}
							/>
						</Tooltip>
					</div>
					<SectionColumn
						//onClick={handleOpenImage(item?.path)}
						className='w-full h-[160px] items-center justify-center pt-5'>
						<div className=''>
							<iframe
								title='Test'
								allowFullScreen='allowFullScreen'
								frameborder={0}
								src={item?.path}
								width='110px'
								height='135px'
							/>
						</div>
					</SectionColumn>
					<SectionColumn className='w-full h-[40px] bg-white text-center text-xs justify-center'>
						<div>{item.name}</div>
					</SectionColumn>
				</SectionColumn>
			))
		) : (
			<div className='h-[450px]'>
				<div>No Field Visit Initiated</div>
			</div>
		);
	};

	const handleCertificate = (index) => {
		let detailArray = detailsArray;
		setFieldVisitArray(detailArray[index].fieldVisitFileEntities);
		setApplicationDetails(detailArray[index]?.stakeholderApplicationEntity);
		setCurrentIndex(index);
		setStakeholderApplicationNumber(
			detailArray[index]?.stakeholderApplicationEntity?.applicationNumber
		);
		setStakeholderType(detailArray[index]?.stakeholderApplicationEntity?.stakeholderType);
		setRejections(detailArray[index].fieldVisitReviewDetails);
		deleteStepper("FCView");
	};

	const renderPhotos = (data) => {
		console.log("Data ===========>", data);
		return (
			<SectionRow className='2xl:w-[700px]  justify-center  overflow-visible'>
				{renderImagePrivew(data)}
			</SectionRow>
		);
	};

	const handleSubmit = () => {
		setIsLoading(true);
		submitFinalApplication(param)
			.then((res) => {
				//deleteStepper("FCView");
				navigateSelf(FINAL_COMMENTS_ROUTES.HOME + "/1");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const navigateRoutes = () => {
		console.log("StakeholderType", getStakeholderType());
		if (getStakeholderType() === "CERTIFICATION_BODY") {
			navigateSelf(FINAL_COMMENTS_ROUTES.CBSTEP01);
		} else if (getStakeholderType() === "IMPORTER") {
			navigateSelf(FINAL_COMMENTS_ROUTES.IMSTEP01);
		} else if (getStakeholderType() === "FARMER") {
			navigateSelf(FINAL_COMMENTS_ROUTES.FRSTEP01);
		} else if (getStakeholderType() === "FARMER_ASSOCIATION") {
			navigateSelf(FINAL_COMMENTS_ROUTES.FASTEP01);
		} else if (getStakeholderType() === "LABORATORIES") {
			navigateSelf(FINAL_COMMENTS_ROUTES.LBSTEP01);
		} else if (getStakeholderType() === "EXPORTER") {
			navigateSelf(FINAL_COMMENTS_ROUTES.EXPORTER_STEP01);
		} else if (getStakeholderType() === "PROCESSOR") {
			navigateSelf(FINAL_COMMENTS_ROUTES.PROCESSOR_STEP01);
		} else if (getStakeholderType() === "TRADER") {
			navigateSelf(FINAL_COMMENTS_ROUTES.TRADER_STEP01);
		}
	};

	const renderCheckList = (_formData) => {
		return (
			<SectionColumn className='lg:h-[65vh] px-3 lg:overflow-y-auto customScrollBar'>
				{applicationDetails?.isReferFieldVisit ? (
					<ReviewCheckList
						readOnly={true}
						data={_formData}
						setForm={_setFormData}></ReviewCheckList>
				) : (
					<div className='w-full text-center'>No Field Visit Initiated</div>
				)}
			</SectionColumn>
		);
	};

	const componentArray = [
		{
			label: "Factory Visit Details",
			component: <div>{formData && renderCheckList(_formData)}</div>,
		},
		{
			label: "Factory Visit Photos",
			component: <div>{renderPhotos(files)}</div>,
		},
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				title={"Recommendations for Technical Committee"}
				tableIcon={DASHBOARD_ICONS.finalComments}
				tableURL={FINAL_COMMENTS_ROUTES.HOME}
				mappedApplicationNumber
			/>

			<FourContainersLayout
				topMiddle={
					<ToggleButtonSet data={nameArray} handleClick={handleCertificate} certificate={true} />
				}
				topLeft={
					<SectionColumn className='w-full items-center'>
						<div className='w-[350px] mt-2 h-[150px] shadow-md rounded-md bg-slate-100 border p-5'>
							<div className='w-full text-sm'>Field Visit remark :</div>
							<div className='text-gray-500  '>
								{applicationDetails?.fieldVisitRemark
									? applicationDetails?.fieldVisitRemark
									: "No comments"}
							</div>
						</div>
						<SectionRow className='w-full justify-center mt-5'>
							<TextareaAutosize
								className='border shadow-md rounded w-[350px] p-3'
								minRows={4}
								maxLength='255'
								placeholder='Add other remarks'
								onChange={(e) => setParams({ ...param, remark: e.target.value })}
							/>
						</SectionRow>
						<SectionRow className='w-full justify-center mt-32'>
							<ViewMoreButton
								name={"summaryReport"}
								title={"View More..."}
								handleClick={() => {
									deleteStepper("FCView");
									navigateRoutes();
								}}
							/>
							{/* <NextButton
								name={"factoryVisitPhotos"}
								title={"Factory Visit Photos"}
								handleClick={() => {
									setTitle("Factory Visit Photos");
									renderPhotos(fieldVisitArray);
								}}
							/> */}
							<AcceptButton name={"submit"} title='Submit' handleClick={handleSubmit} />
						</SectionRow>
						{/* <SectionRow className='w-full mt-8 justify-center'>
							<AcceptButton name={"submit"} title='Submit' handleClick={handleSubmit} />
						</SectionRow> */}
					</SectionColumn>
				}
				topRight={
					<SectionColumn className='w-full items-center z-30 pr-3'>
						{/* <div className='w-full text-center'>{title}</div>
						{title === "Factory Visit Photos" ? (
							renderPhotos(files)
						) : (
							<IFrameViewer path={getFileURL(response?.summeryReportPath)} files={true} />
						)} */}

						<TabHome componentArray={componentArray} />
					</SectionColumn>
				}
			/>
		</div>
	);
};

export default FinalComments;
