import React from "react";
import { Button } from "@mui/material";

function AcceptButton({ name, icon, handleClick, className, title, disabled }) {
	return (
		<Button
			disabled={disabled}
			name={name}
			variant={"contained"}
			className={` !px-4 !capitalize ${className}`}
			sx={{
				backgroundColor: "#20B05E",
				"&:hover": {
					backgroundColor: "#167D43",
					borderColor: "#167D43",
					boxShadow: "none",
				},
				width: 180,
				borderRadius: 3,
				mx: 2,
				py: 1,
			}}
			startIcon={icon}
			onClick={handleClick}>
			{title}
		</Button>
	);
}

export default AcceptButton;
