import React, { useState } from "react";
import { SectionColumn, SectionRow } from "../../components/section";
import TabCardSet from "./components/TabCardSet";
import { Skeleton, ThemeProvider, createTheme } from "@mui/material";

import UserSettingsUpdatePage from "./components/UserSettingsUpdatePage";
import ReassignPage from "./components/ReassignPage";
import MeetingModePage from "./components/MeetingModePage";
import PermissionPage from "./components/PermissionPage";
import UserDetailsCard from "./components/UserDetailsCard";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const UserManagementSettings = ({ userData }) => {
	const nocuTheme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: "#20B05E",
				contrastText: "rgba(255,255,255,0.87)",
			},
		},
	});
	const [selectedPage, setSelectedPage] = useState(0);
	const [refresh, setRefresh] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	return (
		<ThemeProvider theme={nocuTheme}>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='w-full pt-8 h-[90vh]'>
				<SectionColumn className='w-3/12  justify-start items-end px-4 border-r border-gray-200'>
					<SectionColumn className='w-full rounded-lg'>
						{userData && <UserDetailsCard userData={userData} />}
					</SectionColumn>
					<TabCardSet setSelectedPage={setSelectedPage} />
				</SectionColumn>
				{userData && (
					<SectionColumn className='w-9/12 px-8 '>
						{selectedPage === 0 ? (
							<UserSettingsUpdatePage userData={userData} setIsLoading={setIsLoading} />
						) : selectedPage === 1 ? (
							<ReassignPage
								userData={userData}
								refresh={refresh}
								handleRefresh={handleRefresh}
								setIsLoading={setIsLoading}
							/>
						) : selectedPage === 2 ? (
							<MeetingModePage
								userData={userData}
								refresh={refresh}
								handleRefresh={handleRefresh}
								setIsLoading={setIsLoading}
							/>
						) : selectedPage === 3 ? (
							<PermissionPage
								userData={userData}
								refresh={refresh}
								handleRefresh={handleRefresh}
								setIsLoading={setIsLoading}
							/>
						) : (
							<UserSettingsUpdatePage userData={userData} setIsLoading={setIsLoading} />
						)}
					</SectionColumn>
				)}
			</SectionRow>
		</ThemeProvider>
	);
};

export default UserManagementSettings;
