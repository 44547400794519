export const getTableRecord = (data) => {
    return data && Array.isArray(data) && data.map((row) => {
        return [
            row?.fullName,
            row?.designation,
            row?.contactNo,
            row?.email,
            row?.userStatus,

        ];
    });
};