import { Route } from "react-router-dom";
import { useEffect, useState } from "react";
import {
	createStepper,
	disableAlert,
	STEPPER_STATUS,
} from "../../../components/stepper/dynamicStepper";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import NavBar from "../../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";
import { ISSUE_ROUTES } from "../../../app/router/routes";
import FarmerReviewStep1 from "../../farmer/review/flow/FarmerReviewStep1";
import FarmerReviewStep3 from "../../farmer/review/flow/FarmerReviewStep3";
import FarmerReviewStep4 from "../../farmer/review/flow/FarmerReviewStep4";
import { SectionColumn } from "../../../components/section";

export const stepperName = "FCView";

const SignFarmerView = () => {
	const stepper = [
		{
			step: "Business Details",
			status: STEPPER_STATUS.COMPLETED,
		},

		{
			step: "Certificates",
			status: STEPPER_STATUS.COMPLETED,
		},
		{ step: "Other Documents", status: STEPPER_STATUS.COMPLETED },
	];
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setStepperData(createStepper(stepperName, stepper));
		disableAlert();
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}, []);

	const routeArray = [ISSUE_ROUTES.FRSTEP01, ISSUE_ROUTES.FRSTEP02, ISSUE_ROUTES.FRSTEP03];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Signature"}
				tableIcon={DASHBOARD_ICONS.finalComments}
				tableURL={ISSUE_ROUTES.REVIEW}
			/>

			{stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={ISSUE_ROUTES.FRSTEP01}>
						<FarmerReviewStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={0}
							nextCustomRoute={ISSUE_ROUTES.FRSTEP02}
							readOnly
							hideFiles={true}
						/>
					</Route>

					<Route path={ISSUE_ROUTES.FRSTEP02}>
						<FarmerReviewStep3
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={1}
							nextCustomRoute={ISSUE_ROUTES.FRSTEP03}
							readOnly
						/>
					</Route>
					<Route path={ISSUE_ROUTES.FRSTEP03}>
						<FarmerReviewStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							customIndex={2}
							nextCustomRoute={ISSUE_ROUTES.REVIEW}
							readOnly
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default SignFarmerView;
