export const STAT_ICON_PATHS = {
	stat_checkMark: "/icons/checkmark--v1.png",
	stat_timer: "/icons/timer.png",
	stat_cancelMark: "/icons/cancel--v1.png",
	stat_resubmit: "/icons/submit-progress.png",
	stat_new_req: "/icons/new-company.png",
	stat_deleteMark: "/icons/delete-sign.png",
	stat_cancelation: "/icons/cancel-subscription.png",
	stat_duplication: "/icons/duplicate.png",
	stat_upload_verification: "/icons/upload-document.png",
	stat_clarification: "/icons/note.png",
	stat_eligibility_check: "/icons/todo-list--v1.png",
	stat_general_inquiry: "/icons/info--v1.png",
	stat_certificate: "/icons/certificate.png",
	stat_cost_statement:
		"/icons/external-statement-banking-and-finance-smashingstocks-detailed-outline-smashing-stocks.png",
};

export const ICON_PATHS = {
	rounded_check_mark: "/icons/util_icons/checkmark.png",
	rounded_cancel_mark: "/icons/util_icons/icons8-cancel-24.png",
	clarification: "/icons/util_icons/ask-question.png",
	modal_close: "/icons/util_icons/cancel--v1.png",
	history_64: "/icons/util_icons/external-ic_history-menu-outline-lafs.png",
	logo: "/SL_Logo.png",
	addIcon: "/icons/add.png",
	trash: "/icons/trash-cane.png",
};

export const STEPPER_ICON_PATHS = {
	check: "/icons/stepper_icons/checkmark.png",
	reject: "/icons/stepper_icons/delete-sign.png",
	current: "/icons/stepper_icons/clock--v1.png",
	lock: "/icons/stepper_icons/lock--v1.png",
};

export const LOADERS = {
	tableLoader: "/icons/loadingIcons/loadingGif.gif",
};

export const STAT_ACCENT_COLORS = {
	bluishGray: "#E6E9FF",
	yellow: "#FFF5D2",
	lightGreen: "#E3FEF3",
	red: "#ffcdd2",
	orange: "#ffccbc",
	blue: "#b3e5fc",
	darkGreen: "#bbffcf",
};

export const APP_COLORS = {
	green_dark: "#20b15e",
	green_light: "#dcfce7",
	white: "#ffffff"
};

export const setStakeholderTypes = {
	CERTIFICATION_BODY: "CERTIFICATION_BODY",
	FARMER_ASSOCIATION: "FARMER_ASSOCIATION",
	FARMER: "FARMER",
	LABORATORIES: "LABORATORIES",
	EXPORTER: "EXPORTER",
	PROCESSOR: "PROCESSOR",
	TRADER: "TRADER"
};

export const STATUS = {
	PENDING: "PENDING",
	APPROVED: "APPROVED",
	RESUBMIT: "RESUBMIT",
	REJECTED: "REJECTED",
};

export const STAKEHOLDERS = [
	{ key: "All", value: "ALL" },
	{ key: "Certification Body", value: "CERTIFICATION_BODY" },
	{ key: "Farmer", value: "FARMER" },
	{ key: "Farmer Association", value: "FARMER_ASSOCIATION" },
	{ key: "Laboratories", value: "LABORATORIES" },
	{ key: "Exporter", value: "EXPORTER" },
	{ key: "Importer", value: "IMPORTER" },
	{ key: "Trader", value: "TRADER" },
	{ key: "Processor", value: "PROCESSOR" },

];

export const TABS = {
	OVERVIEW: "OVERVIEW",
	CERTIFICATION_BODY: "CERTIFICATION_BODY",
	LABORATORIES: "LABORATORIES",
	FARMER: "FARMER",
	FARMER_ASSOCIATION: "FARMER_ASSOCIATION",
	EXPORTER: "EXPORTER",
	IMPORTER: "IMPORTER",
	TRADER: "TRADER",
	PROCESSOR: "PROCESSOR",
	PRODUCT_CATEGORY_REVIEW: "PRODUCT_CATEGORY_REVIEW",
	PAYMENT: "PAYMENT",
	SLAAB: "SLAAB",
	FIELD_VISIT: "FIELD_VISIT",
	FINAL_COMMENT: "FINAL_COMMENT",
	TECHNICAL_COMMITTEE: "TECHNICAL_COMMITTEE",
	FINAL_VOTE: "FINAL_VOTE",
	ISSUE: "ISSUE",
	INQUIRY: "INQUIRY",
	CERTIFICATE_UPLOAD: "CERTIFICATE_UPLOAD",
	USER_MANAGEMENT: "USER_MANAGEMENT",
	STAKEHOLDERS_MANAGEMENT: "STAKEHOLDERS_MANAGEMENT",
	REPORT_GENERATION: "REPORT_GENERATION",
	SUMMARY: "SUMMARY",
	PROFILE: "PROFILE",
	LOG_OUT: "LOG_OUT",
};

export const monthOptions = [
	{ value: "01-01|01-31", key: "January", },
	{ value: "02-01|02-28", key: "February" },
	{ value: "03-01|03-31", key: "March" },
	{ value: "04-01|04-30", key: "April" },
	{ value: "05-01|05-31", key: "May" },
	{ value: "06-01|06-30", key: "June" },
	{ value: "07-01|07-31", key: "July" },
	{ value: "08-01|08-31", key: "August" },
	{ value: "09-01|09-30", key: "September" },
	{ value: "10-01|10-31", key: "October" },
	{ value: "11-01|11-30", key: "November" },
	{ value: "12-01|12-31", key: "December" },
];
