import React, { useEffect, useState } from "react";
import { SectionColumn, SectionRow } from "../../layouts/sections";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import ReviewFormWithSwitch from "../../components/review/reviewFormWithSwitch";
import ToggleButtonSet from "../../components/buttons/ToggleButtonSet";
import HomeTable from "../../components/table/HomeTable";
import RejectModel, {
	CERTIFICATION_BODY_REJECT_REASONS,
} from "./../../components/model/RejectModel";
import { Button } from "@mui/material";

const formData = [
	{ name: "name1", label: "Test Field 1", value: "Test Value 1", isChecked: true, comment: "" },
	{ name: "name2", label: "Test Field 2", value: "Test Value 2", isChecked: true, comment: "" },
	{ name: "name3", label: "Test Field 3", value: "Test Value 3", isChecked: true, comment: "" },
	{ name: "name4", label: "Test Field 4", value: "Test Value 4", isChecked: true, comment: "" },
];
const tableHeaders = [
	"Header 1",
	"Header 2",
	"Header 3",
	"Header 4",
	"Header 5",
	"Header 6",
	"Header 7",
];

const rowsData = [
	["Cell 1", "Cell 2", "Cell 3", "Cell 4", "Cell 5", "Cell 6", "Cell 7"],
	["Cell 1", "Cell 2", "Cell 3", "Cell 4", "Cell 5", "Cell 6", "Cell 7"],
	["Cell 1", "Cell 2", "Cell 3", "Cell 4", "Cell 5", "Cell 6", "Cell 7"],
	["Cell 1", "Cell 2", "Cell 3", "Cell 4", "Cell 5", "Cell 6", "Cell 7"],
];

const viewFunction = (index) => {
	console.log("Index Of record=================>", index);
	console.log("Record=================>", rowsData[index]);
};
const historyFunction = (index) => {
	console.log("Index Of record=================>", index);
};

const TempPage = () => {
	const [form, setForm] = useState(formData);

	useEffect(() => {
		console.table(form);
	}, [form]);
	return (
		<div>
			<SectionRow>
				<SectionColumn className='w-full lg:w-1/2 items-center justify-center'>
					<ReviewFormWithSwitch data={form} setForm={setForm}>
						Test
					</ReviewFormWithSwitch>
					<Button onClick={() => console.table(form)}>Get Values</Button>
				</SectionColumn>
				<SectionColumn className='w-full lg:w-1/2 items-center justify-center'>
					<IFrameViewer path={""} files={true} />
				</SectionColumn>
			</SectionRow>
			<SectionRow className='w-full justify-center'>
				<HomeTable
					columns={tableHeaders}
					rows={rowsData}
					view={viewFunction}
					history={historyFunction}
				/>
			</SectionRow>
			<SectionRow className='w-full'>
				<RejectModel
					title='Test Doc'
					reasonsType={CERTIFICATION_BODY_REJECT_REASONS}
					handleReject={() => {}}
					button={<Button>Open Model</Button>}
				/>
			</SectionRow>
		</div>
	);
};

export default TempPage;
