import { Button, Stack } from "@mui/material";
import React from "react";

const TopButtonArray = ({ nameArray, statusArray, selectedPage, setSelectedPage }) => {
	const setColor = (index) => {
		const reviewStatus = statusArray[index]?.reviewStatus;
		return reviewStatus === "APPROVED"
			? "primary"
			: reviewStatus === "RESUBMIT"
			? "warning"
			: reviewStatus === "RESUBMITTED"
			? "warning"
			: reviewStatus === "PENDING"
			? "info"
			: reviewStatus === "REJECTED"
			? "error"
			: "primary";
	};

	const renderButtonStack = () => {
		return nameArray.map((btn, index) => {
			return (
				<Button
					onClick={() => {
						setSelectedPage(index);
					}}
					color={setColor(index)}
					variant={selectedPage === index ? "contained" : "text"}>
					{btn}
				</Button>
			);
		});
	};

	return (
		<Stack direction={"row"} spacing={2}>
			{statusArray && renderButtonStack()}
		</Stack>
	);
};

export default TopButtonArray;
