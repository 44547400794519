import React, { useEffect, useState } from "react";
import HomeTable from "../../components/table/HomeTable";
import { SectionRow } from "./../../layouts/sections/index";
import StatCard from "./../../components/cards/statCard";
import {
	STAT_ACCENT_COLORS,
	STAT_ICON_PATHS,
	setStakeholderTypes,
	STAKEHOLDERS,
} from "./../../app/constants";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { navigateNewTab, navigateSelf } from "../../app/router/routerManager";
import { CERTIFICATION_BODY_ROUTES, SLAAB_ROUTES } from "../../app/router/routes";
import { deleteStepper } from "./../../components/stepper/dynamicStepper";

import { setApplicationStage, showViewIcon } from "../../components/table/tableHelpers";
import { getTableCounts, getTableRecords } from "./../../app/api/NOCU/tableAPI";

import { setStakeholderType } from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getUserId } from "./../../app/api/NOCU/helpers/applicationApiHelpers";
// import { getTableData } from "../certificationBody/dataManipulation";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { deleteIsRenewal, setIsRenewal } from "../../components/navbar/helpers";
import { getTableData } from "./dataManipualtion";

const SLAABHome = () => {
	const { id } = useParams();
	const [records, setRecords] = useState();
	const [res, setRes] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [counts, setCounts] = useState({
		pendingCount: 0,
		approvedCount: 0,
		rejectedCount: 0,
		resubmissionCount: 0,
	});
	const [selectedCount, setSelectedCount] = useState();
	const [selectedTitle, setSelectedTitle] = useState("New Requests");
	const [tableCount, setTableCount] = useState();

	const tableHeaders = ["Name", "Application No.", "Date & Time", "Type"];
	// const rowsData = [
	// 	[
	// 		"Coco Tech",
	// 		"CER32627703",
	// 		"BT5678",
	// 		"Export",
	// 		"cocotech@gmail.com",
	// 		"Suran Mendis 0778962383",
	// 		"02-12-2022 13.23",
	// 	],
	// 	[
	// 		"Bpoi Trades",
	// 		"CER32627707",
	// 		"BT5324",
	// 		"Import",
	// 		"bophi@gmail.com",
	// 		"Sudantha Perera 0778962383",
	// 		"02-12-2022 13.23",
	// 	],
	// 	[
	// 		"Coco Tech",
	// 		"CER32627708",
	// 		"BT5678",
	// 		"Export",
	// 		"cocotech@gmail.com",
	// 		"Suran Mendis 0778962383",
	// 		"02-12-2022 13.23",
	// 	],
	// 	[
	// 		"Coco Tech",
	// 		"CER32627709",
	// 		"BT5678",
	// 		"Export",
	// 		"cocotech@gmail.com",
	// 		"Suran Mendis 0778962383",
	// 		"02-12-2022 13.23",
	// 	],
	// ];
	let filters = [
		{ key: "PENDING", value: "PENDING" },
		{ key: "SUCCESS", value: "SUCCESS" },
		{ key: "SUSPENDED", value: "SUSPENDED" },
		{ key: "APPROVED", value: "APPROVED" },
		{ key: "REJECTED", value: "REJECTED" },
	];
	let sorting = [
		{ key: "Acceding", value: true },
		{ key: "Descending", value: false },
	];
	const [selectedStatCard, setSelectedStatCard] = useState(1);
	const [params, setParams] = useState({
		status: "",
		isAscending: true,
		page: 0,
		size: 5,
		officerId: getUserId(),
		stakeholderType: "ALL",
		applicationStage: "SLAAB",
		applicationNumber: "",
	});

	const [searchParams, setSearchParams] = useState({
		status: "",
		isAscending: true,
		page: 0,
		size: 5,
		officerId: getUserId(),
		stakeholderType: "ALL",
		applicationStage: "SLAAB",
		applicationNumber: "",
		district: "",
		divisionalSecretariat: "",
		gramaNiladhariDivision: "",
	});

	useEffect(() => {
		setSelectedStatCard(parseInt(id));
		if (parseInt(id) === 1) {
			setParams({ ...params, status: "PENDING" });
			setSearchParams({ ...searchParams, status: "PENDING" });
			setSelectedCount(counts.pendingCount);
			setSelectedTitle("Pending Requests");
		} else if (parseInt(id) === 2) {
			setParams({ ...params, status: "RESUBMIT" });
			setSearchParams({ ...searchParams, status: "RESUBMIT" });
			setSelectedCount(counts.resubmissionCount);
			setSelectedTitle("Resubmitted Requests");
		} else if (parseInt(id) === 3) {
			setParams({ ...params, status: "APPROVED", isAscending: false });
			setSearchParams({ ...searchParams, status: "APPROVED", isAscending: false });
			setSelectedCount(counts.approvedCount);
			setSelectedTitle("Completed Requests");
		} else if (parseInt(id) === 4) {
			setParams({ ...params, status: "REJECTED" });
			setSearchParams({ ...searchParams, status: "REJECTED" });
			setSelectedCount(counts.rejectedCount);
			setSelectedTitle("Rejected Requests");
		}
	}, [id]);
	const handleFilter = (filter) => {
		setParams({ ...params, status: filter });
	};

	const handleStakeHolderType = (filter) => {
		setParams({ ...params, stakeholderType: filter });
		setSearchParams({ ...searchParams, stakeholderType: filter });
	};

	const handleApplicationID = (filter) => {
		setSearchParams({ ...searchParams, applicationNumber: filter });
	};

	const handleSearchById = (id) => {
		setParams({ ...params, applicationNumber: id });
	};

	const handleSorting = (isAscending) => {
		setParams({ ...params, isAscending: isAscending });
	};
	const handlePage = (page) => {
		//setSearchParams({ ...searchParams, page:page });
		setParams({ ...params, page: page });
		//handleSearchParams(page, params.size);
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size, page: 0 });
		//setSearchParams({ ...searchParams, size: size });
		//handleSearchParams(0, size);
	};

	const viewFunction = (index) => {
		console.log("Index Of record=================>", res.applicationList[index]?.stakeholderType);
		setStakeholderType(res.applicationList[index]?.stakeholderType);
		setIsRenewal(res.applicationList[index]?.isRenewal);
		setApplicationStage(params.applicationStage);
		navigateSelf(SLAAB_ROUTES.REVIEW);
		// deleteStepper();
		// if (id === "1") {
		// 	navigateSelf(CERTIFICATION_BODY_ROUTES.STEP01);
		// } else if (id === "2") {
		// 	navigateSelf(CERTIFICATION_BODY_ROUTES.STEP01);
		// } else if (id === "4") {
		// 	navigateSelf(CERTIFICATION_BODY_ROUTES.FIELD);
		// } else if (id === "7") {
		// 	navigateSelf(CERTIFICATION_BODY_ROUTES.FINAL_COMMENTS);
		// } else {
		// 	return null;
		// }
	};
	const historyFunction = (index) => {
		console.log("Index Of record=================>", index);
	};

	useEffect(() => {
		deleteIsRenewal();
		const cParams = {
			officerId: getUserId(),
			stakeholderType: params.stakeholderType,
			applicationNumber: params.applicationNumber,
			applicationStage: "SLAAB",
		};
		//setStakeholderType(setStakeholderTypes.CERTIFICATION_BODY);
		// setStakeholderType(params.stakeholderType);
		getTableCounts(cParams).then((res) => {
			setCounts(res);
		});
	}, []);

	useEffect(() => {
		!!!selectedCount && setSelectedCount(counts.pendingCount);
	}, [counts]);

	useEffect(() => {
		console.log("Params ====>", params);
		if (params.status && params.status !== "") {
			getTableRecords(params)
				.then((res) => {
					setRes(res.data);
					setRecords(getTableData(res.data));
					setTableCount(res?.data.count);
				})
				.then((e) => console.log(e))
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [params]);

	const handleSearchParams = async (page, size) => {
		let _searchParam = searchParams;
		_searchParam.page = page;
		_searchParam.size = size;
		getTableRecords(_searchParam)
			.then((res) => {
				console.log("Res ======> ", getTableData(res.data));
				setRecords(getTableData(res.data));
			})
			.then((e) => console.log(e));
	};

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />

			<SectionRow className='mt-0'>
				<span className='overViewTitle'>SLAB</span>
			</SectionRow>
			<SectionRow className='w-full'>
				<SectionRow className='w-full overflow-x-auto mb-5 customScrollBar'>
					<SectionRow className='w-full !min-w-max mb-2 justify-center items-center'>
						<StatCard
							value={counts?.pendingCount}
							text={"Pending SLAB Requests"}
							image={STAT_ICON_PATHS.stat_resubmit}
							accent={STAT_ACCENT_COLORS.yellow}
							handleStatCard={() => { }}
							num={1}
							selected={selectedStatCard}
							setSelectStatCard={setSelectedStatCard}
						/>
						<StatCard
							value={counts?.approvedCount}
							text={"Completed SLAB Requests"}
							image={STAT_ICON_PATHS.stat_checkMark}
							accent={STAT_ACCENT_COLORS.lightGreen}
							handleStatCard={() => { }}
							num={3}
							selected={selectedStatCard}
							setSelectStatCard={setSelectedStatCard}
						/>
					</SectionRow>
				</SectionRow>
			</SectionRow>

			<HomeTable
				applicationKey={1}
				columns={tableHeaders}
				filterTitle={"Stakeholder"}
				// tableTitle={selectedTitle}
				handleSearchById={handleSearchById}
				rows={records}
				view={showViewIcon(["1"], id) ? viewFunction : null}
				// history={historyFunction}
				filters={STAKEHOLDERS}
				setFilter={handleStakeHolderType}
				// sort={sorting}
				handleSort={handleSorting}
				handleSize={handleSize}
				count={tableCount}
				page={params.page}
				size={params.size}
				handlePage={handlePage}
			/>
		</div>
	);
};

export default SLAABHome;
