import React, { useRef } from "react";
import { FaUpload } from "react-icons/fa";

const FileUploader = ({ handleFile, tittle, fileType }) => {
	const fileInputRef = useRef(null);

	const handleFileInput = (e) => {
		handleFile(e.target.files[0]);
	};

	return (
		<div className='file-uploader mx-4'>
			<label htmlFor='file-upload' className='file-upload-label'>
				<div className='mr-2'>
					<FaUpload className='download-icon' />
				</div>
				{tittle}
			</label>
			<input
				ref={fileInputRef}
				id='file-upload'
				type='file'
				accept={fileType}
				onChange={handleFileInput}
			/>
		</div>
	);
};

export default FileUploader;
