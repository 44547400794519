import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const getUserProfileRecordsByStatus = async (status, params) => {
    console.log("status ", status);
    return CustomAxios({
       method: "GET",
       baseURL: API_BASE_URL,
       url: `/account/find-by/${status}`,
       headers: {},
       params: params,
    })
        .then((res) => {
            console.log("Get by user status (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get by user status (error) ===>", error);
        });
};

export const getUserDetailsByID = async (id) => {
    console.log("id", id);
    return CustomAxios({
        method: "GET",
        baseURL: API_BASE_URL,
        url: `/account/find-by-id/${id}`,
        headers: {},
        params: {}
    })
        .then((res) => {
            console.log("Get user details by ID (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Get user details by ID (error) ===>", error);
        });
};

export const updateDocUserStatus = async (id, data) => {
    console.log("id", id);
    return CustomAxios({
        method: "PUT",
        baseURL: API_BASE_URL,
        url: `/doc-user/doc/update-status/${id}`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("Update Doc User Status By ID (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Update Doc User Status By ID (error) ===>", error);
        });
};

export const updateUserStatus = async (id, data) => {
    console.log("id", id);
    return CustomAxios({
        method: "PUT",
        baseURL: API_BASE_URL,
        url: `/account/update-status/${id}`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("Update User Status By ID (res) ===>", res);
            return res;
        })
        .catch((error) => {
            console.log("Update User Status By ID (error) ===>", error);
        });
};
