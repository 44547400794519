import { Button, Tooltip } from "@mui/material";
import React, { Component, useState } from "react";
import { useEffect } from "react";
import { getExtension } from "../../Helpers/uiHelper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { navigateNewTab } from "../../app/router/routerManager";

// const initPath = "https://www.ipsgwalior.org/download/number%20system.pdf";
const initPath = "/pdf/init.pdf";
const noPath = "/pdf/document-not-found.pdf";

export default function IFrameViewer({ files, path, ext }) {
	const [position, setPosition] = useState("static");
	const [width, setWidth] = useState("600px");
	const [height, setHeight] = useState("550px");
	const [isFull, setIsFull] = useState(false);
	const [file, setFile] = useState();
	const [style, setStyle] = useState({
		width: "500px",
		height: "550px",
		left: 0,
		top: 0,
		borderRadius: "20px",
		marginBottom: "10px",
		border: "2px solid #444",
		zIndex: "5",
		position: "static",
		backgroundColor: "#ffffff",
		overflow: "hidden",
	});

	const handleZoomIN = () => {
		setWidth("1000px");
		setHeight("1000px");
	};
	const viewFullScreen = () => {
		setIsFull(true);
		setStyle({
			width: "100vw",
			height: "100vh",
			left: 0,
			top: 0,
			borderRadius: "0px",
			marginBottom: "10px",
			border: "2px solid #444",
			zIndex: "5",
			position: "fixed",
			backgroundColor: "#525659",
			overflow: "auto",
		});
	};
	const exitFullScreen = () => {
		setIsFull(false);
		setStyle({
			width: "500px",
			height: "550px",
			left: 0,
			top: 0,
			borderRadius: "20px",
			marginBottom: "10px",
			border: "2px solid #444",
			zIndex: "5",
			position: "static",
			backgroundColor: "#ffffff",
			overflow: "hidden",
		});
	};

	const handleZoomOUT = () => {
		setWidth("800px");
		setHeight("700px");
	};

	const handleFullView = () => {
		setIsFull(!isFull);
	};
	const handleOpenNewTab = () => {
		window.open(path, "_blank");
	};

	useEffect(() => {
		setFile(path);
	}, [ext, path]);

	return (
		<>
			<div className='z-0 flex flex-col items-center'>
				<div className='flex justify-center items-center'>
					{/* <Button onClick={viewFullScreen}>Full Screen</Button> */}
					<Tooltip title='Open Document in Fullscreen'>
						<FullscreenIcon
							onClick={viewFullScreen}
							sx={{ width: 29, height: 29, mx: 1 }}
							color='primary'
						/>
					</Tooltip>
					{path && (
						<Tooltip title='Open Document in new tab'>
							<OpenInNewIcon onClick={handleOpenNewTab} sx={{ mx: 1 }} color='primary' />
						</Tooltip>
					)}
				</div>
				{isFull && (
					<div style={{ position: "fixed", right: 130, top: 10, zIndex: 20 }}>
						<Button onClick={exitFullScreen} variant='contained' color='error'>
							Exit Full Screen
						</Button>
					</div>
				)}
				<iframe
					title='Test'
					allowFullScreen='allowFullScreen'
					frameBorder='0'
					src={!files || files?.length === 0 ? noPath : file || initPath}
					style={style}
				// style={{overflow:"hidden"}}
				/>
			</div>
		</>
	);
}
