import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function FullScreenDialog({ button, title, children, onClose }) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		onClose && onClose();
	};

	return (
		<div>
			<div onClick={handleClickOpen}>{button}</div>

			<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<AppBar sx={{ position: "relative", backgroundColor: "#20b15e", zIndex: "0" }}>
					<Toolbar>
						<Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
							{title}
						</Typography>
						<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<div className='!z-10'>{children}</div>
			</Dialog>
		</div>
	);
}
