import React, { useEffect, useState } from "react";
import ReviewWrapperNOCU from "../../../../components/wrappers/reviewWrapper";
import ToggleButtonSet from "../../../../components/buttons/ToggleButtonSet";
import { APPROVED, DEFAULT } from "../../../../Helpers/constants";
import { navigateSelf } from "../../../../app/router/routerManager";
import { PROCESSOR_ROUTES } from "../../../../app/router/routes";
import DynamicStepper from "../../../../components/stepper/dynamicStepper";

import FileButtonSet from "../../../../components/buttons/FileButtonSet";
import { getFileURL } from "../../../../app/api/file_api";
import {
	getApplicationDetails,
	submitApplicationDetails,
} from "../../../../app/api/NOCU/applicationAPI";
import {
	deleteAllApplicationRejectDetails,
	getApplicationRejectDetailsAll,
	setAllRejections,
} from "../../../../Helpers/rejectionHelpers";
import { STATUS } from "../../../../app/constants";
import {
	appendRejectionsMultipleFiles,
	appendRejectionsMultipleForms,
	validateMultiArray,
} from "../dataManipulation";
import { getRejctionsByStep } from "../../../../app/api/NOCU/rejectionAPI";
import { getApplicationStage } from "../../../../components/table/tableHelpers";
import SourceOfProducts from "../components/SourceOfProducts";
import { SectionColumn } from "../../../../layouts/sections";

const ProcessorStep4 = ({
	stepperData,
	stepperName,
	routeArray,
	setIsLoading,
	nextCustomRoute,
	customIndex,
	readOnly,
	hideBtns,
}) => {
	const [managementPersonnelDetails, setManagementPersonnelDetails] = useState();
	const [sopData, setSopData] = useState();
	const [detailsArray, setDetailsArray] = useState();
	const [nameArray, setNameArray] = useState();
	const [fileArray, setFileArray] = useState();
	const [currentFiles, setCurrentFiles] = useState();
	const [entityIdsArray, setEntityIdsArray] = useState();
	const [currentEntityId, setCurrentEntityId] = useState();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [rejections, setRejections] = useState();
	const [isDisabled, setDisabled] = useState(true);
	const [reviewStatus, setReviewStatus] = useState();
	const [canApprove, setCanApprove] = useState(false);

	useEffect(() => {
		deleteAllApplicationRejectDetails();
		getApplicationDetails(6)
			.then((res) => {
				console.log(res);
				setReviewStatus(res?.stepStatus);
				setManagementPersonnelDetails(res?.companyManagementRequest);
				setSopData(res?.sourceOfProductRequest);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				getRejctionsByStep(6)
					.then((res) => {
						setRejections(res.data);
						console.log("Rejection ======>", res);
					})
					.then((e) => {
						console.log(e);
					})
					.finally(() => {
						setIsLoading(false);
					});
			});
	}, []);
	useEffect(() => {
		setAllRejections(rejections?.rejectReasonDetailList || []);
	}, [rejections]);
	useEffect(() => {
		detailsArray &&
			rejections &&
			appendRejectionsMultipleForms(
				getApplicationRejectDetailsAll(),
				detailsArray,
				setDetailsArray,
				currentEntityId,
				currentIndex
			);
		fileArray &&
			rejections &&
			appendRejectionsMultipleFiles(
				getApplicationRejectDetailsAll(),
				fileArray,
				setFileArray,
				currentEntityId,
				currentIndex
			);
		detailsArray && setFormData(detailsArray[currentIndex]);
		fileArray && setCurrentFiles(fileArray[currentIndex]);
	}, [rejections, currentIndex]);

	const [_files, _setFiles] = useState([
		{
			name: "NIC Front",
			path: "f8dc76f1-f890-4dd5-b0b0-490df6c8311b",
			status: DEFAULT,
		},
		{
			name: "NIC Back",
			path: "f8db8078-793c-4e2a-95ff-4298bd4b241c",
			status: DEFAULT,
		},
	]);
	const [selectedFile, setSelectedFile] = useState();

	const initialFormaData = [
		{ name: "name", label: "Name", value: "---", isChecked: true },
		{ name: "email", label: "Email", value: "---", isChecked: true },
		{
			name: "designation",
			label: "Designation",
			value: "---",
			isChecked: true,
			disable: false,
		},
		{
			name: "address",
			label: "Address",
			value: "---",
			isChecked: true,
			disable: false,
		},
		{
			name: "mobileNumber",
			label: "Mobile Number",
			value: "---",
			isChecked: true,
			disable: false,
		},
		{
			name: "telephoneNumber",
			label: "Telephone Number",
			value: "---",
			isChecked: true,
			disable: false,
		},
		{
			name: "nationality",
			label: "Nationality",
			value: "---",
			isChecked: true,
			disable: true,
		},
		{
			name: "documentType",
			label: "Document Type",
			value: "---",
			isChecked: true,
			disable: true,
		},
		{
			name: "documentNumber",
			label: "Document Number",
			value: "---",
			isChecked: true,
			disable: false,
		},
	];
	const [formData, setFormData] = useState(initialFormaData);
	const handlePerson = (index) => {
		let personArray = detailsArray;
		handleResubmitDisable(personArray[index], fileArray[index]);
		setFormData(personArray[index]);
		setCurrentFiles(fileArray[index]);
		setCurrentEntityId(entityIdsArray[index]);
		setCurrentIndex(index);
		console.log("Index", index);
	};

	const mapNameArray = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((person) => {
					return { name: person?.name };
			  })
			: null;
	};

	const mapContactPersons = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((person) => {
					return [
						{
							name: "name",
							label: "Name",
							value: person?.name,
							isChecked: true,
							disable: false,
						},
						{
							name: "email",
							label: "Email",
							value: person?.email,
							isChecked: true,
							disable: false,
						},
						{
							name: "designation",
							label: "Designation",
							value: person?.designation,
							isChecked: true,
							disable: false,
						},
						{
							name: "address",
							label: "Address",
							value: person?.address,
							isChecked: true,
							disable: false,
						},

						{
							name: "mobileNumber",
							label: "Mobile Number",
							value: person?.mobileNumber,
							isChecked: true,
							disable: false,
						},
						{
							name: "telephoneNumber",
							label: "Telephone Number",
							value: person?.telephoneNumber,
							isChecked: true,
							disable: false,
						},
						{
							name: "nationality",
							label: "Nationality",
							value: person?.nationality,
							isChecked: true,
							disable: true,
						},
						{
							name: "country",
							label: "Country",
							value: person?.country,
							isChecked: true,
							disable: true,
						},
						{
							name: "documentType",
							label: "Document Type",
							value: person?.documentType,
							isChecked: true,
							disable: true,
						},
						{
							name: "documentNumber",
							label: "Document Number",
							value: person?.documentNo,
							isChecked: true,
							disable: false,
						},
					];
			  })
			: null;
	};

	const mapFilesArray = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((person) => {
					return [
						{
							name: person?.documentType === "NIC" ? "NIC" : "Passport",
							path: getFileURL(person?.documentFilePath),
							status: reviewStatus === "PENDING" ? DEFAULT : APPROVED,
						},
					];
			  })
			: null;
	};

	const mapEntityIdsArray = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((person) => {
					return person?.id;
			  })
			: null;
	};

	useEffect(() => {
		// Execute when updating
		setDetailsArray(mapContactPersons(managementPersonnelDetails));
		setNameArray(mapNameArray(managementPersonnelDetails));
		setFileArray(mapFilesArray(managementPersonnelDetails));
		setEntityIdsArray(mapEntityIdsArray(managementPersonnelDetails));
	}, [managementPersonnelDetails]);

	useEffect(() => {
		entityIdsArray && setCurrentEntityId(entityIdsArray[0]);
	}, [entityIdsArray]);

	useEffect(() => {
		fileArray && validateMultiArray(fileArray) ? setCanApprove(true) : setCanApprove(false);
		console.log("Files ============>", fileArray);
	}, [fileArray]);
	useEffect(() => {
		console.log("Selected File ============>", selectedFile?.path);
	}, [selectedFile]);

	const updateFileArray = (updatedFiles) => {
		let _filesArray = fileArray;
		if (_filesArray) {
			_filesArray[currentIndex] = updatedFiles;
		}
		setFileArray(_filesArray);
		console.log(_filesArray);
	};

	const handleNext = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(PROCESSOR_ROUTES.STEP05);
	};
	const handleCUstomNext = (route) => {
		navigateSelf(route);
	};
	const closeApplication = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(PROCESSOR_ROUTES.HOME + "/1");
	};
	const handleResubmit = (comments) => {
		setIsLoading(true);
		console.log("Resubmit Comments", comments);
		submitApplicationDetails(
			6,
			STATUS.RESUBMIT,
			comments,
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleSubmit = () => {
		setIsLoading(true);
		submitApplicationDetails(
			6,
			STATUS.APPROVED,
			"",
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleReject = (comments) => {
		setIsLoading(true);
		console.log("Reject Comments", comments);
		submitApplicationDetails(
			6,
			STATUS.REJECTED,
			comments,
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				setTimeout(() => {
					console.log("Waiting");
				}, 2000);
				closeApplication();
			})
			.catch((e) => console.log(e));
	};

	const checkAllFiles = (_filesArray) => {
		return _filesArray
			.map((files) => {
				return files.some((item) => item.status == "REJECTED");
			})
			.some((item) => item == true);
	};
	const checkAllData = (_dataArray) => {
		return _dataArray
			.map((data) => data.some((field) => field?.isChecked === false))
			.some((item) => item === true);
	};

	useEffect(() => {
		if (detailsArray && currentFiles && fileArray) {
			const filesFalseValue = currentFiles.some((item) => item.status == "REJECTED");
			if (checkAllData(detailsArray) || filesFalseValue || checkAllFiles(fileArray))
				setDisabled(false);
			else setDisabled(true);

			console.log("FalseValueD", checkAllData(detailsArray));
			console.log("FalseValueF", filesFalseValue);
			console.log("FalseValueT", isDisabled);
		}
	}, [detailsArray, currentFiles, fileArray]);

	const handleResubmitDisable = (data, files) => {
		const filesFalseValue = files.some((item) => item.status == "REJECTED");

		if (checkAllData(detailsArray) || filesFalseValue || checkAllFiles(fileArray))
			setDisabled(false);
		else setDisabled(true);
		console.log("detailsArray", detailsArray);
	};

	return (
		<div>
			<ReviewWrapperNOCU
				hideBtns={hideBtns}
				readOnly={readOnly}
				index={customIndex || 3}
				entityId={currentEntityId}
				title={"Management Personnel"}
				setForm={() => {}}
				stepperName={stepperName}
				files={currentFiles}
				isDisabled={isDisabled}
				formToggleSwitch={
					nameArray && <ToggleButtonSet data={nameArray} handleClick={handlePerson} />
				}
				topBar={
					stepperData &&
					stepperName && (
						<DynamicStepper name={stepperName} stepperData={stepperData} routeArray={routeArray} />
					)
				}
				// formData={formData}
				currentFile={selectedFile}
				fileButtons={
					<SectionColumn className='pl-5 w-full'>
						<SourceOfProducts
							hideBtns={hideBtns}
							reviewStatus={reviewStatus}
							setCanApprove={setCanApprove}
							setDisabled={setDisabled}
							readOnly={readOnly}
							rejections={rejections}
							data={sopData}
							selectedFile={selectedFile}
							setSelectedFile={setSelectedFile}
							updateFileArray={updateFileArray}
						/>
					</SectionColumn>
				}
				handleReject={handleReject}
				handleResubmit={handleResubmit}
				handleNext={handleSubmit}
				setDisabled={handleResubmitDisable}
				canApprove={canApprove}
				reviewed={
					nextCustomRoute ? () => handleCUstomNext(nextCustomRoute) : readOnly ? handleNext : null
				}
			/>
		</div>
	);
};

export default ProcessorStep4;
