import React, { useState } from "react";
import { setFieldVisitImages } from "../../Helpers/rejectionHelpers";
import ImagePreviewCard from "../cards/imagePreviewCard";
import ImageUploadCard from "../cards/imageUploadCard";
import { SectionRow } from "../section";

const ImagesUploadButtonSet = ({ files, setFiles }) => {
  // const [files, setFiles] = useState([]);
  const renderImagePreviewCards = () => {
    return files.map((file, index) => (
      <ImagePreviewCard
        key={index}
        index={index}
        file={file}
        deleteImage={deleteImage}
      />
    ));
  };
  //console.log("file", files);
  const deleteImage = (index) => {
    setFiles(files.filter((val, i) => i !== index));
  };
  return (
    <SectionRow className="w-full justify-center">
      {renderImagePreviewCards()}
      <ImageUploadCard files={files} setFiles={setFiles} />
    </SectionRow>
  );
};

export default ImagesUploadButtonSet;
