export const setEligibilityTicketId = (id) => {
	localStorage.setItem("ELIGIBILITY_TICKET", id);
};

export const getEligibilityTicketId = () => {
	return localStorage.getItem("ELIGIBILITY_TICKET");
};

export const clearEligibilityTicketId = () => {
	localStorage.removeItem("ELIGIBILITY_TICKET");
};

export const isCheckAllProducts = (productsList) => {
	const statusArray = productsList.map((product) => product.status);
	console.log(statusArray);
	const hasPending = statusArray.includes("PENDING");
	return !hasPending;
};
