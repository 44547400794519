import React, { useEffect, useState } from "react";
import RoundedButton from "./RoundedButtons";
import SelectionDropDown from "./SelectDropDown";
import TextField from "./TextField";
import { createDocAccount } from "../../../app/api/authApi/authApi";
import { useAlert } from "../../../app/context/alerts";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { navigateSelf } from "../../../app/router/routerManager";
import { ErrorMessage, useField } from "formik";
import TextFieldRegistration from "./TextFieldRegistration";
import { SectionColumn, SectionRow } from "../../../layouts/sections";
import LoaderComponent from "../../../components/Loader/LoaderComponent";

export default function RegistrationCard(props) {
	const [userDesignation, setUserDesignation] = useState();
	const [errors, setErrors] = useState({ designation: "" });
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [mobile, setMobile] = useState();
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [doLevel, setDOLevel] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [doLevelError, setDoLevelError] = useState("");
	const { setAlert } = useAlert();
	const phoneNumberFormat = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,9}$/im;
	const nameFormat = /^[A-Za-z ]+$/;
	const designation = [
		{ value: "NOCU Officer", label: "NOCU Officer" },
		{ value: "Slab", label: "Slab" },
		{ value: "Field Visit", label: "Field Visit" },
		{ value: "Signing Officer", label: "Signing Officer" },
		{ value: "Technical Committee", label: "Technical Committee" },
	];
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 3000);
	}, []);

	const validationSchema = Yup.object({
		fullName: Yup.string()
			.required("Name is required")
			.max(40, "Name length exceeded")
			.matches(nameFormat, "Please insert correct name"),
		email: Yup.string().email("Email is invalid").required("Email is required"),
		mobile: Yup.string()
			.required("Mobile No is required")
			.matches(phoneNumberFormat, "Please insert correct mobile number")
			.max(12, "Mobile number should not exceeded 12 digits"),
		password: Yup.string()
			.min(6, "Password must be 6 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol")
			.required("Password is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password"), null], "Please make sure your passwords match")
			.required("Confirm Password is required"),
	});

	const initialValues = {
		designation: "",
		fullName: "",
		email: "",
		mobile: "",
		password: "",
		confirmPassword: "",
	};

	const registration = (values) => {
		setIsLoading(true);
		console.log("DO Level", doLevel);
		if (userDesignation === "" || userDesignation === undefined) {
			setErrors({ ...errors, designation: "Please select your designation" });
		} else if (userDesignation === "DO" && doLevel === "") {
			setDoLevelError("Please select DO permission level");
		} else {
			setDoLevelError("");
			setErrors({ ...errors, designation: "" });
			values.designation = userDesignation;
			values.doLevel = userDesignation === "DO" ? doLevel : "";
			const payload = {
				fullName: values.fullName,
				email: values.email,
				username: values.email,
				mobile: values.mobile,
				password: values.password,
				roleNames: [userDesignation],
				status: "PENDING",
				modifiedBy: "",
			};
			// {
			// 	designation: values.designation,
			// 	fullName: values.fullName,
			// 	username: values.email,
			// 	contactNo: values.mobile,
			// 	password: values.password,
			// };
			console.log("payload ---------->", payload);

			createDocAccount(payload)
				.then((res) => {
					if (res.data?.accountId) {
						setAlert({
							message: "User created successfully!",
							severity: "success",
							show: true,
						});
						setTimeout(() => navigateSelf("/login"), 2500);
					} else if (res.data?.responseCode === "01") {
						setAlert({
							message: "This email is already registered !",
							severity: "error",
							show: true,
						});
					} else {
						setAlert({
							message: res.data?.responseMsg || "User not created successfully!",
							severity: "error",
							show: true,
						});
					}
				})
				.catch((error) => {
					console.log(error);
					setAlert({
						message: "User not created successfully!",
						severity: "error",
						show: true,
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	return (
		<SectionColumn className='grid col-1 justify-items-center mb-4'>
			<LoaderComponent isOpen={isLoading} />

			<div className='grid justify-items-center relative'>
				<SectionRow>
					<div className='grid justify-items-center'>
						<span className='loginText pt-3 text-center'>Sign Up</span>
					</div>
				</SectionRow>

				<span className='w-48 pb-5 docSubText text-center'>
					Enter your credentials to create new account.
				</span>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={registration}>
					{(formik) => (
						<Form>
							<SelectionDropDown
								options={designation}
								placeholder='Designation'
								name='designation'
								className='eligibilityCheckcardSelectDropDown '
								onChange={(e) => {
									setUserDesignation(e.value);
									setErrors({ ...errors, designation: "" });
								}}
								error={errors?.designation}></SelectionDropDown>

							{userDesignation === "DO" && (
								<div className='flex flex-col justify-center'>
									<div
										className='loginCardRadioButtonField '
										onChange={(e) => {
											setDOLevel(e.target.value);
										}}>
										<input
											type='radio'
											value='check'
											className='inputRadioBtn'
											name='DODesignation'
										/>{" "}
										Check Permission
										<input
											type='radio'
											className='inputRadioBtn'
											value='recommend'
											name='DODesignation'
										/>
										Recommend Permission
									</div>
									{doLevelError !== "" && (
										<div className='error-msg text-center w-full !-mt-3 !mb-5'>{doLevelError}</div>
									)}
								</div>
							)}

							<TextFieldRegistration
								name='fullName'
								type='text'
								placeholder='Name with initials'
								onChange={(e) => setName(e.target.value)}></TextFieldRegistration>

							<TextFieldRegistration
								name='email'
								type='text'
								placeholder='Email'
								onChange={(e) => setEmail(e.target.value)}></TextFieldRegistration>

							<TextFieldRegistration
								name='mobile'
								type='text'
								placeholder='Mobile Number'
								onChange={(e) => setMobile(e.target.value)}></TextFieldRegistration>

							<TextFieldRegistration
								name='password'
								type='password'
								placeholder='Password'
								onChange={(e) => setPassword(e.target.value)}></TextFieldRegistration>

							<TextFieldRegistration
								name='confirmPassword'
								type='password'
								placeholder='Confirm Password'
								onChange={(e) => setConfirmPassword(e.target.value)}></TextFieldRegistration>

							<div className='flex items-center w-full flex-col'>
								<RoundedButton
									className='loginBTN'
									type='submit'
									onClick={() => {
										if (userDesignation === "" || userDesignation === undefined) {
											setErrors({ ...errors, designation: "Please select your designation" });
										}
									}}>
									Sign up
								</RoundedButton>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</SectionColumn>
	);
}
