import React from "react";
import Section, { SectionColumn, SectionRow } from "../../layouts/sections/index";

import RoundedButton from "./components/RoundedButtons";

import RegistrationCard from "./components/RegistrationCard";
import { useHistory } from "react-router-dom";
import LogoHeader from "../../components/custom/LogoHeader";
import { navigateNewTab } from "../../app/router/routerManager";

function Registration() {
	const history = useHistory();

	const handleLogin = () => {
		history.push("/login");
	};

	const handleRoute = () => {
		history.push("/web/overview");
	};
	return (
		<div className='loginContainer'>
			<Section className='authSection'>
				<SectionRow className='w-full'>
					<div className='w-full md:w-5/12    loginPageLeft loginScroll'>
						<SectionColumn className='justify-between pt-10 h-screen'>
							<div className='absolute top-5 right-5 visible md:invisible'>
								<RoundedButton className='floatingBTN' onClick={handleLogin}>
									Login
								</RoundedButton>
							</div>
							<LogoHeader />
							<RegistrationCard handleRoute={handleRoute} />
							<span className='copyrightText pb-5'>
								Copyright 2023 National Organic Control Unit. All Rights Reserved. <span className="cursor-pointer" onClick={() => {
									navigateNewTab("https://www.elysiancrest.com/");
								}} >Design by  Elysian Crest</span>
							</span>
						</SectionColumn>
					</div>
					<div className='w-full md:w-7/12   '>
						<SectionColumn className='relative h-full sectionRightBG p-5'>
							<div className=''>
								<RoundedButton className='floatingBTN' onClick={handleLogin}>
									Login
								</RoundedButton>
							</div>
							<div className='mx-auto my-auto'>
								<div className='w-fit mx-auto text-center'>
									<span className='loginWelcomeText'>Welcome to the National Organic Control Unit (NOCU) Officer Portal</span>
								</div>
								{/* <div className='w-fit mx-auto'>
									<span className='loginWelcomeText'>Web Portal</span>
								</div> */}
							</div>
						</SectionColumn>
					</div>
				</SectionRow>
			</Section>
		</div>
	);
}

export default Registration;
