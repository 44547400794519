import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, TextField, ThemeProvider, createTheme } from "@mui/material";
import { SectionRow } from "../../../components/section";
import { ICON_PATHS } from "../../../app/constants";
import DocInputList from "./DocInputList";
import {
	getMappedApplicationNumber,
	getUserId,
} from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { addExtraDocReq } from "../../../app/api/NOCU/reqExtraDocAPI";
import { useAlert } from "../../../app/context/alerts";

export default function RequestExtraDocuments({ btn, title }) {
	const [open, setOpen] = React.useState(false);
	const [canSubmit, setCanSubmit] = React.useState(false);
	const [docsList, setDocsList] = React.useState([]);
	const { setAlert } = useAlert();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = () => {
		const payload = {
			officerId: getUserId(),
			mappedApplicationNumber: getMappedApplicationNumber(),
			extraDocDetails: docsList,
		};
		console.log("Submit : ", payload);
		addExtraDocReq(payload)
			.then((res) => {
				setAlert({
					message: res.data?.responseMessage
						? res.data?.responseMessage
						: res.data?.responseCode === "00"
						? "Requested Successfully"
						: "Request Error",
					severity: res.data?.responseCode === "00" ? "success" : "error",
					show: true,
				});
			})
			.then((e) => {
				console.log(e);
			})
			.finally(() => {
				setTimeout(() => {
					// handleClose();
					window.location.reload();
				}, 1000);
			});
	};

	const tabTheme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: "#20B05E",
				contrastText: "rgba(255,255,255,0.87)",
			},
		},
	});

	return (
		<ThemeProvider theme={tabTheme}>
			<div>
				<div onClick={handleClickOpen}>{btn}</div>

				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogTitle style={{ backgroundColor: "#20B05E" }} className={`text-white`}>
						{title}
					</DialogTitle>
					<DialogContent>
						<DocInputList setCanSubmit={setCanSubmit} setDocsList={setDocsList} />
					</DialogContent>
					<DialogActions>
						<Button color='error' onClick={handleClose}>
							Cancel
						</Button>
						<Button disabled={!canSubmit} variant='contained' onClick={handleSubmit}>
							Request
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</ThemeProvider>
	);
}
