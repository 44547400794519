import React from "react";

const CommonSelection = ({ filters, onChange, title, adjustable, defaultValue }) => {
	return (
		<div className='perPageContainer'>
			<label className='mr-2 pl-3'>
				{title}
				{""} :
			</label>
			<select
				defaultValue={defaultValue}
				className={`perPage ${adjustable ? "!w-" + adjustable : ""}  hover:!w-fit !px-3 `}
				name='size'
				id='size'
				onChange={(e) => {
					onChange(e.target.value);
				}}>
				{filters.map((fbb) => (
					<option key={fbb.key} value={fbb.value}>
						{fbb.key}
					</option>
				))}
				;
			</select>
		</div>
	);
};

export default CommonSelection;
