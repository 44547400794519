import React from "react";
import { SectionColumn, SectionRow } from "../../../components/section";

import { useEffect } from "react";
import {
	Button,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { useState } from "react";
import {
	getUserAllocation,
	getUserByPermission,
	reassignByApplication,
} from "../../../app/api/NOCU/userManagementApi";
import { useAlert } from "../../../app/context/alerts";
import { getUserId } from "../../../app/api/NOCU/helpers/applicationApiHelpers";

const ReassignPage = ({ userData, setIsLoading, handleRefresh, refresh }) => {
	const [permissions, setPermissions] = useState([]);
	const [userList, setUserList] = useState([]);
	const [application, setApplication] = useState("");
	const [percentage, setPercentage] = useState(0);
	const [assignedOfficer, setAssignedOfficer] = useState([]);
	const { setAlert } = useAlert();
	useEffect(() => {
		setIsLoading(true);

		setUserList([]);
		setApplication("");
		console.log(application);
		setPercentage(0);
		setAssignedOfficer([]);
		userData &&
			getUserAllocation(userData?.userId, { isOnlyPermission: true })
				.then((res) => {
					setPermissions(res.data);
				})
				.catch((e) => console.log(e))
				.finally(() => {
					setIsLoading(false);
				});
	}, [userData, refresh]);

	useEffect(() => {
		const payload = {
			officerId: userData?.userId,
			permission: application,
		};
		getUserByPermission(payload)
			.then((res) => {
				console.log(res.data);
				setUserList(res.data?.userList);
			})
			.catch((e) => console.log(e));
	}, [application]);

	useEffect(() => {
		if (percentage > 100) {
			setPercentage((prevState) => {
				setPercentage(100);
			});
		}
	}, [percentage]);

	const renderApplicationOptions = (_applications) => {
		let modifiedApplication = _applications && Array.isArray(_applications) && _applications.length !== 0 && _applications.map(modApp => {
			if (modApp.permission === "FINAL_COMMENT") {
				return "TECH_COMITTEE_RECOMMENDATION";
			} else {
				return modApp.permission;
			}
		})
		return modifiedApplication && Array.isArray(modifiedApplication) && modifiedApplication.length !== 0 ? (
			modifiedApplication.map((permission, index) => {
				return (
					<MenuItem key={index} value={permission}>
						{permission && permission.replaceAll("_", " ")}
					</MenuItem>
				);
			})
		) : (
			<MenuItem disabled value={""}>
				No Applications Found
			</MenuItem>
		);
	};


	// const renderApplicationOptions = (_applications) => {
	// 	return _applications && Array.isArray(_applications) && _applications.length !== 0 ? (
	// 		_applications.map((app, index) => {
	// 			return (
	// 				<MenuItem key={index} value={app?.permission}>
	// 					{app?.permission && app?.permission.replaceAll("_", " ")}
	// 				</MenuItem>
	// 			);
	// 		})
	// 	) : (
	// 		<MenuItem disabled value={""}>
	// 			No Applications Found
	// 		</MenuItem>
	// 	);
	// };

	const renderOfficerOptions = (_persons) => {
		return _persons && Array.isArray(_persons) && _persons.length !== 0 ? (
			_persons.map((person, index) => {
				return (
					<MenuItem key={index} value={person?.userId}>
						{person?.fullName}
					</MenuItem>
				);
			})
		) : (
			<MenuItem disabled value={""}>
				No Officers Found
			</MenuItem>
		);
	};

	const handleUpdate = () => {
		const payload = {
			officerId: userData?.userId,
			permission: application,
			assignedOfficerList: assignedOfficer,
			percentage: percentage,
			modifiedOfficerId: getUserId(),
		};
		console.log("payload", payload);
		reassignByApplication(payload)
			.then((res) => {
				setAlert({
					message: res.data?.responseMessage
						? res.data?.responseMessage
						: res.data?.responseCode === "00"
							? "Updated Successfully"
							: "Updated Error",
					severity: res.data?.responseCode === "00" ? "success" : "error",
					show: true,
				});
			})
			.catch((e) => console.log(e))
			.finally(() => {
				setTimeout(() => {
					handleRefresh();
				}, 1500);
			});
	};

	return (
		<SectionColumn className='w-full overflow-auto h-[80vh]'>
			<SectionRow className='border-b pb-2 border-gray-200 w-full'>
				Reassign User Applications{" "}
			</SectionRow>
			<SectionRow className='w-full pt-10 content-start h-[70vh]'>
				<SectionRow className='w-full h-14  items-center'>
					<SectionColumn className='w-3/12 items-end px-5'>Application </SectionColumn>
					<SectionColumn className='w-7/12 px-5'>
						<FormControl sx={{ m: 1, width: 300 }} size='small'>
							<InputLabel id='demo-select-small-label'>Select Application</InputLabel>
							<Select
								displayEmpty={true}
								value={application}
								labelId='demo-select-small-label'
								id='demo-select-small'
								label='Select Application'
								onChange={(e) => {
									setApplication(e.target.value);
								}}>
								{permissions && renderApplicationOptions(permissions)}
							</Select>
						</FormControl>
					</SectionColumn>
				</SectionRow>

				<SectionRow className='w-full h-14 items-center'>
					<SectionColumn className='w-3/12 items-end px-5'>Percentage </SectionColumn>
					<SectionColumn className='w-7/12 px-5'>
						<TextField
							sx={{ m: 1, width: 300 }}
							size='small'
							type='number'
							labelId='demo-select-small-label'
							id='demo-select-small'
							value={percentage}
							label='Percentage'
							InputProps={{
								endAdornment: <InputAdornment position='end'>%</InputAdornment>,
								maxLength: 3,
							}}
							onChange={(e) => {
								setPercentage(e.target.value);
							}}
						/>
					</SectionColumn>
				</SectionRow>
				<SectionRow className='w-full h-14  items-center'>
					<SectionColumn className='w-3/12 items-end px-5'>Assign to </SectionColumn>
					<SectionColumn className='w-7/12 px-5'>
						<FormControl sx={{ m: 1, width: 300 }} size='small'>
							<InputLabel id='demo-select-small-label'>Officer</InputLabel>
							<Select
								labelId='demo-select-small-label'
								id='demo-select-small'
								multiple
								value={assignedOfficer}
								label='Officer'
								onChange={(e) => {
									setAssignedOfficer(e.target.value);
								}}>
								{userList && renderOfficerOptions(userList)}
							</Select>
						</FormControl>
					</SectionColumn>
				</SectionRow>
			</SectionRow>
			<SectionRow className='w-full flex-row-reverse px-10'>
				<Button
					variant='contained'
					color='error'
					className='!mx-3 !capitalize'
					onClick={() => {
						handleRefresh();
					}}>
					Reset
				</Button>
				<Button
					disabled={!(application && percentage && assignedOfficer)}
					onClick={() => {
						handleUpdate();
					}}
					variant='contained'
					className='!mx-3 !capitalize'>
					Reassign
				</Button>
			</SectionRow>
		</SectionColumn>
	);
};

export default ReassignPage;
