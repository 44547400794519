import React, { useEffect, useState } from "react";
import HomeTable from "../../../components/table/HomeTable";
import Button from "@mui/material/Button";
import DownloadButton from "../../../components/buttons/DownloadButton";
import CommonSelection from "../../../components/section/commonSelection";
import { STAKEHOLDERS, monthOptions } from "../../../app/constants";
import TabHeader from "../../../components/Tab/tabHeader";
import TabContent from "../../../components/Tab/TabContent";
import { SectionColumn } from "../../../components/section";

const ApplicationCountReport = ({
    setParams,
    setYearOnlyParam,
    params,
    filterType,
    reportDetails,
    reportCount,
    setYearandMonthParam,
    setMonthYearParam,
    downloadPDF,
    downloadExcel,
    setStakeHolderParam,
    listOfYears,
    handleTabChange,
    activeTab,
    SearchByFilters,
    handlePage,
    handleSize,
    stakeholder
}) => {

    const tableHeaders = [
        "Date",
        "Check Process",
        "Final comment",
        "Technical Committee",
        "Final Vote",
        "Pending to Sign",
        "Issued",
    ];

    return (
        <div className='perPageContainer'>
            <div className='w-full flex flex-row my-auto h-16'>
                <div className="w-3/5">
                    <label className='ml-3'> Filter By Date :</label>
                    <div className="flex flex-row ml-3">
                        {filterType && filterType == "Range" || filterType == "Daily" ?
                            <div className="flex flex-row perPageContainer">

                                <div className="flex flex-row">
                                    <span className='mr-2 pl-3 leading-7' >From : </span>
                                    <input
                                        type={"date"}
                                        placeholder='From Date'
                                        className='searchDate'
                                        value={params.fromDate}
                                        onChange={(e) => {
                                            {
                                                filterType && filterType == "Range" ?
                                                    setParams({ ...params, fromDate: e.target.value })

                                                    :
                                                    setParams({ ...params, fromDate: e.target.value, toDate: e.target.value })
                                            }

                                        }}
                                    />
                                </div>

                                {filterType && filterType == "Range" ?

                                    <div className='flex flex-row'>
                                        <span className='ml-3 mr-3 leading-7'>To : </span>
                                        <input
                                            type={"date"}
                                            placeholder='To Date'
                                            value={params.toDate}
                                            className='searchDate'
                                            onChange={(e) => {
                                                setParams({ ...params, toDate: e.target.value })
                                            }}

                                        />
                                    </div>
                                    :
                                    <div className="leading-7"></div>
                                }

                            </div>

                            : filterType && filterType == "Monthly" ?
                                <div className=" flex flex-row">
                                    <CommonSelection
                                        filters={monthOptions}
                                        onChange={setMonthYearParam}
                                        title={`Month`}
                                    />

                                    <CommonSelection
                                        filters={listOfYears}
                                        onChange={setYearandMonthParam}
                                        title={`Year`}
                                    />
                                </div>
                                :
                                <div className="">
                                    <CommonSelection
                                        filters={listOfYears}
                                        onChange={setYearOnlyParam}
                                        title={`Year`}
                                    />
                                </div>
                        }
                    </div>
                </div>

                <div className='w-2/5 float-right'>
                    <div className=''>
                        <div className="filter-tabs">
                            <TabHeader
                                tabLabels={['Daily', 'Monthly', 'Yearly', 'Range']}
                                onTabChange={handleTabChange}
                            />
                            <TabContent active={activeTab === 0} />
                            <TabContent active={activeTab === 1} />
                            <TabContent active={activeTab === 2} />
                            <TabContent active={activeTab === 3} />
                        </div>
                    </div>

                </div>

            </div>
            <div className="flex flex-row justify-between">

                <div className="">
                    {stakeholder &&
                        <CommonSelection
                            filters={STAKEHOLDERS}
                            onChange={setStakeHolderParam}
                            title={`Filter By Stakeholder`}
                        />
                    }
                </div>
                <div className="">
                    <SectionColumn>

                        <div className="">
                            <div className="float-right space-x-2 my-auto">
                                <Button
                                    variant='contained'
                                    style={{
                                        backgroundColor: params.fromDate && params.toDate ? '#26c165' : '#dddddd',
                                        color: '#ffffff',
                                    }} className="float-right"
                                    disabled={params.fromDate && params.toDate ? false : true}
                                    onClick={SearchByFilters}>
                                    Generate
                                </Button>

                            </div>
                        </div>

                        {Array.isArray(reportDetails) && reportDetails.length > 0 ?

                            <div className="flex justify-end space-x-4 mt-3">
                                <DownloadButton
                                    handleClick={() => downloadExcel()}
                                    title={"Download Excel"} />

                                <DownloadButton
                                    handleClick={() => downloadPDF()}
                                    title={"Download PDF"} />
                            </div>
                            :
                            <div className=""></div>
                        }
                    </SectionColumn>

                </div>
            </div>


            <HomeTable
                applicationKey={1}
                columns={tableHeaders}
                // handleSearchById={handleSearchById}
                customSearchDropdown={<div></div>}
                rows={reportDetails}
                //view={null}
                //history={null}
                //filters={selectedFilters}
                // setFilter={handleFilter}
                //sort={sorting}
                //handleSort={handleSorting}
                handleSize={handleSize}
                count={reportCount}
                page={params.page}
                size={params.size}
                handlePage={handlePage}

            />

        </div>
    );
};

export default ApplicationCountReport;
