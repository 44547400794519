import React, { useEffect, useState } from "react";
import HomeTable from "../../../components/table/HomeTable";
import Button from "@mui/material/Button";
import DownloadButton from "../../../components/buttons/DownloadButton";
import CommonSelection from "../../../components/section/commonSelection";
import { STAKEHOLDERS, monthOptions } from "../../../app/constants";
import TabHeader from "../../../components/Tab/tabHeader";
import TabContent from "../../../components/Tab/TabContent";
import { SectionColumn } from "../../../components/section";
import { Chip, Grid, Tooltip } from "@mui/material";

const EndUserAllDetails = ({
    setParams,
    setYearOnlyParam,
    params,
    filterType,
    reportDetails,
    reportCount,
    setYearandMonthParam,
    setMonthYearParam,
    downloadPDF,
    downloadExcel,
    setStakeHolderParam,
    listOfYears,
    handleTabChange,
    activeTab,
    SearchByFilters,
    handlePage,
    handleSize,
    stakeholder,
    customSearchDropdown,
}) => {

    const tableHeaders = [
        "Date",
        "Check Process",
        "Final comment",
        "Technical Committee",
        "Final Vote",
        "Pending to Sign",
        "Issued",
    ];

    return (
        <div className='perPageContainer'>
            <div className='w-full flex flex-row my-auto h-16'>
                <div className="w-3/5">
                    <div className=''>
                        {customSearchDropdown}
                    </div>

                </div>
            </div>
            <div className="flex flex-row justify-between">

                <div className="">
                    {stakeholder &&
                        <CommonSelection
                            filters={STAKEHOLDERS}
                            onChange={setStakeHolderParam}
                            title={`Filter By Stakeholder`}
                        />
                    }
                </div>
                <div className="">
                    <SectionColumn>

                        <div className="">
                            <div className="float-right space-x-2 my-auto">
                                <Button
                                    variant='contained'
                                    style={{
                                        backgroundColor: !params.nocuCertNo && !params.applicationNumber ? '#dddddd' : '#26c165',
                                        color: '#ffffff',
                                    }}

                                    className="float-right"
                                    disabled={!params.nocuCertNo && !params.applicationNumber}
                                    onClick={() => { downloadPDF() }}>
                                    Generate
                                </Button>

                            </div>
                        </div>

                        {Array.isArray(reportDetails) && reportDetails.length > 0 ?

                            <div className="flex justify-end space-x-4 mt-3">
                                <DownloadButton
                                    handleClick={() => downloadExcel()}
                                    title={"Download Excel"} />

                                <DownloadButton
                                    handleClick={() => downloadPDF()}
                                    title={"Download PDF"} />
                            </div>
                            :
                            <div className=""></div>
                        }
                    </SectionColumn>

                </div>
            </div>

        </div>
    );
};

export default EndUserAllDetails;
