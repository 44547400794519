import { Error, Visibility, VisibilityOff } from "@mui/icons-material";
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { changePassword } from "../../../app/api/authApi/authApi";
import { useAlert } from "../../../app/context/alerts";
import RoundedButton from "../../../components/buttons/RoundedButtons";
import { SectionRow } from "../../../components/section";
import { getUserEmail } from "../../../Helpers/loginHelper";
import { validatePassword } from "../../../Helpers/validationHelpers";
import { getChangePasswordObject } from "../helpers/submitHelper";

const ChangePasswordCard = () => {
	const { setAlert } = useAlert();
	const userEmail = getUserEmail();
	const [values, setValues] = useState({
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
		oldPass: false,
		newPass: false,
		confirmPass: false,
	});
	const [email, setEmail] = useState("");

	const [isTallyEmails, setIsTallyEmails] = useState(false);
	const [errors, setErrors] = useState({
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
		email: "",
	});

	useEffect(() => {
		if (userEmail === email) {
			setIsTallyEmails(true);
		}
	}, [email, userEmail]);

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};
	const handelClear = () => {
		setValues({
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
			oldPass: false,
			newPass: false,
			confirmPass: false,
		});
		setErrors({
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
			email: "",
		});
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const handleValidateFelids = (values) => {
		let oldE = "";
		let newE = "";
		let confirmE = "";
		if (values.confirmPassword === "") {
			// setErrors({ ...errors, confirmPassword: "Confirm Password is required" });
			confirmE = "Confirm Password is required";
		}
		if (values.newPassword === "") {
			// setErrors({ ...errors, newPassword: "Password is required" });
			newE = "Password is required";
		}
		if (values.oldPassword === "") {
			// setErrors({ ...errors, oldPassword: "Password is required" });
			oldE = "Password is required";
		}
		setErrors({ ...errors, confirmPassword: confirmE, newPassword: newE, oldPassword: oldE });
	};

	const handleChangePassword = () => {
		const data = getChangePasswordObject(values, email);
		handleValidateFelids(values);
		if (values.oldPassword === values.newPassword && values.oldPassword !== "") {
			setAlert({
				message: "Old password and new password is same !, Enter new password ",
				severity: "error",
				show: true,
			});
		} else if (values.newPassword !== values.confirmPassword) {
			setErrors({ ...errors, confirmPassword: "Please make sure your passwords match" });
		} else if (
			values.oldPassword !== "" &&
			errors.newPassword === false &&
			errors.confirmPassword === false &&
			email !== ""
		) {
			changePassword(data)
				.then((res) => {
					if (res.data?.responseCode === "02") {
						setAlert({
							message: "Incorrect Email",
							severity: "error",
							show: true,
						});
					} else if (res.data?.responseCode === "03") {
						setAlert({
							message: res.data.responseMsg,
							severity: "error",
							show: true,
						});
					} else if (res.data?.responseCode === "04") {
						setAlert({
							message: res.data.responseMsg,
							severity: "error",
							show: true,
						});
					} else {
						setAlert({
							message: res.data.responseMsg,
							severity: "success",
							show: true,
						});
						setTimeout(() => window.location.reload(), 2500);
					}
				})
				.catch((error) => {
					console.log("Reset error", error);
				});
		} else {
			setAlert({
				message: "Fill all details",
				severity: "error",
				show: true,
			});
		}
	};
	return (
		<SectionRow className='px-10 pt-4 '>
			<SectionRow className='w-full items-baseline'>
				<span className='mr-5'>Enter email to change password </span>
				<SectionRow>
					<FormControl sx={{ m: 1, width: "30ch" }} variant='outlined'>
						<OutlinedInput
							disabled={isTallyEmails}
							autoComplete='off'
							size='small'
							placeholder='Email'
							type='email'
							name='email'
							value={email}
							onBlur={(e) =>
								e.target.value === ""
									? setErrors({ ...errors, email: "Email is required to change password" })
									: isTallyEmails
									? setErrors({ ...errors, email: "" })
									: setErrors({ ...errors, email: "Incorrect email" })
							}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
						{!isTallyEmails && errors.email && (
							<span className='text-red-500 text-xs ml-3 mt-1'>{errors.email}</span>
						)}
					</FormControl>
				</SectionRow>
			</SectionRow>
			{isTallyEmails && (
				<div>
					<FormControl sx={{ m: 1, width: "30ch" }} variant='outlined'>
						<OutlinedInput
							size='small'
							autoComplete='off'
							placeholder='Old Password'
							id='outlined-adornment-password-old'
							type={values.oldPass ? "text" : "password"}
							value={values.oldPassword}
							onBlur={() => {
								validatePassword(values.oldPassword).then((res) =>
									setErrors({ ...errors, oldPassword: res })
								);
							}}
							onChange={handleChange("oldPassword")}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => setValues({ ...values, oldPass: !values.oldPass })}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{values.oldPass ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
						{errors.oldPassword && (
							<span className='text-red-500 text-xs ml-3'>Old {errors.oldPassword}</span>
						)}
					</FormControl>
					<FormControl sx={{ m: 1, width: "30ch" }} variant='outlined'>
						<OutlinedInput
							size='small'
							autoComplete='off'
							placeholder='New Password'
							id='outlined-adornment-password-new'
							type={values.newPass ? "text" : "password"}
							value={values.newPassword}
							onBlur={() => {
								validatePassword(values.newPassword).then((res) =>
									setErrors({ ...errors, newPassword: res })
								);
							}}
							onChange={handleChange("newPassword")}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => setValues({ ...values, newPass: !values.newPass })}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{values.newPass ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
						{errors.newPassword && (
							<span className='text-red-500 text-xs ml-3'>New {errors.newPassword}</span>
						)}
					</FormControl>
					<FormControl sx={{ m: 1, width: "30ch" }} variant='outlined'>
						<OutlinedInput
							size='small'
							autoComplete='off'
							placeholder='Confirm Password'
							id='outlined-adornment-password-con'
							type={values.confirmPass ? "text" : "password"}
							value={values.confirmPassword}
							onBlur={(e) => {
								e.target.value !== values.newPassword
									? setErrors({
											...errors,
											confirmPassword: "Please make sure your passwords match",
									  })
									: e.target.value === ""
									? setErrors({ ...errors, confirmPassword: "Confirm Password is required" })
									: setErrors({ ...errors, confirmPassword: false });
							}}
							onChange={handleChange("confirmPassword")}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => setValues({ ...values, confirmPass: !values.confirmPass })}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{values.confirmPass ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
						{errors.confirmPassword && (
							<span className='text-red-500 text-xs ml-3'>{errors.confirmPassword}</span>
						)}
					</FormControl>
					<SectionRow className='w-full'>
						<RoundedButton
							className='pdfRejectBTN mx-5'
							onClick={() => {
								handelClear();
							}}>
							Clear
						</RoundedButton>
						<RoundedButton
							className='nextButton mx-5'
							onClick={() => {
								handleChangePassword();
							}}>
							Change Password
						</RoundedButton>
					</SectionRow>
				</div>
			)}
		</SectionRow>
	);
};

export default ChangePasswordCard;
