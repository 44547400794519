import React, { useEffect, useState } from "react";
import { SectionRow } from "../../components/section";
import HomeTable from "../../components/table/HomeTable";
import { getUserTableRecords } from "../../app/api/NOCU/userManagementApi";
import { getTableRecord } from "./dataManipulation";

import SettingsIcon from "@mui/icons-material/Settings";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import FullScreenDialog from "../../components/model/FullScreenDialog";
import UserManagementSettings from "./UserManagementSettings";
import TableCount from "../../components/section/tableCount";

const columns = ["Full Name", "Designation", "Contact No", "Email", "Status"];

const UserManagement = () => {
	const [records, setRecords] = useState([]);
	const [response, setResponse] = useState();
	const [selectedRecord, setSelectedRecord] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [count, setCount] = useState(0);
	const [params, setParams] = useState({
		userStatus: "ALL",
		page: 0,
		size: 10,
		email: "",
		designation: "",
	});
	useEffect(() => {
		setIsLoading(true);
		getUserTableRecords(params)
			.then((res) => {
				setRecords(getTableRecord(res.data?.userList));
				setCount(res.data?.count);
				setResponse(res.data);
			})
			.catch((e) => console.log(e))
			.finally(() => {
				setIsLoading(false);
			});
	}, [params]);

	const handlePage = (page) => {
		setParams({ ...params, page: page });
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size });
	};
	const handleFilter = (filter) => {
		setParams({ ...params, userStatus: filter });
	};
	const viewFunction = (index) => {
		console.log(index);
		setSelectedRecord(response?.userList[index]);
	};

	const handleSearchById = (email) => {
		setParams({ ...params, email: email });
	};
	let filters = [
		{ key: "ALL", value: "ALL" },
		{ key: "PENDING", value: "PENDING" },
		{ key: "APPROVED", value: "APPROVED" },
		{ key: "VACATION", value: "VACATION" },
		{ key: "DISABLED", value: "DISABLED" },
		{ key: "SUSPENDED", value: "SUSPENDED" },
		{ key: "REJECTED", value: "REJECTED" },
	];

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0'>
				<span className='overViewTitle'>User Management</span>
			</SectionRow>
			<SectionRow className='w-full mt-10'>
				<HomeTable
					customViewIcon={
						<FullScreenDialog
							button={<SettingsIcon />}
							children={<UserManagementSettings userData={selectedRecord} />}
							title={"User Settings"}
						/>
					}
					applicationKey={1}
					customSearchPlaceHolder={"Email"}
					columns={columns}
					// tableTitle={"User Table"}
					handleSearchById={handleSearchById}
					rows={records}
					view={viewFunction}
					filters={filters}
					setFilter={handleFilter}
					customFilter={<TableCount count={count} />}
					// sort={sorting}
					// handleSort={handleSorting}
					handleSize={handleSize}
					count={count}
					page={params.page}
					size={params.size}
					handlePage={handlePage}
				/>
			</SectionRow>
		</div>
	);
};

export default UserManagement;
