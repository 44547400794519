import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

export default function LoaderComponent({ isOpen, setIsOpen }) {
	return (
		<div>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isOpen}
				onClick={() => {
					setIsOpen(false);
				}}>
				<div className='w-64 rounded-3xl h-44 bg-white flex justify-center items-center flex-col'>
					<div className='w-28 rounded-3xl h-28 flex justify-center items-center '>
						<div className='loader'></div>
					</div>
					<div className='text-[#20B05E] text-lg font-bold'>Loading...</div>
				</div>
			</Backdrop>
		</div>
	);
}
