import React, { useEffect, useState } from "react";
import ReviewWrapperNOCU from "../../../../components/wrappers/reviewWrapper";
import ToggleButtonSet from "../../../../components/buttons/ToggleButtonSet";
import FileButtonSet from "../../../../components/buttons/FileButtonSet";
import { APPROVED, DEFAULT } from "./../../../../Helpers/constants";
import DynamicStepper, { createStepper } from "./../../../../components/stepper/dynamicStepper";
import { CERTIFICATION_BODY_ROUTES } from "../../../../app/router/routes";
import {
	completeStep,
	activeStep,
	setCurrentStep,
} from "./../../../../components/stepper/dynamicStepper";
import { navigateSelf } from "../../../../app/router/routerManager";
import {
	getApplicationDetails,
	submitApplicationDetails,
} from "../../../../app/api/NOCU/applicationAPI";
import { getFileURL } from "../../../../app/api/file_api";
import { getStakeholderApplicationNumber } from "../../../../app/api/NOCU/helpers/applicationApiHelpers";
import {
	getStakeholderType,
	getUserId,
} from "./../../../../app/api/NOCU/helpers/applicationApiHelpers";
import { STATUS } from "../../../../app/constants";
import { comment } from "postcss";
import {
	deleteAllApplicationRejectDetails,
	getApplicationRejectDetailsAll,
	setAllRejections,
} from "../../../../Helpers/rejectionHelpers";
import { getRejctionsByStep } from "../../../../app/api/NOCU/rejectionAPI";
import { Button } from "@mui/material";
import {
	appendRejectionsFiles,
	appendRejectionsSingleForm,
	validateFiles,
} from "../../dataManipulation";
import { getApplicationStage } from "../../../../components/table/tableHelpers";

const CertificationBodyReviewStep1 = ({
	stepperData,
	stepperName,
	routeArray,
	setIsLoading,
	nextCustomRoute,
	customIndex,
	readOnly,
	hideFiles,
	hideBtns,
}) => {
	const [businessDetail, setBusinessDetail] = useState();
	const [form, setForm] = useState();
	const [rejections, setRejections] = useState();
	const [isDisabled, setDisabled] = useState(true);
	const [_files, _setFiles] = useState([
		{ name: "BRC", path: "", status: DEFAULT, comment: "" },
		{ name: "TIN", path: "", status: DEFAULT },
		{ name: "VAT", path: "", status: DEFAULT },
	]);
	const [canApprove, setCanApprove] = useState(false);
	const [reviewStatus, setReviewStatus] = useState();

	useEffect(() => {
		deleteAllApplicationRejectDetails();
		getApplicationDetails(1)
			.then((res) => {
				setReviewStatus(res?.stepStatus);
				setBusinessDetail(res?.businessDetailRequest);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				getRejctionsByStep(1)
					.then((res) => {
						setRejections(res.data);
						console.log("Rejection ======>", res);
					})
					.then((e) => {
						console.log(e);
					})
					.finally(() => {
						setIsLoading(false);
					});
			});
	}, []);

	useEffect(() => {
		setAllRejections(rejections?.rejectReasonDetailList || []);
	}, [rejections]);

	useEffect(() => {
		form &&
			rejections &&
			appendRejectionsSingleForm(
				getApplicationRejectDetailsAll(),
				form,
				setForm,
				businessDetail?.entityId
			);

		_files &&
			rejections &&
			appendRejectionsFiles(
				getApplicationRejectDetailsAll(),
				_files,
				_setFiles,
				businessDetail?.entityId
			);
	}, [rejections]);

	useEffect(() => {
		_setFiles([
			{
				name: "BRC",
				path: getFileURL(businessDetail?.brnFilePath),
				status: reviewStatus === "PENDING" ? DEFAULT : APPROVED,
				comment: "",
			},
			{
				name: "TIN",
				path: getFileURL(businessDetail?.tinFilePath),
				status: reviewStatus === "PENDING" ? DEFAULT : APPROVED,
				comment: "",
			},
			{
				name: "VAT",
				path: getFileURL(businessDetail?.vatFilePath),
				status: reviewStatus === "PENDING" ? DEFAULT : APPROVED,
				comment: "",
			},
		]);
		setForm([
			{
				name: "companyName",
				label: "Name of Certification Body",
				value: businessDetail?.companyName,
				isChecked: true,
			},
			{
				name: "businessAddress",
				label: "Business Address",
				value: businessDetail?.businessAddress,
				isChecked: true,
			},
			{
				name: "district",
				label: "District",
				value: businessDetail?.district,
				isChecked: true,
			},
			{
				name: "divisionalSecretariat",
				label: "Divisional Secretariat",
				value: businessDetail?.divisionalSecretariat,
				isChecked: true,
			},
			{
				name: "gramaNiladhariDivision",
				label: "Grama Niladhari Division",
				value: businessDetail?.gramaNiladhariDivision,
				isChecked: true,
			},
			{
				name: "email",
				label: "Email",
				value: businessDetail?.email,
				isChecked: true,
			},
			{
				name: "tin",
				label: "TIN",
				value: businessDetail?.tin,
				isChecked: true,
			},
			{
				name: "vat",
				label: "VAT",
				value: businessDetail?.vat,
				isChecked: true,
			},
			{
				name: "companyRegistrationType",
				label: "Type of Business Registration",
				value: businessDetail?.companyRegNumberType,
				isChecked: true,
			},
			{
				name: "companyRegNumber",
				label: "Company Registration No",
				value: businessDetail?.companyRegNumber,
				isChecked: true,
			},
			{
				name: "companyRegDate",
				label: "Company Registration Date",
				value: businessDetail?.companyRegDate,
				isChecked: true,
			},
			{
				name: "placeOfRegistration",
				label: "Place of registration",
				value: businessDetail?.companyPlaceofReg,
				isChecked: true,
			},
			{
				name: "website",
				label: "Website",
				value: businessDetail?.website,
				isChecked: true,
			},
			{
				name: "typeOfBusinessRegistration ",
				label: "Type of Business Registration ",
				value: businessDetail?.typeOfBusiness,
				isChecked: true,
			},
			{
				name: "otherServices",
				label: "Other Services",
				value: businessDetail?.otherServices,
				isChecked: true,
			},
		]);
	}, [businessDetail, reviewStatus]);
	const [selectedFile, setSelectedFile] = useState();
	const nameArray = [{ name: "Suran Mendis" }, { name: "Sudesh Kumara" }, { name: "Ruwan Perera" }];

	const handlePerson = (index) => {
		console.log("Index", index);
	};

	useEffect(() => {
		console.log("Files ============>", _files);
	}, [_files]);
	useEffect(() => {
		console.log("Selected File ============>", selectedFile?.path);
	}, [selectedFile]);

	const handleNext = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(CERTIFICATION_BODY_ROUTES.STEP02);
	};
	const handleCUstomNext = (route) => {
		navigateSelf(route);
	};

	const closeApplication = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(CERTIFICATION_BODY_ROUTES.HOME + "/1");
	};
	const handleResubmit = (comments) => {
		setIsLoading(true);
		console.log("Resubmit Comments", comments);
		submitApplicationDetails(
			1,
			STATUS.RESUBMIT,
			comments,
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleSubmit = () => {
		setIsLoading(true);
		submitApplicationDetails(
			1,
			STATUS.APPROVED,
			"",
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleReject = (comments) => {
		setIsLoading(true);
		console.log("Reject Comments", comments);
		submitApplicationDetails(
			1,
			STATUS.REJECTED,
			comments,
			getApplicationRejectDetailsAll(),
			false,
			[],
			getApplicationStage(),
			null,
			rejections?.reasonId
		)
			.then((res) => {
				closeApplication();
			})
			.catch((e) => console.log(e));
	};
	const updateFileArray = (updatedFiles) => {
		_setFiles(updatedFiles);
	};

	useEffect(() => {
		if (form && _files) {
			const dataFalseValue = form.some((item) => !item.isChecked);
			const filesFalseValue = _files.some((item) => item.status == "REJECTED");
			if (dataFalseValue || filesFalseValue) setDisabled(false);
			else setDisabled(true);
		}
	}, [form, _files]);

	const handleResubmitDisable = (data, files) => {
		const dataFalseValue = data.some((item) => !item.isChecked);
		const filesFalseValue = files.some((item) => item.status == "REJECTED");

		if (dataFalseValue || filesFalseValue) setDisabled(false);
		else setDisabled(true);
	};

	useEffect(() => {
		_files && validateFiles(_files) ? setCanApprove(true) : setCanApprove(false);
		console.log("Wrapper Files=====>", _files);
	}, [_files]);

	return (
		<div>
			<ReviewWrapperNOCU
				hideBtns={hideBtns}
				readOnly={readOnly}
				index={customIndex || 0}
				entityId={businessDetail?.entityId}
				title={"Business Details"}
				setForm={setForm}
				stepperName={stepperName}
				files={_files}
				isDisabled={isDisabled}
				// formToggleSwitch={<ToggleButtonSet data={nameArray} handleClick={handlePerson} />}
				topBar={
					stepperData &&
					stepperName && (
						<DynamicStepper name={stepperName} stepperData={stepperData} routeArray={routeArray} />
					)
				}
				formData={form}
				currentFile={selectedFile}
				fileButtons={
					!!!hideFiles && (
						<FileButtonSet
							hideBtns={hideBtns}
							files={_files}
							readOnly={readOnly}
							setFiles={_setFiles}
							selectedFile={selectedFile}
							updateFileArray={updateFileArray}
							setSelectedFile={setSelectedFile}
							entityId={businessDetail?.entityId}
							setDisabled={handleResubmitDisable}
							formData={form}
						/>
					)
				}
				handleReject={handleReject}
				handleResubmit={handleResubmit}
				handleNext={handleSubmit}
				setDisabled={handleResubmitDisable}
				canApprove={canApprove}
				reviewed={
					nextCustomRoute ? () => handleCUstomNext(nextCustomRoute) : readOnly ? handleNext : null
				}
			/>
		</div>
	);
};

export default CertificationBodyReviewStep1;
