import React from "react";
import { SectionRow } from "../../layouts/sections";
import ImageViewer from "./ImageViewer";


export default function ImageWrapper({ data }) {
    console.log("Data ===========>", data);
    return (
        <SectionRow className='2xl:w-[700px]  justify-center  overflow-visible'>
            <ImageViewer data={data} />
        </SectionRow>
    );
};