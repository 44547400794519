import { userRoles } from "../../Helpers/constants";
import { getUserRoleFromLocal } from "../../Helpers/loginHelper";
import { COMPANY_REG_ROUTE } from "./routes";
import { setCurrentTabActive } from "../../features/auth/checkUserLogin";

export const navigateNewTab = (route) => {
	setCurrentTabActive();
	window.open(route);
};

export const navigateSelf = (route) => {
	window.open(route, "_self");
};

export const saveCurrentRoute = () => {
	setCurrentTabActive();
	console.log("Current Route", window.location.pathname);
	localStorage.setItem("backLink", window.location.pathname);
};

export const getCurrentRoute = () => {
	return localStorage.getItem("backLink");
};
