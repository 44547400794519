import CustomAxios from "../customAxios/customAxios";
import { API_BASE_URL } from "./../core_api";

export const getDashBoardCounts = async (data) => {
    return CustomAxios({
        method: "POST",
        baseURL: API_BASE_URL,
        url: `/dashboard/v1/get-count`,
        headers: {},
        data: data,
    })
        .then((res) => {
            console.log("getDashBoardCounts", res);
            return res;
        })
        .catch((error) => {
            console.log("getDashBoardCounts error!");
            throw error.response;
        });
};