import React from 'react';

function TabContent(props) {
    return (
        <div className={`tab-content ${props.active ? 'active' : ''}`}>
            {/* Filter controls go here */}
        </div>
    );
}

export default TabContent;