import React from "react";
import { navigateNewTab, navigateSelf } from "../../app/router/routerManager";
import { INQUIRY_ROUTE } from "../../app/router/routes";
import { setEligibilityTicketId } from "./eligibilityCheck/helpers/sessionHelpers";
import { Button } from "@mui/material";
import StatCard from "../../components/cards/statCard";
import { SectionColumn, SectionRow } from "../../components/section";
import { getInquiriesByStatus } from "../../app/api/inquiry_api";
import { getAllCount } from "../../app/api/core_api";
import Eye from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { COMPANY_REG_ROUTE } from "../../app/router/routes";
import { Table } from "react-bootstrap";
import UpdateIcon from "@mui/icons-material/Update";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import loadingGif from "../../assets/images/Icons/loadingGif.gif";
import PromptBase from "../../components/model/promptBase";
import { tailMock } from "../../app/api/mock/registration";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
	getUserIDFromLocal,
	setProductId,
	setCompanyDCOM,
	setCostStatementID,
	setCompanyID,
	getRoleIDFromLocal,
} from "../../Helpers/loginHelper";
import { navigateApprovalProcess } from "../../app/router/routerManager";
import { PENDING, APPROVED, REJECTED, RESUBMISSION } from "../../Helpers/constants";
import {
	PendingImageLink,
	ResubImageLink,
	CompleteImageLink,
	RejectedImageLink,
} from "../../Helpers/statCardImage";
import GeneralInquiryTable from "./generalInquiry/generalInquiryTable";
import { getGeneralInquiryByStatus } from "../../app/api/inquiryApi/generalInquiry/generalInquiryApi";

let tableBodyData = [];
let currentCompanies = [];
let selectStatCard = 1;
let columnsForPending = ["Ticket ID", "Exporter Email", "Date", "Status", "View", "History"];
let columns = ["Ticket ID", "Exporter Email", "Date", "Status", "History"];
let columnsForGeneralInquiry = [
	"Ticket ID",
	"Exporter Email",
	"Created Date",
	"Status",
	"Inquiry Type",
];
let companiesArray = [];
let inquiryStatuses = [
	{ key: "PENDING", value: "PENDING" },
	{ key: "REPLIED", value: "CHECKED" },
];
class InquiryTable extends React.Component {
	constructor() {
		super();
		this.container = React.createRef();
		this.state = {
			pendingCount: "",
			resubCount: "",
			completedCount: "",
			rejectedCount: "",
			currentTotalCount: "",
			maxPages: 1000,
			searchFilter: "",
			isOpen: false,
			userID: "",
			companyIdForTrail: "",
			resultFetched: false,
			tableHeaders: [],
			selectedStatCard: PENDING,
			currentPage: 0,
			rowsPerPage: 5,
			generalInquiryCount: "",
			selectedTable: "EC",
		};
	}
	setGeneralInquiryCount = (count) => {
		this.setState({ generalInquiryCount: count });
	};
	componentDidMount = async () => {
		if (sessionStorage.getItem("inquiryTableType") === "GI") {
			selectStatCard = 2;
			await this.setState({
				selectedTable: "GI",
				// selectedStatCard: "GENERAL_INQUIRIES",
			});
		} else {
			await this.setState({
				selectedTable: "EC",
			});
		}
		await this.setState({
			tableHeaders: columnsForPending,
		});
		console.log("headers", this.state.tableHeaders);

		if (companiesArray.length == 0) {
			await this.getResponce();
			console.log("currentTotalCount", this.state.currentTotalCount);
		}

		let responseCount = {};
		await getAllCount().then((resCount) => {
			responseCount = resCount.data;
			console.log("resCount", resCount);
			console.log("responseCount", responseCount);
		});
		let generalInquiryCountParams = {
			page: 0,
			size: 5,
			status: "PENDING",
		};
		await getGeneralInquiryByStatus(generalInquiryCountParams).then((res) => {
			this.setGeneralInquiryCount(res.data.count);
		});

		const userID = getRoleIDFromLocal();
		console.log("getCompanyRegStatus USER:", userID);

		await this.setState({
			pendingCount: responseCount?.eligibilityCheck?.pendingCount,
			resubCount: responseCount?.eligibilityCheck?.resubmissionCount,
			completedCount: responseCount?.eligibilityCheck?.completedCount,
			rejectedCount: responseCount?.eligibilityCheck?.rejectedCount,
			currentTotalCount: responseCount?.eligibilityCheck?.pendingCount,
			userID: userID,
		});
	};

	getResponce = async () => {
		let response = {};

		companiesArray = [];
		tableBodyData = [];

		let params = {
			page: this.state.currentPage,
			size: this.state.rowsPerPage,
			pagination: true,
			status: this.state.selectedStatCard,
		};

		await getInquiriesByStatus(params).then((res) => {
			response = res.data.tickets;
			console.log("res", res);
			console.log("response", response);
		});

		companiesArray = response;

		console.log("companiesArray", companiesArray);

		await this.setDataForTableRows();
	};

	setDataForTableRows = async () => {
		tableBodyData = [];
		await this.setState({
			resultFetched: false,
		});
		await companiesArray?.map((d, i) => {
			const companyDetailArray = [];
			companyDetailArray[0] = d.ticketId ? d.ticketId : "-";
			companyDetailArray[1] = d.exporterEmail ? d.exporterEmail : "-";
			companyDetailArray[2] = "Date";
			companyDetailArray[3] = d.status ? d.status : "-";
			tableBodyData[i] = companyDetailArray;
		});

		console.log("tableBodyData", tableBodyData);
		await this.setState({
			resultFetched: true,
		});
		console.log("resultFetched", this.state.resultFetched);
	};

	statCard = async (e) => {
		tableBodyData = [];
		let string = e.target.value;
		await this.setState({
			selectedStatCard: string,
			currentPage: 0,
		});

		if (string === PENDING) {
			await this.setState({
				currentTotalCount: this.state.pendingCount,
				tableHeaders: columnsForPending,
			});
		} else if (string === "GENERAL_INQUIRIES") {
			await this.setState({
				currentTotalCount: this.state.resubCount,
				tableHeaders: columns,
			});
		} else if (string === REJECTED) {
			await this.setState({
				currentTotalCount: this.state.rejectedCount,
				tableHeaders: columns,
			});
		} else if (string === APPROVED) {
			await this.setState({
				currentTotalCount: this.state.completedCount,
				tableHeaders: columns,
			});
		}
		console.log("selectedStatCard", this.state.selectedStatCard);
		console.log("currentTotalCount", this.state.currentTotalCount);

		await this.getResponce();
		await this.totalPagesCounter();
	};

	rowsPerPageHandler = async (e) => {
		await this.setState({
			rowsPerPage: e.target.value,
			currentPage: 0,
		});

		// call get responce
		console.log("rowsPerPage", this.state.rowsPerPage);
		console.log("currentPage", this.state.currentPage);

		await this.getResponce();
		await this.totalPagesCounter();
	};

	prevPageHandler = async () => {
		if (this.state.currentPage > 0) {
			await this.setState({
				currentPage: this.state.currentPage - 1,
			});
		}

		// call get responce
		console.log("currentPage", this.state.currentPage);

		await this.getResponce();
	};

	nextPageHandler = async () => {
		// if (this.state.currentPage <= totalPages) {
		await this.setState({
			currentPage: this.state.currentPage + 1,
		});
		// }

		// call get responce
		console.log("currentPage", this.state.currentPage);

		await this.getResponce();
	};

	searchEventsHandler = async (title) => {
		await this.setState({
			searchFilter: title,
		});
		console.log("searchFilter", this.state.searchFilter);
	};

	totalPagesCounter = async () => {
		let totalPages = "";
		if (this.state.currentTotalCount % this.state.rowsPerPage > 0) {
			totalPages = Math.floor(this.state.currentTotalCount / this.state.rowsPerPage) + 1;
		} else {
			totalPages = Math.floor(this.state.currentTotalCount / this.state.rowsPerPage);
		}

		console.log("totalPages", totalPages);
		await this.setState({
			maxPages: totalPages,
		});
	};

	render() {
		currentCompanies = tableBodyData;

		const filteredData =
			currentCompanies &&
			currentCompanies.filter(
				(obj) => obj[1].toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) !== -1
			);

		currentCompanies = filteredData;

		let showingPage = this.state.currentPage + 1;
		const setSelectStatCard = (num) => {
			selectStatCard = num;
		};

		return (
			<div>
				<SectionRow className='mb-5'>
					<span className='heading-1'>Inquiry</span>
				</SectionRow>
				<SectionRow className='flex-wrap section-row-1 mb-6 sm:mb-0 dashboard-row !justify-start'>
					<StatCard
						value={this.state.pendingCount}
						text={"Eligibility Check Inquiries"}
						image={PendingImageLink}
						accent={"#E6E9FF"}
						handleStatCard={() => {
							sessionStorage.setItem("inquiryTableType", "EC");
							this.setState({ selectedTable: "EC" });
						}}
						num={1}
						selected={selectStatCard}
						setSelectStatCard={setSelectStatCard}
					/>
					<StatCard
						value={this.state.generalInquiryCount}
						text={"General Inquiries"}
						image={ResubImageLink}
						accent={"#FFF5D2"}
						handleStatCard={() => {
							sessionStorage.setItem("inquiryTableType", "GI");
							this.setState({ selectedTable: "GI" });
						}}
						num={2}
						selected={selectStatCard}
						setSelectStatCard={setSelectStatCard}
					/>
					{/* <StatCard
						value={this.state.resubCount}
						text={"Resubmitted Inquiry Applications"}
						image={ResubImageLink}
						accent={"#FFF5D2"}
						handleStatCard={() => this.statCard(RESUBMISSION)}
						num={2}
						selected={selectStatCard}
						setSelectStatCard={setSelectStatCard}
					/>
					<StatCard
						value={this.state.completedCount}
						text={"Approved Inquiry Applications"}
						image={CompleteImageLink}
						accent={"#E3FEF3"}
						handleStatCard={() => this.statCard(APPROVED)}
						num={3}
						selected={selectStatCard}
						setSelectStatCard={setSelectStatCard}
					/>
					<StatCard
						value={this.state.rejectedCount}
						text={"Rejected Inquiry Applications"}
						image={RejectedImageLink}
						accent={"#ffcdd2"}
						handleStatCard={() => this.statCard(REJECTED)}
						num={4}
						selected={selectStatCard}
						setSelectStatCard={setSelectStatCard}
					/> */}
				</SectionRow>
				{this.state.selectedTable === "EC" ? (
					<div className='companyRegTbleContainer'>
						<Grid container>
							<Grid item xs={3} style={{ borderTopLeftRadius: "15px" }} className='tableHeaderItem'>
								<Chip icon={<HowToRegIcon />} label='Inquiry List' />
							</Grid>
							<Grid item xs={6} className='tableHeaderItem'>
								<div className='perPageContainer'>
									<label className='mr-2'> Filter By Status :</label>
									<select
										className='perPage'
										name='size'
										id='size'
										onChange={(e) => this.statCard(e)}>
										{inquiryStatuses.map((fbb) => (
											<option key={fbb.key} value={fbb.value}>
												{fbb.key}
											</option>
										))}
										;
										{/* <option label="ALL" value="ALL" />
									<option label="CHECKED" value="CHECKED" />
									<option label="RECOMMENDED" value="RECOMMENDED" />
									<option label="APPROVED" value="APPROVED" />
									<option label="CRO_REJECTED" value="CRO_REJECTED" />
									<option label="AD_REJECTED" value="AD_REJECTED" />
									<option label="DG_REJECTED" value="DG_REJECTED" /> */}
									</select>
								</div>
							</Grid>
							<Grid
								item
								xs={3}
								style={{ borderTopRightRadius: "15px" }}
								className='tableHeaderSearch'>
								<input
									placeholder='Search by Exporter Email'
									className='searchIconText'
									onChange={(e) => this.searchEventsHandler(e.target.value)}
								/>
							</Grid>
						</Grid>
						<Table responsive>
							<thead>
								<tr>
									{this.state.tableHeaders.map((column) => (
										<th style={{ verticalAlign: "initial" }} className='thBtn'>
											{column}
										</th>
									))}
								</tr>
							</thead>
							{this.state.currentTotalCount === 0 && currentCompanies.length === 0 ? (
								<tbody className='tbody'>
									<tr align='center'>
										<td colSpan='12'>
											<br />
											<div style={{ fontSize: "10px", fontWeight: "bold" }}>
												{" "}
												No Inquiry Details Found{" "}
											</div>
										</td>
									</tr>
								</tbody>
							) : (
								<tbody className='tbody'>
									{currentCompanies.length === 0 && this.state.resultFetched === true ? (
										<tr align='center'>
											<td colSpan='12'>
												<br />
												<img src={loadingGif} alt='loading...' />
											</td>
										</tr>
									) : (
										currentCompanies.map((s, i) => (
											<tr>
												{s.slice(0, 3).map((row) => (
													<td className='tdStart'>{row}</td>
												))}
												{this.state.selectedStatCard === PENDING ? (
													<td className='tdStart'>
														<Chip label='PENDING' color='primary' />
													</td>
												) : (
													<td className='tdStart'>
														<Chip label='REPLIED' color='success' />
													</td>
												)}
												<td className='tdStart'>
													<div className='justify-center flex '>
														<Eye
															onClick={() => {
																let ticketId = s[0];
																setEligibilityTicketId(ticketId);
																navigateNewTab(
																	this.state.selectedStatCard === PENDING
																		? INQUIRY_ROUTE.ELIGIBILITY_CHECK
																		: INQUIRY_ROUTE.ELIGIBILITY_CHECK
																);
															}}></Eye>
													</div>
												</td>
												<td className={currentCompanies.length === i + 1 ? "tdLastEnd" : "tdStart"}>
													<div className='justify-center flex '>
														<UpdateIcon
															onClick={() => {
																this.setState({
																	isOpen: true,
																	companyIdForTrail: s.companyId,
																});
															}}></UpdateIcon>
													</div>
												</td>
											</tr>
										))
									)}
								</tbody>
							)}
							<PromptBase
								companyID={this.state.companyIdForTrail}
								data={tailMock}
								isOpen={this.state.isOpen}
								handleDismiss={() => this.setState({ isOpen: false })}
							/>
						</Table>
						<Grid container>
							<Grid
								item
								xs={6}
								style={{ borderBottomLeftRadius: "15px" }}
								className='paginationItem'></Grid>
							<Grid item xs={3} className='paginationItem'>
								<div>
									<label style={{ margin: "0px" }}> Rows per page  </label>
									<select
										className='perPage'
										style={{ cursor: "pointer" }}
										name='size'
										id='size'
										onChange={this.rowsPerPageHandler.bind(this)}>
										<option label='5' value='5' />
										<option label='10' value='10' />
										<option label='25' value='25' />
									</select>
								</div>
							</Grid>
							{/* <Grid item xs={2} className="paginationItem">
              <label>
                <>Showing Page</>&nbsp;
                <>{this.state.currentPage + 1}</>
              </label>
            </Grid> */}
							<Grid
								item
								xs={3}
								style={{ borderBottomRightRadius: "15px" }}
								className='paginationItem'>
								<div>
									{this.state.currentPage <= 0 ? (
										<ArrowBackIosIcon
											style={{ fontSize: "medium" }}
											color='disabled'></ArrowBackIosIcon>
									) : (
										<ArrowBackIosIcon
											style={{ fontSize: "medium" }}
											onClick={() => {
												this.prevPageHandler();
											}}></ArrowBackIosIcon>
									)}
									<Chip label={"Showing Page " + showingPage} color='primary' size='small' />
									{this.state.maxPages > this.state.currentPage + 1 ? (
										<ArrowForwardIosIcon
											style={{ fontSize: "medium" }}
											onClick={() => {
												this.nextPageHandler();
											}}></ArrowForwardIosIcon>
									) : (
										<ArrowForwardIosIcon
											style={{ fontSize: "medium" }}
											color='disabled'></ArrowForwardIosIcon>
									)}
								</div>
							</Grid>
						</Grid>
					</div>
				) : (
					<GeneralInquiryTable setCount={this.setGeneralInquiryCount} />
				)}
				<SectionRow className='flex-wrap section-row-1 dashboard-row'>
					<SectionColumn className='w-full   relative '></SectionColumn>
				</SectionRow>
			</div>
		);
	}
}

export default InquiryTable;
