import React, { useEffect, useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "../../../Helpers/ICONS";
import { EXPORTER_ROUTES } from "../../../app/router/routes";
import { SectionColumn } from "../../../layouts/sections";
import { Route } from "react-router-dom";

import { createStepper } from "../../../components/stepper/dynamicStepper";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import { getStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { convertStepperRes } from "./dataManipulation";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import ImporterStep3 from "./flow/ExporterStep3";
import ImporterStep4 from "./flow/ExporterStep5";
import ImporterStep5 from "./flow/ExporterStep6";
import ImporterStep6 from "./flow/ExporterStep7";
import ImporterStep7 from "./flow/ExporterStep8";
import ExporterStep1 from "./flow/ExporterStep1";
import ExporterStep2 from "./flow/ExporterStep2";
import ExporterStep3 from "./flow/ExporterStep3";
import ExporterStep4 from "./flow/ExporterStep4";
import ExporterStep8 from "./flow/ExporterStep8";
import ExporterStep5 from "./flow/ExporterStep5";
import ExporterStep6 from "./flow/ExporterStep6";
import ExporterStep7 from "./flow/ExporterStep7";
import { getApplicationStatusOnSession } from "../../certificationBody/dataManipulation";

export const stepperName = "EXPORTER";

const ExpoterReviewHome = () => {
	const [res, setRes] = useState();
	const [stepper, setStepper] = useState();
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getStepStatusByApplicationId(getStakeholderApplicationNumber())
			.then((res) => {
				setRes(res.data);
				console.log("Data", res.data);
				console.log("Stepper=====>", convertStepperRes(res.data));
				setStepper(convertStepperRes(res.data));
			})
			.catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		stepper && setStepperData(createStepper(stepperName, stepper));
	}, [stepper]);

	const routeArray = [
		EXPORTER_ROUTES.STEP01,
		EXPORTER_ROUTES.STEP02,
		EXPORTER_ROUTES.STEP03,
		EXPORTER_ROUTES.STEP04,
		EXPORTER_ROUTES.STEP05,
		EXPORTER_ROUTES.STEP06,
		EXPORTER_ROUTES.STEP07,
		EXPORTER_ROUTES.STEP08,
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Exporter"}
				tableIcon={DASHBOARD_ICONS.importer}
				tableURL={EXPORTER_ROUTES.HOME}
			/>

			{res && stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={EXPORTER_ROUTES.STEP01}>
						<ExporterStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[0]?.reviewStatus === "APPROVED" || res[0]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={EXPORTER_ROUTES.STEP02}>
						<ExporterStep2
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[1]?.reviewStatus === "APPROVED" || res[1]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={EXPORTER_ROUTES.STEP03}>
						<ExporterStep3
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[2]?.reviewStatus === "APPROVED" || res[2]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={EXPORTER_ROUTES.STEP04}>
						<ExporterStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[5]?.reviewStatus === "APPROVED" || res[5]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={EXPORTER_ROUTES.STEP05}>
						<ExporterStep5
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[6]?.reviewStatus === "APPROVED" || res[6]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={EXPORTER_ROUTES.STEP06}>
						<ExporterStep6
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[7]?.reviewStatus === "APPROVED" || res[7]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={EXPORTER_ROUTES.STEP07}>
						<ExporterStep7
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[3]?.reviewStatus === "APPROVED" || res[3]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={EXPORTER_ROUTES.STEP08}>
						<ExporterStep8
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[4]?.reviewStatus === "APPROVED" || res[4]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default ExpoterReviewHome;
