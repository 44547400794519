import Eye from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import HomeTable from "../../components/table/HomeTable";
import { showViewIcon } from "../../components/table/tableHelpers";
import { SectionRow } from "../../layouts/sections";

import { getRecordsForPaymentTable } from "../../app/api/NOCU/paymentAPI";
import FullScreenDialog from "../../components/model/FullScreenDialog";
import { getTableData } from "./dataHelper";
import ViewPayment from "./ViewPayment";
import CommonSelection from "../../components/section/commonSelection";
import TableCount from "../../components/section/tableCount";

const PaymentHome = () => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState();
	const [records, setRecords] = useState();
	const [tableCount, setTableCount] = useState();
	const [currentPayment, setCurrentPayment] = useState();
	const [counts, setCounts] = useState({
		pendingCount: 0,
		approvedCount: 0,
		rejectedCount: 0,
		resubmissionCount: 0,
		resubmitPendingCount: 0,
	});
	const [params, setParams] = useState({
		page: 0,
		size: 5,
		paymentType: ["STAKEHOLDER", "PRODUCT_CATEGORY"],
		paymentStatus: ["PAID"],
		isAscending: true,
		paymentReceiptNumber: "",
	});

	const tableHeaders = [
		"Payment Receipt",
		"Reference Id",
		"Payment Type",
		"Payment Method",
		"Payment Date",
	];
	let filters = [{ key: "Paid", value: "PAID" }];
	let typeFilters = [
		{ key: "All ", value: ["ALL"] },
		{ key: "Stakeholder ", value: ["STAKEHOLDER"] },
		{ key: "Product Category ", value: ["PRODUCT_CATEGORY"] },
	];

	const handleFilter = (filter) => {
		setParams({ ...params, paymentStatus: [filter] });
	};
	const handleSearchById = (id) => {
		setParams({ ...params, paymentReceiptNumber: id });
	};

	const handleSorting = (isAscending) => {
		setParams({ ...params, isAscending: isAscending });
	};
	const handlePage = (page) => {
		setParams({ ...params, page: page });
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size });
	};
	const handleTypeFilter = (type) => {
		type === "ALL"
			? setParams({ ...params, paymentType: ["STAKEHOLDER", "PRODUCT_CATEGORY"] })
			: setParams({ ...params, paymentType: [type] });
	};

	const viewFunction = (index) => {
		console.log("Index Of record=================>", index);
		setCurrentPayment(response?.paymentDetails[index]);
	};
	const historyFunction = (index) => {
		console.log("Index Of record=================>", index);
	};

	useEffect(() => {
		setIsLoading(true);
		console.log("Params ====>", params);

		getRecordsForPaymentTable(params)
			.then((res) => {
				console.log("Res ======> ", res.data);
				setRecords(getTableData(res.data));
				setTableCount(res.data?.count);
				setResponse(res.data);
			})
			.then((e) => console.log(e))
			.finally(() => {
				setIsLoading(false);
			});
	}, [params]);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0 pb-10'>
				<span className='overViewTitle'>Payments</span>
			</SectionRow>

			<HomeTable
				customViewIcon={
					<FullScreenDialog
						title={"Payment Details"}
						button={<Eye />}
						children={<ViewPayment currentPayment={currentPayment} />}
					/>
				}
				customFilter={
					<SectionRow className=' '>
						<CommonSelection
							filters={typeFilters}
							onChange={handleTypeFilter}
							title={`Filter By Payment Type`}
						/>
						<TableCount count={tableCount} />
					</SectionRow>
				}
				applicationKey={1}
				columns={tableHeaders}
				handleSearchById={handleSearchById}
				customSearchPlaceHolder={"Payment Receipt"}
				rows={records}
				view={showViewIcon(["1"], id) ? viewFunction : null}
				// history={historyFunction}
				// filters={filters}
				setFilter={handleFilter}
				// sort={sorting}
				handleSort={handleSorting}
				handleSize={handleSize}
				count={tableCount}
				page={params.page}
				size={params.size}
				handlePage={handlePage}
			/>
		</div>
	);
};

export default PaymentHome;
