import React, { useEffect } from "react";
import { useState } from "react";
import { getFileURL } from "../../app/api/file_api";
import { submitFinalApplication } from "../../app/api/NOCU/applicationAPI";
import { fileServerSave } from "../../app/api/NOCU/filserverAPI";
import {
	getMappedApplicationNumber,
	getStakeholderApplicationNumber,
	getUserId,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getMapApplicationDetails } from "../../app/api/NOCU/tableAPI";
import { navigateSelf } from "../../app/router/routerManager";
import {
	FINAL_VOTE_VERIFICATION_ROUTES,
	TECHNICAL_COMMITTEE_ROUTES,
} from "../../app/router/routes";
import AcceptButton from "../../components/buttons/acceptbutton";
import RejectButton from "../../components/buttons/rejectButton";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import ConfirmationPopup from "../../components/model/ConfirmationPopup";
import NavBar from "../../components/navbar/NavBar";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import { SectionColumn, SectionRow } from "../../components/section";
import VotingTable from "../../components/table/VotingTable";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import { getVotingTableData } from "../certificationBody/dataManipulation";
import { Divider } from "@mui/material";
import { Stack } from "@mui/material";
import DocListView from "../technicalCommittee/components/DocListView";

const FinalVote = () => {
	const tableHeaders = ["Committee Member", "Vote Status", "Remark"];

	const [param, setParams] = useState({
		mappedApplicationNumber: getMappedApplicationNumber(),
		remark: "",
		reviewStatus: "",
		checkedBy: getUserId(),
		applicationStage: "TECHNICAL_COMMITTEE",
	});

	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [color, setColor] = useState("success");
	const [_file, _setFile] = useState();
	const [_votes, _setVotes] = useState();

	useEffect(() => {
		const mapApplicationKey = getMappedApplicationNumber();

		getMapApplicationDetails(mapApplicationKey)
			.then((res) => {
				_setFile(res.data?.summeryReportPath);
				_setVotes(getVotingTableData(res.data));
				setIsLoading(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	const BackwordNavigation = () => {
		navigateSelf(FINAL_VOTE_VERIFICATION_ROUTES.HOME + "/1");
	};

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				mappedApplicationNumber
				title={"Final Vote Verificaton"}
				tableIcon={DASHBOARD_ICONS.technicalCommittee}
				tableURL={FINAL_VOTE_VERIFICATION_ROUTES.HOME}
			/>
			<SectionRow className='w-full'>
				<SectionRow className='w-full   lg:w-1/2 mt-5 p-5 '>
					<DocListView
						editable={true}
						title={"Requested documents from Technical Committee Members"}
					/>
				</SectionRow>
				<SectionRow className='w-full  lg:w-1/2 mt-5 p-5'>
					<SectionColumn className='w-full lg:w-10/12 mx-auto min-h-[75vh] z-10'>
						<VotingTable rows={_votes} columns={tableHeaders} />
						<div className='flex justify-center mt-3'>
							<RejectButton
								className={""}
								handleClick={() => {
									BackwordNavigation();
								}}
								name='rejectButton'
								title='Close'
								disabled={false}
							/>
						</div>
					</SectionColumn>
					{/* <SectionColumn className='w-full lg:w-1/2 min-h-[75vh] z-10 '>
                    <IFrameViewer path={getFileURL(_file)} files={true} />
                </SectionColumn> */}
				</SectionRow>
			</SectionRow>
		</div>
	);
};

export default FinalVote;
