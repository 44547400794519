import { API_BASE_URL } from "../core_api";
import CustomAxios from "../customAxios/customAxios";

export const fileServerSave = async (file) => {
	const data = { file: file, tagname: "test" };
	const bodyFormData = new FormData();
	bodyFormData.append("tagname", data.tagname);
	bodyFormData.append("file", data.file);

	console.log(
		Object.fromEntries(bodyFormData),
		"**************File Server request API***************"
	);
	return await CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/file/save",
		data: bodyFormData,
	})
		.then((response) => {
			console.log(response, "*************File Server response*******************");
			return response.data;
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fileSaveCertUpload = async (file) => {
	const data = { file: file, tagname: "test" };
	const bodyFormData = new FormData();
	bodyFormData.append("tagname", data.tagname);
	bodyFormData.append("file", data.file.file[0]);

	console.log(
		Object.fromEntries(bodyFormData),
		"**************File Server Certificate Upload request API***************"
	);
	return await CustomAxios({
		method: "POST",
		baseURL: API_BASE_URL,
		url: "/file/save",
		data: bodyFormData,
	})
		.then((response) => {
			console.log(response, "*************File Server Certificate Upload response*******************");
			return response.data;
		})
		.catch((error) => {
			console.log(error);
		});
};

/**
 * File Delete
 * params: fileServerDTO
 */
export const fileDelete = async (filePath) => {
	return await CustomAxios({
		method: "DELETE",
		baseURL: API_BASE_URL,
		url: "/file/delete",
		data: {
			"fileId": filePath
		},
	})
		.then((response) => {
			if (response.data.responseCode === "200 OK") {
				console.log(response, "*************File Delete response*******************");
				return response.data;
			}
		})
		.catch((error) => {
			console.log(error);
		});
};