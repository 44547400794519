import {
	getCompanyIdFromLocal,
	getIsResubmission,
	getRoleIDFromLocal,
	getUserIDFromLocal,
} from "../../Helpers/loginHelper";
import { getApprovedStatus } from "../../Helpers/uiHelper";
import { convertApprovalsTo, convertRejectDocs } from "./apiManipulation";
import { getCompanyTrailAPI } from "./comRegAPI";
import {
	finalApprovalAPI,
	getFromStep,
	getFromStep1API,
	getFromStep2API,
	getFromStep3API,
	getFromStep4API,
	getFromStep5API,
	getRegRequestAPI,
	finalApprovalStatusUpdateAPI,
	postTrial,
	getTrial,
	submitApprovalAPI,
} from "./core_api";
import { getFileAPI } from "./file_api";

export const getRegRequest_adapter = () => {
	const res = getRegRequestAPI();
	// manipulate object
	return res;
};

export const getFromStep_adapter = () => {
	const res = getFromStep();
	// manipulate object
	return res;
};

export const getFromStep1_adapter = async (id) => {
	try {
		const res = await getFromStep1API(id);
		console.log("adapter :", res);
		return res;
	} catch (err) {
		console.log("adapter error :", err);
		throw err;
	}
};

export const getFromStep2_adapter = async (id) => {
	try {
		const res = await getFromStep2API(id);
		console.log("adapter :", res);
		return res;
	} catch (err) {
		console.log("adapter error :", err);
		throw err;
	}
};

export const getFromStep3_adapter = async (id) => {
	try {
		const res = await getFromStep3API(id);
		console.log("adapter :", res);
		return res;
	} catch (err) {
		console.log("adapter error :", err);
		throw err;
	}
};

export const getFromStep4_adapter = async (id) => {
	try {
		const res = await getFromStep4API(id);
		console.log("adapter :", res);
		return res;
	} catch (err) {
		console.log("adapter error :", err);
		throw err;
	}
};

export const getFromStep5_adapter = async (id) => {
	try {
		const res = await getFromStep5API(id);
		console.log("adapter :", res);
		return res;
	} catch (err) {
		console.log("adapter error :", err);
		throw err;
	}
};

export const getFileAPI_adapter = async (fileID) => {
	try {
		const res = await getFileAPI(fileID);
		console.log("adapter :", res);
		return res;
	} catch (err) {
		console.log("adapter error :", err);
		throw err;
	}
};

export const finalApprovalAPI_adapter = async (data, cpID) => {
	// const userID =  getUserIDFromLocal();
	// const dataShape = {
	//   step01:{status:'APROVE',comment: "ඒැිඒවවිාය"},
	//   step02:{status:'APROVE',comment: "නනිදාස"},
	//   step03:{status:'APROVE',comment: "jkasdh"},
	//   step04:{status:'APROVE',comment: "oowue"},
	//   step05:{status:'APROVE',comment: "lckmkssh"}
	// }

	console.log("Approval steps comment data >>>>>>>>", data);
	try {
		const res = await finalApprovalAPI(data, cpID);
		console.log("Approval steps adapter response   >>>>>>>> :", res);
		return res;
	} catch (err) {
		console.log("adapter error :", err);
		throw err;
	}
};
export const finalApprovalStatusUpdateAPI_adapter = async (approvals, companyId) => {
	const data = convertApprovalsTo(approvals);
	try {
		const res = await finalApprovalStatusUpdateAPI(data, companyId);
		console.log("Approval Status adapter response   >>>>>>>> :", res);
		return res;
	} catch (err) {
		console.log("adapter error :", err);
		throw err;
	}
};

export const postTrial_adapter = async (appover, comment) => {
	const request = {
		applicationType: "COMPANY REGISTRATION",
		officer: getRoleIDFromLocal(),
		applicationStatus: appover || "CRO_APPROVED",
		remarks: comment || "No Issues",
		cpCompanyId: {
			id: getCompanyIdFromLocal(),
		},
	};

	try {
		const res = await postTrial(request);
		console.log("postTrial adapter :", res);
		return res;
	} catch (err) {
		console.log("postTrial adapter error :", err);
		throw err;
	}
};

export const getTrial_adapter = async (id) => {
	try {
		const res = await getTrial(id);
		console.log("getTrial adapter :", res);
		return res;
	} catch (err) {
		console.log("getTrial adapter error :", err);
		throw err;
	}
};

export const submitApprovalAPI_adapter = async (userID, companyID, comments, approvals, status) => {
	const data = {
		// statues: getApprovedStatus(comments),
		// statues: getStatusByUser(status),
		statues: status,
		officerId: userID,
		companyId: Number(companyID),
		approveStatus: convertApprovalsTo(approvals),
		approved: {
			comment: `${getApprovedStatus(comments)}`, //isApproved?"":convertComments(comments),
		},
		rejected: convertRejectDocs(comments),
		isResubmission: getIsResubmission(),
	};
	try {
		const res = await submitApprovalAPI(data);
		console.log("submitApprovalAPI adapter :", res);
		return res;
	} catch (err) {
		console.log("submitApprovalAPI adapter error :", err);
		throw err;
	}
};

export const submitApprovalAPI_adapter_DG = async (userID, companyID, comments) => {
	let status = getApprovedStatus(comments);
	let approvals = {
		companyStatus: status,
		contactStatus: status,
		factoryStatus: status,
		managementStatus: status,
		productStatus: status,
	};
	const data = {
		statues: status,
		officerId: userID,
		companyId: Number(companyID),
		approveStatus: approvals,
		approved: {
			comment: `${status}`, //isApproved?"":convertComments(comments),
		},
		rejected: convertRejectDocs(comments) || [],
		isResubmission: getIsResubmission(),
	};
	try {
		const res = await submitApprovalAPI(data);
		console.log("submitApprovalAPI adapter DG:", res);
		return res;
	} catch (err) {
		console.log("submitApprovalAPI adapter DG error :", err);
		throw err;
	}
};

export const getCompanyTrailAPI_adapter = async (companyID) => {
	try {
		const res = await getCompanyTrailAPI(companyID);
		console.log("getCompanyTrailAPI_adapter :", res);
		return res;
	} catch (err) {
		console.log("getCompanyTrailAPI_adapter error :", err);
		throw err;
	}
};
