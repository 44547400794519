export const getStakeholderTypeFromApplicationId = (applicationId) => {
  if (applicationId.startsWith('CER')) {
    return 'Certification body';
  } else if (applicationId.startsWith('LAB')) {
    return 'Laboratory';
  } else if (applicationId.startsWith('FAR')) {
    return 'Farmer';
  } else if (applicationId.startsWith('FMA')) {
    return 'Farmer Association';
  } else if (applicationId.startsWith('EXP')) {
    return 'Exporter';
  } else if (applicationId.startsWith('IMP')) {
    return 'Importer';
  } else if (applicationId.startsWith('PRO')) {
    return 'Processor';
  } else if (applicationId.startsWith('TRA')) {
    return 'Trader';
  } else {
    return '';
  }
}