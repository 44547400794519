import React from "react";
import { Button } from "@mui/material";
import { height } from "@mui/system";

function SearchButton({ name, icon, handleClick, className, title, color, disabled }) {
	const handleChange = (event) => {
		handleClick(0, 5);
	};
	return (
		<Button
			disabled={disabled}
			name={name}
			variant={"contained"}
			className={`!capitalize ${className}`}
			sx={{
				width: 100,
				borderRadius: 3,
				mt: 1,
				height: 25,
				color: "#DCFCE7",
			}}
			color={color ? color : "success"}
			startIcon={icon}
			onClick={handleChange}>
			{title}
		</Button>
	);
}

export default SearchButton;
