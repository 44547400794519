import React, { Component, useEffect, useState } from "react";

import { SectionRow } from "../../../components/section";
import CompRegRow from "../../../components/cards/compRegRow";
import DashDetailCard from "../../../components/cards/dashDetailCard";
import TimePeriodSelection from "../../../components/cards/timePeriodSelection";
import StatCard from "../../../components/cards/statCard";
import { getOverviewStatusByUserRole, getUserRoleFromLocal } from "../../../Helpers/loginHelper";
import { APPROVED, permissions, userRoles } from "../../../Helpers/constants";
import {
	CERTIFICATION_BODY_ROUTES,
	EXPORTER_ROUTES,
	FARMER_ASSOCIATION_ROUTES,
	FARMER_ROUTES,
	FIELD_VISIT_ROUTES,
	FINAL_COMMENTS_ROUTES,
	FINAL_VOTE_VERIFICATION_ROUTES,
	IMPORTER_ROUTES,
	ISSUE_ROUTES,
	LABORATORIES_ROUTES,
	PROCESSOR_ROUTES,
	SLAAB_ROUTES,
	TECHNICAL_COMMITTEE_ROUTES,
	TRADER_ROUTES,
} from "./../../../app/router/routes";
import { checkTabPermission } from "../../../app/api/NOCU/permissionAPI";
import { TABS } from "../../../app/constants";
import { getDashBoardCounts } from "../../../app/api/NOCU/overviewAPI";
import { getUserId } from "../../../app/api/NOCU/helpers/applicationApiHelpers";

export default function OverViewContent({
	certification_body,
	laboratories,
	farmer,
	farmer_association,
	exporter,
	importer,
	trader,
	processor,
	slaab,
	field_visit,
	final_comment,
	final_vote,
	issue,
	ready_to_issue,
	technical_committee,
	setTimeRange,
}) {
	return (
		<>
			<SectionRow className='justify-end pr-12'>
				<TimePeriodSelection setTimeRange={setTimeRange} />
			</SectionRow>
			{/* <SectionRow className='mb-5'>
				<span className='overViewTitle'>Total Application Summary</span>
			</SectionRow>
			<SectionRow>
				<StatCard
					value={allData?.totalCounts?.totalApprovedCount}
					text={"Approved in this Period"}
					presentage={allData?.totalApprovedPercentage}
					image={"https://img.icons8.com/ios-filled/50/6EEDA8/checkmark--v1.png"}
					accent={"#E3FEF3"}
				/>
				<StatCard
					value={allData?.totalCounts?.totalPendingCount}
					text={"Pending in this Period"}
					presentage={allData?.totalPendingPercentage}
					image={"https://img.icons8.com/ios/50/FFBA32/timer.png"}
					accent={"#FFF5D2"}
				/>
				<StatCard
					value={allData?.totalCounts?.totalRejectedCount}
					text={"Rejected in this Period"}
					presentage={allData?.totalRejectedPercentage}
					image={"https://img.icons8.com/material-outlined/50/FB6161/cancel--v1.png"}
					accent={"#FFE2E2"}
				/>
			</SectionRow> */}
			<SectionRow className='mt-5'>
				<span className='overViewTitle'>Application Status</span>
			</SectionRow>
			<SectionRow className='w-full justify-around'>
				{checkTabPermission(TABS.CERTIFICATION_BODY) && (
					<DashDetailCard title={`Certification Body`} path={CERTIFICATION_BODY_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending New Applications"}
							value={certification_body ? certification_body.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Pending Resubmitted Applications"}
							value={certification_body ? certification_body.resubmitPendingCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Completed Applications"}
							value={certification_body ? certification_body.approvedCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Applications sent for resubmission"}
							value={certification_body ? certification_body.resubmissionCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={certification_body ? certification_body.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.LABORATORIES) && (
					<DashDetailCard title={`Laboratories`} path={LABORATORIES_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending New Applications"}
							value={laboratories ? laboratories.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Pending Resubmitted Applications"}
							value={laboratories ? laboratories.resubmitPendingCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Completed Applications"}
							value={laboratories ? laboratories.approvedCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Applications sent for resubmission"}
							value={laboratories ? laboratories.resubmissionCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={laboratories ? laboratories.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.FARMER) && (
					<DashDetailCard title={`Farmer`} path={FARMER_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending New Applications"}
							value={farmer ? farmer.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Pending Resubmitted Applications"}
							value={farmer ? farmer.resubmitPendingCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Completed Applications"}
							value={farmer ? farmer.approvedCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Applications sent for resubmission"}
							value={farmer ? farmer.resubmissionCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={farmer ? farmer.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.FARMER_ASSOCIATION) && (
					<DashDetailCard title={`Farmer Association`} path={FARMER_ASSOCIATION_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending New Applications"}
							value={farmer_association ? farmer_association.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Pending Resubmitted Applications"}
							value={farmer_association ? farmer_association.resubmitPendingCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Completed Applications"}
							value={farmer_association ? farmer_association.approvedCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Applications sent for resubmission"}
							value={farmer_association ? farmer_association.resubmissionCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={farmer_association ? farmer_association.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}

				{checkTabPermission(TABS.EXPORTER) && (
					<DashDetailCard title={`Exporter`} path={EXPORTER_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending New Applications"}
							value={exporter ? exporter.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Pending Resubmitted Applications"}
							value={exporter ? exporter.resubmitPendingCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Completed Applications"}
							value={exporter ? exporter.approvedCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Applications sent for resubmission"}
							value={exporter ? exporter.resubmissionCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={exporter ? exporter.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.IMPORTER) && (
					<DashDetailCard title={`Importer`} path={IMPORTER_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending New Applications"}
							value={importer ? importer.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Pending Resubmitted Applications"}
							value={importer ? importer.resubmitPendingCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Completed Applications"}
							value={importer ? importer.approvedCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Applications sent for resubmission"}
							value={importer ? importer.resubmissionCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={importer ? importer.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.TRADER) && (
					<DashDetailCard title={`Trader`} path={TRADER_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending New Applications"}
							value={trader ? trader.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Pending Resubmitted Applications"}
							value={trader ? trader.resubmitPendingCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Completed Applications"}
							value={trader ? trader.approvedCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Applications sent for resubmission"}
							value={trader ? trader.resubmissionCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={trader ? trader.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.PROCESSOR) && (
					<DashDetailCard title={`Processor`} path={PROCESSOR_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending New Applications"}
							value={processor ? processor.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Pending Resubmitted Applications"}
							value={processor ? processor.resubmitPendingCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Completed Applications"}
							value={processor ? processor.approvedCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Applications sent for resubmission"}
							value={processor ? processor.resubmissionCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={processor ? processor.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.SLAAB) && (
					<DashDetailCard title={`SLAB`} path={SLAAB_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending SLAB Applications"}
							value={slaab ? slaab.pendingCount : 0}
							color='#3375EF'
						/>

						<CompRegRow
							title={"Completed SLAB Applications"}
							value={slaab ? slaab.approvedCount : 0}
							color='#8df1bc'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.FIELD_VISIT) && (
					<DashDetailCard title={`Field Visit`} path={FIELD_VISIT_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending Field Visit"}
							value={field_visit ? field_visit.pendingCount : 0}
							color='#3375EF'
						/>

						<CompRegRow
							title={"Completed Field Visit"}
							value={field_visit ? field_visit.approvedCount : 0}
							color='#8df1bc'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.FINAL_COMMENT) && (
					<DashDetailCard
						title={`Tech Committee Recommendations`}
						path={FINAL_COMMENTS_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending Technical Committee Recommendations"}
							value={final_comment ? final_comment.pendingCount : 0}
							color='#3375EF'
						/>

						<CompRegRow
							title={"Completed Technical Committee Recommendations"}
							value={final_comment ? final_comment.approvedCount : 0}
							color='#8df1bc'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.TECHNICAL_COMMITTEE) && (
					<DashDetailCard
						title={`Technical Committee`}
						path={TECHNICAL_COMMITTEE_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending Applications"}
							value={technical_committee ? technical_committee.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Approved Applications"}
							value={technical_committee ? technical_committee?.approvedOnlyCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Rejected Applications"}
							value={technical_committee ? technical_committee?.rejectedOnlyCount : 0}
							color='#d95757'
						/>
						<CompRegRow
							title={"Unvoted Applications"}
							value={technical_committee ? technical_committee?.discardOnlyCount : 0}
							color='#8df1bc'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.FINAL_VOTE) && (
					<DashDetailCard title={`Final Vote`} path={FINAL_VOTE_VERIFICATION_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending Applications with Tech Committee"}
							value={final_vote ? final_vote.pendingCount : 0}
							color='#3375EF'
						/>
						<CompRegRow
							title={"Final Vote Verifications"}
							value={final_vote ? final_vote.approvedCount : 0}
							color='#ffc652'
						/>
						<CompRegRow
							title={"Pending Signature Approval"}
							value={final_vote ? final_vote.completeCount : 0}
							color='#8df1bc'
						/>
						<CompRegRow
							title={"Rejected Final Vote Applications"}
							value={final_vote ? final_vote.rejectedCount : 0}
							color='#d95757'
						/>
					</DashDetailCard>
				)}
				{checkTabPermission(TABS.ISSUE) && (
					<DashDetailCard title={`Signature`} path={ISSUE_ROUTES.HOME + "/1"}>
						<CompRegRow
							title={"Pending Applications"}
							value={issue ? issue.pendingCount : 0}
							color='#3375EF'
						/>

						<CompRegRow
							title={"Approved Applications"}
							value={issue ? issue.approvedCount : 0}
							color='#8df1bc'
						/>
					</DashDetailCard>
				)}
			</SectionRow>
			<br />
		</>
	);
}
