import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getUserId, setStakeholderType } from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getTableCounts, getTableRecords } from "../../app/api/NOCU/tableAPI";
import {
	setStakeholderTypes,
	STAKEHOLDERS,
	STAT_ACCENT_COLORS,
	STAT_ICON_PATHS,
} from "../../app/constants";
import { navigateSelf } from "../../app/router/routerManager";
import { SLAAB_ROUTES } from "../../app/router/routes";
import StatCard from "../../components/cards/statCard";
import HomeTable from "../../components/table/HomeTable";
import { setApplicationStage, showViewIcon } from "../../components/table/tableHelpers";
import { SectionRow } from "../../layouts/sections";

import { FARMER_ROUTES } from "./../../app/router/routes";
import { deleteStepper } from "./../../components/stepper/dynamicStepper";
import { getTableData } from "./dataManipulation";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { deleteIsRenewal, setIsRenewal } from "../../components/navbar/helpers";
import { setApplicationStatusOnSession } from "../certificationBody/dataManipulation";

const FarmerHome = () => {
	const { id } = useParams();
	const [records, setRecords] = useState();
	const [res, setRes] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [counts, setCounts] = useState({
		pendingCount: 0,
		approvedCount: 0,
		rejectedCount: 0,
		resubmissionCount: 0,
	});
	const [selectedCount, setSelectedCount] = useState();
	const [selectedTitle, setSelectedTitle] = useState("New Requests");
	const tableHeaders = ["Name", "Application No.", "Contact Person", "Date & Time", "Type"];

	let filters = [
		{ key: "PENDING", value: "PENDING" },
		{ key: "SUCCESS", value: "SUCCESS" },
		{ key: "SUSPENDED", value: "SUSPENDED" },
		{ key: "APPROVED", value: "APPROVED" },
		{ key: "REJECTED", value: "REJECTED" },
	];
	let sorting = [
		{ key: "Acceding", value: true },
		{ key: "Descending", value: false },
	];
	const [selectedStatCard, setSelectedStatCard] = useState(1);
	const [params, setParams] = useState({
		status: "",
		isAscending: true,
		page: 0,
		size: 5,
		officerId: getUserId(),
		stakeholderType: "FARMER",
		applicationStage: "CHECK",
		applicationNumber: "",
	});

	const [searchParams, setSearchParams] = useState({
		status: "",
		isAscending: true,
		page: 0,
		size: 5,
		officerId: getUserId(),
		stakeholderType: "FARMER",
		applicationStage: "CHECK",
		applicationNumber: "",
		district: "",
		divisionalSecretariat: "",
		gramaNiladhariDivision: "",
	});

	useEffect(() => {
		setSelectedStatCard(parseInt(id));
		if (parseInt(id) === 1) {
			setParams({ ...params, status: "PENDING", applicationStage: "CHECK" });
			setApplicationStatusOnSession(true);
			setSelectedCount(counts.pendingCount);
			setSelectedTitle("New Requests");
		} else if (parseInt(id) === 2) {
			setParams({ ...params, status: "PENDING", applicationStage: "RESUBMISSION_CHECK" });
			setApplicationStatusOnSession(false);
			setSelectedCount(counts.resubmitPendingCount);
			setSelectedTitle("Received Resubmissions");
		} else if (parseInt(id) === 3) {
			setParams({ ...params, status: "APPROVED", applicationStage: "CHECK", isAscending: false });
			setSelectedCount(counts.approvedCount);
			setApplicationStatusOnSession(false);
			setSelectedTitle("Completed Requests");
		} else if (parseInt(id) === 4) {
			setParams({ ...params, status: "RESUBMIT", applicationStage: "CHECK", isAscending: false });
			setSelectedCount(counts.resubmissionCount);
			setApplicationStatusOnSession(false);
			setSelectedTitle("Sent for Resubmission");
		} else if (parseInt(id) === 5) {
			setParams({ ...params, status: "REJECTED", applicationStage: "CHECK", isAscending: false });
			setSelectedCount(counts.rejectedCount);
			setApplicationStatusOnSession(false);
			setSelectedTitle("Rejected Requests");
		}
	}, [id, counts]);
	const handleFilter = (filter) => {
		setParams({ ...params, status: filter });
	};

	const handleStakeHolderType = (filter) => {
		setParams({ ...params, stakeholderType: filter });
		setSearchParams({ ...searchParams, stakeholderType: filter });
	};

	const handleSearchById = (id) => {
		setParams({ ...params, applicationNumber: id });
	};

	const handleSorting = (isAscending) => {
		setParams({ ...params, isAscending: isAscending });
	};
	const handlePage = (page) => {
		//setSearchParams({ ...searchParams, page:page });
		setParams({ ...params, page: page });
		//handleSearchParams(page, params.size);
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size, page: 0 });
		//setSearchParams({ ...searchParams, size: size });
		//handleSearchParams(0, size);
	};

	const viewFunction = (index) => {
		console.log("Index Of record=================>", res.applicationList[index]?.stakeholderType);
		setStakeholderType(res.applicationList[index]?.stakeholderType);
		setApplicationStage(params.applicationStage);
		setIsRenewal(res.applicationList[index]?.isRenewal);
		deleteStepper("FARMER");
		navigateSelf(FARMER_ROUTES.STEP01);
	};
	const historyFunction = (index) => {
		console.log("Index Of record=================>", index);
	};

	useEffect(() => {
		deleteIsRenewal();
		const cParams = {
			officerId: getUserId(),
			stakeholderType: "FARMER",
			applicationNumber: params.applicationNumber,
			applicationStage: "CHECK",
		};
		setStakeholderType(setStakeholderTypes.FARMER);
		// setStakeholderType(params.stakeholderType);
		getTableCounts(cParams).then((res) => {
			setCounts(res);
		});
	}, []);

	useEffect(() => {
		console.log("Params ====>", params);
		if (params.status && params.status !== "") {
			getTableRecords(params)
				.then((res) => {
					setRes(res.data);
					console.log("Res ======> ", getTableData(res.data));
					setRecords(getTableData(res.data));
				})
				.catch((e) => console.log(e))
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [params]);

	const handleSearchParams = async (page, size) => {
		let _searchParam = searchParams;
		_searchParam.page = page;
		_searchParam.size = size;
		getTableRecords(_searchParam)
			.then((res) => {
				console.log("Res ======> ", getTableData(res.data));
				setRecords(getTableData(res.data));
			})
			.then((e) => console.log(e));
	};

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0'>
				<span className='overViewTitle'>Farmer</span>
			</SectionRow>
			<SectionRow className='w-full'>
				<SectionRow className='w-full overflow-x-auto mb-5 customScrollBar'>
					<SectionRow className='w-full !min-w-max mb-2 justify-center items-center'>
						<StatCard
							value={counts.pendingCount}
							text={"Pending New Applications"}
							image={STAT_ICON_PATHS.stat_new_req}
							accent={STAT_ACCENT_COLORS.bluishGray}
							handleStatCard={() => {}}
							num={1}
							selected={selectedStatCard}
							setSelectStatCard={setSelectedStatCard}
						/>
						<StatCard
							value={counts.resubmissionCount}
							text={"Applications Sent for Resubmission"}
							image={STAT_ICON_PATHS.stat_resubmit}
							accent={STAT_ACCENT_COLORS.yellow}
							handleStatCard={() => {}}
							num={4}
							selected={selectedStatCard}
							setSelectStatCard={setSelectedStatCard}
						/>
						<StatCard
							value={counts.resubmitPendingCount}
							text={"Pending Resubmitted Applications"}
							image={STAT_ICON_PATHS.stat_cancelation}
							accent={STAT_ACCENT_COLORS.orange}
							handleStatCard={() => {}}
							num={2}
							selected={selectedStatCard}
							setSelectStatCard={setSelectedStatCard}
						/>
						<StatCard
							value={counts.approvedCount}
							text={"Completed Applications"}
							image={STAT_ICON_PATHS.stat_checkMark}
							accent={STAT_ACCENT_COLORS.lightGreen}
							handleStatCard={() => {}}
							num={3}
							selected={selectedStatCard}
							setSelectStatCard={setSelectedStatCard}
						/>

						<StatCard
							value={counts.rejectedCount}
							text={"Rejected Applications"}
							image={STAT_ICON_PATHS.stat_deleteMark}
							accent={STAT_ACCENT_COLORS.red}
							handleStatCard={() => {}}
							num={5}
							selected={selectedStatCard}
							setSelectStatCard={setSelectedStatCard}
						/>
					</SectionRow>
				</SectionRow>
			</SectionRow>

			<HomeTable
				applicationKey={1}
				columns={tableHeaders}
				filterTitle={"Stakeholder"}
				tableTitle={selectedTitle}
				handleSearchById={handleSearchById}
				rows={records}
				view={showViewIcon(["1", "2"], id) ? viewFunction : null}
				history={historyFunction}
				//filters={STAKEHOLDERS}
				//setFilter={handleStakeHolderType}
				// sort={sorting}
				handleSort={handleSorting}
				handleSize={handleSize}
				count={selectedCount}
				page={params.page}
				size={params.size}
				handlePage={handlePage}
			/>
		</div>
	);
};

export default FarmerHome;
