import { Link, useLocation } from "react-router-dom";
import { navigateSelf } from "../../app/router/routerManager";
import { logOutApi } from "../../app/api/authApi/authApi";

export default function DrawerItem({ icon, label, path, path2, disabled }) {
	let location = useLocation();
	const logOut = () => {
		logOutApi()
			.then((res) => {
				sessionStorage.clear();
				localStorage.clear();
				navigateSelf("/login");
			})
			.catch((e) => console.log(e));
	};

	return (
		<ul className='sidebarList'>
			{disabled === true ? (
				<div
					className='hover:cursor-pointer'
					onClick={() => {
						navigateSelf(path);
					}}>
					<div
						className='row'
						id={location.pathname === path || location.pathname.includes(path2) ? "active" : ""}>
						<div className=' ' id='icon'>
							<img src={icon} alt='' width='25' height='25' />
						</div>
						<div id='label' className='pl-4'>
							{label}
						</div>
						<div
							className={
								location.pathname === path || location.pathname.includes(path2) ? "dot" : "dot-hide"
							}>
							&bull;
						</div>
					</div>
				</div>
			) : (
				<div onClick={() => {
					if (label === "Log out") {
						logOut();
					} else {
						navigateSelf(path);
					}
				}}>
					<div
						className='hover:cursor-pointer'
						onClick={() => {
							navigateSelf(path);
						}}>
						<div
							className='row'
							id={location.pathname === path || location.pathname.includes(path2) ? "active" : ""}>
							<div className=' ' id='icon'>
								<img src={icon} alt='' width='25' height='25' />
							</div>
							<div id='label' className='pl-4'>
								{label}
							</div>
							<div
								className={
									location.pathname === path || location.pathname.includes(path2) ? "dot" : "dot-hide"
								}>
								&bull;
							</div>
						</div>
					</div>
				</div>
			)}
		</ul>
	);
}
