import React, { useEffect, useState } from "react";
import ReviewWrapperNOCU from "./../../../../components/wrappers/reviewWrapper";
import ToggleButtonSet from "./../../../../components/buttons/ToggleButtonSet";
import DynamicStepper from "./../../../../components/stepper/dynamicStepper";
import FileButtonSet from "./../../../../components/buttons/FileButtonSet";
import { APPROVED, DEFAULT } from "./../../../../Helpers/constants";
import { FARMER_ROUTES } from "./../../../../app/router/routes";
import { navigateSelf } from "../../../../app/router/routerManager";

import NextButton from "./../../../../components/buttons/nextButton";
import { SectionRow } from "./../../../../layouts/sections/index";
import {
	getApplicationDetails,
	submitApplicationDetails,
} from "../../../../app/api/NOCU/applicationAPI";
import { getFileURL } from "../../../../app/api/file_api";
import { Checkbox } from "@mui/material";
import {
	deleteAllApplicationRejectDetails,
	getApplicationRejectDetailsAll,
	setAllRejections,
} from "../../../../Helpers/rejectionHelpers";
import { STATUS } from "../../../../app/constants";
import { getRejctionsByStep } from "../../../../app/api/NOCU/rejectionAPI";
import { appendRejectionsFiles, validateFiles } from "../dataManipulation";
import { getApplicationStage } from "../../../../components/table/tableHelpers";
import { Alert } from "@mui/material";

const FarmerReviewStep4 = ({
	stepperData,
	stepperName,
	routeArray,
	setIsLoading,
	nextCustomRoute,
	customIndex,
	readOnly,
	hideBtns,
}) => {
	const [documents, setDocuments] = useState();
	const [hasFieldVisit, setHasFieldVisit] = useState(false);
	const [rejections, setRejections] = useState();
	const [isDisabled, setDisabled] = useState(true);
	const [canApprove, setCanApprove] = useState(false);
	const [_files, _setFiles] = useState();
	const [field_comment, setField_comment] = useState();
	const [response, setResponse] = useState();

	const [reviewStatus, setReviewStatus] = useState();

	useEffect(() => {
		deleteAllApplicationRejectDetails();
		getApplicationDetails(5)
			.then((res) => {
				console.log("IsFieldVisit", res);
				setHasFieldVisit(res?.isReferFieldVisit);
				setReviewStatus(res?.stepStatus);
				setDocuments(res?.otherDocumentRequest);
				setResponse(res);

				// setCertificateRequest(res?.certificateRequest);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				getRejctionsByStep(5)
					.then((res) => {
						setRejections(res.data);
						console.log("Rejection ======>", res);
					})
					.then((e) => {
						console.log(e);
					})
					.finally(() => {
						setIsLoading(false);
					});
			});
	}, []);
	useEffect(() => {
		setAllRejections(rejections?.rejectReasonDetailList || []);
	}, [rejections]);

	useEffect(() => {
		_files &&
			rejections &&
			appendRejectionsFiles(
				getApplicationRejectDetailsAll(),
				_files,
				_setFiles,
				documents?.entityId
			);
	}, [rejections]);

	useEffect(() => {
		documents?.otherDocFilePath &&
			_setFiles([
				{
					name: "Other Documents ",
					path: getFileURL(documents?.otherDocFilePath),
					status: reviewStatus === "PENDING" ? DEFAULT : APPROVED,
				},
			]);
	}, [documents]);
	const [selectedFile, setSelectedFile] = useState();
	const nameArray = [
		{ name: "Certificate 1" },
		{ name: "Certificate 2" },
		{ name: "Certificate 3" },
	];
	const formData = [
		{ label: "Country of Accreditation", value: "Sri lanka", isChecked: true },
		{ label: "Name of Accreditation Body", value: "KPMG", isChecked: true },
		{
			label: "Address of Accreditation Body",
			value: "34/65, Nawam Mawatha, Colombo",
			isChecked: true,
		},
		{ label: "Contact Number", value: "0332433000", isChecked: true },
		{ label: "Approved Date", value: "23/02/2022", isChecked: true },
		{ label: "Title of Organic Standard", value: "NIC", isChecked: true },
	];
	const handlePerson = (index) => {
		console.log("Index", index);
	};

	useEffect(() => {
		_files && validateFiles(_files) ? setCanApprove(true) : setCanApprove(false);
		console.log("Files ============>", _files);
	}, [_files]);
	useEffect(() => {
		console.log("Selected File ============>", selectedFile?.path);
	}, [selectedFile]);
	useEffect(() => {
		console.log("hasFieldVisit ============>", hasFieldVisit);
	}, [hasFieldVisit]);

	const handleNext = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(FARMER_ROUTES.HOME + "/1");
	};
	const closeApplication = () => {
		deleteAllApplicationRejectDetails();
		navigateSelf(FARMER_ROUTES.HOME + "/1");
	};

	const handleResubmit = (comments) => {
		setIsLoading(true);
		console.log("Resubmit Comments", comments);
		submitApplicationDetails(
			5,
			STATUS.RESUBMIT,
			comments,
			getApplicationRejectDetailsAll(),
			hasFieldVisit,
			[],
			getApplicationStage(),
			field_comment,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleSubmit = () => {
		setIsLoading(true);
		submitApplicationDetails(
			5,
			STATUS.APPROVED,
			"",
			getApplicationRejectDetailsAll(),
			hasFieldVisit,
			[],
			getApplicationStage(),
			field_comment,
			rejections?.reasonId
		)
			.then((res) => {
				handleNext();
			})
			.catch((e) => console.log(e));
	};
	const handleReject = (comments) => {
		setIsLoading(true);
		console.log("Reject Comments", comments);
		submitApplicationDetails(
			5,
			STATUS.REJECTED,
			comments,
			getApplicationRejectDetailsAll(),
			hasFieldVisit,
			[],
			getApplicationStage(),
			field_comment,
			rejections?.reasonId
		)
			.then((res) => {
				setTimeout(() => {
					console.log("Waiting");
				}, 2000);
				closeApplication();
			})
			.catch((e) => console.log(e));
	};

	const updateFileArray = (updatedFiles) => {
		_setFiles(updatedFiles);
	};
	const handleCUstomNext = (route) => {
		navigateSelf(route);
	};
	useEffect(() => {
		if (_files) {
			const filesFalseValue = _files.some((item) => item.status == "REJECTED");
			setDisabled(!filesFalseValue);
		}
	}, [_files]);

	const handleResubmitDisable = (a, files) => {
		if (files) {
			const filesFalseValue = files.some((item) => item.status == "REJECTED");
			setDisabled(!filesFalseValue);
		}
	};

	const handleChange = (value) => {
		setField_comment(value);
	};

	return (
		<div>
			<ReviewWrapperNOCU
				hideBtns={hideBtns}
				lastStep
				index={customIndex || 3}
				readOnly={readOnly}
				title={"Other Documents"}
				setForm={() => {}}
				stepperName={stepperName}
				files={_files}
				isDisabled={isDisabled}
				formToggleSwitch={
					<div>
						<div className='w-[350px] mt-20 h-[150px] shadow-md rounded-md bg-slate-100  border p-5'>
							<div className='w-full text-sm'>Remark :</div>
							<div className='text-gray-500  '>
								{documents?.remarks ? documents?.remarks : "No comments"}
							</div>
						</div>
						<SectionRow>
							<SectionRow className='w-full items-center mt-2'>
								<span>Initiate Field visit : </span>
								{response && (
									<Checkbox
										defaultChecked={response?.isReferFieldVisit}
										disabled={reviewStatus === "PENDING" && !hideBtns ? false : true}
										onChange={(e) => {
											setHasFieldVisit(e.target.checked);
										}}
									/>
								)}
							</SectionRow>
							{!hasFieldVisit && reviewStatus !== "PENDING" && (
								<Alert severity='info'>
									Remark for skipped field visit : {response?.remarkForSkippingFieldVisit || "---"}
								</Alert>
							)}
						</SectionRow>
					</div>
				}
				topBar={
					stepperData &&
					stepperName && (
						<DynamicStepper name={stepperName} stepperData={stepperData} routeArray={routeArray} />
					)
				}
				formTitle={<div>Test</div>}
				// formData={formData}
				currentFile={selectedFile}
				fileButtons={
					<FileButtonSet
						hideBtns={hideBtns}
						files={_files}
						readOnly={readOnly}
						setFiles={_setFiles}
						selectedFile={selectedFile}
						setSelectedFile={setSelectedFile}
						updateFileArray={updateFileArray}
						entityId={documents?.entityId}
						setDisabled={handleResubmitDisable}
					/>
				}
				// additionalButtons={

				// }
				handleReject={handleReject}
				handleResubmit={handleResubmit}
				handleNext={handleSubmit}
				setDisabled={handleResubmitDisable}
				canApprove={!_files || _files.length === 0 ? true : canApprove ? true : false}
				reviewed={nextCustomRoute ? () => handleCUstomNext(nextCustomRoute) : null}
				// reviewed={reviewStatus !== "PENDING" && reviewStatus !== "RESUBMIT" ? handleNext : null}
				hasFieldVisit={hasFieldVisit}
				comment={field_comment}
				handleChange={handleChange}
				reviewStatus={reviewStatus}
			/>
		</div>
	);
};

export default FarmerReviewStep4;
