import React, { useState, useEffect } from "react";
import { SectionColumn, SectionRow } from "../../layouts/sections";
import ReviewForm from "../../components/review/reviewForm";
import { getStakeholder, getStakeholderFormData } from "./dataHelper";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import AcceptButton from "../../components/buttons/acceptbutton";
import RejectButton from "../../components/buttons/rejectButton";
import { updateStakeholderManagementStatus } from "../../app/api/NOCU/stakeholderManagementApi";

const StakeholderManagementReview = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState();
    const parsedData = JSON.parse(getStakeholder());

    useEffect(() => {
        setFormData(getStakeholderFormData(getStakeholder()));
    }, []);

    const handleSubmit = (status) => {
        setIsLoading(true);
        console.log("Status", status);
        const payload = {
            userId: parsedData?.userId,
            status: status,
        };
        console.log("Payload", payload);
        updateStakeholderManagementStatus(payload)
            .then((res) => {
                console.log(res.data);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setIsLoading(false);
                window.location.reload();
            });
    };

    return (
        <div>
            <SectionRow className='w-full pt-10'>
                <SectionColumn className='w-full pl-5 pt-10 items-center'>
                    {formData && <ReviewForm data={formData} />}
                    <SectionRow className='w-full pt-10 items-center justify-center'>
                        {parsedData?.userStatus === "APPROVED" ? (
                            <RejectButton
                                className={""}
                                handleClick={() => {
                                    handleSubmit('SUSPENDED');
                                }}
                                name='suspendButton'
                                title='Suspend'
                                disabled={false}
                            />
                        ) : (
                            <AcceptButton
                                className={""}
                                handleClick={() => {
                                    handleSubmit('APPROVED');
                                }}
                                name='nextButton'
                                title='Approve'
                                color={""}
                                disabled={false}
                            />
                        )}
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <LoaderComponent isOpen={isLoading} />
        </div>
    );
};

export default StakeholderManagementReview;