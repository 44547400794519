import React, { useEffect, useState } from "react";
import { getIsDisableAlert, setCurrentStep } from "../dynamicStepper";
import AlertModal from "../../model/AlertModal";

const StartStep = ({ title, focus, status, name, index, route }) => {
	// const currentStep = parseInt(sessionStorage.getItem("currentStep"));
	const currentStep = 1;
	const ACTIVE = status === "ACTIVE" ? true : false;
	const COMPLETED = status === "COMPLETED" ? true : false;
	const RESUBMIT = status === "RESUBMIT" ? true : false;
	const RESUBMITTED = status === "RESUBMITTED" ? true : false;
	const REJECTED = status === "REJECTED" ? true : false;

	const CURRENT = focus;
	const [alertOpen, setAlertOpen] = useState(false);

	const iconSelection = () => {
		if (REJECTED) {
			return "/icons/stepper_icons/delete-sign.png";
		} else {
			if (ACTIVE) {
				return "/icons/stepper_icons/clock--v1.png";
			} else if (COMPLETED) {
				return "/icons/stepper_icons/done.png";
			} else if (RESUBMIT) {
				return "/icons/stepper_icons/resubmit.png";
			} else {
				return "/icons/stepper_icons/lock--v1.png";
			}
		}
	};
	const svgColorSelector = () => {
		if (REJECTED) {
			return "#F5463A";
		} else {
			if (ACTIVE) {
				return "#4c94e7";
			} else if (COMPLETED) {
				return "#20B05E";
			} else if (RESUBMIT) {
				return "#F57C00";
			} else if (RESUBMITTED) {
				return "#F57C00";
			} else {
				return "#dddddd";
			}
		}
	};
	const textColorSelector = () => {
		if (ACTIVE || REJECTED || RESUBMIT || RESUBMITTED) {
			return { color: "#fcfcfc" };
		} else if (COMPLETED) {
			return { color: "#fcfcfc" };
		} else {
			return { color: "#2c2c2c" };
		}
	};

	return (
		<div
			className={
				!(COMPLETED || ACTIVE || REJECTED || RESUBMIT || RESUBMITTED)
					? "cursor-not-allowed"
					: "cursor-pointer"
			}
			style={{ width: "250px" }}
			onClick={() => {
				if (COMPLETED || ACTIVE || REJECTED || RESUBMIT || RESUBMITTED) {
					if (getIsDisableAlert()) {
						setCurrentStep(name, index);
						window.open(route, "_self");
					} else if (!alertOpen) setAlertOpen(true);
				}
			}}>
			<AlertModal
				isOpen={alertOpen}
				setIsOpen={setAlertOpen}
				title={"Warning !"}
				content={"Going back will cause loss of data for the current step !"}
				agree={() => {
					setCurrentStep(name, index);
					window.open(route, "_self");
				}}
			/>
			<svg
				className={
					!(COMPLETED || ACTIVE || REJECTED || RESUBMIT || RESUBMITTED)
						? "cursor-not-allowed"
						: "cursor-pointer"
				}
				width={250}
				height={50}
				viewBox='0 0 250 50'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
				fill={svgColorSelector()}>
				<path
					className='st0'
					d='M219.29,45H24.78c-33.04,0-33.04-40,0-40h194.51L250,25L219.29,45z'
				/>
			</svg>
			<div
				style={{
					marginTop: "-40px",
					marginLeft: "5px",
					flexDirection: "row",
					display: "flex",
					alignItems: "center",
				}}>
				<span
					style={{
						width: 30,
						height: 30,
						backgroundColor: "#fff",
						borderRadius: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<img src={iconSelection()} alt='icon' />
				</span>
				<span className='ml-1 text-sm' style={textColorSelector()}>
					{title}
				</span>
			</div>
			{CURRENT && (
				<div
					style={{
						width: 150,
						height: 15,
						borderStyle: "solid",
						borderWidth: "10px 75px 0 75px",
						borderTopColor: svgColorSelector(),
						borderLeftColor: "transparent",
						borderRightColor: "transparent",
						borderBottomColor: "transparent",
						margin: "10px auto 2px auto",
					}}></div>
			)}
		</div>
	);
};

export default StartStep;
