import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { useState } from "react";
import LoaderComponent from "../Loader/LoaderComponent";
import { Tooltip } from "@mui/material";

const test = {
	summeryId: 102,
	userId: "674",
	userFullName: "RGBA",
	userUsername: "RGBA",
	userDesignation: null,
	userEmail: "fohexi7786@hrisland.com",
	assignedUserId: null,
	assignedUserFullName: null,
	assignedUserUsername: null,
	assignedUserDesignation: null,
	assignedUserEmail: null,
	applicationNumber: "CER67872681",
	mappedApplicationNumber: "-",
	stakeholderType: "CERTIFICATION_BODY",
	applicationStatus: "DRAFT",
	applicationStage: "CREATE",
	isReferSlaab: false,
	isReferFieldVisit: false,
	stepId: 1,
	createdDate: "2023-04-20T06:46:21.641743",
};
function createData(row) {
	const date = `${row?.createdDate.split("T")[0] ? row.createdDate.split("T")[0] : ""} ${
		row?.createdDate.split("T")[1].split(".")[0] ? row.createdDate.split("T")[1].split(".")[0] : ""
	}`;
	const rUser = row.userFullName;
	const role = row.userDesignation;
	const email = row.userEmail;
	const appStage = row.applicationStage;
	const appStatus = row.applicationStatus;
	const remark = row.remark;
	const step = row.stepId;
	const aUser = row.assignedUserFullName;

	return { date, rUser, role, email, appStage, appStatus, remark, step, aUser };
}

// const rows = [
// 	createData(
// 		"2023/01/23 - 13.39 PM",
// 		"Suran Mendis",
// 		"NOCU Officer",
// 		"suran@gmail.com",
// 		"CHECK",
// 		"DRAFT",
// 		"Kamal Perera"
// 	),
// ];

export default function TrailTable({ trailData }) {
	const [rows, setRows] = useState();
	const [isOpen, setIsOpen] = useState(true);

	const convertEnums = (eNum) => {
		return eNum === "NOCU_OFFICER" ? "NOCU Officer" : eNum;
	};

	const getStepName = (step) => {
		return step === 1
			? "Business Details"
			: step === 2
			? "Contact Personnel"
			: step === 3
			? "Management Personnel"
			: step === 4
			? "Certificates"
			: step === 5
			? "Other Documents"
			: step === 6
			? "Source Of Products"
			: step === 7
			? "Previous Product Details"
			: step === 8
			? "Current Product Details"
			: step === 9
			? "Current Product Details"
			: "";
	};

	const setTooltip = (status, remark) => {
		return status === "REJECTED" ? (
			<Tooltip title={<div className='p-2 text-base'>Remark : {remark} </div>}>
				<div>{status}</div>
			</Tooltip>
		) : (
			status
		);
	};

	useEffect(() => {
		trailData &&
			Array.isArray(trailData) &&
			setRows(
				trailData.map((row) => {
					return createData(row);
				})
			);
		setIsOpen(false);
	}, [trailData]);

	return (
		<TableContainer component={Paper}>
			<LoaderComponent isOpen={isOpen} />
			{rows && Array.isArray(rows) && rows.length > 0 ? (
				<Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
					<TableHead>
						<TableRow>
							<TableCell>Date/Time</TableCell>
							<TableCell align='right'>User Name</TableCell>
							<TableCell align='right'>Role</TableCell>
							<TableCell align='right'>Application Stage</TableCell>
							<TableCell align='right'>Application Status</TableCell>
							<TableCell align='right'>Step</TableCell>
							<TableCell align='right'>Assigned To</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => {
							let rowColor = "#ffffff";
							// if (row.appStage === "CHECK") rowColor = "#DCFCE7";
							// if (row.appStage === "TECHNICAL_COMMITTEE") rowColor = "#BCE6BF";
							// if (row.appStage === "READY_TO_ISSUE") rowColor = "#D0FEFA";
							// if (row.appStage === "SLAAB" || row.appStage === "SLAB") rowColor = "#DCFED0";
							// if (row.appStage === "FIELD_VISIT") rowColor = "#DCFED0";
							// if (row.appStage === "FINAL_COMMENT") rowColor = "#BDFDCB";
							// if (row.appStage === "RESUBMISSION_CHECK") rowColor = "#FFF5A8";
							if (row.appStatus === "REJECTED") rowColor = "#EF9A9A";
							if (row.appStatus === "APPROVED") rowColor = "#DCEDC8";
							if (row.appStatus === "RESUBMIT") rowColor = "#FFE0B2";
							if (row.appStatus === "SUBMITTED") rowColor = "#BBDEFB";
							if (row.appStatus === "RESUBMITTED") rowColor = "#BBDEFB";
							if (row.appStatus === "PENDING") rowColor = "#C8E6C9";
							if (row.appStatus === "DRAFT") rowColor = "#E1F5FE";
							return (
								<TableRow
									key={index}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
										backgroundColor: rowColor,
									}}>
									<TableCell component='th' scope='row'>
										{row.date}
									</TableCell>
									<TableCell align='right'>{row.rUser || "---"}</TableCell>
									<TableCell align='right'>{convertEnums(row.role) || "---"}</TableCell>
									<TableCell align='right'>{row.appStage || "---"}</TableCell>
									<TableCell align='right'>
										{setTooltip(row.appStatus, row.remark) || "---"}
									</TableCell>
									<TableCell align='right'>{getStepName(row.step) || "---"}</TableCell>
									<TableCell align='right'>{row.aUser || "---"}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			) : (
				<div className='w-[650px] text-center p-5'>No History Records Found</div>
			)}
		</TableContainer>
	);
}
