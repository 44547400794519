import React, { useEffect, useState } from "react";
import { SectionColumn } from "../section";
import { ICON_PATHS } from "../../app/constants";
import { Button } from "@mui/material";
import { setFieldVisitImages } from "../../Helpers/rejectionHelpers";
import { useAlert } from "../../app/context/alerts";

const ImageUploadCard = ({ files, setFiles }) => {
	const { setAlert } = useAlert();

	const hiddenFileInput = React.useRef(null);
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const handleChange = (event) => {
		const fileUploaded = event.target.files;
		console.log(fileUploaded);
		const MAX_FILE_SIZE = 10 * 1024 * 1024;

		let _files = [];
		for (let index = 0; index < fileUploaded.length; index++) {
			const fileType = fileUploaded.item(index).type;
			console.log("File Uploaded ====>", fileUploaded);
			console.log("file type,", fileType);
			if (fileUploaded.item(index).size > MAX_FILE_SIZE) {
				showAlert("File size should be less than 10MB");
			} else if (
				fileType === "image/png" ||
				fileType === "image/jpg" ||
				fileType === "image/jpeg"
			) {
				_files.push(fileUploaded.item(index));
			} else showAlert("Sorry, Please upload only .jpg or .jpeg files.");
		}
		setFiles(files.concat(_files));
	};

	const showAlert = (comments) => {
		setAlert({
			message: comments,
			severity: "error",
			show: true,
		});
	};

	useEffect(() => {
		console.log(files);
	}, [files]);

	return (
		<div onClick={handleClick}>
			<SectionColumn className='w-[150px] mx-4 my-4 cursor-pointer hover:bg-gray-200 bg-gray-100 h-[200px] rounded-3xl shadow-lg text-center items-center justify-center'>
				<img src={ICON_PATHS.addIcon} alt='add' width={50} />
				<span className='text-[#20B05E]'>Upload Image</span>
				{/* <Button onClick={handleClick}>Upload a file</Button> */}
				<input
					type='file'
					ref={hiddenFileInput}
					onChange={handleChange}
					style={{ display: "none" }}
					multiple
					accept='image/*'
				/>
			</SectionColumn>
		</div>
	);
};

export default ImageUploadCard;
