import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { nocuTheme } from "./../../Theme/customTheme";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material";

//* componentArray param structure
//  componentArray = [
// 	{
// 		label: "Name Of Tab",
// 		component: "Component.jsx",
// 	},
// ];

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const tabTheme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#20B05E",
			contrastText: "rgba(255,255,255,0.87)",
		},
	},
});

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function TabHome({ componentArray }) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const renderTabTitles = (components) => {
		return components.map((component, index) => {
			return <Tab color='' label={component?.label} {...a11yProps(index)} />;
		});
	};
	const renderTabComponents = (components) => {
		return components.map((component, index) => {
			return (
				<TabPanel value={value} index={index}>
					{component?.component}
				</TabPanel>
			);
		});
	};

	return (
		<ThemeProvider theme={tabTheme}>
			<Box sx={{ width: "100%", px: 0 }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs value={value} onChange={handleChange} aria-label='basic tabs example' centered>
						{componentArray && renderTabTitles(componentArray)}
					</Tabs>
				</Box>
				{componentArray && renderTabComponents(componentArray)}
			</Box>
		</ThemeProvider>
	);
}
