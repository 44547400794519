import React, { useState } from 'react';

function TabHeader(props) {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        props.onTabChange(tabIndex);
    };

    return (
        <div className="tab-header">
            {props.tabLabels.map((label, index) => (
                <div
                    key={index}
                    className={`tab-label ${index === activeTab ? 'active' : ''}`}
                    onClick={() => handleTabClick(index)}
                >
                    {label}
                </div>
            ))}
        </div>
    );
}
export default TabHeader;