import React, { useEffect, useState } from "react";
import { Chip, Grid } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { Table } from "react-bootstrap";
import loadingGif from "../../../assets/images/Icons/loadingGif.gif";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Eye from "@mui/icons-material/Visibility";
import { PENDING } from "../../../Helpers/constants";
import { getGeneralInquiryByStatus } from "../../../app/api/inquiryApi/generalInquiry/generalInquiryApi";
import { navigateNewTab, navigateSelf } from "../../../app/router/routerManager";
import { INQUIRY_ROUTE } from "../../../app/router/routes";
import { useSelect } from "@mui/base";

const GeneralInquiryTable = ({ setCount }) => {
	const [records, setRecords] = useState([]);
	const [total, setTotal] = useState();
	const [params, setParams] = useState({
		page: 0,
		size: 5,
		status: "PENDING",
		pagination: true,
		inquiryType: "ALL",
	});

	useEffect(() => {
		getGeneralInquiryByStatus(params).then((res) => setRecords(res.data));
	}, [params]);
	useEffect(() => {
		setTotal(records?.totalCount);
	}, [records]);

	let inquiryStatuses = [
		{ key: "PENDING", value: "PENDING" },
		{ key: "REPLIED", value: "REPLIED" },
	];
	let inquiryTypes = [
		{ key: "ALL", value: "ALL" },
		{ key: "REGISTRATION", value: "REGISTRATION" },
		{ key: "COST STATEMENT", value: "COST_STATEMENT" },
		{ key: "AFFIDAVITS", value: "AFFIDAVITS" },
		{ key: "COO OTHER THAN SPICES", value: "COO_OTHER_THAN_SPICES" },
		{ key: "COO SPICES", value: "COO_SPICES" },
		{ key: "PRE SHIPMENT APPROVAL", value: "PRE_SHIPMENT_APPROVAL" },
		{ key: "REX", value: "REX" },
		{ key: "TRQC", value: "TRQC" },
		{ key: "UK GSP", value: "UK_GSP" },
	];

	let count = 0;
	let columns = [
		"Ticket Id",
		"Exporter Email",
		"Submit Date and Time",
		"Status",
		"Inquiry Type",
		"View",
	];

	const renderTableHeading = (headings) => {
		return headings.map((heading, i) => {
			return (
				<th key={i} style={{ verticalAlign: "initial" }} className='thBtn'>
					{heading}
				</th>
			);
		});
	};

	const renderRow = (data) => {
		return data.map((row) => (
			<tbody className='tbody'>
				<td className='tdStart'>{row.ticketId}</td>
				<td className='tdStart'>{row.exporterEmail}</td>
				<td className='tdStart'>
					{row.createdDate &&
						`${row.createdDate.split("T")[0]} (${row.createdDate.split("T")[1].split(".")[0]})`}
				</td>
				<td className='tdStart'>
					{row.status === PENDING ? (
						<Chip label='PENDING' color='primary' />
					) : (
						<Chip label='REPLIED' color='success' />
					)}
				</td>
				<td className='tdStart capitalize'>{row.inquiryType.replaceAll("_", " ").toLowerCase()}</td>
				<td className='tdStart'>
					<Eye
						onClick={() => {
							sessionStorage.setItem("giToken", row.ticketId);
							sessionStorage.setItem("giTokenStatus", row.status);
							navigateNewTab(INQUIRY_ROUTE.GENERAL_INQUIRY);
						}}></Eye>
				</td>
			</tbody>
		));
	};

	const handleNextPage = () => {
		setParams({ ...params, page: params.page + 1 });
	};

	const handlePrevPage = () => {
		setParams({ ...params, page: params.page - 1 });
	};

	const renderCount = () => {
		// if (params.status === PENDING) {
		// 	count = records?.count;
		// }
		// if (params.status === "REPLIED") {
		// 	count = records?.count;
		// }
		count = records?.count;
		return (
			<span>
				<b>{count}</b>
			</span>
		);
	};

	return (
		<div className='companyRegTbleContainer'>
			<Grid container>
				<Grid item xs={3} style={{ borderTopLeftRadius: "15px" }} className='tableHeaderItem'>
					<Chip icon={<HowToRegIcon />} label='General Inquiries' />
				</Grid>
				<Grid item xs={4} className='tableHeaderItem'>
					<div className='perPageContainer'>
						<label className='mr-2'> Filter By Status :</label>
						<select
							className='perPage'
							name='size'
							id='size'
							onChange={(e) => setParams({ ...params, status: e.target.value, page: 0 })}>
							{inquiryStatuses.map((fbb) => (
								<option key={fbb.key} value={fbb.value}>
									{fbb.key}
								</option>
							))}
							;
						</select>
						{/* <span className='ml-5'>Count : {renderCount()}</span> */}
					</div>
				</Grid>
				<Grid
					item
					xs={5}
					style={{ borderTopRightRadius: "15px" }}
					className='tableHeaderSearch tableHeaderItem'>
					{/* <label className='mr-2'> Filter By Type :</label>
					<select
						className='perPage mr-2'
						name='search'
						id='search'
						onChange={(e) =>{}}>
						<option value={"businessDcom"}>Business Dcom</option>;
						<option value={"businessName"}>Business Name</option>;
					</select> */}
					<div className='perPageContainer'>
						<label className='mr-2'> Filter By Inquiry Type :</label>
						<select
							className='perPage'
							name='size'
							id='size'
							onChange={(e) => setParams({ ...params, inquiryType: e.target.value, page: 0 })}>
							{inquiryTypes.map((fbb) => (
								<option key={fbb.key} value={fbb.value}>
									{fbb.key}
								</option>
							))}
							;
						</select>
					</div>
				</Grid>
			</Grid>
			<Table responsive>
				<thead>
					<tr>{renderTableHeading(columns)}</tr>
				</thead>
				{records?.inquiries ? (
					renderRow(records?.inquiries)
				) : (
					<tbody className='tbody'>
						<tr align='center'>
							<td colSpan='12'>
								<br />
								<img src={loadingGif} alt='loading...' />
							</td>
						</tr>
					</tbody>
				)}
				{records?.inquiries && records.inquiries.length === 0 && (
					<tbody className='tbody'>
						<tr align='center'>
							<td colSpan='12'>
								<br />
								<div style={{ fontSize: "10px", fontWeight: "bold" }}>
									No {params.inquiryType.replaceAll("_", " ")} Details Found
								</div>
							</td>
						</tr>
					</tbody>
				)}
			</Table>
			<Grid container>
				<Grid
					item
					xs={6}
					style={{ borderBottomLeftRadius: "15px" }}
					className='paginationItem'></Grid>
				<Grid item xs={3} className='paginationItem'>
					<div>
						<label style={{ margin: "0px" }}> Rows per page  </label>
						<select
							className='perPage'
							style={{ cursor: "pointer" }}
							name='size'
							id='size'
							onChange={(e) => setParams({ ...params, size: e.target.value })}>
							<option label='5' value='5' />
							<option label='10' value='10' />
							<option label='25' value='25' />
						</select>
					</div>
				</Grid>
				<Grid item xs={3} style={{ borderBottomRightRadius: "15px" }} className='paginationItem'>
					<div>
						{params.page <= 0 ? (
							<ArrowBackIosIcon style={{ fontSize: "medium" }} color='disabled' />
						) : (
							<ArrowBackIosIcon
								style={{ fontSize: "medium" }}
								onClick={() => {
									handlePrevPage();
								}}
							/>
						)}
						<Chip label={`Showing Page ${params.page + 1}`} color='primary' size='small' />
						{/* {params.size <= records?.count ? ( */}
						{(params.page + 1) * params.size < total ? (
							<ArrowForwardIosIcon
								style={{ fontSize: "medium" }}
								onClick={() => {
									handleNextPage();
								}}
							/>
						) : (
							<ArrowForwardIosIcon style={{ fontSize: "medium" }} color='disabled' />
						)}
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default GeneralInquiryTable;
