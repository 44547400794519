const INQUIRY_BASE = "/inquiry";
const GENERAL_INQUIRY_BASE = "/general-inquiry";

const SUMMARY_BASE = "/web/summery";

export const overview = "web/overview";

export const INQUIRY_ROUTE = {
	ELIGIBILITY_CHECK: `${INQUIRY_BASE}/eligibility-check`,
	GENERAL_INQUIRY: `${INQUIRY_BASE}/general-inquiry`,
};

export const GENERAL_INQUIRY_ROUTE = {
	GENERAL_INQUIRY: `${GENERAL_INQUIRY_BASE}/review`,
};

export const SUMMARY_ROUTE = {
	HOME: `${SUMMARY_BASE}`,
	DOCUMENT_PAGE: `/review/docs`,
	ECOO: `${SUMMARY_BASE}/ecoo`,
	CP_REG: `${SUMMARY_BASE}/cp-reg`,
};

//NOCU features routes

export const CERTIFICATION_BODY_ROUTES = {
	HOME: `/web/cert-body`,
	REVIEW: `/review/cert-body`,
	STEP01: `/review/cert-body/st1`,
	STEP02: `/review/cert-body/st2`,
	STEP03: `/review/cert-body/st3`,
	STEP04: `/review/cert-body/st4`,
	STEP05: `/review/cert-body/st5`,
	FINAL_COMMENTS: `/review/cert-body/f-comments`,
	TC_VOTING: `/review/cert-body/tc-voting`,
	FINAL_VOTE_VERIFICATION: `/review/cert-body/f-voting-verification`,
};
export const LABORATORIES_ROUTES = {
	HOME: `/web/labs`,
	REVIEW: `/review/lab`,
	STEP01: `/review/lab/st1`,
	STEP02: `/review/lab/st2`,
	STEP03: `/review/lab/st3`,
	STEP04: `/review/lab/st4`,
	STEP05: `/review/lab/st5`,
};
export const FARMER_ROUTES = {
	HOME: `/web/farmer`,
	REVIEW: `/review/farmer`,
	STEP01: `/review/farmer/st1`,
	STEP02: `/review/farmer/st2`,
	STEP03: `/review/farmer/st3`,
	STEP04: `/review/farmer/st4`,
};
export const FARMER_ASSOCIATION_ROUTES = {
	HOME: `/web/fa-association`,
	REVIEW: `/review/fa-association`,
	STEP01: `/review/fa-association/st1`,
	STEP02: `/review/fa-association/st2`,
	STEP03: `/review/fa-association/st3`,
	STEP04: `/review/fa-association/st4`,
	STEP05: `/review/fa-association/st5`,
};
export const EXPORTER_ROUTES = {
	HOME: `/web/exporter`,
	REVIEW: `/review/exporter`,
	STEP01: `/review/exporter/st1`,
	STEP02: `/review/exporter/st2`,
	STEP03: `/review/exporter/st3`,
	STEP04: `/review/exporter/st4`,
	STEP05: `/review/exporter/st5`,
	STEP06: `/review/exporter/st6`,
	STEP07: `/review/exporter/st7`,
	STEP08: `/review/exporter/st8`,
};
export const IMPORTER_ROUTES = {
	HOME: `/web/importer`,
	REVIEW: `/review/importer`,
	STEP01: `/review/importer/st1`,
	STEP02: `/review/importer/st2`,
	STEP03: `/review/importer/st3`,
	STEP04: `/review/importer/st4`,
	STEP05: `/review/importer/st5`,
	STEP06: `/review/importer/st6`,
	STEP07: `/review/importer/st7`,
};
export const PROCESSOR_ROUTES = {
	HOME: `/web/processor`,
	REVIEW: `/review/processor`,
	STEP01: `/review/processor/st1`,
	STEP02: `/review/processor/st2`,
	STEP03: `/review/processor/st3`,
	STEP04: `/review/processor/st4`,
	STEP05: `/review/processor/st5`,
	STEP06: `/review/processor/st6`,
	STEP07: `/review/processor/st7`,
};
export const TRADER_ROUTES = {
	HOME: `/web/trader`,
	REVIEW: `/review/trader`,
	STEP01: `/review/trader/st1`,
	STEP02: `/review/trader/st2`,
	STEP03: `/review/trader/st3`,
	STEP04: `/review/trader/st4`,
	STEP05: `/review/trader/st5`,
	STEP06: `/review/trader/st6`,
};
export const SLAAB_ROUTES = {
	HOME: `/web/slaab`,
	REVIEW: `/review/slaab`,
};
export const FIELD_VISIT_ROUTES = {
	HOME: `/web/field-visit`,
	REVIEW: `/review/field-visit`,
};
export const UPLOAD_CERTIFICATE_DETAILS_ROUTES = {
	HOME: `/web/upload-cretificate-details`,
	REVIEW: `/review/upload-cretificate-details`,
};
export const FINAL_COMMENTS_ROUTES = {
	HOME: `/web/final-comments`,
	REVIEW: `/review/final-comments`,
	//APPLICATION_VIEW: `/review/final-comments-application`,
	CBAPPLICATION_VIEW: `/review/cb-final-comments-application`,
	CBSTEP01: `/review/cb-final-comments-application/cb-st1`,
	CBSTEP02: `/review/cb-final-comments-application/cb-st2`,
	CBSTEP03: `/review/cb-final-comments-application/cb-st3`,

	LBAPPLICATION_VIEW: `/review/lb-final-comments-application`,
	LBSTEP01: `/review/lb-final-comments-application/lb-st1`,
	LBSTEP02: `/review/lb-final-comments-application/lb-st2`,
	LBSTEP03: `/review/lb-final-comments-application/lb-st3`,

	FAAPPLICATION_VIEW: `/review/fa-final-comments-application`,
	FASTEP01: `/review/fa-final-comments-application/fa-st1`,
	FASTEP02: `/review/fa-final-comments-application/fa-st2`,
	FASTEP03: `/review/fa-final-comments-application/fa-st3`,

	FRAPPLICATION_VIEW: `/review/fr-final-comments-application`,
	FRSTEP01: `/review/fr-final-comments-application/fr-st1`,
	FRSTEP02: `/review/fr-final-comments-application/fr-st2`,
	FRSTEP03: `/review/fr-final-comments-application/fr-st3`,

	IMAPPLICATION_VIEW: `/review/im-final-comments-application`,
	IMSTEP01: `/review/im-final-comments-application/im-st1`,
	IMSTEP02: `/review/im-final-comments-application/im-st2`,
	IMSTEP03: `/review/im-final-comments-application/im-st3`,
	IMSTEP04: `/review/im-final-comments-application/im-st4`,
	IMSTEP05: `/review/im-final-comments-application/im-st5`,

	EXPORTER_APPLICATION_VIEW: `/review/exp-final-comments-application`,
	EXPORTER_STEP01: `/review/exp-final-comments-application/exp-st1`,
	EXPORTER_STEP02: `/review/exp-final-comments-application/exp-st2`,
	EXPORTER_STEP03: `/review/exp-final-comments-application/exp-st3`,
	EXPORTER_STEP04: `/review/exp-final-comments-application/exp-st4`,
	EXPORTER_STEP05: `/review/exp-final-comments-application/exp-st5`,
	EXPORTER_STEP06: `/review/exp-final-comments-application/exp-st6`,

	PROCESSOR_APPLICATION_VIEW: `/review/pro-final-comments-application`,
	PROCESSOR_STEP01: `/review/pro-final-comments-application/pro-st1`,
	PROCESSOR_STEP02: `/review/pro-final-comments-application/pro-st2`,
	PROCESSOR_STEP03: `/review/pro-final-comments-application/pro-st3`,
	PROCESSOR_STEP04: `/review/pro-final-comments-application/pro-st4`,
	PROCESSOR_STEP05: `/review/pro-final-comments-application/pro-st5`,

	TRADER_APPLICATION_VIEW: `/review/trd-final-comments-application`,
	TRADER_STEP01: `/review/trd-final-comments-application/trd-st1`,
	TRADER_STEP02: `/review/trd-final-comments-application/trd-st2`,
	TRADER_STEP03: `/review/trd-final-comments-application/trd-st3`,
	TRADER_STEP04: `/review/trd-final-comments-application/trd-st4`,

	// STEP01: `/review/final-comments-application/st1`,
	// STEP02: `/review/final-comments-application/st2`,
	// STEP03: `/review/final-comments-application/st3`,
	// STEP04: `/review/final-comments-application/st4`,
	// STEP05: `/review/final-comments-application/st5`,
};

export const TECHNICAL_COMMITTEE_ROUTES = {
	HOME: `/web/technical-committee`,
	REVIEW: `/review/technical-committee`,
	// APPLICATION_VIEW: `/review/technical-committee-application`,
	// STEP01: `/review/technical-committee-application/st1`,
	// STEP02: `/review/technical-committee-application/st2`,
	// STEP03: `/review/technical-committee-application/st3`,
	CBAPPLICATION_VIEW: `/review/cb-technical-committee-application`,
	CBSTEP01: `/review/cb-technical-committee-application/cb-st1`,
	CBSTEP02: `/review/cb-technical-committee-application/cb-st2`,
	CBSTEP03: `/review/cb-technical-committee-application/cb-st3`,

	LBAPPLICATION_VIEW: `/review/lb-technical-committee-application`,
	LBSTEP01: `/review/lb-technical-committee-application/lb-st1`,
	LBSTEP02: `/review/lb-technical-committee-application/lb-st2`,
	LBSTEP03: `/review/lb-technical-committee-application/lb-st3`,

	FAAPPLICATION_VIEW: `/review/fa-technical-committee-application`,
	FASTEP01: `/review/fa-technical-committee-application/fa-st1`,
	FASTEP02: `/review/fa-technical-committee-application/fa-st2`,
	FASTEP03: `/review/fa-technical-committee-application/fa-st3`,

	FRAPPLICATION_VIEW: `/review/fr-technical-committee-application`,
	FRSTEP01: `/review/fr-technical-committee-application/fr-st1`,
	FRSTEP02: `/review/fr-technical-committee-application/fr-st2`,
	FRSTEP03: `/review/fr-technical-committee-application/fr-st3`,

	IMAPPLICATION_VIEW: `/review/im-technical-committee-application`,
	IMSTEP01: `/review/im-technical-committee-application/im-st1`,
	IMSTEP02: `/review/im-technical-committee-application/im-st2`,
	IMSTEP03: `/review/im-technical-committee-application/im-st3`,
	IMSTEP04: `/review/im-technical-committee-application/im-st4`,
	IMSTEP05: `/review/im-technical-committee-application/im-st5`,

	EXPORTER_APPLICATION_VIEW: `/review/exp-technical-committee-application`,
	EXPORTER_STEP01: `/review/exp-technical-committee-application/exp-st1`,
	EXPORTER_STEP02: `/review/exp-technical-committee-application/exp-st2`,
	EXPORTER_STEP03: `/review/exp-technical-committee-application/exp-st3`,
	EXPORTER_STEP04: `/review/exp-technical-committee-application/exp-st4`,
	EXPORTER_STEP05: `/review/exp-technical-committee-application/exp-st5`,
	EXPORTER_STEP06: `/review/exp-technical-committee-application/exp-st6`,

	PROCESSOR_APPLICATION_VIEW: `/review/pro-technical-committee-application`,
	PROCESSOR_STEP01: `/review/pro-technical-committee-application/pro-st1`,
	PROCESSOR_STEP02: `/review/pro-technical-committee-application/pro-st2`,
	PROCESSOR_STEP03: `/review/pro-technical-committee-application/pro-st3`,
	PROCESSOR_STEP04: `/review/pro-technical-committee-application/pro-st4`,
	PROCESSOR_STEP05: `/review/pro-technical-committee-application/pro-st5`,

	TRADER_APPLICATION_VIEW: `/review/trd-technical-committee-application`,
	TRADER_STEP01: `/review/trd-technical-committee-application/trd-st1`,
	TRADER_STEP02: `/review/trd-technical-committee-application/trd-st2`,
	TRADER_STEP03: `/review/trd-technical-committee-application/trd-st3`,
	TRADER_STEP04: `/review/trd-technical-committee-application/trd-st4`,
};
export const FINAL_VOTE_VERIFICATION_ROUTES = {
	HOME: `/web/final-vote-verification`,
	REVIEW: `/review/final-vote-pending`,
	VOTEREVIEW: `/review/final-vote-verification`,

	CBAPPLICATION_VIEW: `/review/cb-final-vote-application`,
	CBSTEP01: `/review/cb-final-vote-application/cb-st1`,
	CBSTEP02: `/review/cb-final-vote-application/cb-st2`,
	CBSTEP03: `/review/cb-final-vote-application/cb-st3`,

	LBAPPLICATION_VIEW: `/review/lb-final-vote-application`,
	LBSTEP01: `/review/lb-final-vote-application/lb-st1`,
	LBSTEP02: `/review/lb-final-vote-application/lb-st2`,
	LBSTEP03: `/review/lb-final-vote-application/lb-st3`,

	FAAPPLICATION_VIEW: `/review/fa-final-vote-application`,
	FASTEP01: `/review/fa-final-vote-application/fa-st1`,
	FASTEP02: `/review/fa-final-vote-application/fa-st2`,
	FASTEP03: `/review/fa-final-vote-application/fa-st3`,

	FRAPPLICATION_VIEW: `/review/fr-final-vote-application`,
	FRSTEP01: `/review/fr-final-vote-application/fr-st1`,
	FRSTEP02: `/review/fr-final-vote-application/fr-st2`,
	FRSTEP03: `/review/fr-final-vote-application/fr-st3`,

	IMAPPLICATION_VIEW: `/review/im-final-vote-application`,
	IMSTEP01: `/review/im-final-vote-application/im-st1`,
	IMSTEP02: `/review/im-final-vote-application/im-st2`,
	IMSTEP03: `/review/im-final-vote-application/im-st3`,
	IMSTEP04: `/review/im-final-vote-application/im-st4`,
	IMSTEP05: `/review/im-final-vote-application/im-st5`,

	EXPORTER_APPLICATION_VIEW: `/review/exp-final-vote-application`,
	EXPORTER_STEP01: `/review/exp-final-vote-application/exp-st1`,
	EXPORTER_STEP02: `/review/exp-final-vote-application/exp-st2`,
	EXPORTER_STEP03: `/review/exp-final-vote-application/exp-st3`,
	EXPORTER_STEP04: `/review/exp-final-vote-application/exp-st4`,
	EXPORTER_STEP05: `/review/exp-final-vote-application/exp-st5`,
	EXPORTER_STEP06: `/review/exp-final-vote-application/exp-st6`,

	PROCESSOR_APPLICATION_VIEW: `/review/pro-final-vote-application`,
	PROCESSOR_STEP01: `/review/pro-final-vote-application/pro-st1`,
	PROCESSOR_STEP02: `/review/pro-final-vote-application/pro-st2`,
	PROCESSOR_STEP03: `/review/pro-final-vote-application/pro-st3`,
	PROCESSOR_STEP04: `/review/pro-final-vote-application/pro-st4`,
	PROCESSOR_STEP05: `/review/pro-final-vote-application/pro-st5`,

	TRADER_APPLICATION_VIEW: `/review/trd-final-vote-application`,
	TRADER_STEP01: `/review/trd-final-vote-application/trd-st1`,
	TRADER_STEP02: `/review/trd-final-vote-application/trd-st2`,
	TRADER_STEP03: `/review/trd-final-vote-application/trd-st3`,
	TRADER_STEP04: `/review/trd-final-vote-application/trd-st4`,

	// APPLICATION_VIEW: `/review/final-vote-application`,
	// STEP01: `/review/final-vote-application/st1`,
	// STEP02: `/review/final-vote-application/st2`,
	// STEP03: `/review/final-vote-application/st3`,
};

export const ISSUE_ROUTES = {
	HOME: `/web/signature`,
	REVIEW: `/review/signature`,

	CBAPPLICATION_VIEW: `/review/cb-sign-application`,
	CBSTEP01: `/review/cb-sign-application/cb-st1`,
	CBSTEP02: `/review/cb-sign-application/cb-st2`,
	CBSTEP03: `/review/cb-sign-application/cb-st3`,

	LBAPPLICATION_VIEW: `/review/lb-sign-application`,
	LBSTEP01: `/review/lb-sign-application/lb-st1`,
	LBSTEP02: `/review/lb-sign-application/lb-st2`,
	LBSTEP03: `/review/lb-sign-application/lb-st3`,

	FAAPPLICATION_VIEW: `/review/fa-sign-application`,
	FASTEP01: `/review/fa-sign-application/fa-st1`,
	FASTEP02: `/review/fa-sign-application/fa-st2`,
	FASTEP03: `/review/fa-sign-application/fa-st3`,

	FRAPPLICATION_VIEW: `/review/fr-sign-application`,
	FRSTEP01: `/review/fr-sign-application/fr-st1`,
	FRSTEP02: `/review/fr-sign-application/fr-st2`,
	FRSTEP03: `/review/fr-sign-application/fr-st3`,

	IMAPPLICATION_VIEW: `/review/im-sign-application`,
	IMSTEP01: `/review/im-sign-application/im-st1`,
	IMSTEP02: `/review/im-sign-application/im-st2`,
	IMSTEP03: `/review/im-sign-application/im-st3`,
	IMSTEP04: `/review/im-sign-application/im-st4`,
	IMSTEP05: `/review/im-sign-application/im-st5`,

	EXPORTER_APPLICATION_VIEW: `/review/exp-sign-application`,
	EXPORTER_STEP01: `/review/exp-sign-application/exp-st1`,
	EXPORTER_STEP02: `/review/exp-sign-application/exp-st2`,
	EXPORTER_STEP03: `/review/exp-sign-application/exp-st3`,
	EXPORTER_STEP04: `/review/exp-sign-application/exp-st4`,
	EXPORTER_STEP05: `/review/exp-sign-application/exp-st5`,
	EXPORTER_STEP06: `/review/exp-sign-application/exp-st6`,

	PROCESSOR_APPLICATION_VIEW: `/review/pro-sign-application`,
	PROCESSOR_STEP01: `/review/pro-sign-application/pro-st1`,
	PROCESSOR_STEP02: `/review/pro-sign-application/pro-st2`,
	PROCESSOR_STEP03: `/review/pro-sign-application/pro-st3`,
	PROCESSOR_STEP04: `/review/pro-sign-application/pro-st4`,
	PROCESSOR_STEP05: `/review/pro-sign-application/pro-st5`,

	TRADER_APPLICATION_VIEW: `/review/trd-sign-application`,
	TRADER_STEP01: `/review/trd-sign-application/trd-st1`,
	TRADER_STEP02: `/review/trd-sign-application/trd-st2`,
	TRADER_STEP03: `/review/trd-sign-application/trd-st3`,
	TRADER_STEP04: `/review/trd-sign-application/trd-st4`,
};



export const PRODUCT_CATEGORY_ROUTES = {
	HOME: `/web/product-category`,
	REVIEW: `/review/product-category`,
};
export const PAYMENT_ROUTES = {
	HOME: `/web/payment`,
	REVIEW: `/review/payment`,
};
export const USER_MANAGEMENT_ROUTES = {
	HOME: `/web/user-management`,
	REVIEW: `/review/user-management`,
};
export const STAKEHOLDERS_MANAGEMENT_ROUTES = {
	HOME: `/web/stakeholder-management`,
};

export const ERROR_ROUTES = {
	newTabError: "/error/new-tab-error",
	sessionError: "/error/session-error",
};