import { ErrorMessage, useField } from "formik";
import { useEffect, useState } from "react";
import Select from "react-select";

const customStyles = {
	option: (provided) => ({
		// borderBottom: "1px dotted #444",
		...provided,
		padding: 10,
	}),
	control: () => ({
		display: "flex",
		borderWidth: 0,
		justifyContent: "center",
		// padding: 0,
	}),
	menu: (base) => ({ ...base, width: 240, backgroundColor: "white" }),
	dropdownIndicator: (base) => ({ ...base, cursor: "pointer" }),
};

const SelectionDropDown2 = ({
	onChange,
	...props
}) => {
	const [error, setError] = useState("");
	const [value, setValue] = useState("");
	const [field, meta] = useField(props);

	useEffect(() => {
		if (props.value) {
			setValue(props.value);
		}
		if (props.getValue) props.getValue(value);
	}, [props, value]);

	useEffect(() => {
		setError("");
	}, [value]);

	useEffect(() => {
		setError(props.error ? props.error : "");
	}, [props]);

	const handleChange = (event) => {
		if (onChange) onChange(event);
	};

	return (
		<div className="py-2">
			<Select
				{...field}
				className={`inputBaseInput ${props.className}  ${meta.touched && meta.error && "validationErrorBorder"
					}`}
				name={props.name}
				styles={customStyles}
				options={props.options}
				placeholder={props.placeholder}
				value={props.value || null}
				onChange={handleChange}
				maxMenuHeight={props.maxLength}
				components={props.components}
				isDisabled={props.isDisabled}
			/>
			<ErrorMessage
				component="div"
				name={field.name}
				className="validationError"
			/>
		</div>
	);
};

export default SelectionDropDown2;
