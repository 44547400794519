import React, { useEffect, useState } from "react";
import { SectionColumn, SectionRow } from "./../../layouts/sections/index";
import IFrameViewer from "../pdfPreview/IFrameViewer";
import ReviewFormWithSwitch from "../review/reviewFormWithSwitch";
import AcceptButton from "../buttons/acceptbutton";
import RejectButton from "../buttons/rejectButton";
import NextButton from "../buttons/nextButton";
import {
	activeStep,
	completeStep,
	rejectStep,
	resubmitStep,
	setCurrentStep,
} from "./../stepper/dynamicStepper";
import ResubmitButton from "../buttons/resubmitButton";
import RejectModel, { CERTIFICATION_BODY_REJECT_REASONS } from "../model/RejectModel";
import { ICON_PATHS } from "../../app/constants";
import { getStakeholderApplicationNumber } from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getApplicationRejectDetailsAll } from "../../Helpers/rejectionHelpers";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { validateFiles } from "../../features/certificationBody/dataManipulation";
import { Button } from "@mui/material";
import ConfirmationModel from "../model/ConfirmationModel";

const ReviewWrapperNOCU = ({
	index,
	title,
	topBar,
	setForm,
	formToggleSwitch,
	formData,
	fileButtons,
	currentFile,
	handleNext,
	handleApprove,
	handleResubmit,
	handleReject,
	additionalButtons,
	formTitle,
	stepperName,
	entityId,
	slaabSubmit,
	setDisabled,
	files,
	isDisabled,
	remark,
	canApprove,
	reviewed,
	readOnly,
	slaabSubmitDisable,
	lastStep,
	hasFieldVisit,
	reviewStatus,
	handleChange,
	comment,
	isFormEmpty,
	hideBtns,
}) => {
	const [isOpenResubmitPrompt, setIsOpenResubmitPrompt] = useState(false);
	const [isOpenRejectPrompt, setIsOpenRejectPrompt] = useState(false);
	const [isOpenSlaabReject, setIsOpenSlaabReject] = useState(false);
	const [isOpenFieldVisit, setIsOpenFieldVisit] = useState(false);
	const [color, setColor] = useState("error");

	const next = () => {
		if (lastStep && !hasFieldVisit && reviewStatus === "PENDING") {
			setIsOpenFieldVisit(true);
		} else {
			completeStep(stepperName, index);
			activeStep(stepperName, index + 1);
			setCurrentStep(stepperName, index + 1);
			handleNext();
		}
	};

	const submitWithoutFieldVisit = () => {
		completeStep(stepperName, index);
		activeStep(stepperName, index + 1);
		setCurrentStep(stepperName, index + 1);
		handleNext();
	};

	const resubmissionCheck = () => {
		if (getApplicationRejectDetailsAll().length === 0) {
			setIsOpenResubmitPrompt(true);
		} else {
			resubmit("");
		}
	};

	const resubmit = (comment) => {
		resubmitStep(stepperName, index);
		activeStep(stepperName, index + 1);
		setCurrentStep(stepperName, index + 1);
		handleResubmit(comment || "");
	};
	const reject = () => {
		rejectStep(stepperName, index);
		activeStep(stepperName, index + 1);
		setCurrentStep(stepperName, index + 1);
		handleNext();
	};

	return (
		<div>
			<RejectModel
				type={"RESUBMIT"}
				title={title}
				reasonsType={CERTIFICATION_BODY_REJECT_REASONS}
				handleReject={(comment) => {
					resubmit(comment);
				}}
				isOpen={isOpenResubmitPrompt}
				setIsOpen={setIsOpenResubmitPrompt}
			/>
			<RejectModel
				title={getStakeholderApplicationNumber()}
				reasonsType={CERTIFICATION_BODY_REJECT_REASONS}
				handleReject={(comment) => {
					handleReject(comment);
					// reject();
				}}
				isOpen={isOpenRejectPrompt}
				setIsOpen={setIsOpenRejectPrompt}
			/>
			<RejectModel
				title={getStakeholderApplicationNumber()}
				reasonsType={CERTIFICATION_BODY_REJECT_REASONS}
				handleReject={(comment) => {
					slaabSubmit("REJECTED", comment);
				}}
				isOpen={isOpenSlaabReject}
				setIsOpen={setIsOpenSlaabReject}
			/>
			<ConfirmationModel
				isOpen={isOpenFieldVisit}
				setIsOpen={setIsOpenFieldVisit}
				title={"Please provide the reason for skipping the field visit"}
				content={
					"Provide the reason for skipping the field visit. If you did not wish to skip the field visit, click Cancel and proceed to tick the checkbox 'Initiate Field Visit'"
				}
				color={color}
				handleClick={submitWithoutFieldVisit}
				handleChange={handleChange}
				remark={comment}
			/>
			{topBar && (
				<SectionRow className='w-full mx-auto px-10 overflow-x-auto customScrollBar '>
					{topBar}
				</SectionRow>
			)}
			<SectionRow className='w-full '>
				<SectionColumn className='w-full lg:w-1/2 min-h-[650px] lg:h-[75vh] items-center pb-10 overflow-y-auto customScrollBar pt-2'>
					{formToggleSwitch ? (
						<SectionRow className='w-full  items-center justify-center scale-100 lg:scale-90 xl:scale-100'>
							{formToggleSwitch}
						</SectionRow>
					) : (
						<SectionRow className='w-full h-[70px] items-center justify-center scale-100 lg:scale-90 xl:scale-100'></SectionRow>
					)}
					{remark && (
						<SectionColumn className='px-5 justify-start mb-5'>
							<SectionRow>
								<SectionRow className='w-full items-center'>
									<span className='mr-1'>Remark : </span>
									{remark}
								</SectionRow>
							</SectionRow>
						</SectionColumn>
					)}
					{isFormEmpty && (
						<SectionRow className='w-full justify-center'>
							<div className='text-center text-gray-700'>
								User skipped optional step for previous product details.
								<br />
								No information available. Please proceed accordingly.
								<br />
								Thank you.
							</div>
						</SectionRow>
					)}

					<SectionColumn className='px-5 scale-100 lg:scale-90 xl:scale-100 !h-[550px] overflow-y-auto justify-start customScrollBar'>
						{formData && (
							<ReviewFormWithSwitch
								files={files}
								readOnly={readOnly}
								setDisabled={setDisabled}
								data={formData}
								setForm={setForm}
								entityId={entityId}>
								{formTitle}
							</ReviewFormWithSwitch>
						)}
						<SectionRow className='w-full  mt-6'>{fileButtons}</SectionRow>
					</SectionColumn>
				</SectionColumn>
				<SectionColumn className='w-full lg:w-1/2 min-h-[75vh] z-10 '>
					<IFrameViewer path={currentFile?.path} files={true} />
					<div className='w-full text-center'>{currentFile?.name}</div>
				</SectionColumn>
			</SectionRow>
			{!hideBtns && (
				<SectionRow className='w-full pb-10'>
					<SectionRow className='w-full lg:w-1/2 justify-center'>{additionalButtons}</SectionRow>
					{!reviewed ? (
						<SectionRow className='w-full lg:w-1/2  justify-center'>
							{/* <AcceptButton
						className={""}
						handleClick={handleApprove}
						name='approveButton'
						title='Approve'
						disabled={false}
					/> */}
							{handleReject && (
								<div
									// className={`mr-10 ${isDisabled ? "" : "hover:cursor-pointer hover:brightness-75"} `}
									className={`mr-10 hover:cursor-pointer hover:brightness-75 `}
									onClick={() => {
										// !isDisabled &&
										setIsOpenRejectPrompt(true);
									}}>
									<img
										src={ICON_PATHS.trash}
										alt='Trash'
										className={`w-10

									`}
										// ${isDisabled ? "filter grayscale" : ""}
									/>
								</div>
							)}
							{handleResubmit && (
								<div>
									<ResubmitButton
										className={""}
										handleClick={() => {
											resubmissionCheck();
										}}
										name='rejectButton'
										title='Resubmit'
										disabled={isDisabled}
									/>
								</div>
							)}
							{handleNext && (
								<div>
									<AcceptButton
										className={""}
										handleClick={next}
										name='nextButton'
										title='Approve & Submit'
										color={""}
										disabled={!!!canApprove}
									/>
								</div>
							)}
							{slaabSubmit && (
								<div>
									<RejectButton
										className={""}
										handleClick={() => {
											slaabSubmit("REJECTED");
										}}
										name='rejectButton'
										title='Reject'
										disabled={slaabSubmitDisable}
									/>
								</div>
							)}
							{slaabSubmit && (
								<div>
									<AcceptButton
										className={""}
										handleClick={() => {
											slaabSubmit("APPROVED");
										}}
										name='nextButton'
										title='Approve'
										color={""}
										disabled={slaabSubmitDisable}
									/>
								</div>
							)}
						</SectionRow>
					) : (
						<SectionRow className='w-full lg:w-1/2  justify-center'>
							<div>
								<Button
									variant='contained'
									onClick={() => {
										activeStep(stepperName, index + 1);
										setCurrentStep(stepperName, index + 1);
										reviewed();
									}}>
									Next
								</Button>
							</div>
						</SectionRow>
					)}
				</SectionRow>
			)}
		</div>
	);
};

export default ReviewWrapperNOCU;
