import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import RoundedButton from "./RoundedButtons";
import TextField from "./TextField";
import * as Yup from "yup";
import TextFieldRegistration from "./TextFieldRegistration";
import { resetPassword } from "../../../app/api/authApi/authApi";
import { useAlert } from "../../../app/context/alerts";
import LoaderComponent from "../../../components/Loader/LoaderComponent";

const ResetPassword = ({ setResetData, resetData, email }) => {
	const { setAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(true);
	const handleSubmit = (values) => {
		setIsLoading(true);
		console.log(values);
		let _data = {
			username: email,
			tempPassword: values?.tempPassword,
			newPassword: values?.newPassword,
			confirmPassword: values?.confirmPassword,
		};
		resetPassword(_data)
			.then((res) => {
				setIsLoading(false);
				if (res.data?.responseCode === "00") {
					setAlert({
						message: "Password reset successfully",
						severity: "success",
						show: true,
					});
					setTimeout(() => window.location.reload(), 2500);
				} else if (res.data?.responseCode === "02") {
					setAlert({
						message: "Email Address doesn't match",
						severity: "error",
						show: true,
					});
				} else if (res.data?.responseCode === "03") {
					setAlert({
						message: "Tempory Password doesn't match",
						severity: "error",
						show: true,
					});
				}
				else {
					setAlert({
						message: "Please check the details",
						severity: "error",
						show: true,
					});
				}
			})
			.catch((error) => {
				console.log("Reset error", error);
			});
		setResetData({
			username: email,
			tempPassword: values?.tempPassword,
			newPassword: values?.newPassword,
			confirmPassword: values?.confirmPassword,
		});
		console.log("Reset data ==========>", resetData);
	};
	const initialValues = {
		tempPassword: "",
		newPassword: "",
		confirmPassword: "",
	};

	const validationSchema = Yup.object({
		tempPassword: Yup.string().required("Temporary Password is required"),
		newPassword: Yup.string()
			.min(6, "Password must be 6 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol")
			.required("Password is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("newPassword"), null], "Please make sure your passwords match")
			.required("Confirm Password is required"),
	});

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}>
				{(formik) => (
					<Form>
						<div className='flex flex-col justify-center items-center'>
							<TextFieldRegistration
								autoComplete='off'
								placeholder='Temporary Password'
								type='password'
								name='tempPassword'
								onChange={(e) =>
									setResetData({ ...resetData, tempPassword: e.target.value })
								}></TextFieldRegistration>
							<TextFieldRegistration
								placeholder='New Password'
								type='password'
								name='newPassword'
								onChange={(e) =>
									setResetData({ ...resetData, newPassword: e.target.value })
								}></TextFieldRegistration>
							<TextFieldRegistration
								placeholder='Confirm Password'
								type='password'
								name='confirmPassword'
								onChange={(e) =>
									setResetData({ ...resetData, confirmPassword: e.target.value })
								}></TextFieldRegistration>
							<RoundedButton
								className='loginBTN'
								onClick={() => {
									// handleSubmit();
								}}>
								Reset
							</RoundedButton>
						</div>
					</Form>
				)}
			</Formik>
		</div>

	);
};

export default ResetPassword;
