import React, { useEffect, useState } from "react";
import NavBar from "./../../../components/navbar/NavBar";
import { DASHBOARD_ICONS } from "./../../../Helpers/ICONS";
import { FARMER_ASSOCIATION_ROUTES } from "../../../app/router/routes";
import { SectionColumn } from "../../../layouts/sections";
import { Route } from "react-router-dom";

import { createStepper } from "../../../components/stepper/dynamicStepper";
import { getStepStatusByApplicationId } from "../../../app/api/NOCU/applicationAPI";
import { getStakeholderApplicationNumber } from "../../../app/api/NOCU/helpers/applicationApiHelpers";

import LoaderComponent from "../../../components/Loader/LoaderComponent";
import FarmerAssociationReviewStep1 from "./flow/FarmerAssociationReviewStep1";
import FarmerAssociationReviewStep2 from "./flow/FarmerAssociationReviewStep2";
import FarmerAssociationReviewStep3 from "./flow/FarmerAssociationReviewStep3";
import FarmerAssociationReviewStep4 from "./flow/FarmerAssociationReviewStep4";
import FarmerAssociationReviewStep5 from "./flow/FarmerAssociationReviewStep5";
import { convertStepperRes } from "./dataManipulation";
import { getApplicationStatusOnSession } from "../../certificationBody/dataManipulation";

export const stepperName = "FARMER_ASSOCIATION";

const FarmerAssociationReviewHome = () => {
	const [res, setRes] = useState();
	const [stepper, setStepper] = useState();
	const [stepperData, setStepperData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getStepStatusByApplicationId(getStakeholderApplicationNumber())
			.then((res) => {
				setRes(res.data);
				console.log("Data", res.data);
				console.log("Stepper=====>", convertStepperRes(res.data));
				setStepper(convertStepperRes(res.data));
			})
			.catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		stepper && setStepperData(createStepper(stepperName, stepper));
	}, [stepper]);

	const routeArray = [
		FARMER_ASSOCIATION_ROUTES.STEP01,
		FARMER_ASSOCIATION_ROUTES.STEP02,
		FARMER_ASSOCIATION_ROUTES.STEP03,
		FARMER_ASSOCIATION_ROUTES.STEP04,
		FARMER_ASSOCIATION_ROUTES.STEP05,
	];
	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<NavBar
				stepperName={stepperName}
				title={"Farmer Association"}
				tableIcon={DASHBOARD_ICONS.farmerAssociation}
				tableURL={FARMER_ASSOCIATION_ROUTES.HOME}
			/>

			{res && stepperData && (
				<SectionColumn className='w-full pt-1'>
					<Route path={FARMER_ASSOCIATION_ROUTES.STEP01}>
						<FarmerAssociationReviewStep1
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[0]?.reviewStatus === "APPROVED" || res[0]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={FARMER_ASSOCIATION_ROUTES.STEP02}>
						<FarmerAssociationReviewStep2
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[1]?.reviewStatus === "APPROVED" || res[1]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={FARMER_ASSOCIATION_ROUTES.STEP03}>
						<FarmerAssociationReviewStep3
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[2]?.reviewStatus === "APPROVED" || res[2]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={FARMER_ASSOCIATION_ROUTES.STEP04}>
						<FarmerAssociationReviewStep4
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[3]?.reviewStatus === "APPROVED" || res[3]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
					<Route path={FARMER_ASSOCIATION_ROUTES.STEP05}>
						<FarmerAssociationReviewStep5
							stepperData={stepperData}
							stepperName={stepperName}
							routeArray={routeArray}
							setIsLoading={setIsLoading}
							readOnly={
								getApplicationStatusOnSession()
									? false
									: res[4]?.reviewStatus === "APPROVED" || res[4]?.reviewStatus === "RESUBMIT"
									? true
									: false
							}
						/>
					</Route>
				</SectionColumn>
			)}
		</div>
	);
};

export default FarmerAssociationReviewHome;
