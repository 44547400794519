import React from "react";
import { SectionColumn, SectionRow } from "../../../components/section";
import { Button, Chip, Divider, Stack, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { getExtraDocReq, updateExtraDocReq } from "../../../app/api/NOCU/reqExtraDocAPI";
import {
	getMappedApplicationNumber,
	getUserId,
} from "../../../app/api/NOCU/helpers/applicationApiHelpers";
import { useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import FileButtonSet from "../../../components/buttons/FileButtonSet";
import { navigateNewTab } from "../../../app/router/routerManager";
import { getFileURL } from "../../../app/api/file_api";
import { ICON_PATHS } from "../../../app/constants";
import { useAlert } from "../../../app/context/alerts";
import ConfirmationPopup from "../../../components/model/ConfirmationPopup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import StringTooltip from "../../../components/tooltip/StringTooltip";
import LoaderComponent from "../../../components/Loader/LoaderComponent";

const DocListView = ({ title, editable, filterByStatus }) => {
	const [docList, setDocList] = useState([]);
	const [canSave, setCanSave] = useState(false);
	const [confirmPrompt, setConfirmPrompt] = useState(false);
	const { setAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const payload = {
			status: filterByStatus ? [filterByStatus] : ["ALL"],
			mappedApplicationNumber: getMappedApplicationNumber(),
		};
		getExtraDocReq(payload)
			.then((res) => {
				setDocList(res.data);
			})
			.then((e) => console.log(e));
	}, []);

	const updateDocList = (status, index) => {
		setCanSave(true);
		const _list = [...docList];
		_list[index].status = status;
		_list[index].notSaved = true;
		setDocList(_list);
	};
	const handleSave = () => {
		setIsLoading(true);
		const payload = {
			userId: getUserId(),
			docDetails: docList,
		};
		updateExtraDocReq(payload)
			.then((res) => {
				setAlert({
					message: res.data?.responseMessage
						? res.data?.responseMessage
						: res.data?.responseCode === "00"
						? "Requested Successfully"
						: "Request Error",
					severity: res.data?.responseCode === "00" ? "success" : "error",
					show: true,
				});
			})
			.catch((e) => console.log(e))
			.finally(() => {
				window.location.reload();
			});
	};

	return (
		<SectionColumn className='w-full'>
			<SectionRow className='w-full text-lg'>{title}</SectionRow>
			<Divider />
			<SectionRow className='w-full  text-base pl-10 pb-3 pt-5'>
				{docList.length === 0 && (
					<div className='w-full text-sm text-gray-600 text-center pr-10'>
						No extra documents requested from Technical Committee
					</div>
				)}
				<Stack className='w-full' spacing={0.5}>
					{docList.map((element, index) => {
						return (
							<SectionRow className='w-full' key={element?.extraDocReqId}>
								<SectionRow className='w-7/12'>
									<DescriptionIcon fontSize='small' className='mr-3' />
									<StringTooltip text={element?.fileName} length={25} />
								</SectionRow>
								<SectionRow className='w-3/12 items-center justify-center'>
									{editable ? (
										<Tooltip title='Click to view file in new tab'>
											<Chip
												size='small'
												className={element?.filePath ? "!cursor-pointer" : "!cursor-not-allowed"}
												label={
													element?.status === "SUBMITTED"
														? "Uploaded"
														: element?.status === "RESUBMITTED"
														? "Re-Uploaded"
														: element?.status === "TECH_COMMIT_REQ"
														? "Request"
														: element?.status === "TECH_COMMIT_REQ_RESUBMITTED"
														? "Re-request"
														: element?.status === "TECH_COMMIT_REQ_APPROVED"
														? "Pending Upload"
														: element?.status === "TECH_COMMIT_REQ_REJECTED"
														? "Request rejected"
														: element?.status === "CLIENT_SUBMISSION_APPROVED"
														? "View"
														: element?.status === "CLIENT_SUBMISSION_REJECTED"
														? "Pending Re-Upload"
														: "Pending"
												}
												color='primary'
												icon={<VisibilityIcon />}
												disabled={element?.filePath ? false : true}
												onClick={() => {
													navigateNewTab(getFileURL(element?.filePath));
												}}
											/>
										</Tooltip>
									) : (
										<Tooltip
											title={
												element?.status === "TECH_COMMIT_REQ_REJECTED"
													? "Document request was rejected by NOCU officer"
													: element?.status === "CLIENT_SUBMISSION_APPROVED"
													? "Click to view file in new tab"
													: ""
											}>
											<div>
												<Chip
													size='small'
													icon={<VisibilityIcon />}
													className={
														element?.status === "CLIENT_SUBMISSION_APPROVED"
															? "!cursor-pointer"
															: "!cursor-not-allowed"
													}
													label={
														element?.status === "CLIENT_SUBMISSION_APPROVED"
															? "View"
															: element?.status === "TECH_COMMIT_REQ_REJECTED"
															? "Rejected"
															: "Pending"
													}
													color={
														element?.status === "TECH_COMMIT_REQ_REJECTED" ? "error" : "primary"
													}
													disabled={element?.status === "CLIENT_SUBMISSION_APPROVED" ? false : true}
													onClick={() => {
														navigateNewTab(getFileURL(element?.filePath));
													}}
												/>
											</div>
										</Tooltip>
									)}
								</SectionRow>
								<SectionRow className='w-2/12 items-center'>
									{editable && (
										<SectionRow className='items-center'>
											{(element?.status === "TECH_COMMIT_REQ" ||
												element?.status === "TECH_COMMIT_REQ_RESUBMITTED") && (
												<SectionRow className='items-center'>
													<div className='ml-2'>
														<img
															className='cursor-pointer p-0.5'
															src={ICON_PATHS.rounded_check_mark}
															alt='check'
															width={24}
															height={24}
															onClick={() => {
																updateDocList("TECH_COMMIT_REQ_APPROVED", index);
															}}
														/>
													</div>
													<div className='ml-2'>
														<img
															className='cursor-pointer'
															src={ICON_PATHS.rounded_cancel_mark}
															alt='reject'
															onClick={() => {
																updateDocList("TECH_COMMIT_REQ_REJECTED", index);
															}}
														/>
													</div>
												</SectionRow>
											)}
											{(element?.status === "SUBMITTED" || element?.status === "RESUBMITTED") && (
												<SectionRow className='items-center'>
													<div className='ml-2'>
														<img
															className='cursor-pointer p-0.5'
															src={ICON_PATHS.rounded_check_mark}
															alt='check'
															width={24}
															height={24}
															onClick={() => {
																updateDocList("CLIENT_SUBMISSION_APPROVED", index);
															}}
														/>
													</div>
													<div className='ml-2'>
														<img
															className='cursor-pointer'
															src={ICON_PATHS.rounded_cancel_mark}
															alt='reject'
															onClick={() => {
																updateDocList("CLIENT_SUBMISSION_REJECTED", index);
															}}
														/>
													</div>
												</SectionRow>
											)}
											{(element?.status === "TECH_COMMIT_REQ_APPROVED" ||
												element?.status === "CLIENT_SUBMISSION_APPROVED") && (
												<div className='ml-2'>
													<img
														className={"cursor-not-allowed p-0.5"}
														src={ICON_PATHS.rounded_check_mark}
														alt='check'
														width={24}
														height={24}
													/>
												</div>
											)}
											{(element?.status === "TECH_COMMIT_REQ_REJECTED" ||
												element?.status === "CLIENT_SUBMISSION_REJECTED") && (
												<div className='ml-2'>
													<img
														className='cursor-not-allowed'
														src={ICON_PATHS.rounded_cancel_mark}
														alt='check'
														width={23}
														height={23}
													/>
												</div>
											)}
										</SectionRow>
									)}
								</SectionRow>
							</SectionRow>
						);
					})}
				</Stack>
				{docList.length !== 0 && editable && (
					<SectionRow className='w-full justify-end pr-20 pt-10'>
						<Stack direction={"row"} spacing={2}>
							<Button
								size='small'
								color='error'
								variant='outlined'
								onClick={() => {
									window.location.reload();
								}}>
								<span className='!capitalize'>Reset</span>
							</Button>
							<Button
								size='small'
								disabled={!canSave}
								variant='contained'
								onClick={() => {
									setConfirmPrompt(true);
								}}>
								<span className='!capitalize'>Save</span>
							</Button>
						</Stack>
					</SectionRow>
				)}
			</SectionRow>
			<ConfirmationPopup
				isOpen={confirmPrompt}
				setIsOpen={setConfirmPrompt}
				title={"Confirm Your Changes"}
				content={"Please confirm to save your changes !"}
				handleClick={() => {
					handleSave();
				}}
			/>
			<LoaderComponent isOpen={isLoading} />
		</SectionColumn>
	);
};

export default DocListView;
