import React, { useEffect } from "react";
import { useState } from "react";
import { getFileURL } from "../../app/api/file_api";
import { getApplicationDetails, submitFinalApplication } from "../../app/api/NOCU/applicationAPI";
import {
	getMappedApplicationNumber,
	getStakeholderType,
	getUserId,
	setStakeholderApplicationNumber,
	setStakeholderType,
	setViewOnlyPermission,
} from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { getMapApplicationDetails } from "../../app/api/NOCU/tableAPI";
import { useAlert } from "../../app/context/alerts";
import { navigateSelf } from "../../app/router/routerManager";
import {
	CERTIFICATION_BODY_ROUTES,
	FINAL_COMMENTS_ROUTES,
	TECHNICAL_COMMITTEE_ROUTES,
} from "../../app/router/routes";
import AcceptButton from "../../components/buttons/acceptbutton";
import FileArrayWrapper from "../../components/buttons/FileArrayWrapper";
import FileButtonSet from "../../components/buttons/FileButtonSet";
import NextButton from "../../components/buttons/nextButton";
import RejectButton from "../../components/buttons/rejectButton";
import ToggleButtonSet from "../../components/buttons/ToggleButtonSet";
import ImageWrapper from "../../components/ImagePreview/ImageWrapper";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import ConfirmationPopup from "../../components/model/ConfirmationPopup";
import NavBar from "../../components/navbar/NavBar";
import IFrameViewer from "../../components/pdfPreview/IFrameViewer";
import { SectionColumn, SectionRow } from "../../components/section";
import VotingTable from "../../components/table/VotingTable";
import { DASHBOARD_ICONS } from "../../Helpers/ICONS";
import FourContainersLayout from "../../layouts/page/fourContainers";
import { appendRejectionsFieldVisit, mapFiles } from "../certificationBody/dataManipulation";
import { deleteStepper } from "../../components/stepper/dynamicStepper";
import ConfirmationModel from "../../components/model/ConfirmationModel";
import TabHome from "../../components/Tabs/TabHome";
import ReviewCheckList from "../../components/review/reviewCheckList";
import ViewMoreButton from "../../components/buttons/ViewMoreButton";
import RequestExtraDocuments from "./components/RequestExtraDocuments";
import DocListView from "./components/DocListView";
// import { TECHNICAL_COMMITTEE_ROUTES } from './../../app/router/routes';

const TechnicalCommitteeReview = () => {
	const tableHeaders = ["Committee Member", "Vote Status"];

	const [_formData, _setFormData] = useState([
		{ name: "ch1", label: "Is ICS System Document Available ?", isChecked: true },
		{ name: "ch2", label: "Organic Inputs User ?", isChecked: true },
		{
			name: "ch3",
			label: "Seed plating and transplant records ...",
			isChecked: true,
		},
		{ name: "ch4", label: "Soil management activities", isChecked: true },
		{ name: "ch5", label: "Water use", isChecked: true },
		{
			name: "ch6",
			label: "Organic Integrity (Contamination Critical Control points",
			isChecked: true,
		},
		{ name: "ch7", label: "Wast Management", isChecked: true },
		{ name: "ch8", label: "Pest Management Activities", isChecked: true },
		{ name: "ch9", label: "Labels and labelling", isChecked: true },
		{ name: "ch10", label: "Storage", isChecked: true },
		{ name: "ch11", label: "Complain Record Available", isChecked: true },
	]);
	const [param, setParams] = useState({
		mappedApplicationNumber: getMappedApplicationNumber(),
		remark: "",
		reviewStatus: "",
		checkedBy: getUserId(),
		applicationStage: "TECHNICAL_COMMITTEE",
	});
	const [title, setTitle] = useState("Summery Report");
	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [color, setColor] = useState("success");
	const { setAlert } = useAlert();
	const [selectedFile, setSelectedFile] = useState();
	const [detailsArray, setDetailsArray] = useState();
	const [response, setResponse] = useState();
	const [applicationDetails, setApplicationDetails] = useState();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [fieldVisitArray, setFieldVisitArray] = useState();
	const [formData, setFormData] = useState();
	const [nameArray, setNameArray] = useState();
	const [_images, _setImages] = useState();
	const [_files, _setFiles] = useState();
	const [_acceptedFiles, _setAcceptedFiles] = useState();
	const [certification, setCertification] = useState();
	const [rejections, setRejections] = useState();

	useEffect(() => {
		const mapApplicationKey = getMappedApplicationNumber();

		getMapApplicationDetails(mapApplicationKey)
			.then((res) => {
				setResponse(res.data);
				setDetailsArray(res.data.detailsResponseList);
				setFormData(res.data.detailsResponseList[currentIndex]);
				setFieldVisitArray(res.data.detailsResponseList[currentIndex].fieldVisitFileEntities);
				setApplicationDetails(
					res.data.detailsResponseList[currentIndex].stakeholderApplicationEntity
				);
				setStakeholderApplicationNumber(
					res.data.detailsResponseList[currentIndex]?.stakeholderApplicationEntity
						?.applicationNumber
				);
				setStakeholderType(
					res.data.detailsResponseList[currentIndex]?.stakeholderApplicationEntity?.stakeholderType
				);
				setRejections(res.data.detailsResponseList[currentIndex].fieldVisitReviewDetails);
				deleteStepper("TCView");

				setIsLoading(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	const navigateRoutes = () => {
		deleteStepper("FCView");
		console.log("StakeholderType", getStakeholderType());
		if (getStakeholderType() === "CERTIFICATION_BODY") {
			navigateSelf(TECHNICAL_COMMITTEE_ROUTES.CBSTEP01);
		} else if (getStakeholderType() === "IMPORTER") {
			navigateSelf(TECHNICAL_COMMITTEE_ROUTES.IMSTEP01);
		} else if (getStakeholderType() === "FARMER") {
			navigateSelf(TECHNICAL_COMMITTEE_ROUTES.FRSTEP01);
		} else if (getStakeholderType() === "FARMER_ASSOCIATION") {
			navigateSelf(TECHNICAL_COMMITTEE_ROUTES.FASTEP01);
		} else if (getStakeholderType() === "LABORATORIES") {
			navigateSelf(TECHNICAL_COMMITTEE_ROUTES.LBSTEP01);
		} else if (getStakeholderType() === "EXPORTER") {
			navigateSelf(TECHNICAL_COMMITTEE_ROUTES.EXPORTER_STEP01);
		} else if (getStakeholderType() === "PROCESSOR") {
			navigateSelf(TECHNICAL_COMMITTEE_ROUTES.PROCESSOR_STEP01);
		} else if (getStakeholderType() === "TRADER") {
			navigateSelf(TECHNICAL_COMMITTEE_ROUTES.TRADER_STEP01);
		}
	};

	useEffect(() => {
		setNameArray(mapMultiForms(detailsArray));
	}, [detailsArray]);

	useEffect(() => {
		_formData &&
			rejections &&
			appendRejectionsFieldVisit(rejections?.rejectReasonDetailList, _formData, _setFormData);
	}, [rejections]);

	useEffect(() => {
		// Execute when updating
		let images = [];
		fieldVisitArray?.map((item) =>
			images.push({
				name: item?.name,
				path: getFileURL(item?.path),
			})
		);
		_setImages(images);
	}, [fieldVisitArray]);

	const handleSubmit = () => {
		setIsLoading(true);
		submitFinalApplication(param)
			.then((res) => {
				navigateSelf(TECHNICAL_COMMITTEE_ROUTES.HOME + "/1");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handleChange = (value) => {
		setParams({ ...param, remark: value });
	};

	const mapMultiForms = (data) => {
		return Array.isArray(data) && data.length !== 0
			? data.map((item) => {
					return {
						name: item?.stakeholderApplicationEntity.applicationNumber,
						id: item?.stakeholderApplicationEntity.applicationNumber,
						type: item?.stakeholderApplicationEntity.stakeholderType,
					};
			  })
			: null;
	};

	const handleCertificate = (index) => {
		let detailArray = detailsArray;
		setFieldVisitArray(detailArray[index].fieldVisitFileEntities);
		setApplicationDetails(detailArray[index]?.stakeholderApplicationEntity);
		setCurrentIndex(index);
		setStakeholderApplicationNumber(
			detailArray[index]?.stakeholderApplicationEntity?.applicationNumber
		);
		setStakeholderType(detailArray[index]?.stakeholderApplicationEntity?.stakeholderType);
		deleteStepper("TCView");
		//navigateSelf(TECHNICAL_COMMITTEE_ROUTES.STEP01);
	};

	const renderCheckList = (_formData) => {
		return (
			<SectionColumn className='lg:h-[65vh] px-3 lg:overflow-y-auto customScrollBar'>
				{applicationDetails?.isReferFieldVisit ? (
					<ReviewCheckList
						readOnly={true}
						data={_formData}
						setForm={_setFormData}></ReviewCheckList>
				) : (
					<div className='w-full text-center'>No Field Visit Initiated</div>
				)}
			</SectionColumn>
		);
	};

	const componentArray = [
		{
			label: "Factory Visit Details",
			component: <div>{formData && renderCheckList(_formData)}</div>,
		},
		{
			label: "Factory Visit Photos",
			component: <ImageWrapper data={_images} />,
		},
	];

	return (
		<div>
			<NavBar
				title={"Technical Committee"}
				tableIcon={DASHBOARD_ICONS.technicalCommittee}
				tableURL={TECHNICAL_COMMITTEE_ROUTES.HOME}
				mappedApplicationNumber
			/>
			<FourContainersLayout
				topMiddle={
					<ToggleButtonSet data={nameArray} handleClick={handleCertificate} certificate={true} />
				}
				topLeft={
					<SectionColumn className='w-full items-center'>
						<div className='w-[350px] mt-2 shadow-md rounded-md bg-slate-100 border p-5'>
							<div className='w-full text-sm'>NOCU Remark :</div>
							<div className='text-gray-500  '>{response?.finalComment || "---"}</div>
						</div>
						<div className='w-[350px] mt-5 shadow-md rounded-md bg-slate-100 border p-5'>
							<div className='w-full text-sm'>Field Visit Remark :</div>
							<div className='text-gray-500  '>
								{applicationDetails?.fieldVisitRemark || "---"}{" "}
							</div>
						</div>
						<SectionColumn className='w-full justify-center mt-7'>
							{/* <SectionRow className='mb-5'>
								<FileArrayWrapper
									files={_acceptedFiles}
									setFiles={_setAcceptedFiles}
									selectedFile={selectedFile}
									setSelectedFile={setSelectedFile}
									setTitle={setTitle}
								/>
							</SectionRow> */}

							<SectionRow className='justify-center'>
								<DocListView title={"Requested Documents"} />
							</SectionRow>
							<SectionRow className='justify-center'>
								<RequestExtraDocuments
									title={"Request extra documents from client"}
									btn={
										<ViewMoreButton
											name={"viewDetails"}
											title={"Request Extra Documents"}
											handleClick={() => {
												deleteStepper("TCView");
											}}
										/>
									}
								/>
								<ViewMoreButton
									name={"viewDetails"}
									title={"View More..."}
									handleClick={() => {
										deleteStepper("TCView");
										navigateRoutes();
									}}
								/>
							</SectionRow>

							<SectionRow className='w-full justify-center mt-5'>
								<RejectButton
									className={""}
									handleClick={() => {
										setColor("error");
										setIsOpen(true);
										setParams({ ...param, reviewStatus: "REJECTED" });
									}}
									name='rejectButton'
									title='VOTE NO'
									disabled={false}
								/>

								<AcceptButton
									className={""}
									handleClick={() => {
										setColor("success");
										setIsOpen(true);
										setParams({ ...param, reviewStatus: "APPROVED" });
									}}
									name='nextButton'
									title='VOTE YES'
									color={""}
								/>
							</SectionRow>
						</SectionColumn>
					</SectionColumn>
				}
				topRight={
					<SectionColumn className='w-full items-center z-30'>
						{/* <div className='w-full text-center'>{title}</div>
						{title === "Factory Visit Photos" ? (
							renderPhotos(files)
						) : (
							<IFrameViewer path={getFileURL(response?.summeryReportPath)} files={true} />
						)} */}

						<TabHome componentArray={componentArray} />
					</SectionColumn>
				}
			/>

			<ConfirmationModel
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={`Please provide the reason for voting ${color === "error" ? "No" : "Yes"}`}
				content={`Provide the reason for voting ${
					color === "error" ? "No" : "Yes"
				}. If you did not wish to vote ${
					color === "error" ? "No" : "Yes"
				}, click Cancel and proceed with the corrected choice.`}
				color={color}
				handleClick={handleSubmit}
				handleChange={handleChange}
			/>
			<LoaderComponent isOpen={isLoading} />
		</div>
	);
};

export default TechnicalCommitteeReview;
