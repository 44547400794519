export const customStyleSelect = {
    control: (provided, state) => ({
        ...provided,
        background: "#fff",
        border: 0,
        borderRadius: 10,
        // This line disable the blue border
        boxShadow: "none",
        minHeight: "30px",
        height: "30px",
        boxShadow: state.isFocused ? null : null,
        fontSize: '12px',
        fontFamily: 'Arial, sans-serif',
        width: '286px',
    }),


    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#00A651" : "#fff",
    }),
    indicatorSeparator: (state) => ({
        display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "30px",
    }),
    input: (provided, state) => ({
        // ...provided,
        height: "25px",
        width: "286ppx",
        paddingLeft: "3px",


    }),
    placeholder: (provided) => ({
        ...provided,
        position: "absolute",
        color: "#5C5C5C",
        fontSize: 13,
    }),
    singleValue: (provided) => ({
        ...provided,
        position: "absolute",
        color: "#000000",

    }),
};


