import React from "react";
import { SectionColumn } from "../section";
import { stringCrop } from "../../Helpers/stringCrop";
import { ICON_PATHS } from "../../app/constants";

const ImagePreviewCard = ({ index, file, deleteImage }) => {
  return (
    <SectionColumn className="relative  w-[150px] mx-4 my-4  hover:bg-gray-200 bg-gray-100 h-[200px] rounded-3xl shadow-lg text-center items-center justify-center overflow-clip">
      <div
        className="absolute top-3 right-3 cursor-pointer"
        onClick={() => deleteImage(index)}
      >
        <img
          src={ICON_PATHS.rounded_cancel_mark}
          alt="Remove"
          className="bg-white rounded-full"
        />
      </div>
      <SectionColumn className="w-full h-[160px] items-center justify-center">
        {file && (
          <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
            <img
              src={URL.createObjectURL(file)}
              alt="UploadedImage"
              className="object-fill w-full"
            />
          </a>
        )}
      </SectionColumn>
      <SectionColumn className="w-full h-[40px] bg-white text-center text-xs">
        {stringCrop(file?.name, 35)}
      </SectionColumn>
    </SectionColumn>
  );
};

export default ImagePreviewCard;
