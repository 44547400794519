import React from "react";
import { SectionRow } from "../../components/section";
import { SectionColumn } from "../sections";

const FourContainersLayout = ({ topMiddle, topRight, topLeft, bottomRight, bottomLeft, bottomCenter }) => {
	return (
		<div>
			<SectionRow className='w-full mt-5 '>
				<SectionColumn className='w-full h-fit  lg:w-1/2 min-h-[650px] lg:h-[80vh] items-center pb-5 lg:overflow-y-auto customScrollBar pt-2'>
					<div className="">
						{topMiddle}
					</div>
					<div className="">
						{topLeft}
					</div>

				</SectionColumn>
				<SectionColumn className='w-full h-fit  lg:w-1/2 min-h-[650px] lg:h-[80vh] items-center pb-5 lg:overflow-y-auto customScrollBar pt-2'>
					{topRight}
				</SectionColumn>
			</SectionRow>
			{(bottomLeft || bottomRight) && (
				<SectionRow className='w-full mt-5'>
					<SectionColumn className='w-1/2 justify-center items-center'>{bottomLeft}</SectionColumn>
					<SectionColumn className='w-1/2 justify-center items-center'>{bottomRight}</SectionColumn>
				</SectionRow>
			)}
			{bottomCenter && (
				<SectionRow className='w-full mt-5 pb-10 justify-center items-center'>
					{bottomCenter}
				</SectionRow>
			)}
		</div>
	);
};

export default FourContainersLayout;
