import React from "react";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { SectionRow } from "../../layouts/sections";
import StatCard from "../../components/cards/statCard";
import { useState } from "react";
import Eye from "@mui/icons-material/Visibility";
import { STAT_ACCENT_COLORS, STAT_ICON_PATHS } from "../../app/constants";
import { useParams } from "react-router";
import HomeTable from "../../components/table/HomeTable";
import { getUserId, setStakeholderType } from "../../app/api/NOCU/helpers/applicationApiHelpers";
import { showViewIcon } from "../../components/table/tableHelpers";
import { useEffect } from "react";
import {
	getCountsForCateRegTable,
	getRecordsForCateRegTable,
} from "../../app/api/NOCU/productCategoryAPI";
import { deleteProductCategoryID, getTableData, setProductCategoryId } from "./dataHelper";
import FullScreenDialog from "../../components/model/FullScreenDialog";
import { Button } from "@mui/material";
import ProductCategoryReview from "./productCategoryReview";

const ProductCategoryHome = () => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState();
	const [records, setRecords] = useState();
	const [tableCount, setTableCount] = useState();
	const [counts, setCounts] = useState({
		pendingCount: 0,
		approvedCount: 0,
		rejectedCount: 0,
		resubmissionCount: 0,
		resubmitPendingCount: 0,
	});
	const [params, setParams] = useState({
		status: "",
		isAscending: true,
		page: 0,
		size: 5,
		officerId: getUserId(),
		applicationStage: "PRODUCT_CATEGORY_REVIEW",
		productCategoryName: "",
		userFullName: "",
	});
	const [selectedStatCard, setSelectedStatCard] = useState(1);
	useEffect(() => {
		setStakeholderType("");
		const _params = {
			applicationStage: "PRODUCT_CATEGORY_REVIEW",
			officerId: getUserId(),
			productCategoryName: "",
			userFullName: "",
		};
		getCountsForCateRegTable(_params)
			.then((res) => {
				setCounts(res.data);
				setTableCount(res.data?.pendingCount);
			})
			.catch((e) => console.log(e));
	}, []);
	const tableHeaders = ["Category Name", "Contact Person", "Contact No.", "Email", "Date & Time"];

	const handleFilter = (filter) => {
		setParams({ ...params, status: filter });
	};
	const handleSearchById = (id) => {
		setParams({ ...params, userFullName: id });
	};

	const handleSorting = (isAscending) => {
		setParams({ ...params, isAscending: isAscending });
	};
	const handlePage = (page) => {
		setParams({ ...params, page: page });
	};
	const handleSize = (size) => {
		setParams({ ...params, size: size });
	};

	const viewFunction = (index) => {
		// console.log("Index Of record=================>", index);
		// console.log("Clicked record", response?.categoryResponses[index]?.productCategoryId);
		setProductCategoryId(response?.categoryResponses[index]?.productCategoryId);
	};
	const historyFunction = (index) => {
		console.log("Index Of record=================>", index);
	};

	useEffect(() => {
		setSelectedStatCard(parseInt(id));
		if (parseInt(id) === 1) {
			setParams({ ...params, status: "PENDING" });
			setTableCount(counts.pendingCount);
		} else if (parseInt(id) === 2) {
			setParams({ ...params, status: "APPROVED", isAscending: false });
			setTableCount(counts.approvedCount);
		} else if (parseInt(id) === 3) {
			setParams({ ...params, status: "REJECTED", isAscending: false });
			setTableCount(counts.rejectedCount);
		}
	}, [id, counts]);

	useEffect(() => {
		setIsLoading(true);
		console.log("Params ====>", params);
		if (params.status && params.status !== "") {
			getRecordsForCateRegTable(params)
				.then((res) => {
					console.log("Res ======> ", getTableData(res.data));
					setRecords(getTableData(res.data));
					setResponse(res.data);
				})
				.then((e) => console.log(e))
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [params]);

	return (
		<div>
			<LoaderComponent isOpen={isLoading} />
			<SectionRow className='mt-0'>
				<span className='overViewTitle'>Product Category Registration</span>
			</SectionRow>
			<SectionRow className='w-full overflow-x-auto mb-5 customScrollBar'>
				<SectionRow className='w-full !min-w-max mb-2 justify-center items-center'>
					<StatCard
						value={counts.pendingCount}
						text={"Pending Category Registration"}
						image={STAT_ICON_PATHS.stat_new_req}
						accent={STAT_ACCENT_COLORS.bluishGray}
						handleStatCard={() => {}}
						num={1}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>

					<StatCard
						value={counts.approvedCount}
						text={"Approved Category Registration"}
						image={STAT_ICON_PATHS.stat_checkMark}
						accent={STAT_ACCENT_COLORS.lightGreen}
						handleStatCard={() => {}}
						num={2}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>

					<StatCard
						value={counts.rejectedCount}
						text={"Rejected Category Registration"}
						image={STAT_ICON_PATHS.stat_deleteMark}
						accent={STAT_ACCENT_COLORS.red}
						handleStatCard={() => {}}
						num={3}
						selected={selectedStatCard}
						setSelectStatCard={setSelectedStatCard}
					/>
				</SectionRow>
			</SectionRow>
			<HomeTable
				customViewIcon={
					<FullScreenDialog
						title={"Review Product Category Registration"}
						button={<Eye />}
						children={<ProductCategoryReview />}
						onClose={deleteProductCategoryID}
					/>
				}
				applicationKey={1}
				columns={tableHeaders}
				handleSearchById={handleSearchById}
				customSearchPlaceHolder={"Contact Person"}
				rows={records}
				view={showViewIcon(["1"], id) ? viewFunction : null}
				// history={historyFunction}
				// filters={filters}
				setFilter={handleFilter}
				// sort={sorting}
				handleSort={handleSorting}
				handleSize={handleSize}
				count={tableCount}
				page={params.page}
				size={params.size}
				handlePage={handlePage}
			/>
		</div>
	);
};

export default ProductCategoryHome;
